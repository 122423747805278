/*globals  $*/
/*jshint esversion:11*/
/*jshint module:true */

import core from "./idbcore.js";
import config from "./config.js";
import bundle from "./lang/t.js";

function getAlertFaviconUrl(faviconUrl) {
    let re = /(\/)([^\/\.]+)(\.)/;

    if(!faviconUrl || !re.test(faviconUrl)) {
        return faviconUrl;
    }

    return faviconUrl.replace(re, "$1$2_alert$3");

}
        var initialized = false;
        var cfg = config.config, contextRoot = cfg.contextRoot,
            $favicon = $("link[rel$=icon]"),
            faviconUrl = $favicon.attr("href") || null,
            faviconAlertUrl = getAlertFaviconUrl(faviconUrl),
            $pageTitle = $("title"),
            pageTitleText = $pageTitle.text(),
            A_KEY = "A:" + contextRoot,
            C_KEY = "C:" + contextRoot,
            K_KEY = "K:" + contextRoot,
            internal = {},
            timeoutSeconds = cfg.timeoutSeconds || 0,
            enabled = timeoutSeconds > 0,
            exports, sessionTimeoutMgr,
            log = function() {
                if(!config.config.dli) {
                    return;
                }
                var args = Array.prototype.slice.call(arguments);
                args.unshift("TIMEOUT:");
                console.log.apply(console, args);

            },
            countdownSecondsFromTimeoutSeconds = function(ts, minCountdown) {
                var c = Math.floor(ts/5);
                minCountdown = Math.min(ts-5, (minCountdown || 30));
                return Math.max(minCountdown, Math.min(300, c - (c%minCountdown)));
            },

            initModule = function(maybeTimeoutSeconds, maybeMinCountdown) {
                if(initialized || !enabled) {
                    return;
                }
                var ts = maybeTimeoutSeconds || timeoutSeconds,
                    countdownSeconds = countdownSecondsFromTimeoutSeconds(ts,  maybeMinCountdown),
                    warningSeconds = ts - countdownSeconds;
                sessionTimeoutMgr = new internal.SessionTimeoutMgr(ts, warningSeconds);
                initialized = true;
            };

        
/*globals internal, config, $, log */
/*jshint strict:implied */

internal.SessionTimeoutMgr = function SessionTimeoutMgr(timeoutSeconds, warningSeconds) {
    var me = this;

    log("SessionTimeoutMgr, timeoutSeconds=" + timeoutSeconds + 
        ", warningSeconds=" + warningSeconds +
        ", countdownSeconds=" + (timeoutSeconds-warningSeconds));

    // timeoutSeconds is the maximum number of seconds that
    // may elapse between requests to the server, to keep the server
    // session alive. This is 60 seconds less than the actual configured
    // server timeout.
    me._timeoutSeconds = timeoutSeconds;


    // This is the number of seconds of inactivity that can elapse before
    // the warning countdown begins, and the warning countdown is shown to the user.
    me._warningSeconds = warningSeconds;

    // When this is set to false through the setter, the inactivity timer will
    // not be run. The keepalive timer will keep the server session from timing out,
    // so the session will effectively never time out as long as the user stays on 
    // the page. 
    me._inactivityTimeoutEnabled = true;

    me._delayedCountdown = false;

    me._pingRetries = 0;

    me._loggedOut = false;

    me._build();

};

internal.SessionTimeoutMgr.prototype = {
    _build:function() {
        var me = this;
        me._inactivityTimer = new internal.InactivityTimer(me, me._warningSeconds);
        me._keepAliveTimer = new internal.KeepAliveTimer(me, me._timeoutSeconds);
        me._countdownTimer = new internal.CountdownTimer(me, me._timeoutSeconds - me._warningSeconds);

        if(config.config.dli) {
            window.idbCountdown = function() {
                setTimeout(me._countdownTimer.start.bind(me._countdownTimer),  3000);
                return "Countdown will start in 3 seconds.";
            };
        }
    },

    /**
     * This is called by the InactivityTimer when warningSeconds has
     * elapsed without any keyboard or mouse activity. The skewMs is 
     * the number of milliseconds between the time the timer was 
     * expected to expire, and the time it actually did. A large 
     * value is likely an indication that the PC went to sleep. 
     */
    _onInactivityTimeout:function(skewMs) {
        var me = this, keepAlive = me._keepAliveTimer;
        log("Inactivity time has expired. Skew = " + skewMs + " milliseconds.");
        this._inactivityTimer.stop();


        if(skewMs < 30000) {
            me._countdownTimer.start();
        }
        else {
            me._delayedCountdown = true;
            if(keepAlive._pingInProgress) {
                log("ping already in progress.");
                return;    
            }
            keepAlive.stop();
            keepAlive._sendPing();
        }
    },

    _onPingTimeout:function() {
        var me = this;
        log("Ping failed with timeout");
        me._sendLogout();
    },

    _onPingSuccess:function() {
        var me = this;
        me._pingRetries = 0;
        if(me._delayedCountdown) {
            me._delayedCountdown = false;
            me._countdownTimer.start();
        }
    },

    _onPingFailure:function() {
        var me = this;
        me._pingRetries++;
        if(me._pingRetries < 10) {
            setTimeout(function() {
                me._keepAliveTimer._sendPing();
            }, 1000);
        }
        else {
            console.error("TIMEOUT:", "10 consecutive pings have failed.");
            if(me._delayedCountdown) {
                me._delayedCountdown = false;
                me._sendLogout();
            }
        }
    },



    _onCountdownComplete:function() {
        var me = this;
        log("Countdown complete.");
        me._keepAliveTimer.destroy();
        me._sendLogout();
    },

    _onCountdownInterrupted:function() {
        log("Countdown interrupted.");
        this._inactivityTimer.start();
    },
    resetKeepAlive:function() {
        this._keepAliveTimer.reset();
    },
    _sendLogout:function() {
        var me = this, req = {},
            reload = function() {
                let url = window.location.href;
                if(url.includes("knowledgebase") && !url.includes("logout")) {
                    if(url.includes("?")) {
                        url += "&";
                    }
                    else {
                       url += "?"; 
                    } 
    
                    url += "logout=true";
                }
                window.location.replace(url);
            };
        $.ajax({
             url:config.config.dispatchPath + "session/inactivityLogout",
             dataType:"json",
             type:"POST",
             contentType:"text/json; charset=UTF-8",
             data:JSON.stringify(req),
             success:reload,
             error:reload
             });
        me._loggedOut = true;
    },
    setInactivityTimeoutEnabled:function(b) {
        var me = this;
        log("setInactivityTimeoutEnabled: " + b);
        me._inactivityTimeoutEnabled = !!b;
        if(!me._inactivityTimeoutEnabled) {
            me._inactivityTimer.removeListeners(); 
            me._inactivityTimer.stop(); 
        }
        else {
            me._inactivityTimer.start();    
        }
    },
    isLoggedOut:function() {
        return this._loggedOut;
    }

};

/*global internal, config, $, log, K_KEY */

"use strict";

internal.KeepAliveTimer = function KeepAliveTimer(sessionTimeoutMgr, timeoutSeconds) {
    var me = this;
    me._sessionTimeoutMgr = sessionTimeoutMgr;
    me._timeoutSeconds = timeoutSeconds;
    me._timerId = 0;
    me._lastPing = 0;
    me._pingInProgress = false;
    me._destroyed = false;
    me._build();
};

internal.KeepAliveTimer.prototype = {
    _build:function() {
        var me = this, cfg = config.config;
        me._pingUrl = cfg.contextRoot + "ping";
        me._timeoutMs = me._timeoutSeconds * 1000;
        me.reset();

    },
    _getNextPingTime:function() {
        var s = localStorage.getItem(K_KEY), n = Number(s);
        if(!s || isNaN(n)) {
            return 0;
        }
        return n;
    },
    _sendPing:function() {
        var me = this, now = Date.now(),
            scheduledPingTime = me._getNextPingTime(),
            diff = scheduledPingTime - now;
        if(me._destroyed) {
            return;
        }

        // is the scheduled ping time more than 60 seconds in the future?
        if(diff > 60000) {
            // Another tab called reset after this one. Set this timer for
            // 30 seconds after that one to make sure the ping gets sent.
            // The other tab may have closed by then.
            log("Delaying ping until " + new Date(now + diff + 30000) );
            me.stop();
            localStorage.setItem(K_KEY, now + diff + 30000);
            me._timerId = setTimeout(me._sendPing.bind(me),  diff + 30000);
            return;
        }

        $.ajax({
             url:me._pingUrl,
             dataType:"json",
             type:"GET",
             success:me._onPingComplete.bind(me),
             error:function(){
                me._pingInProgress = false;
                var args = Array.prototype.slice.call(arguments);
                args.unshift("PING FAILED");
                args.unshift("TIMEOUT:");
                console.error.apply(console, args);
                me._sessionTimeoutMgr._onPingFailure();
             }
             });
         me._pingInProgress = true;
    },
    _onPingComplete:function(dp) {
        var me = this, mgr = me._sessionTimeoutMgr, conf = (dp && dp.confirmation) || null;
        me._pingInProgress = false;
        log("_onPingComplete", dp);
        if(me._destroyed) {
            return;
        }
        if(conf === "ok") {
            me._lastPing = Date.now();
            me.reset();
            mgr._onPingSuccess();
        }
        else if(conf === "timeout") {
            mgr._onPingTimeout();
        }
        else {
            console.error("ping failed", dp);
        }
    },
    reset:function() {
        var me = this;
        if(me._destroyed) {
            return;
        }
        me.stop();
        localStorage.setItem(K_KEY, Date.now() + me._timeoutMs);
        me._timerId = setTimeout(me._sendPing.bind(me),  me._timeoutMs);
    },
    stop:function() {
        var me = this;
        if(me._timerId > 0) {
            clearTimeout(me._timerId);
            me._timerId = 0;
        }
    },
    destroy:function() {
        var me = this;
        me.stop();
        me._destroyed = true;
    }

};

/*global internal, A_KEY, log */

"use strict";

internal.ACTIVITY_EVENTS = ["mousemove", "mousedown", "keypress"];

internal.InactivityTimer = function(sessionTimeoutMgr, warningSeconds) {
    var me = this;
    me._sessionTimeoutMgr = sessionTimeoutMgr;
    me._warningSeconds = warningSeconds;
    me._timerId = 0;
    me._resetTime = me._expireTime = 0;
    me._build();
};

internal.InactivityTimer.prototype = {
    _build:function() {
        var me = this;

        me._warningMs = me._warningSeconds * 1000;
        me._resetCaller = me._reset.bind(me);
        me._onTimeoutCaller = me._onTimeout.bind(me);
        me.start();
    },
    _onTimeout:function() {
        var me = this, now = Date.now(), expectedExpireTime = me._getExpectedExpireTime(),
            diff = expectedExpireTime - now, 
            skew = now - (me._resetTime + me._warningMs);
        me.stop();
        log("diff", diff);
        if(diff > 100) {
            // activity happening in another tab. Sync this timer
            // with that one.
            me._resetTime = Date.now();
            me._timerId = setTimeout(me._onTimeoutCaller, diff);
            return;
        }

        me.removeListeners();
        me._sessionTimeoutMgr._onInactivityTimeout(skew);
    },
    _getExpectedExpireTime:function() {
        var me = this, s = localStorage.getItem(A_KEY), n = Number(s);
        if(!s || isNaN(n)) {
            return me._resetTime + me._warningMs;
        }
        return n;
    },
    _reset:function() {
        var me = this;
        me.stop();
        me._resetTime = Date.now();
        localStorage.setItem(A_KEY, me._resetTime + me._warningMs);
        me._timerId = setTimeout(me._onTimeoutCaller, me._warningMs);
    },
    stop:function() {
        var me = this;
        if(me._timerId > 0) {
            clearTimeout(me._timerId);
            me._timerId = 0;
        }
    },
    start:function() {
        var me = this;
        me.stop();
        me.addListeners();
        me._reset();
    },
    addListeners:function() {
        var me = this, resetCaller = me._resetCaller,
            evts = internal.ACTIVITY_EVENTS;

        // Insure there are never duplicate listeners.
        me.removeListeners();

        evts.forEach(function(evt){
            document.addEventListener(evt, resetCaller, true);
        });
    },
    removeListeners:function() {
        var me = this, resetCaller = me._resetCaller,
            evts = internal.ACTIVITY_EVENTS;

        evts.forEach(function(evt){
            document.removeEventListener(evt, resetCaller, true);
        });
    }

};

/*global internal, core, $, bundle, log, contextRoot, C_KEY, $favicon, faviconUrl, $pageTitle, pageTitleText, faviconAlertUrl */

"use strict";

internal.CountdownTimer = function CountdownTimer(sessionTimeoutMgr, countdownSeconds) {
    var me = this;
    me._sessionTimeoutMgr = sessionTimeoutMgr;
    me._countdownSeconds = countdownSeconds;
    me._elapsedSeconds = 0;
    me._startTime = 0;
    me._completionTime = 0;
    me._isRunning = false;
    me._expired = false;
    localStorage.setItem(C_KEY, 0);
    // When the tab is in the background, Chrome will not 
    // reliably execute timers that are slightly more or less than
    // 1000 ms, unless the code is running in a web worker.
    // The other timers, like the keep alive timer and the inactivity timer,
    // that are set for much longer intervals, seem to work reliably in
    // background tabs.
    me._ticker = new Worker(new URL("../ticker.js", import.meta.url)); // See https://webpack.js.org/guides/web-workers/
    me._ticker.onmessage = function(e) {
        if(e.data[0] === "tick") {
            me._onTick(e.data[1]);
        }
    };
    me._build();
};

internal.CountdownTimer.prototype = {
    _build:function() {
        var me = this;
        me._onUserResponseCaller = me._onUserResponse.bind(me);
    },
    _onTick:function(elapsedSeconds) {
        var me = this,
            remainingSeconds = me._countdownSeconds - elapsedSeconds;

        me._elapsedSeconds = elapsedSeconds;

        if("0" === localStorage.getItem(C_KEY)) {
            // countdown was interrupted in another tab.
            me._onUserResponse();
            return;
        }
        me._updateCountdown(remainingSeconds);
        if(remainingSeconds <= 0) {
            me._expired = true;
            me._sessionTimeoutMgr._onCountdownComplete();
            return;
        }
    },
    _onUserResponse:function() {
        var me = this;
        localStorage.setItem(C_KEY, 0);
        if(me._expired) {
            return;
        }
        log("User response received.");
        me.stop();
        me.$countdownDiv.remove();
        me._sessionTimeoutMgr._onCountdownInterrupted();

        $favicon.attr({href:faviconUrl});
        $pageTitle.text(pageTitleText);

    },
    start:function() {
        var me = this;
        if(me._isRunning) {
            return;
        }
        me._isRunning = true;
        localStorage.setItem(C_KEY, 1);
        me._startTime = Date.now();
        me._completionTime = me._startTime + (me._countdownSeconds * 1000);
        me._elapsedSeconds = 0;
        me._buildCountdownScreen();
        me._updateCountdown(me._countdownSeconds);
        me._ticker.postMessage(["start", me._countdownSeconds]);
    },
    stop:function() {
        var me = this;
        me._isRunning = false;
        me._ticker.postMessage(["stop"]);
    },
    _buildCountdownScreen:function() {
        var me = this, zIndex = core.nextZIndex() + 50000,
            $div = me.$countdownDiv = $("<div>")
                .appendTo($("body"))
                .css({
                    position:"fixed",
                    width:"100vw",
                    height:"100vh",
                    top:"0px",
                    left:"0px",
                    "background-color":"rgba(0,0,0,0.6)",
                    display:"flex",
                    "flex-flow":"column nowrap",
                    "align-items":"center",
                    "justify-content":"center",
                    "z-index":zIndex
                })
                .on("touchmove", function(evt){
                    // on touchscreen, prevent scrolling
                    evt.preventDefault();
                }),
             $dlg = $("<div>")
                .addClass("idb-dialog")
                .css({
                    "flex":"0 0 auto",
                    "max-width":"400px",
                    "display":"flex",
                    "flex-flow":"column nowrap",
                    "background-color":"rgb(236,238, 239)"
                })
                .appendTo($div),
             $titlebar = $("<div>")
                .addClass("idb-dialog-titlebar")
                .css({
                    "flex":"0 0 auto"
                })
                .appendTo($dlg),
             $titletext = $("<div>").text(bundle.format("timeout.countdown.title"))
                .appendTo($titlebar),
             $body = $("<div>").addClass("idb-dialog-body")
                .appendTo($dlg)
                .css({
                    "flex":"0 0 auto",
                    display:"flex",
                    "flex-flow":"column nowrap",
                    "align-items":"stretch"
                }),
         $countdownBar = $("<div>").appendTo($body)
            .css({
                "flex":"0 0 auto",
                "font-size":"50px",
                "font-weight":"bold",
                display:"flex",
                "flex-flow":"row nowrap",
                "align-items":"center"
            }),
             $minutes = me.$minutes = $("<div>").appendTo($countdownBar)
             .css({
                "text-align":"right",
                "flex":"4 5 auto"
             }),
         $colon = $("<div>").appendTo($countdownBar).text(":")
            .css({
                "text-align":"center",
                flex:"0 0 auto"
            }),
            $seconds = me.$seconds = $("<div>").appendTo($countdownBar)
            .css({
                "text-align":"left",
                "flex":"5 4 auto"
             }),
             $msg = $("<div>").appendTo($body)
                .css({
                    "padding":"10px",
                    "font-weight":"bold"
                })
                .text(bundle.format("timeout.countdown.message")),
             $buttonBar = $("<div>")
                .addClass("idb-dialog-buttonbar")
                .css({
                    display:"flex",
                    "justify-content":"center",
                    "flex-flow":"row nowrap"
                })
                .appendTo($dlg),
            $btn = $("<button>")
                .css({
                    "padding-left":"30px",
                    "padding-right":"30px"
                })
                .addClass("idb-btn idb-btn-sm idb-btn-success")
                .text(bundle.format("timeout.countdown.confirm"))
                .appendTo($buttonBar);

        $btn.on("click", me._onUserResponse.bind(me));
        $btn.focus();
        
        $.noop($titletext, $minutes, $colon, $seconds, $msg);
             
    },
    _updateCountdown:function(remainingSeconds) {
        var me = this,
            minutes = Math.floor(remainingSeconds/60),
            seconds = remainingSeconds - (minutes*60),
            s = seconds.toString(),
            sPad = s.length < 2 ? "0" + s : s,
            $minutes = me.$minutes, $seconds = me.$seconds,
            isEven = (seconds % 2) == 0,
            remaining = minutes + ":" + sPad,
            href = isEven ? faviconUrl : faviconAlertUrl;

        $minutes.text(minutes);
        $seconds.text(sPad);
        $favicon.attr({href:href});
        $pageTitle.text(remaining);
    }

};




        exports = {
            resetKeepAlive:function() {
                log("resetKeepAlive called.");
                if(sessionTimeoutMgr) {
                    sessionTimeoutMgr.resetKeepAlive();
                }
            },
            showCountdown:function() {
                if(sessionTimeoutMgr) {
                    sessionTimeoutMgr._onInactivityTimeout(0);
                }
            },
            setInactivityTimeoutEnabled:function(b) {
                if(sessionTimeoutMgr) {
                    sessionTimeoutMgr.setInactivityTimeoutEnabled(b);
                }
            },
            isLoggedOut:function() {
                if(sessionTimeoutMgr) {
                    return sessionTimeoutMgr._loggedOut;
                }
                return false;
            },
            init:initModule
        };

        core.setSessionTimeoutManager(exports);

        export default exports;


    

