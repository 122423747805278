/*global jQuery, $, Float64Array */
/*jshint bitwise:false*/

var exports = {};

Object.defineProperties(exports, {

	LUM_R: {get: function() { return 0.212671; }},
    LUM_G: {get: function() { return 0.715160; }},
    LUM_B: {get: function() { return 0.072169; }}
});

exports._BLACK_RGB = ({ r: 0, g: 0, b: 0, css: '#000000', i: 0 }/*: Color */);
exports._WHITE_RGB = ({ r: 255, g: 255, b: 255, css: '#FFFFFF', i: 16777215 }/*: Color */);

/**
 * Given the Red, Green and Blue components of an RGB color, this function returns the color's
 * Luminance value by using the formula:
 * <code>Luminance = 0.212671 ~~ R + 0.715160 ~~ G + 0.072169 ~~ B</code>
 *
 * @param R a Number from 0 to 255 representing the red component of a color in the RGB color space.
 * @param G a Number from 0 to 255 representing the green component of a color in the RGB color space.
 * @param B a Number from 0 to 255 representing the blue component of a color in the RGB color space.
 * 
 * @return a Number from 0 to 255 (max 2 decimal places) representing the Luminance value of the 
 * color that would be created by combining the given R, G and B values into an RGB color.
 * 
 * @see http://www.faqs.org/faqs/graphics/colorspace-faq/
 */
exports.rgbToLuminance = function(R/*: number */, G/*: number */, B/*: number */)/*: string */ {

    return ((0.212671 * R + 0.715160 * G + 0.072169 * B).toFixed(2));

};

/**
 * Returns a string in the form "#RRGGBB" based on the
 * given RGB object.
 */
exports.rgbToCSS = function(rgb) {
   function hh(n) {
      var s = n.toString(16);
      return (s.length == 1 ? "0" + s : s).toUpperCase();
   }
   return ( "#" + hh(rgb.r) + hh(rgb.g) + hh(rgb.b));
};

/**
 * Convert an integer representing a color to a hexadecimal color code.
 *
 * @param  {number} color An integer color.
 *
 * @return {string} A hexadecimal color code in the format of "#RRGGBB"
 */
exports.colorToCss = function (color/*: number */)/*: string */ {
    return '#' + ('000000' + color.toString(16).toUpperCase()).slice(-6);
};

/**
 * Parses a string in the format of "#RRGGBB" or "#rrggbb" in to an integer
 * representing the color.
 *
 * @param  {string} css The CSS hex code of the color. 
 *
 * @return {number}     The integer color.
 */
exports.cssToColor = function (css/*: string */)/*: number */ {
    if (css[0] !== '#' || css.length !== 7) {
        throw new Error('Color format not supported.');
    }

    return parseInt(css.slice(1), 16);
};

exports.toRGBA = function(color/*: AnyColorType */, alpha/*: number */) {
    var rgb = exports.rgb(color);
    return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + alpha + ")";
};
exports.toRGB = function(color/*: AnyColorType */) {
    var rgb = exports.rgb(color);
    return "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")";       
};   
exports.getRGB = function(color) {
    var rgb = [];
    rgb[0] = ((color & 0xFF0000) >> 16);
    rgb[1] = ((color & 0x00FF00) >> 8);
    rgb[2] = (color & 0x0000FF);
    return rgb;
};

exports.rgbToColor = function(r, g, b) {
    if(typeof r === "object") {
        b = r.b;
        g = r.g;
        r = r.r;
    }
    return (Math.round(r) << 16) | (Math.round(g) << 8) | (Math.round(b));
};

exports.rgb = function/*:: <D> */(val/*: AnyColorType */, defaultVal/*?: D */)/*: (Color | D) */ {
   var rgb = null;
   if(typeof val === "boolean") {
       val = Number(val);
   }
   if(Array.isArray(val)) {
      rgb /*: Color */ = {r:val[0], g:val[1], b:val[2]};
      rgb.i = exports.rgbToColor(rgb.r,  rgb.g, rgb.b);
   }
   if(typeof val === "object") {return val;}

   if(typeof val === "number") {
       /*jshint bitwise:false */
       rgb = {r:(val & 0xFF0000) >> 16, g:(val & 0x00FF00) >> 8, b:(val & 0x0000FF), i:val};
       /*jshint bitwise:true */
   }
   if(typeof val === "string") {
       val = val.replace(/^#/, "0x");
       var n = parseInt(val);
       if(isNaN(n)) {return defaultVal;}
       /*jshint bitwise:false */
       rgb = {r:(n & 0xFF0000) >> 16, g:(n & 0x00FF00) >> 8, b:(n & 0x0000FF), i:n};
       /*jshint bitwise:true */
   }
   if(rgb) {
       rgb.css = exports.rgbToCSS(rgb);
       if(!rgb.hasOwnProperty("i")) {
           rgb.i = exports.rgbToColor(rgb);
       }
       return rgb;
   }
   return defaultVal;
};

/**
 * Linearily interpolate between two colors.
 *
 * @param  {Color} colorA  Main color.
 * @param  {Color} colorB  The color to mix in.
 * @param  {number} amount The amount of colorB to mix in.
 *
 * @return {Color}         The interpolated color.
 */
exports.lerp = function(colorA/*: Color */, colorB/*: Color */, amount/*: number */)/*: Color */ {
    var rgb /*: Color */ = {
        r: Math.round(colorA.r + (colorB.r-colorA.r)*amount),
        g: Math.round(colorA.g + (colorB.g-colorA.g)*amount),
        b: Math.round(colorA.b + (colorB.b-colorA.b)*amount)
    };

    rgb.css = exports.rgbToCSS(rgb);
    rgb.i = exports.rgbToColor(rgb);
    return rgb;
};

exports.rgb.BLACK = {r:0,g:0,b:0,i:0,css:"#000000",x:{r:255,g:255,b:255,i:16777215,css:"#FFFFFF"}};
exports.rgb.WHITE = {r:255,g:255,b:255,i:16777215,css:"#FFFFFF",x:{r:0,g:0,b:0,i:0,css:"#000000"}};

/**
 * Works like exports.rgb(), however the returned rgb will have a
 * property "x" that will be its contrasting color, unless the 
 * property "x" prexisted, in which case it will remain whatever 
 * it was. 
 */
exports.rgbx = function/*:: <D> */(val/*: AnyColorType */, defaultVal/*: D */)/*: Color | D */ {
    var rgb = exports.rgb(val, defaultVal);
    if(rgb && !rgb.x) {
        rgb.x = exports.getContrastingColor(rgb);
    }
    return rgb;
};


/**
 * Given the Red, Green and Blue components of an RGB color, this function calculates the equivalant
 * HSV color and returns an Object with the properties "h" &#x2014; the Hue component, "s" &#x2014; the Saturation component)
 * and "v" &#x2014; the Value component of the resulting HSV value.
 *
 * @param R a Number from 0 to 255 representing the red component of a color in the RGB color space.
 * @param G a Number from 0 to 255 representing the green component of a color in the RGB color space.
 * @param B a Number from 0 to 255 representing the blue component of a color in the RGB color space.
 * 
 * @return an Object with the properties "h" &#x2014; the Hue component, "s" &#x2014; the Saturation component,
 * and "v" &#x2014; the Value component of the resulting HSV value.
 */
exports.rgbToHSV = function(R/*: number */, G/*: number */, B/*: number */)/*: HSVColor */ {

    var h = -1, s = 0,  
        rVal = R / 255, 
        gVal = G / 255, 
        bVal = B / 255, 
        maxVal = Math.max(rVal, Math.max(gVal, bVal)), 
        minVal = Math.min(rVal, Math.min(gVal, bVal)),
        delta = maxVal - minVal,
        v = maxVal * 100;

    if(maxVal !== 0) {
        s = (delta / maxVal) * 100;
    }
    else { 
        // r = g = b = 0, black.
        s = 0;
        h = -1;
        return {h:h, s:s, v:v};
    }

    if(s === 0) { // happens when r == g == b.
        // hue is undefined, so we're done.
        return {h:-1, s:s, v:v};
    }

    if(rVal == maxVal) {
        h = (gVal - bVal) / delta;   // between yellow & magenta
    }
    else if(gVal == maxVal) {
        h = 2 + (bVal - rVal) / delta;  // between cyan & yellow
    }
    else {
        h = 4 + (rVal - gVal) / delta;  // between magenta & cyan
    }

    h = h * 60;
    if(h < 0) h += 360;

    return {h:h, s:s, v:v};

};

/**
 * Convert a HSV color to HSL
 *
 * @param {number} hue    The hue component of the color (0 to 360)
 * @param {number} hsvSat The saturation component of the color (0 to 1).
 * @param {number} hsvLum The luminance of the color (0 to 1).
 *
 * @return {number[]} The HSL color in the format [hue, saturation, lightness].
 */
exports.hsvToHSL = function (hue/*: number */, hsvSat/*: number */, hsvLum/*: number */)/*: Float64Array */ {
    var hsl = new Float64Array(3),
        hslLum = (2 - hsvSat) * hsvLum / 2,
        hslSat = hsvSat;

    if (hslLum < 1) {
        if (hslLum === 0) {
            hslSat = 0;
        } else {
            hslSat = hsvSat * hsvLum / (hslLum < 0.5 ? hslLum * 2 : 2 - hslLum * 2);
        }
    }

    hsl[0] = hue;
    hsl[1] = hslSat;
    hsl[2] = hslLum;

    return hsl;
};

exports.normalize = function(val/*: number */, min/*?: number */, max/*?: number */)/*: number */ {
    if(min===undefined){min = 0;}
    if(max===undefined){max=255;}
    if(val < min) return min;
    if(val > max) return max;
    return val;
};

/**
 * Converts an HSV color to RGB.
 * 
 * @param h hue - 0 to 359 are valid hues; -1 means "undefined", which is the case for shades of gray.
 * @param s saturation - 0 to 100 (Saturation is represented as a percentage, rather than a fraction.)
 * @param v value - 0 to 100 (Value is represented as a percentage, rather than a fraction.)
 * 
 * @return an RGB representing the color.
 */
exports.hsvToColor = function(h/*: number */, s/*: number */, v/*: number */)/*: Color */ {
    var CH = exports, vFract, sFract, val, r = 0, g = 0, b = 0, i, f, p, q, t, rgb/*: Color */;
    h = h % 360;
    s = CH.normalize(s, 0, 100);
    v = CH.normalize(v, 0, 100);

//            trace("ColorHelper.hsvToRGB(): h=" + h + ",s=" + s + ",v=" + v);

    // convert v to fraction for calculations:
    vFract = v / 100;

    if(s === 0) {  // some shade of gray, determined by v.
        val = Math.floor(vFract * 255);
        rgb = {r:val,g:val,b:val};
        rgb.css = exports.rgbToCSS(rgb);
        rgb.i = exports.rgbToColor(rgb);
        return rgb;
    }

    // convert s to a fraction for calculations
    sFract = s / 100;

    h = h / 60;  // sector 0 to 5

    i = Math.floor(h);  // integer part of h
    f = h - i; // fractional part of h
    p = vFract * (1 - sFract);
    q = vFract * (1 - sFract * f);
    t = vFract * (1 - sFract * (1 - f));
//            trace("ColorHelper.hsvToRGB(): h=" + h + ",i=" + i + ",f=" + f + ",p=" + p + ",q=" + q + ",t=" + t);
    switch(i) {
        case 0 : 
            r = vFract; 
            g = t; 
            b = p; 
            break;
        case 1 :
            r = q;
            g = vFract;
            b = p;
            break;
        case 2 :
            r = p;
            g = vFract;
            b = t;
            break;
        case 3 :
            r = p;
            g = q;
            b = vFract;
            break;
        case 4 :
            r = t;
            g = p;
            b = vFract;
            break;
        default:
            r = vFract;
            g = p;
            b = q;
            break;
    }

//            trace("ColorHelper.hsvToRGB(): r=" + r + ",g=" + g + ",b=" + b);


    rgb = {r:Math.round(r*255), g:Math.round(g*255), b:Math.round(b*255)};
    rgb.css = exports.rgbToCSS(rgb);
    rgb.i = exports.rgbToColor(rgb);
    return rgb;

};


/**
 * Darken the given color by the specified amount. Amount is the percentage
 * darker the color will be made. 
 *
 * @param  {Color} color   The color to darken
 * @param  {number} amount How many percent (0 - 1) darker.
 *
 * @return {Color}        A new color that is darker than the original.
 */
exports.darkenColor = function (color/*: Color */, amount/*: number */)/*: Color */ {
    return exports.lerp(color, exports._BLACK_RGB, amount);
};

/**
 * Lighten the given color by the specified amount. Amount is
 * the percentage lighter the color will be made.
 *
 * @param  {Color} color   The color to lighten
 * @param  {number} amount How many percent (0 - 1) lighter.
 *
 * @return {Color}        A new color that is lighter than the
 *         original.
 */
exports.lightenColor = function (color/*: Color */, amount/*: number */)/*: Color */ {
    return exports.lerp(color, exports._WHITE_RGB, amount);
};


/**
 * Returns a color that contrasts sufficiently from the given color so
 * that that when the two colors are combined as text and background colors,
 * the text will be readable against the background. First, the given color is classified as a
 * "light" or "dark" color, by calculating its Luminance value on a scale of 0 to 255, and
 * considering it "light" if its Luminance is greater than 127 and "dark" otherwise.
 * 
 * <p>If the color is "light", the returned color is close to black. (Black with a little
 * of the original color mixed in.) If the color is "dark", then the returned color is close
 * to white. (White with a little of the original color mixed in.)</p>
 * 
 * @param color an RGB or a uint representing an RGB color value.
 * @return an RGB representing a contrasting color.
 * 
 * @see #rgbToLuminance()
 */
exports.getContrastingColor = function(color/*: AnyColorType */)/*: Color */ {

    var CH = exports,
        rgb = CH.rgb(color), 
        lum = CH.rgbToLuminance(rgb.r,  rgb.g,  rgb.b),
        hsv = CH.rgbToHSV(rgb.r,  rgb.g,  rgb.b);
    
    if(lum > 127) { // light color

        // reduce the Value component of the HSV color 1o 10. This will make it near-black
        // while preserving the hue.
        hsv.v = 10;
    }
    else { // dark color

        // increase the Value to 90 but reduce the Saturation to 10. This
        // will make it near-white while preserving the hue.
        hsv.v = 90;
        hsv.s = Math.min(10, hsv.s);
    }
        
    return CH.hsvToColor(hsv.h,  hsv.s,  hsv.v);


};

exports.getSimpleContrastingColor = function(color) {
    var CH = exports,
	lum = CH.rgbToLuminance(color.r, color.g, color.b);
	return (lum > 127 ? CH._BLACK_RGB: CH._WHITE_RGB);
};

exports.isLightColor = function(color) {
    var CH = exports,
	lum = CH.rgbToLuminance(color.r, color.g, color.b);
	return (lum > 127);
};

exports.isDarkColor = function(color) {
    var CH = exports,
	lum = CH.rgbToLuminance(color.r, color.g, color.b);
	return (lum <= 127);
};



exports.getHSVColorProgression = function(beginColor, endColor, numColors, reverse) { // (beginColor:uint, endColor:uint, numColors:int, reverse:Boolean):Array {
    var CH = exports;
    beginColor = CH.rgb(beginColor);
    endColor = CH.rgb(endColor);

    numColors = numColors || 5;
    reverse = !!reverse;

    if(numColors <=2) return [beginColor, endColor];
    var beginHSV = CH.rgbToHSV(beginColor.r, beginColor.g, beginColor.b),
        endHSV = CH.rgbToHSV(endColor.r, endColor.g, endColor.b),
        beginH = beginHSV.h,
        beginS = beginHSV.s,
        beginV = beginHSV.v,
        endH = endHSV.h,
        endS = endHSV.s,
        endV = endHSV.v,
        numSteps, moveH, stepH, stepS, stepV, results;

    if(beginH < 0) { // we're starting from a shade of gray, so move directly to the end color
        beginH = endH;
    }
    else if(endH < 0) {
        endH = beginH;
    }

//            trace("ColorHelper.getHSVColorProgression(): beginH=" + beginH + ", beginS=" + beginS 
//                + ", beginV=" + beginV + ", endH=" + endH + ", endS=" + endS + ", endV=" + endV);

    numSteps = numColors - 1;

    if(endH <= beginH) {
        moveH = reverse ? (endH - beginH) : (360 + endH - beginH);
    }
    else {
        moveH = reverse ? (-360 + endH - beginH) : (endH - beginH);
    }

    stepH = moveH / numSteps;
    stepS = (endS-beginS) / numSteps;
    stepV = (endV-beginV) / numSteps;

//            trace("ColorHelper.getHSVColorProgression(): moveH=" + moveH + ", stepH=" + stepH);

    results = [];
    for(var j=0; j<numColors; j++) {

        results.push(CH.hsvToColor(beginH, beginS, beginV));
//                trace("ColorHelper.getHSVColorProgression(): beginH before =" + beginH);
        beginH += stepH;
        if(beginH > 359) {beginH -= 360;}
        else if(beginH < 0) {beginH += 360;}
//                trace("ColorHelper.getHSVColorProgression(): beginH after =" + beginH);
        beginS += stepS;
        beginV += stepV;
    }

    return results;

};

exports.normalizeAlpha = function(n) {
    if(isNaN(n)) n = 1;
    if(n < 0) n = 0;
    if(n > 1) n = 1;
    return (Math.round(n * 100) / 100);
};

/**
 * Returns the color that would be seen when overlaying a partially transparent
 * object whose color is "transparentColor" over an opaque object whose
 * color is "opaqueColor".
 * 
 * @param transparentColor the color of the partially transparent object.
 * @param opaqueColor the color of the opaque object.
 * @param alpha a number between 0 and 1.0, which represents the degree of opacity of
 * the partially transparent object. 0 represents completely transparent, and 1.0
 * represents completely opaque.
 * 
 * @return a number representing an RGB color.
 */
exports.alphaBlend = function(transparentColor/*: AnyColorType */, opaqueColor/*: AnyColorType */, alpha/*: number */) {
    if(alpha > 1.0) alpha = 1.0;
    else if(alpha < 0) alpha = 0;

    var tc = exports.rgb(transparentColor);
    var oc = exports.rgb(opaqueColor);

    var newR = oc.r * (1.0 - alpha) + tc.r * alpha;
    var newG = oc.g * (1.0 - alpha) + tc.g * alpha;
    var newB = oc.b * (1.0 - alpha) + tc.b * alpha;

    return exports.rgbToColor(newR, newG, newB);
};

/**
  * Returns a color which is similar, but slightly darker or lighter than the given color.
  */
exports.offColor = function(color/*: AnyColorType */) {
    return exports.alphaBlend(color, exports.getContrastingColor(color), 0.8);
};

exports.highlight = function(color/*: AnyColorType */, numTimes/*?: number */, FACTOR/*?: number */)/*: ?Color */ {
    var r,g,b,gray,newColor;
    if(typeof numTimes === "undefined") {
        numTimes = 1;    
    }
    if(typeof FACTOR === "undefined") {
        FACTOR = 0.8;
    }
    color = exports.rgb(color);
    if(numTimes < 1) {
        return color;
    }

    gray = Math.floor(1.0/(1.0-FACTOR));
    if(!color || color.i === 0) {
        return exports.rgb((Math.round(gray) << 16) | (Math.round(gray) << 8) | (Math.round(gray)));
    }

    r = color.r;
    g = color.g;
    b = color.b;

    if(r > 0 && r < gray) r = gray;
    if(g > 0 && g < gray) g = gray;
    if(b > 0 && g < gray) b = gray;

    
    newColor = exports.rgbToColor(Math.min(Math.floor(r/FACTOR), 255),
                     Math.min(Math.floor(g/FACTOR), 255),
                     Math.min(Math.floor(b/FACTOR), 255));
    if(numTimes == 1) {
        return exports.rgb(newColor);
    }
    return exports.highlight(newColor, numTimes-1, FACTOR);
};

/**
 * @param numTimes the number of times the function will be called in total. If more than 1,
 * the function will be called recursively (numTimes-1) on each previous result.
 * 
 * @param FACTOR the factor used to reduce the individual R, G and B values. Should be greater than 0
 * and less than 1.0. Smaller numbers produce darker results.
 */
exports.shadow = function(color/*: AnyColorType */, numTimes/*?: number */, FACTOR/*?: number */)/*: ?Color */ {
    var r,g,b,newColor;
    if(typeof numTimes === "undefined") {
        numTimes = 1;    
    }
    if(typeof FACTOR === "undefined") {
        FACTOR = 0.8;
    }
    color = exports.rgb(color);
    if(numTimes < 1) {
        return color;
    }

    r = color.r;
    g = color.g;
    b = color.b;


    newColor = exports.rgbToColor(exports.normalize(Math.floor(r*FACTOR)), 
             exports.normalize(Math.floor(g*FACTOR)),
             exports.normalize(Math.floor(b*FACTOR)));
    if(numTimes == 1) {
        return exports.rgb(newColor);
    }
    return exports.shadow(newColor, numTimes-1, FACTOR);
};

exports.bevelBorderCss = function(baseColor/*: AnyColorType */, borderThickness/*: number */, inset/*: boolean */)/*: {} */ {
    var highlightFunction = inset ? "shadow" : "highlight",
        highlight = exports[highlightFunction](baseColor).css, 
        shadowFunction = inset ? "highlight" : "shadow",
        shadow = exports[shadowFunction](baseColor).css,
        b = (borderThickness || 2) + "px solid ", css = {
            "border-top":b + highlight,
            "border-left":b + highlight,
            "border-bottom":b + shadow,
            "border-right":b + shadow
        };

    return css;

};


exports.ColorSet = function(startIndex /*?: ?number */) {
    if(!startIndex) {startIndex = 0;}
    this._length = exports.ColorSet.BASIC_COLORS.length;
    this._minusLength = 0 - this._length;
    this._startIndex = startIndex;
    this._cursor = startIndex;
    if(!exports.ColorSet._cache) {
        exports.ColorSet._cache = {};
    }
};



exports.ColorSet.prototype.setStartIndex = function(index/*: number */)/*: void */ {
    if(index > 0) {
        this._startIndex = index % this._length;
    }
    else if (index < 0) {
        this._startIndex = this._length + (index % this._length);
    }
    else {
       this. _startIndex = 0;
    }
};

exports.ColorSet.prototype.getColor = function(index/*: number */)/*: Color */ {

    // move to the indicated position to the right or left of _startIndex
    index += this._startIndex;

    // wrap around, if we've gone past the end or before the beginning of the array.
    if(index >= this._length) {
        index %= this._length;
    }
    else if(index < 0) {
        index = this._length + (index % this._length);
    }
    var color = exports.ColorSet.BASIC_COLORS[index];
    // check cache
    var rgb = exports.ColorSet._cache[color];
    if(!(rgb)) {
        rgb = exports.rgbx(color);
        exports.ColorSet._cache[color] = rgb; 
    }

    return rgb;
};

exports.ColorSet.prototype.getNextColor = function() {
    return this.getColor(this._cursor++);
};

exports.ColorSet.BASIC_COLORS = [

	0x000000, //index (0) is unused
	
	//pattern 1 (Nine Labs 18x new color pattern)
	0xE3AD25,0x41AEBE,0xB1D236,0xB31F68,
	0xE37325,0x63BEAE,0x294494,0x786AB4,
	0xD04040,0xBE8F18,0x348C99,0x88A324,
	0x87174E,0xBE5C18,0x378578,0x1E316C,
	0x5643A9,0xB02C2C,
	
	//3x extra colors to pad until index (22) is reached
	0x7EC3E6,0x85CDAF,0xF2CE60,
	
	//repeat "pattern 1" above (minus index (0)) for X-Axis charts because they begin after position 22
	0xE3AD25,0x41AEBE,0xB1D236,0xB31F68,
	0xE37325,0x63BEAE,0x294494,0x786AB4,
	0xD04040,0xBE8F18,0x348C99,0x88A324,
	0x87174E,0xBE5C18,0x378578,0x1E316C,
	0x5643A9,0xB02C2C,0x7EC3E6,0x85CDAF,
	0xF2CE60,
	
 	//pattern 2
	0xff6a59,0xc14ad3,0x6270d8,0x26c5ff,
	0x23b9af,0xaae66d,0xe6e655,0xff8045,
	0xbcbcbc,0xff418d,0x8f5dda,0x44b4ff,
	0x23e6f8,0x6fd26f,0xe9ff5c,0xffc123,
	0x9c7a6b,0x83a1ae,0xfe5340,0xaa31ba,
	0x4957bf,0x0daafe,0x0aa096,0x91cd54,
	0xfffc45,0xff682c,0xa3a3a3,0xf32865,
	0x7644c1,0x2b98fd,0x0abfdf,0x56b95d,
	0xd0e643,0xffa90a,0x836152,0x6a8595,
	0xe53a27,0x9118a1,0x303ea6,0x0091e5,
	0x00877e,0x78b43b,0xf0e32c,0xf04e13,
	0x898989,0xda0f4c,0x5d2ba8,0x127fe4,
	0x00a5c6,0x3da044,0xb7cd2a,0xf09000,
	0x6a4839,0x516c7c,
	
	//pattern 1&2 (dark)
	0x715612,0x20575F,0x58691B,0x590F34,
	0x713912,0x315F57,0x14224A,0x3C355A,
	0x682020,0x5F470C,0x1A464C,0x445112,
	0x430B27,0x5F2E0C,0x1B423C,0x0F1836,
	0x2B2154,0x581616,0x7F352C,0x602569,
	0x31386C,0x13627F,0x115C57,0x557336,
	0x73732A,0x7F4022,0x5E5E5E,0x7F2046,
	0x472E6D,0x225A7F,0x11737C,0x376937,
	0x747F2E,0x7F6011,0x4E3D35,0x415057,
	0x7F2920,0x55185D,0x242B5F,0x06557F,
	0x05504B,0x48662A,0x7F7E22,0x7F3416,
	0x515151,0x791432,0x3B2260,0x154C7E,
	0x055F6F,0x2B5C2E,0x687321,0x7F5405,
	0x413029,0x35424A,0x721D13,0x480C50,
	0x181F53,0x004872,0x00433F,0x3C5A1D,
	0x787116,0x782709,0x444444,0x6D0726,
	0x2E1554,0x093F72,0x005263,0x1E5022,
	0x5B6615,0x784800,0x35241C,0x28363E,
	0x662D32,0x6C3A2B,0x796730,0x576638,
	0x426657,0x3F6173,0x304879,0x514A73,
	0x66475D,0x67686C,0x66141C,0x6C2510,
	0x795C06,0x48660A,0x146644,0x0B5073,
	0x0C3079,0x1D0B73,0x66144F,0x313339,
	
	//pattern 1&2 (light)
	0xF1D692,0xA0D7DF,0xD8E99B,0xD98FB4,
	0xF1B992,0xB1DFD7,0x94A2CA,0xBCB5DA,
	0xE8A0A0,0xdfc78c,0x9ac6cc,0xc4d192,
	0xc38ba7,0xdfae8c,0x9bc2bc,0x8f98b6,
	0xaba1d4,0xd89696,0xFFB5AC,0xE0A5E9,
	0xB1B8EC,0x93E2FF,0x91DCD7,0xD5F3B6,
	0xF3F3AA,0xFFC0A2,0xDEDEDE,0xFFA0C6,
	0xC7AEED,0xA2DAFF,0x91F3FC,0xB7E9B7,
	0xF4FFAE,0xFFE091,0xCEBDB5,0xC1D0D7,
	0xFFA9A0,0xD598DD,0xA4ABDF,0x86D5FF,
	0x85D0CB,0xC8E6AA,0xFFFEA2,0xFFB496,
	0xD1D1D1,0xF994B2,0xBBA2E0,0x95CCFE,
	0x85DFEF,0xABDCAE,0xE8F3A1,0xFFD485,
	0xC1B0A9,0xB5C2CA,0xF29D93,0xC88CD0,
	0x989FD3,0x80C8F2,0x80C3BF,0xBCDA9D,
	0xF8F196,0xF8A789,0xC4C4C4,0xED87A6,
	0xAE95D4,0x89BFF2,0x80D2E3,0x9ED0A2,
	0xDBE695,0xF8C880,0xB5A49C,0xA8B6BE,
	0xE6ADB2,0xECBAAB,0xF9E7B0,0xD7E6B8,
	0xC2E6D7,0xBFE1F3,0xB0C8F9,0xD1CAF3,
	0xE6C7DD,0xE7E8EC,0xE6949C,0xECA590,
	0xF9DC86,0xC8E68A,0x94E6C4,0x8BD0F3,
	0x8CB0F9,0x9D8BF3,0xE694CF,0xB1B3B9
	];


        /**
         * Always returns a number between 0 and 1, rounded to two decimal places.
         */
        exports.normalizeAlpha = function(n/*: number */)/*: number */ {
            if(isNaN(n)) n = 1;
            if(n < 0) n = 0;
            if(n > 1) n = 1;
            var x = (Math.round(n * 100) / 100);
            return x;
        };

        /**
         * Returns an integer between 0 and 100, which represents a percentage transparancy. ( (1-alpha) * 100 ).
         */
        exports.normalizeTransparency = function(t/*: number */)/*: number */ {
            if(t < 0) t = 0;
            if(t > 100) t = 100;
            return Math.round(t);
        };

        exports.alphaToTransparency = function(alpha/*: number */)/*: number */ {
            return 100 - Math.round(exports.normalizeAlpha(alpha)*100);
        };

        exports.transparencyToAlpha = function(t/*: number */)/*: number */ {
            return exports.normalizeAlpha(1 - (exports.normalizeTransparency(t) / 100));
        };


        /**
         * Returns a new object with the same properties as the argument
         * object, except that all of the properties whose names end in 
         * &quot;Color&quot; are passed to colorhelper.rgbx() and the 
         * returned values are assigned back to the properties. 
         */
        exports.normalizeColorProperties = function(obj/*: any */)/*: any */ {
            var propName, newObj = {};
            for(propName in obj) {
                if(obj.hasOwnProperty(propName)) {
                    if(/.*Color$/.test(propName)) {
                        newObj[propName] = exports.rgbx(obj[propName],  0xFFFFFF);
                    }
                    else {
                        newObj[propName] = obj[propName];
                    }
                }
            }
            return newObj;
        };

        exports.normalizeColorPropertiesRecursive = function(obj/*: any */)/*: any */ {
            var propName, value, regex = /color$/i,
            newObj = Array.isArray(obj) ? [] : {};

            for(propName in obj) {
                if(obj.hasOwnProperty(propName)) {
                    value = obj[propName];
                    if(value && typeof value === "object") {
                        newObj[propName] = exports.normalizeColorPropertiesRecursive(value);
                    }
                    else {
                        if(regex.test(propName) && typeof value === "number") {
                            newObj[propName] = exports.rgbx(value, 0xFFFFFF);
                        }
                        else {
                            newObj[propName] = value;
                        }
                    }
                }
            }
            return newObj;
        };

if(jQuery) {
    jQuery._colorhelper = exports;
}

export default exports;

