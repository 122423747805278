/* globals window */

export function getDeviceIdFromLocalStorage() /*: string | null */ {
    const { localStorage } = window;
    const deviceId = localStorage.getItem('deviceId');

    if (deviceId != 'null') {
        // console.log('reading device id from local storage', deviceId);
        return deviceId;
    } else {
        return null;
    }
}

export function getDeviceIdFromMeta() /*: string | null */ {
    const collection = document.getElementsByTagName('meta');
    // $FlowFixMe[method-unbinding]
    const elts = Array.prototype.slice.call(collection);

    const deviceElt = elts.find((e) => e.getAttribute('name') == 'deviceId');

    if (deviceElt != null) {
        const deviceId = deviceElt.getAttribute('content');

        if (deviceId != 'null') {
            // console.log('reading device id from meta', deviceId);
            return deviceId;
        } else {
            return null;
        }
    } else {
        console.warn('deviceId meta not set');
        return null;
    }
}

export function updateDeviceId(deviceId /*: string | null */) {
    const oldId = getDeviceIdFromLocalStorage();

    if (oldId != deviceId) {
        // console.debug('updating deviceId', deviceId);
        window.localStorage.setItem('deviceId', deviceId);
    }
}

/**
 * Reads the deviceId from the page's meta tag
 */
export function getDeviceId() /*: string | null */ {
    const metaId = getDeviceIdFromMeta();
    const localStorageId = getDeviceIdFromLocalStorage();

    const deviceId = metaId || localStorageId;
    updateDeviceId(deviceId);
    return deviceId;
}

export default { getDeviceId, updateDeviceId };
