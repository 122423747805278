import $ from 'jquery';
import FocusBlurRegistry from './FocusBlurRegistry.js';
import { requireDefined } from './cache/CacheUtils.js';

/**
 * A CSS classname
 * @typedef {string} CssClassName
 */

/**
 * A `.data()` key Name
 * @typedef {string} ElementDataKey
 */

/**
 * Keep trying to focus `$element` using `tries` and `delay`
 *
 * @param {JQuery} $element - to focus
 * @param {number} tries   - number of attempts
 * @param {number} delay   - delay between attempts
 * @param {boolean} [log]  - log attempts and success
 */
export function tryFocusUntil($element, tries, delay, log) {
    if (tries < 1) {
        if (log) {
            console.log('tryFocusUntil: FAIL', tries, $element[0]);
        }
        return;
    }
    const $foc = $(':focus');
    if ($foc.length && $element[0] === $foc[0]) {
        if (log) {
            console.log('tryFocusUntil: FOUND', tries, $element[0]);
        }
        return;
    }
    $element.eq(0)[0].focus();
    if (log) {
        console.log('tryFocusUntil: NEXT', tries);
    }
    setTimeout(() => {
        tryFocusUntil($element, --tries, delay, log);
    }, delay);
}

/**
 * Focus first found element with `firstFocus` class that is visible and not disabled
 * in `$within` using  `tries` and `delay`
 *
 * @param {JQuery} $within - selection to search
 * @param {number} tries   - number of attempts
 * @param {number} delay   - delay between attempts
 * @param {boolean} [log]  - log the attempts and success
 */
export function findFirstFocusUntil($within, tries, delay, log) {
    if (tries < 1) {
        return;
    }
    const $found = $within.find(`.${firstFocus}:visible:not(:disabled)`);
    if ($found.length) {
        $found.eq(0)[0].focus();
        if (log) {
            console.log('findFirstFocusUntil:found', $found.eq(0));
        }
        return;
    }
    if (log) {
        console.log('findFirstFocusUntil', tries);
    }
    setTimeout(() => {
        findFirstFocusUntil($within, --tries, delay);
    }, delay);
}

/**
 * Returns selection of closest element using up, down, left, right.
 * First winner is returned in case of a tie.
 *
 * @param {JQuery} $from - source
 * @param {JQuery} $elements - targets
 * @param {string} direction - up, down, left, right
 * @returns {JQuery} nearest elements
 */
export function getVisuallyAdjacentElement($from, $elements, direction) {
    // Function to get the distance between two points (squared distance to avoid square root calculation)
    function getDistanceSq(x1, y1, x2, y2) {
        const dx = x2 - x1;
        const dy = y2 - y1;
        return dx * dx + dy * dy;
    }

    if ($from.length === 0) {
        // If no element is focused, return null
        return $([]);
    }

    // Get the position of the focused element
    const focusedOffset = requireDefined($from.offset());
    const focusedX = focusedOffset.left + requireDefined($from.outerWidth()) / 2;
    const focusedY = focusedOffset.top + requireDefined($from.outerHeight()) / 2;

    // Find the adjacent element based on the screen position and distance
    /**
     * @type {JQuery}
     */
    let $closestElement = $([]);
    let closestDistance = Infinity;

    $elements.each(function () {
        /**
         * @type {JQuery}
         */
        const $element = $(this);

        if ($element.is(':visible') && !$element.is($from)) {
            const offset = requireDefined($element.offset());
            const x = offset.left + requireDefined($element.outerWidth()) / 2;
            const y = offset.top + requireDefined($element.outerHeight()) / 2;
            const distanceSq = getDistanceSq(focusedX, focusedY, x, y);

            // Update the closest element if it's the closest in the specified direction
            if (
                (direction === 'up' && y < focusedY && distanceSq < closestDistance) ||
                (direction === 'down' && y > focusedY && distanceSq < closestDistance) ||
                (direction === 'left' && x < focusedX && distanceSq < closestDistance) ||
                (direction === 'right' && x > focusedX && distanceSq < closestDistance)
            ) {
                $closestElement = $element;
                closestDistance = distanceSq;
            }
        }
    });

    return $closestElement;
}

/**
 * Scroll UL with focused LI for PageUp PageDown
 *
 * @param {JQuery} $ul - scollable UL with an LI that has focus
 * @param {string} [upDown='down'] - scroll direction
 * @param {string} [focusSelector=':focus'] - or single css classname with dot prefix
 * @returns {JQuery} - new Focus, or empty JQuery
 */
export function pageScrollAndFocusUlVertically($ul, upDown = 'down', focusSelector = ':focus') {
    const useFocus = focusSelector === ':focus',
        focusSelectorClass = focusSelector.substring(1);

    if ($ul.find('li' + focusSelector).length === 0) {
        throw new Error(
            'pageScrollAndFocusUlVertically Error: $ul must have a ' +
                (useFocus ? 'focused LI' : 'existing LI' + focusSelector)
        );
    }
    const ct = countLiRowsThatFitInUlViewport($ul),
        $elements = $ul.find('li:visible');

    let $from = $ul.find('li' + focusSelector),
        $last = $from;
    for (let x = 0; x < ct && $from.length; x++) {
        $last = $from;
        $from = getVisuallyAdjacentElement($from, $elements, upDown);
        // console.log({x, $from, $last});
    }
    // backup when no more found
    $from = $from.length === 0 ? $last : $from;
    if ($from.length) {
        const gridStyles = window.getComputedStyle($ul[0]),
            gapHeight = gridStyles.rowGap === 'normal' ? 0 : parseFloat(gridStyles.rowGap);

        $from[0].scrollIntoView();
        if (useFocus) {
            $from.trigger('focus');
        } else {
            $ul.find('>li' + focusSelector).removeClass(focusSelectorClass);
            $from.addClass(focusSelectorClass);
        }
        // precisely to top
        $ul.scrollTop(requireDefined($ul.scrollTop()) - gapHeight);
        return $from;
    } else {
        return $([]);
    }
}

/**
 * Count of rows that will fit in a UL viewport, UL must have at least 1 LI.
 *
 * @param {JQuery} $ul
 * @return {number}
 */
export function countLiRowsThatFitInUlViewport($ul) {
    const $liElements = $ul.find('li');
    if ($liElements.length === 0) {
        throw new Error('countVisibleRowsOfVisibleInUlScroll Error: $ul must have at least 1 LI');
    }
    const liHeight = requireDefined($liElements.eq(0).outerHeight());
    const ulHeight = $ul.height();
    const gridStyles = window.getComputedStyle($ul[0]);
    const gapHeight = gridStyles.rowGap === 'normal' ? 0 : parseFloat(gridStyles.rowGap);
    const paddingHeight = parseFloat(gridStyles.paddingTop) + parseFloat(gridStyles.paddingBottom);
    const availableHeight = requireDefined(ulHeight) - paddingHeight;
    const rowsPerViewport = Math.floor((availableHeight + gapHeight) / (liHeight + gapHeight));
    return rowsPerViewport;
}

/**
 * Transforms JQuery into first HTML Element.
 *
 * @param {JQuery | HTMLElement} jqOrElement
 * @returns {HTMLElement | undefined} - undefined if no first element
 */
export function asElement(jqOrElement) {
    const isJquery = jqOrElement && (jqOrElement instanceof $ || jqOrElement.constructor.prototype.jquery);
    return isJquery ? jqOrElement[0] : jqOrElement;
}

/**
 * Determine if $element is visible in $container.
 *
 * @param {JQuery | HTMLElement} $element
 * @param {JQuery | HTMLElement} $container
 * @returns {boolean} true if in viewport
 */
export function isElementInViewport($element, $container) {
    $element = $($element);
    $container = $($container);

    const elementTop = requireDefined($element.offset()).top;
    const elementBottom = elementTop + requireDefined($element.outerHeight());

    const containerTop = requireDefined($container.offset()).top;
    const containerBottom = containerTop + requireDefined($container.height());

    return elementBottom > containerTop && elementTop < containerBottom;
}

/**
 * Singleton class that tracks the focus and blur events of the document and window.
 * @type {FocusBlurRegistry | null}
 */
let fbrSingletonInstance = null;

/**
 * Retrieves the singleton instance of the FocusBlurRegistry class.
 * @returns {FocusBlurRegistry} - The FocusBlurRegistry singleton instance.
 */
export function getFocusBlurRegistry() {
    if (!fbrSingletonInstance) {
        const newRegistry = new FocusBlurRegistry();
        fbrSingletonInstance = newRegistry;
        document.addEventListener('visibilitychange', (e) => newRegistry.handleVisibilityChange(e));
        window.addEventListener('focus', (e) => newRegistry.handleWindowFocus(e));
        window.addEventListener('blur', (e) => newRegistry.handleWindowBlur(e));
        document.addEventListener('focus', (e) => newRegistry.handleDocumentFocus(e), true);
        document.addEventListener('blur', (e) => newRegistry.handleDocumentBlur(e), true);
    }
    return fbrSingletonInstance;
}

/**
 * Typically use on BODY to cause idb control components to display focus indicators.
 *
 * @type {CssClassName}
 */
export const pageFocusIndicators = 'idb-page-focus-indicators';

/**
 * Use to indicate a browser control is to be focused on dialog launch, first found is used.
 * The attempt at first focus is tried when the dialog element itself recieves focus for the first time.
 *
 * @type {CssClassName}
 */
export const firstFocus = 'js-first-focus';

/**
 * Used for keyboard tabbing; use this class on a high level element that contains `.data(firstFocusControlsData)`
 * that has a space delimited string value indicating browser control types eligable in finding next/prev control.
 * Note: this is required in the forms dialog due to 'a' tags being used.
 * Default / Example: "a input button textarea select"
 *
 * @see {@link firstFocusControlsData}
 *
 * @type {CssClassName}
 */
export const firstFocusControls = 'js-first-focus-controls';

/**
 * The `.data()` key that contains the browser control types string.
 *
 * @see {@link firstFocusControls}
 *
 * @type {ElementDataKey}
 */
export const firstFocusControlsData = 'firstFocusControlsData';

/**
 * Place on a dialog launching element to stop dialog from refocusing the element on dialog close.
 *
 * @type {CssClassName}
 */
export const preventRefocusOnCloseDialog = 'js-prevent-refocus-on-close-dialog';

/**
 * By default tabbing forward when on the last browser control in a dialog will focus the first browser
 * control in the dialog. Using this class on the last browser control will focus on the first control
 * that has the class `js-first-focus` aka `firstFocus`
 *
 * @type {CssClassName}
 */
export const tabCycleUseFirstFocus = 'js-tab-cycle-use-first-focus';

/**
 * Without this class ctrl-enter will seek the first elements with `idb-btn-success` or `btn-success`.
 * If any inputs are marked with this class, the first marked input found will be used instead.
 *
 * @type {CssClassName}
 */
export const keyCtrlEnter = 'js-key-ctrl-enter';

/**
 * Elements that respond to a click can use this css class to be triggered by ctrl-enter when they have focus.
 * Use for 'chooser' type of dialogs.
 *
 * @type {CssClassName}
 */
export const keyCtrlEnterFocusClick = 'js-key-ctrl-enter-focus-click';

/**
 * Dialog launching elements use this `.data()` key to store the element to call `.focus()` on when
 * the dialog closes.
 *
 * @type {ElementDataKey}
 */
export const focusReturnControlElement = 'focusReturnControlElement';

/**
 * External css class name that FirstFocus mechanism depends on. Currently not using these as constants in clients.
 *
 * @see {@link btntnSuccess}
 *
 * @type {CssClassName}
 */
export const idbBtnSuccess = 'idb-btn-success';

/**
 * External css class name that FirstFocus mechanism depends on. Currently not using these as constants in clients.
 *
 * @see {@link idbBtnSuccess}
 *
 * @type {CssClassName}
 */
export const btnSuccess = 'btn-success';
