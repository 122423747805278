/* globals $, jQuery, DocumentTouch, DOMMatrix, crypto */
/*jshint esversion: 11*/
// NOTE: This file must not depend on any other scripts
// aside from jQuery.

/**
 * @module idbcore
 */

/*::
  export type NormalizeCase = 'lower' | 'upper';

  export type Position = {
      left: number,
      top: number,
  };
*/
const EL = document.createElement.bind(document),
    APPEND = (parentNode, toAppend) => {
        if("string" === typeof toAppend) {
            return parentNode.appendChild(EL(toAppend));
        }
        if(toAppend instanceof Node) {
            return parentNode.appendChild(toAppend);
        }
        return parentNode.appendChild(EL("div"));
    },
    buildDOMTree = (config) => {
        const tag = config.tag, 
            className = config.className,
            text = config.text ?? null,
            value = config.value ?? null,
            children = config.children || [],
            style = config.style || {},
            attrs = config.attrs || {},
            props = config.props || {},
            name = config.name,
            childMap = {
            },
            element = document.createElement(tag),
            reserved = {
                element:true,
                name:true,
                children:true
            };
        if(name) {
            element.dataset.xname = name;
        }
        const shadow = {
            element,
            name,
            // We need to build the elements in the tree
            // depth-first. That way, a <select>'s value
            // property can select one if its <option> children.
            children:children.map((child) => {
                let c = buildDOMTree(child);
                element.appendChild(c.element);
                if(c.name) {
                    if(reserved[c.name]) {
                        console.error("Illegal name property: " + c.name, c);
                        throw new Error("Illegal name property: " + c.name, {cause:c});
                    }
                    childMap[c.name] = c;
                }
                return c;
            })
        };

        if("string" === typeof className) {
            element.className = className;
        }

        if(text !== null) {
            element.textContent = text;
        }

        if(value !== null) {
            element.value = value;
        }

        Object.assign(element.style, style);
        setAttributes(element,  attrs);
        Object.assign(element,  props);
        return Object.assign(childMap, shadow);
    },
    setAttributes = (element, attributes) => {
        const attributeNames = Object.keys(attributes);
        attributeNames.forEach((attributeName) => {
            element.setAttribute(attributeName, attributes[attributeName]);
        });
    };
var entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;'
},
focusableElements = {
    A:true,
    BUTTON:true,
    INPUT:true,
    SELECT:true,
    TEXTAREA:true
},
$window = $(window), $body = $("body"), $html = $("html"),
sessionTimeoutMgr = null,
//: TODO: 
//: 1. Is there less hackish solution other than initing nextZIndex to higher value?
//: 2. What are the implications of this quick fix?
nextZIndex = 2050,
userAgent = navigator.userAgent, 
isIOS = (function() {
    var devices = [
        "iPod Simulator",
        "iPod",
        "iPad Simulator",
        "iPhone Simulator",
        "iPad",
        "iPhone"
    ], 
    device = null, platform = navigator.platform || false;

    if (platform) {
        while (!!(device = devices.pop())) {
            if (platform === device){ 
                return true; 
            }
        }
    }

    return false;
})(),
isAndroid = /android/i.test(userAgent),
isChrome = /chrome/i.test(userAgent) && /google inc/i.test(navigator.vendor), 
dndConfigured = false, dndSupportsCustomMimeTypes = false, dndSupportsJsonMimeType = false,
customMimeType = "application/x-idbcore-dnd", jsonMimeType = "application/json", textMimeType = "Text",
getDndMimeType = function(preferredCustomMimeType) {
     if(dndSupportsCustomMimeTypes) {
        return preferredCustomMimeType || customMimeType;
     }
     else if(dndSupportsJsonMimeType) {
        return jsonMimeType;
     }
     return textMimeType;
},
touchSupported = null,
formatRegexes = [
    /\{0\}/g,
    /\{1\}/g,
    /\{2\}/g,
    /\{3\}/g,
    /\{4\}/g,
    /\{5\}/g,
    /\{6\}/g,
    /\{7\}/g,
    /\{8\}/g,
    /\{9\}/g
],
isMobileApp = false,
isMobileAppSet = false,
suppressContextMenu = true,
CORE = {
    APPEND,
    buildDOMTree,
    setAttributes,
    EL,
     // Safari immediately fires a dragend event if you try to set an empty element
     // as a drag image. Use a 1px transparent gif instead.
    emptyDragImage:$('<img>').prop('src', 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7')[0],

    dndSetData:function(dataTransfer, dataString, preferredCustomMimeType) {
        var data = JSON.stringify({"idbcore-data":dataString}),
            customMime = preferredCustomMimeType || customMimeType, retVal;
        if(dndConfigured) {
            return dataTransfer.setData(getDndMimeType(preferredCustomMimeType), data);
        }
        try {
            retVal = dataTransfer.setData(customMime, data);
            dndSupportsCustomMimeTypes = true;
            dndSupportsJsonMimeType = true;
        } catch(err) {
            console.log("mime type not supported: " + customMime, err);
            try {
                retVal = dataTransfer.setData(jsonMimeType, data);
                dndSupportsJsonMimeType = true;
            } catch(err2) {
                console.log("application/json mime type not supported.", err2);
                retVal = dataTransfer.setData(textMimeType, data);
            }
        }
        dndConfigured = true;
        return retVal;
    },
    dndGetData:function(dataTransfer, preferredCustomMimeType) {
         if(!dndConfigured) {
            return null;
         }
         var mimeType, s, data;
         try {
            mimeType = getDndMimeType(preferredCustomMimeType);
            s = dataTransfer.getData(mimeType);
            if(!s) {
                return null;
            }

            if(s.indexOf("\"idbcore-data\"") < 0) { 
                // Whatever was dropped didn't set its data with dndSetData.
                return null;
            }

            try {
                data = JSON.parse(s);
                return data["idbcore-data"];
            } catch(err) {
                console.log("Error parsing as JSON: " + s);
                return null;
            }
         } 
         catch(err ) {
             console.log("Error getting data for mime type " + mimeType, s, err);
             return null;
         }
    },
    escapeHtml:function(string) {
      if(string === null || (typeof string === "undefined")) {
        return "";
      }
      return String(string).replace(/[&<>"'`=\/]/g, function (s) {
        return entityMap[s];
      });
    },

    /**
     * This first HTML-escapes the input string, replacing null or 
     * undefined with an empty string, and then replaces all of the
     * linebreak characters it contains with &lt;br&gt; tags. The
     * returned string will always be valid HTML. 
     */
    escapeHtmlAndReplaceLineBreaks:function(s) {
        return CORE.escapeHtml(s).replace(/\n/g, "<br>");
    },
    subclass:function subclass(superclassConstructor, subclassConstructor, subclassPrototype) {

        var proto = $.extend(Object.create(superclassConstructor.prototype), subclassPrototype);
        proto.constructor = subclassConstructor;
        subclassConstructor.prototype = proto;
        return subclassConstructor;
    },
    afterCompletion:function(func, timeoutMs) {
       var timeoutID, timeout = timeoutMs || 200;
       return function () {
          var scope = this , args = arguments;
          if(timeoutID !== undefined) {
            clearTimeout( timeoutID );
          }
          timeoutID = setTimeout( 
            function () {
              func.apply( scope , Array.prototype.slice.call( args ) );
            }, 
            timeout);
       };
    },
    round:function(value, decimals) {
      return Number(Math.round(value+"e"+decimals)+"e-"+decimals);
    },    

    /**
     * Returns true if the given select has one or more option 
     * elements with the given value.
     */
    valueInOptions:function($select, value) {
        return $("option[value='"+value+"']", $select).length > 0;
    },   

    //
    // IE doesn't support Array.find. See documentation for Array.find.
    //
    Array_find:function(arr, callback, thisArg) {
       if(!arr) return null;
       var filtered = arr.filter(callback, thisArg); 
       return (filtered.length > 0)?filtered[0]:null;
    },

    //
    // IE doesn't support Array.findIndex. See documentation for Array.findIndex.
    //
    Array_findIndex:function(arr, callback, thisArg) {
       if(!arr) return -1;
       for (var i = 0; i < arr.length; i++)
       {
           if (callback.call(thisArg, arr[i], i, arr))
           {
               return i;
           }
       }
       return -1;
    },

    //
    // IE doesn't support Object.values. See documentation for Object.values.
    //
    Object_values: function(obj) {
        return Object.keys(obj).map(function(key) { return obj[key]; });
    },

    /**
     * IE doesn't support Object.is. See documentation for 
     * Object.is. 
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is 
     */
    Object_is:function(x, y) {
        // SameValue algorithm
        if (x === y) { // Steps 1-5, 7-10
          // Steps 6.b-6.e: +0 != -0
          return x !== 0 || 1 / x === 1 / y;
        } 
        else {
          // Step 6.a: NaN == NaN
          return x !== x && y !== y;
        }
    },

    /**
     * Downloading a file.
     */
    downloadFile:function(url, request, errorCallback) {
        var $iframe = $("#idb-download"), $body = $("body"), $downloadForm;

        if($iframe.length === 0) {
            //
            // A form is used to submit the request because an AJAX request can't save a file directly to the user's computer for security reasons.
            // The form is necessary to force the opening of the browse window to allow the user to save the file.
            //
            // A hidden iframe is created as a target because we don't want the target to be another window or to replace the current window.
            // This reference gives some idea of why a hidden iframe is a solution to this issue.
            // https://stackoverflow.com/questions/3749231/download-file-using-javascript-jquery
            //
            $iframe = $("<iframe name='idb-download' id='idb-download'>").appendTo($body).hide();
        }
    
        $downloadForm = $("<form method='post' target='idb-download'><input type='hidden' name='json'>")
                                  .appendTo($body).hide();
        $downloadForm.attr({"action":url});
        var $input = $downloadForm.find('input');
        $input.val(JSON.stringify(request));

        //
        // This behaves strangely; calling onLoad multiple times without a fully loaded document. I have
        // spent way too long on this, so it will be a callback with a probably empty message.
        //
        if(errorCallback) {
            var doc = $iframe[0].contentWindow.document;
            
            $iframe.one("load", function() {
                              if(doc.body) {
                                  var msg = $(doc.body).find("#errmsg").text();
                                  if(errorCallback) {
                                      errorCallback(msg);
                                  }
                              }});
        }

        $downloadForm.submit().remove();
    },

    /**
     * This method is for cloning plain objects.
     * @template T
     * @param {T} obj
     * @returns {T}
     */
    deepClone:function(obj) {
        // DO NOT CHANGE HOW THIS METHOD WORKS.
        if(!obj) return obj;
        var json = JSON.stringify(obj);
        return JSON.parse(json);
    },
    /**
     * This will call JSON.parse with the given string parameter and 
     * return the result. If JSON.parse throws an error, it will be 
     * caught and null will be returned. 
     * 
     * @param jsonString 
     */
    safeParseJson(jsonString = null) {
        if(jsonString === null) {
            return null;
        }
        try {
            return JSON.parse(jsonString);
        }
        catch(err) {
            console.error("INVALID JSON: ", jsonString, err);
            return null;
        }
    },
    //
    // This only works if objects are 'plain objects' according to jQuery.isPlainObject. This is an
    // object created using {} or  'new Object'.
    //
    deepArrayCopy:function(toCopy) {
        return $.extend(true, [], toCopy);
    },

    /**
     * Copies properties from the object &quot;src&quot;, whose 
     * names appear in the array &quot;properties&quot;, to the 
     * object &quot;dest&quot;. The object &quot;dest&quot; is 
     * returned. 
     */  
    copyProperties:function(src, dest, properties) {
       if(!properties) {
           return dest;
       }
    
       properties.forEach(function(property) {
           dest[property] = src[property];
       });

       return dest; 
    },
    /**
     * This works similar to copyProperties, except that only the 
     * properties that exist in the src object (as determined by 
     * Object.hasOwnProperty) will be copied to the dest object. 
     * The dest object is returned. 
     */
    copyPropertiesThatExistOnSource:function(src, dest, properties) {
       if(!properties) {
           return dest;
       }
    
       properties.forEach(function(property) {
           if(src.hasOwnProperty(property)) {
            dest[property] = src[property];
           }
       });
       return dest; 
    },

    deletePropertiesWithUndefinedValues:function(obj) {
        if(!obj) {
            return;
        }
        for(var propName in obj) {
            if(obj.hasOwnProperty(propName)) {
                if("undefined" === typeof obj[propName]) {
                    delete obj[propName];
                }
            }
        }
    },

    deleteProperties:function(obj, properties) {
       if(!properties) {
           return;
       }
    
       properties.forEach(function(property) {
            delete obj[property];
       });
    },

    /**
     * This method copies all enumerable properties from the src to dest. This method includes enumerable inherited properties. 
     */
    copyAllProperties:function(src, dest) {
        /*jshint forin:false */
        for(var prop in src) {
            dest[prop] = src[prop];
        }
        return dest;
        /*jshint forin:true */
    },

    removeProperties:function(obj, properties) {
        if(!properties) {
            return;
        }

        properties.forEach(function(property) {
            delete obj[property];
        });
    },

    toInt:function(val, min, max) { // used to simulate integer arithmetic, and optionally normalize to a given range.
        val = Math.floor(val);
        if(min !== undefined && val < min) {
            return min;
        }
        if(max !== undefined && val > max) {
            return max;
        }
        return val;
    },
    isNil:function(val) {
        return ((typeof(val) === 'undefined') || (val === null));
    },
    isUndef:function(val) {
        return (typeof(val) === 'undefined');
    },
    ifUndef:function(val, valIfUndef) {
        return (CORE.isUndef(val) ? valIfUndef : val);
    },
    ifNil:function(val, valIfNil) {
        return (CORE.isNil(val) ? valIfNil : val);
    },
    isJQuery:function(obj) {
        /*jshint proto:true */
        if((typeof obj) !== "object") {
            return false;
        }
        try {
            if(obj instanceof jQuery) {
                return true;
            }
            return (obj.__proto__.constructor === jQuery);
        } catch(err) {
            console.log("isJQuery ERROR: ", err);  
            console.log(err);
            return ((typeof obj.jquery) === "string" && (typeof obj.selector) !== "undefined");
        }
        /*jshint proto:true */
    },
    rtrim:function(s) {
        if(!s) return s;
        return s.replace(/\s*$/, '');
    },
    ltrim:function(s) {
        if(!s) return s;
        return s.replace(/^\s*/, '');
    },
    trimToEmpty:function(s) {
        return s?s.trim():"";
    },
    trimToNull:function(s) {
        s = s?s.trim():null;
        return s?s:null;
    },
    trim:function(s) {
       if(!s) return s;
       return s.trim();
    },
    trimForType:function(s, dataType) {
        if(!s) return s;
        dataType = (dataType || "").toLowerCase();
        if(dataType === "string") {
           return CORE.rtrim(s);
        }
        else {
            return CORE.trim(s);
        }
    },
    trimValues:function(values, removeEmpty) {
        if(!values) {
            return values;
        }

        var newValues = [];
        values.forEach(function(value) {
                    value = CORE.rtrim(value);
                    if(value || !removeEmpty) {
                        newValues.push(value);
                    }
                  });

        return newValues;
    },
    escapeRegEx:function(str) {
        if(!str) return str;
        return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    },
     escapeToken:function(token, delimiterCharacter, escapeCharacter) {
        if(!escapeCharacter || escapeCharacter.length > 1) {
            throw new Error("core.escapeToken(): Invalid escapeCharacter: '" + escapeCharacter + "': escapeCharacter must be exactly one character.");
        }
        if(!delimiterCharacter || delimiterCharacter.length > 1) {
            throw new Error("core.escapeToken(): Invalid delimiterCharacter: '" + delimiterCharacter + "': delimiterCharacter must be exactly one character.");
        }
        if(delimiterCharacter == escapeCharacter) {
            throw new Error("core.escapeToken(): delimiterCharacter and escapeCharacter arguments may not be identical. (" + delimiterCharacter + ")");
        }
        if(!token) return "";
        if(token.indexOf(delimiterCharacter) < 0 && token.indexOf(escapeCharacter) < 0) return token;
        var escapedToken = '', currentChar, len = token.length, j = 0;
        for( ; j<len; j++) {
            currentChar = token.charAt(j);
            if(currentChar == delimiterCharacter || currentChar == escapeCharacter) escapedToken += escapeCharacter;
            escapedToken += currentChar;
        }
        return escapedToken;
     },
    assembleTokens:function(tokens, delimiterCharacter, escapeCharacter) {

        var j = 0, len = tokens.length, result = '';

        for(; j<len; j++) {
            if(j>0) result += delimiterCharacter;
            var val = tokens[j];
            if(val === undefined || val === null) continue; // empty string
            var token = val.toString();
            if(escapeCharacter) {
                token = CORE.escapeToken(token, delimiterCharacter, escapeCharacter);
            }
            result += token;
        }
        return result;
    },
    parseTokens:function(input, delimiter, esc) {
    	if ( esc && esc.length > 1 ) {
    		throw "StringUtils.parseTokens(): Invalid esc: '"+esc+
    			"': esc may not be more than one character.";
    	}

    	if ( !delimiter || delimiter.length > 1 ) {
    		throw "StringUtils.parseTokens(): Invalid separator: '"+delimiter+
    			"': separator must be exactly one character.";
    	}

    	if ( delimiter == esc ) {
    		throw "StringUtils.parseToken(): separator and esc arguments may not be identical. ("+
    			delimiter+")";
    	}

    	if ( input === null ) {
    		return [];
    	}

    	var len = input.length;
    	var currToken = '';
    	var j, currChar;
    	var tokens = [];

    	for ( j = 0 ; j < len; j++ ) {
    		currChar = input[j];

    		if ( esc == currChar ) {
    			if ( j == input.length-1 ) {
    				throw "StringUtils.parseTokens(): esc character '"+esc+
    					"' must be escaped itself when appearing at the end of the input string.";
    			}

    			currChar = input[++j];
    			currToken += currChar;
    			continue;
    		}

    		if ( currChar == delimiter ) {
    			tokens.push(currToken);
    			currToken = '';
    			continue;
    		}

    		currToken += currChar;
    	}

    	tokens.push(currToken);
    	return tokens;
    },
    pairsStringToMap:function(s, pairsDelimChar, delimChar, escapeChar) {
       pairsDelimChar = pairsDelimChar || ':';
       delimChar = delimChar || '|';
       escapeChar = escapeChar || '\\';

       var pairs = CORE.parseTokens(s, delimChar, escapeChar);
       if (!pairs.length) {
               return {};
       }
       
       var map = {};
       var i, pair;
       
       for ( i = 0 ; i < pairs.length ; ++i ) {
               pair = CORE.parseTokens(pairs[i], pairsDelimChar, escapeChar);
               map[pair[0]] = pair[1];
       }
       return map;
    },
    dedup:function(array) {
        if(!array) return [];
    
        return array.filter(function(item, pos) {
                               return array.indexOf(item) === pos;
                            });
    },
    objectHasMatchingProps:function(object, props) {
         var propName;

         if(!object) {
            return false;
         }
         if(!props) {
            return true; // treat props like an object with no properties
         }

         for(propName in props) {
             if(!props.hasOwnProperty(propName)) {
                continue; 
             }
             if(!object.hasOwnProperty(propName)) {
                return false;
             }
             if(object[propName] !== props[propName]) {
                return false;
             }
         }

         return true;
    },
    findArrayElementsWithMatchingProps:function(array, props) {
        var results = [], arr = array || [];
        arr.forEach(
            function(element) {
                if(CORE.objectHasMatchingProps(element, props)) {
                    results.push(element);
                }
            }
        );
        return results;
    },
    isValidEmail:function(email) {
       var regex  = /^[A-Z0-9_.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
       return regex.test(email);
    },
    returnFunctionThatReturnsArg:function(arg) {
        return function() {
            return arg;
        };
    },
    /**
     * Parse a languagePref setting to produce a BCP-47 language tag.
     *
     * @return {string} A BCP-47 language tag.
     */
    parseLangPref: function(langPref/*: string */)/*: string */ {
        var langCode = langPref.split(':')[0];

        return langCode.replace('|', '-');
    },
    format:function(bundle, key) {
        var temp, s, originalOptions = key, options = {escapeTemplate:false, replaceLinebreaks:false, escapeTokens:false};
        if(typeof key === "object") {
            options = $.extend(options, key);
            key = options.key;
        }

        if(!bundle) {
            return key;
        }


        temp = bundle[key];
        if(CORE.isNil(temp)) {
            console.error("i18n - Orphaned key: " + key);
            if(/\[TR\]$/.test(key)) {
                // Since the [TR] will indicate the error, we can
                // continue with the token substitutions.
                temp = key;
            }
            else {
                // Otherwise, try to make the failure more noticeable.
                return key;
            }
        }

//        // TODO: THIS IS TEMPORARY. IT SHOULD NOT BE RELEASED.
//        var prepend = 0 === key.indexOf("chart.config.GraphCommon.");
//        if(prepend) {
//            temp = "|" + temp;
//        }
//
//        if(bundle._COUNTS) {
//            var matches = key.match(/^chart\.config\.\w+ConfigPanel\.(.+)$/) ||
//                key.match(/^chart\.config\.GalleryConfigUIStrings\.(.+)$/),
//                propName = matches ? matches[1] : null;
//
//            if(bundle._COUNTS.hasOwnProperty(propName)) {
//                temp = temp + " " + bundle._COUNTS[propName];
//            }
//        }

        // END TEMP



        if(options.escapeTemplate) {
            temp = CORE.escapeHtml(temp);
        }

        if(options.replaceLinebreaks) {
            s = temp;
            temp = temp.replace(/\n/g, "<br>");
            originalOptions.lineBreaks = (s !== temp);
        }
        //
        // Dollar signs have special meaning in the replacement string, so rather than using the method
        // with a replacement string, a function will be called returning the string.
        //
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace
        //
        if(arguments.length > 2) {
           for(var j=2, jj=arguments.length; j<jj; j++) {
               var val = arguments[j];
               if(options.escapeTokens) {
                   val = CORE.escapeHtml(String(val));
               }
               var token = formatRegexes[j-2] || ( "{" + (j-2) + "}");
               temp = temp.replace(token, CORE.returnFunctionThatReturnsArg(val));
           }
       }
       return temp;
    },
    makeFmtFunction:function(bundle, prefix) {
        return function() { // The first argument to the returned function will be the suffix.
            var args = Array.prototype.slice.call(arguments), suffix = args.shift(), key = prefix + suffix;
            args.unshift(key);
            args.unshift(bundle);
            return CORE.format.apply(CORE, args);
        };
    },
    leftClickListener:function(listenerObject, methodName){
        return function(e){
            // The konva event does not have the which property, that must
            // be read from the orginal event, which is attached to the 
            // konva event as the evt property.
            var evt = e ? (e.evt || e) : null; 
            if(evt && evt.which && evt.which !== 1) {
                return;
            }
            return listenerObject[methodName].apply(listenerObject, arguments); 
        };
    },
    arraysAreEqualAsSets:function(arr1, arr2, equalFunction) {
        if(CORE.isNil(arr1)) {
            return CORE.isNil(arr2);
        }

        if(CORE.isNil(arr2)) {
            return false;
        }

        if(arr1.length !== arr2.length) {
            return false;
        }

        var callback = function(v1, v2) {
                            if(!CORE.isNil(equalFunction)) {
                                return equalFunction(v1, v2);
                            }
                            else {
                                return v1 === v2;
                            } 
                       };

        return arr2.every(function(v1) {
                               return arr1.some(callback.bind(null, v1));
                         });
    },
    copyProps:function(fromObj, toObj, preserve) {
       for(var propName in fromObj) {
           if(fromObj.hasOwnProperty(propName)) {
               if(!preserve) {
                   toObj[propName] = fromObj[propName];
               }
               else if(!(propName in toObj)) {
                   toObj[propName] = fromObj[propName];
               }
           }
       }
       return toObj;
    },
    debuggerEnabled:false,
    debugger:function() {
        if(!CORE.debuggerEnabled) {
            return;
        }
        /*jshint debug:true */
            debugger;

        /*jshint debug:false */ 
    },
    /**
     * <p>Returns an object containing position and size information (top, left, width, height, css) 
     * for a child object, based on the parent object's width and height, desired margins (top, left, bottom, right),
     * and constraints on the size of the child object (maxWidth, maxHeight, minWidth, minHeight, and optionally absMinWidth and absMinHeight). The top, left,
     * width and height properties of the returned object will all be numbers, however the returned object will
     * also have a property named "css", which is an object containing the top, left, width and height properties
     * in CSS-compatible "Npx" format.</p>
     *
     * <p>There will always be minWidth and minHeight constraints in effect, and neither will be below 1px. (Default is 10).
     * If the maxWidth is missing or less than the applied minWidth, then the child will be allowed to extend to the
     * left and right margins, and accordingly, if the maxHeight is missing or less than the applied minHeight,
     * then the child will be allowed to extend to the top and bottom margins.</p>
     * 
     * <p>When minWidth or minHeight is reached, the margins will be shrunk, however, when the margins are 0, the object will be shrunk, but not smaller
     * than absMinWidth X absMinHeight.</p>
     */
    getChildPos:function(parentWidth, parentHeight, margins, constraints) {
        
        margins = $.extend({top:0, right:0, bottom:0, left:0}, margins);
        constraints = $.extend({maxWidth:0, maxHeight:0, minWidth:10, minHeight:10, absMinWidth:0, absMinHeight:0}, constraints);
    
        var w = parentWidth, h = parentHeight, M = margins, C = constraints, 
            tm = M.top, bm = M.bottom, lm = M.left, rm = M.right,
            verM = tm + bm, horM = lm + rm,
            maxW = C.maxWidth, maxH = C.maxHeight,
            minW = Math.max(C.minWidth, 1), 
            minH = Math.max(C.minHeight, 1),  
            absMinW = (C.absMinWidth > 0 ? Math.min(C.absMinWidth,  minW) : minW),
            absMinH = (C.absMinHeight > 0 ? Math.min(C.absMinHeight,  minH) : minH),
            availW = w - horM, availH = h - verM, shortage = 0,
            A, G, childWidth, childHeight, leftPart, topPart;
    
    //        IDB.log("w=" + w + ", h=" + h + ", minH=" + minH + ", minW=" + minW + ", maxH=" + maxH + ", maxW=" + maxW
    //           + ", availW=" + availW + ", availH=" + availH);
    
        // If there isn't enough width between the margins, reduce them proportionally to gain the needed space.
        if((availW < minW) && (horM > 0)) {
            shortage = minW - availW;
            leftPart = ((lm / horM) * shortage );
//            IDB.log("GFrame.resize(): shortage=" + shortage + ", leftPart=" + leftPart);
            lm = Math.max(0, lm - leftPart);
            rm = Math.max(0, rm - (shortage - leftPart));
            horM = lm + rm;
        }
    
        if((availH < minH) && (verM > 0)) {
            shortage = minH - availH;
            topPart = ((tm / verM) * shortage);
//            IDB.log("GFrame.resize(): topPart=" + topPart + ", shortage=" + shortage);
    
            tm = Math.max(0, tm - topPart);
            bm = Math.max(0, bm - (shortage - topPart));
            verM = tm + bm;
        }
    
        // Rectangle for the margins.
        A = {x:lm, y:tm, width:w-rm-lm, height:h-tm-bm};
    
        // always honor the minimum dimensions
        if(horM === 0) {
            childWidth = Math.max(absMinW, A.width);
        }
        else {
            childWidth = Math.max(minW, A.width);
        }
        if(verM === 0) {
            childHeight = Math.max(absMinH, A.height);
        }
        else {
            childHeight = Math.max(minH, A.height);
        }
    
        // apply a max dimension only if it is >= the corresponding min dimension,
        // otherwise, let it fill out the margins in that direction.
        if(maxW >= minW) {childWidth = Math.min(childWidth, maxW);}
        if(maxH >= minH) {childHeight = Math.min(childHeight, maxH);}
    
        G = {
             left:Math.max(0, A.x + (0.5*A.width) - (0.5*childWidth)), 
             top:Math.max(0, A.y + (0.5*A.height) - (0.5*childHeight)),
             width:childWidth, 
             height:childHeight
        };
    
        G.css = {
            left:G.left + "px",
            top:G.top + "px",
            width:G.width + "px",
            height:G.height + "px"
        };
    
        return G;
    
    },

    /**
     * Can be used as an event listener. Calls 
     * stopImmediatePropagation on the passed-in event. 
     */
    stopImmediatePropagation:function(evt) {
         if(evt) {
            evt.stopImmediatePropagation();
         }
    },

    /**
     * Can be used as an event listener. Calls 
     * stopPropagation on the passed-in event. 
     */
    stopPropagation:function(evt) {
         if(evt) {
            evt.stopPropagation();
         }
    },

    /**
     * Can be used as an event listener. Calls 
     * preventDefault on the passed-in event. 
     */
    preventDefault:function(evt) {
         if(evt) {
            evt.preventDefault();
         }
    },

    /**
     * Calls preventDefault then stopImmediatePropagation on the 
     * passed-in event. 
     */
    killEvent:function(evt) {
         if(evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
         }
    },


    /**
     * Adds _idbIgnore:true to the passed in event, and the same for 
     * its originalEvent property value if it has one. This is used 
     * in various places where calling preventDefault, 
     * stopPropagation, or stopImmediatePropagation would have 
     * undesirable effects. 
     */
    addIdbIgnoreFlag:function(evt) {
        if(evt) {
            evt._idbIgnore = true;
            if(evt.originalEvent) {
                evt.originalEvent._idbIgnore = true;
            }
        }
    },

    hasIdbIgnoreFlag:function(evt) {
        if(evt) {

            // check the originalEvent first.
            if(evt.originalEvent) {
                if(evt.originalEvent.hasOwnProperty("_idbIgnore")) {
                    return evt.originalEvent._idbIgnore; 
                }
            }

            if(evt.hasOwnProperty("_idbIgnore")) {
                return evt._idbIgnore; 
            }

            return false;
        }
    },

   insertText:function($textItem, toInsert, maxLength) {
       if(!toInsert) return;

       var textItem = $textItem[0],
       selectionStart = textItem.selectionStart,
       selectionEnd = textItem.selectionEnd,
       originalText = $textItem.val(),
       preSlice = originalText.slice(0, selectionStart),
       postSlice = originalText.slice(selectionEnd),
       text = preSlice + toInsert + postSlice,
       insertionPoint = selectionEnd + toInsert.length;

       if(!maxLength || text.length < maxLength) {
           $textItem.val(text);
           textItem.selectionStart = insertionPoint;
           textItem.selectionEnd = insertionPoint;
           $textItem.trigger("input");
           $textItem.focus();
       } else {
           console.warn('max length exceeded.');
       }
   },

    /**
     * Prevents relative URLs. Any URL that does not begin with /, 
     * http://, or https:// is prefixed with http://. 
     */ 
    normalizeUrl:function(url) {
        if(!url) {return url;}
        if(url.indexOf("/") !== 0 && url.indexOf("http://") !== 0 && 
            url.indexOf("https://") !== 0) {
            url = "http://" + url;
        }
        return url;
    },

    /**
     * Useful for titlebars and similiar things. Prevents text from 
     * wrapping, and adds an ellipsis (...) when the text is too 
     * long to be entirely displayed. The text inside the $item
     * is also set as its title attribute (tooltip.) 
     */
    textEllipsisAndTooltip:function($item) {

        $item.css({"overflow":"hidden",
                   "white-space":"nowrap",
                   "text-overflow":"ellipsis"})
             .attr('title', $item.text());
    },

    /**
     * Returns an object that can be passed into 
     * $(target).positionui, that will cause the $(target)
     * element to be centered over $of. 
     */
    center:function($of) {
        return {my:"center", at:"center", of:$of};
    },

    isScrolledIntoView:function($element, $container) {
        var containerViewTop = $container.offset().top,
        containerViewBottom = containerViewTop + $container.height(),
        elementTop = $element.offset().top,
        elementBottom = elementTop + $element.height();

        return (elementBottom <= containerViewBottom) && (elementTop >= containerViewTop);
    },


    //
    // http://stackoverflow.com/questions/4880381/check-whether-html-element-has-scrollbars
    //
    isScrolling:function(node) {
      if(CORE.isJQuery(node)) {
          node = node.get(0);
      }

      var overflowY = window.getComputedStyle(node)['overflow-y'];
      var overflowX = window.getComputedStyle(node)['overflow-x'];
      return {
        vertical: (overflowY === 'scroll' || overflowY === 'auto') && node.scrollHeight > node.clientHeight,
        horizontal: (overflowX === 'scroll' || overflowX === 'auto') && node.scrollWidth > node.clientWidth
      };
    },

    isScrollingVertically:function(element) {
        var scrolling = CORE.isScrolling(element);
        return scrolling.vertical;
    },

    isScrollingHorizontally:function(element) {
        var scrolling = CORE.isScrolling(element);
        return scrolling.horizontal;
    },
    getScrollingParent:function($element, direction) {
       for(var $parent = $element.parent(); ($parent !== null && $parent.get(0) !== document); $parent = $parent.parent()) {
           var scrolling = CORE.isScrolling($parent);
           if(direction) {
               if(scrolling[direction]) {
                   return $parent;
               }
           }
           else {
               if(scrolling.horizontal || scrolling.vertical) {
                   return $parent;
               }
           }
       }
       return null;
    },
    overflows:function(element) {
        return element.scrollWidth - element.clientWidth > 0 || element.scrollHeight - element.scrollHeight > 0;
    },
    /**
     * <p>This is a utility function that can be used by functions 
     * that have a variable argument list with lots of optional 
     * arguments. The argument list of the function can look like:
     * </p><p> stringArg1, [stringArg2], functionArg1, 
     * [functionArg2], arrayArg1, [arrayArg2], jQueryArg1, 
     * [jQueryArg2], plainObjectArg1, [plainObjectArg2] 
     * </p><p> 
     * The argument for this function should be the 
     * arguments object of the calling function. The return value is
     * a plain object with properties named strings, functions, 
     * arrays, jqueries, objects, and the values of the properties 
     * are arrays, containing the args of the respective types, in 
     * their original order.
     * </p> 
     */
    coalesceArgs:function(anArgumentsObject) {
        var args = Array.prototype.slice.call(anArgumentsObject),
            strings = [], functions = [], arrays = [], objects = [], jQueries = [],
    //        all = [strings, functions, arrays, objects, jQueries],
            arg, argType, isNothing, isArray, isJQuery, isPlainObject;

        // If the first arg is
        // an object previously returned by this method, simply return that
        // object.

        if((typeof args[0]) === "object" && args[0].__is_coalesced_args === true) {
            return args[0];
        }


        // Gather the strings at the beginning of the array.
        while(args.length) {
            arg = args.shift();
            argType = typeof arg;
            isNothing = (arg === null || argType === "undefined");
            if(argType === "string" || isNothing) {
                strings.push(arg);
            }
            else {
                args.unshift(arg);
                break;
            }
        }

         // Gather any functions that follow the strings
        while(args.length) {
            arg = args.shift();
            argType = typeof arg;
            isNothing = (arg === null || argType === "undefined");
            if(argType === "function" || isNothing) {
                functions.push(arg);
            }
            else {
                args.unshift(arg);
                break;
            }
        }

         // Gather any arrays that follow the functions
        while(args.length) {
            arg = args.shift();
            isNothing = (arg === null || argType === "undefined");
            isArray = Array.isArray(arg);
            if(isArray || isNothing) {
                arrays.push(arg);
            }
            else {
                args.unshift(arg);
                break;
            }
        }

         // Gather any jQuery objects that follow the arrays
        while(args.length) {
            arg = args.shift();
            argType = typeof arg;
            isNothing = (arg === null || argType === "undefined");
            isJQuery = arg instanceof jQuery;
            if(isJQuery || isNothing) {
                jQueries.push(arg);
            }
            else {
                args.unshift(arg);
                break;
            }
        }

         // Gather any plain objects that follow the jQueries
        while(args.length) {
            arg = args.shift();
            argType = typeof arg;
            isNothing = (arg === null || argType === "undefined");
            isJQuery = arg instanceof jQuery;
            isArray = Array.isArray(arg);
            isPlainObject = !isNothing && !isJQuery && !isArray && argType === "object";
            
            if(isPlainObject || isNothing) {
                objects.push(arg);
            }
            else {
                break; // argument out of order.
            }
        }

        return {
            strings:strings,
            functions:functions,
            arrays:arrays,
            jqueries:jQueries,
            objects:objects,
            __is_coalesced_args:true
        };
    },
    b64EncodeUnicode:function(str) { // From https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
        }));
    },
    b64DecodeUnicode:function(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    },
    toSvgDataUrl:function(svgXmlString) {
        return "url(\"data:image/svg+xml;base64," + CORE.b64EncodeUnicode(svgXmlString) + "\")";
    },

    bytesToBase64(bytes) {
        const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join("");
        return btoa(binString);
    },
    /**
     * This returns a string that can be used as the template option
     * for a bootstrap popover. It is only needed when a popover is 
     * appearing above something it should not be above. It sets the 
     * z-index using nextZIndex(). Otherwise, the z-index for the 
     * .popover CSS class is set to 4000 in idbadmin.css. The 
     * default in boostrap.css is 1060, which puts it below all of
     * our dialogs. The code for this function should be checked 
     * against boostrap popover's code each time we upgrade 
     * bootstrap. This was checked against bootstrap 4.1.2. 
     */
    popoverTemplate:function() {  

        return '<div class="popover" style="z-index:' +
            CORE.nextZIndex() +
            ';" role="tooltip">' + 
            '<div class="arrow"></div>' + 
            '<h3 class="popover-header"></h3>' + 
            '<div class="popover-body"></div></div>';
    },
    /**
     * Used by the popupmgr and combolist modules.
     */
    nextZIndex:function() {
        return nextZIndex++;
    },
    isIOS:function() {
        return isIOS;
    },
    isAndroid:function() {
        return isAndroid;
    },
    isChrome:function() {
        return isChrome;
    },
    maybeInvokeFunction:function(obj, funName) {
        var args = Array.prototype.slice.call(arguments),
            func = obj[funName];
        if(func && (typeof func === 'function')) {
            return func.apply(obj, args.slice(2));
        }
        else {
             return null;
        }
     },
     addPlaceholderToSelect:function($select, placeholder) {
         var $firstOption = $(":first-child", $select);
         $select.addClass('idb-select-with-placeholder');
         if (!$firstOption.is('.js-select-placeholder'))
         {
 //            $('<option selected value="">').insertBefore($("option:first", $select)).text(placeholder).css({"display":"none"});

             // IE doesn't honor CSS on option elements. The styling here is only
             // for visual purposes in compliant browsers and should not be
             // relied on by the code.
             $('<option value="">').prependTo($select).text(placeholder).addClass('js-select-placeholder').css({"display":"none"});
             $select
                 .val("")
                 .on
                 (
                     "change",
                     function()
                     {
                         var $firstOption = $("option:first", $select);
                         if ($firstOption.is('.js-select-placeholder'))
                         {
                             $firstOption.remove();
                         }
                     }
                 );
         }
     },

     /** 
      * booleanLenient means "true" strings will evaluate to true and "false" will evaluate to false.
      **/ 
     compare:function(obj1, obj2, booleanLenient) {
         var printObjects = function(obj1, obj2) {
//            console.log("obj1=" + JSON.stringify(obj1)); 
//            console.log("obj2=" + JSON.stringify(obj2));
         };
   
         if(obj1 === obj2) return true;
   
         if(obj1 === null || obj2 === null) return false;
         if(obj1 === undefined || obj2 === undefined) return false;

         // Could probably first obtain list of own properties of both objects via Object.getOwnPropertyNames and compare the lists.
   
         // Loop through properties in obj1
         for (var p in obj1) {
         /*jshint forin:false */
         //Check property exists on both objects
             if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                 console.log("obj2 doesn't contain property " + p);
                 printObjects(obj1, obj2);
                 return false;
             }
             switch (typeof (obj1[p])) {
                 //Deep compare objects
                 case 'object':
                    if (!CORE.compare(obj1[p], obj2[p], booleanLenient)) {
                        console.log("obj1 and obj2 are different for property " + p);
                        printObjects(obj1, obj2);
                        return false;
                    }
                    break;
                 //Compare function code
                 case 'function':
                    if (typeof (obj2[p]) == 'undefined' ||
                                          (p != 'compare' && obj1[p].toString() != obj2[p].toString())) {
                        console.log("obj1 and obj2 are different for property " + p);
                        printObjects(obj1, obj2);
                        return false;
                    }
                    break;
                 //Compare values
                 default:
                    if (obj1[p] != obj2[p]) {
                        if(booleanLenient) {
                            var obj1Type = typeof obj1[p];
                            var obj2Type = typeof obj2[p];
                            if(obj1Type === "string" && obj2Type === "boolean") {
                               if(obj1[p] === obj2[p].toString()) {
                                   return true;
                               }
                            }
                            if(obj2Type === "string" && obj1Type === "boolean") {
                               if(obj2[p] === obj1[p].toString()) {
                                   return true;
                               }
                            }
                        }
                        console.log("obj1 and obj2 are different for property " + p);
                        printObjects(obj1, obj2);
                        return false;
                    }
             }
         }
   
         //Check object 2 for any extra properties
         for (p in obj2) {
             if (!obj1.hasOwnProperty(p)) { 
                 console.log("obj1 doesn't contain property " + p);
                 printObjects(obj1, obj2);
                 return false;
             }
         }
         /* jshint forin:true */
         return true;
     },
     /** 
      * This function considers null and undefined to be equivalent.
      **/ 
     compareNullUndefinedEquivalent:function(obj1, obj2) {
         var printObjects = function(obj1, obj2) {
            console.log("obj1=" + JSON.stringify(obj1));
            console.log("obj2=" + JSON.stringify(obj2));
         };
   
         if(obj1 === obj2) return true;
   
         if(obj1 === null || obj2 === null) return false;
         if(obj1 === undefined || obj2 === undefined) return false;

         // Could probably first obtain list of own properties of both objects via Object.getOwnPropertyNames and compare the lists.
   
         // Loop through properties in obj1
         for (var p in obj1) {
         /*jshint forin:false */
             if(CORE.isNil(obj1[p]) && CORE.isNil(obj2[p])) {
                 continue;
             }

             //Check property exists on both objects
             if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                 console.log("obj2 doesn't contain property " + p);
                 printObjects(obj1, obj2);
                 return false;
             }
             switch (typeof (obj1[p])) {
                 //Deep compare objects
                 case 'object':
                    if (!CORE.compareNullUndefinedEquivalent(obj1[p], obj2[p])) {
                        console.log("obj1 and obj2 are different for property " + p);
                        printObjects(obj1, obj2);
                        return false;
                    }
                    break;
                 //Compare function code
                 case 'function':
                    if (typeof (obj2[p]) == 'undefined' ||
                                          (p != 'compare' && obj1[p].toString() != obj2[p].toString())) {
                        console.log("obj1 and obj2 are different for property " + p);
                        printObjects(obj1, obj2);
                        return false;
                    }
                    break;
                 //Compare values
                 default:
                    if (obj1[p] != obj2[p]) {
                        console.log("obj1 and obj2 are different for property " + p);
                        printObjects(obj1, obj2);
                        return false;
                    }
             }
         }
   
         //Check object 2 for any extra properties
         for (p in obj2) {
             if(CORE.isNil(obj2[p]) && CORE.isNil(obj1[p])) {
                 continue;
             }
             if (!obj1.hasOwnProperty(p)) { 
                 console.log("obj1 doesn't contain property " + p);
                 printObjects(obj1, obj2);
                 return false;
             }
         }
         /* jshint forin:true */
         return true;
     },

     compareProperties:function(obj1, obj2, propertiesToCompare, booleanLenient) {
        var a1 = {};
        CORE.copyProperties(obj1, a1, propertiesToCompare);
        var a2 = {};
        CORE.copyProperties(obj2, a2, propertiesToCompare);
        return CORE.compare(a1, a2, booleanLenient);
     },

     comparePropertiesNullUndefinedEquivalent:function(obj1, obj2, propertiesToCompare) {
        var a1 = {};
        CORE.copyProperties(obj1, a1, propertiesToCompare);
        var a2 = {};
        CORE.copyProperties(obj2, a2, propertiesToCompare);
        return CORE.compareNullUndefinedEquivalent(a1, a2);
     },

     emptyToNull:function(obj) {
         for (var p in obj) {
             if(typeof obj[p] === "string" && !obj[p]) {
                 obj[p] = null;    
             }
             else {
                 if(typeof obj[p]  === "object") {
                     CORE.emptyToNull(obj[p]);
                 }
             }
         }
     },

     // The following methods named is...Axis are designed to
     // work with graphcore.Axis instances, or plain objects that
     // have the basic properties that an Axis has.
     isHiddenAxis:function(axis) {

         // unfortunately, both of these are used in various 
         // places. The Axis class uses isHidden, while JSON representations
         // of axes use hidden.
         if(typeof axis.isHidden === "boolean") {
             return axis.isHidden;
         }
         if(typeof axis.hidden === "boolean") {
             return axis.hidden;
         }
         return false;
     },
     isXAxis:function(axis) {
         if(typeof axis.axisId !== "number") {
             return false;
         }
         return axis.axisId === 0;
     },
     isNumericAxis:function(axis) {
         return (axis.dataType || "").toUpperCase() === "NUMBER";
     },
     isYAxis:function(axis) {
         if(typeof axis.axisId !== "number") {
             return false;
         }
         return axis.axisId > 0;
     },
     isPivotAxis:function(axis) {
         if(typeof axis.isPivot === "boolean") {
             return axis.isPivot;
         }
         if(typeof axis.pivotRank === "number") {
             return axis.pivotRank > 0;
         }
         return false;
     },
     isYGraphAxis:function(axis) {
         return CORE.isYAxis(axis) && !CORE.isHiddenAxis(axis) && !CORE.isPivotAxis(axis);
     },
     isNumericYGraphAxis:function(axis) {
         return CORE.isYGraphAxis(axis) && CORE.isNumericAxis(axis);
     },
     isGraphAxis:function(axis) {
        return CORE.isXAxis(axis) || CORE.isYGraphAxis(axis);
     },
     isGraphAxisNumericYOnly:function(axis) {
        return CORE.isXAxis(axis) || CORE.isNumericYGraphAxis(axis);
     },
     getAxisValueType:function(axis) {
        if(!CORE.isNil(axis.aggFunctionId) && axis.aggFunctionId >= 0) return "Number";
        else return axis.dataType;
     },

    axesAreEqual: function (a1 /*: FvAxis */, a2 /*: FvAxis */) {
        var same =
            a1 === a2 ||
            (a1.axisName === a2.axisName &&
             a1.dataType === a2.dataType &&
             a1.isPivot === a2.isPivot &&
             CORE.isHiddenAxis(a1) === CORE.isHiddenAxis(a2) &&
             a1.axisId === a2.axisId);

         if (!same) {
             console.log('Different:', a1, a2);
         }

         return same;
     },

    areAxisListsCompatible: function (
        oldAxes /*: FvAxis[] */,
        newAxes /*: FvAxis[] */
    ) /*: boolean */ {
        if (oldAxes.length !== newAxes.length) {
            return false;
        }

        for (let j = 0, jj = newAxes.length; j < jj; j++) {
            if (!CORE.axesAreEqual(newAxes[j], oldAxes[j])) {
                return false;
            }
        }

        return true;
    },

     /**
      * Install the service worker.
      *
      * @param  {string} url The URL of the service worker.
      *
      * @return {Promise}    A promise that resolves to a service worker
      *                      registration or null if service workers are not
      *                      supported.
      */
     installServiceWorker: function (url/*: string */) {
        if ('serviceWorker' in navigator) {
            return navigator.serviceWorker.register(url);
        } else {
            return null;
        }
     },

     /**
      * This should be the single source of truth for determining if 
      * touch support is available. There is currently no 100% 
      * reliable method, so it errs on the side of saying touch is 
      * available. This is fine for our purposes, it just means that 
      * TouchHandlers will be listening for touch events that are 
      * never going to be dispatched. 
      *
      * @return {boolean} true if touch is supported.
      */
     touchSupported:function() {
        if(touchSupported === null) {
            try {
                touchSupported = !!(('ontouchstart' in window) || window.TouchEvent || window.DocumentTouch && document instanceof DocumentTouch);
                console.log("touchSupported: ", touchSupported);
            }
            catch(err) {
                touchSupported = true;
                console.error(err);
            }
        }
        return touchSupported;
     },
    setSessionTimeoutManager:function(mgr) {
        // this is called by the session module when it's loaded. It
        // passes itself into this method.
        sessionTimeoutMgr = mgr;
    },
    resetKeepAlive:function() {
        if(sessionTimeoutMgr) {
            sessionTimeoutMgr.resetKeepAlive();
        }
    },
    maybeResetKeepAlive:function(datapacket) {
        if(!datapacket || !datapacket.hasOwnProperty("confirmation")) {
            return;
        }

        if(datapacket.confirmation !== "timeout") {
            CORE.resetKeepAlive();
        }
    },
    getText:function(element, firstOnly) {
        var nodes = element.childNodes, result = '';
        firstOnly = CORE.isNil(firstOnly) || firstOnly;

        for(var i = 0; i < nodes.length; i++) {
            if(nodes[i].nodeType === Node.TEXT_NODE) {  
                result += nodes[i].nodeValue;  
                if(firstOnly) break;
            }
        }
        return result;
    },
    focusSetter:function($ctrl, maybeDelay) {
        if(maybeDelay === 0 || maybeDelay > 0) {
            return () => {
                setTimeout(() => {
                    $ctrl.focus();    
                }, maybeDelay);
            };
        }
        return function() {
            $ctrl.focus();
        };
    },
    booleanize:function(s) {
        if(typeof s !== "string") return !!s;
        return s.toLowerCase() === "true";
    },
    isOnDOM:function(element, returnOnError) {
        if(!element) {
            return false;
        }
        try {
            return document.body.contains(element);
        }
        catch(err) {
            console.error(err);
            console.error("returnOnError", returnOnError);
        }

        if("boolean" === (typeof returnOnError)) {
            return returnOnError;
        }
        return true;
    },
    PromiseControl:function PromiseControl() {
        var me = this, resolve = null, reject = null;

        const executor = function (resolveCallback, rejectCallback) {
            resolve = resolveCallback;
            reject = rejectCallback;
        },
        promise = new Promise(executor);

        me.promise = function() {
            return promise;
        };

        me.resolve = function() {
            if(!resolve) {
                return;
            }
            var args = Array.prototype.slice.call(arguments);
            resolve.apply(null,  args);
        };

        me.reject = function() {
            if(!reject) {
                return;
            }
            var args = Array.prototype.slice.call(arguments);
            reject.apply(null,  args);
        };
    },
    transitionStartFilter:function(evt) {
        var oe = evt.originalEvent;
        if(oe.eventPhase === 2) {
//            console.log("TRANSITION START - " + oe.propertyName);
            $(evt.currentTarget).trigger("transitionstart:" + (oe.propertyName || "").replace(/-/g, "_"));
        }
    },
    transitionEndFilter:function(evt) {
        var oe = evt.originalEvent;
        if(oe.eventPhase === 2) {
//            console.log("TRANSITION END - " + oe.propertyName);
            $(evt.currentTarget).trigger("transitionend:" + (oe.propertyName || "").replace(/-/g, "_"), [oe]);
        }
    },
    isElementFocusable:function(element) {
        if(!element) {
            return false;
        }
        return !!focusableElements[element.tagName];
    },
    setIsMobileApp:function(b) {
        var next = !!b;
        if(isMobileAppSet) {
            if(isMobileApp !== next) {
                console.warn("isMobileApp has already been set to " + isMobileApp);
            }
            return;
        }
        isMobileAppSet = true;
        isMobileApp = next;
    },
    /**
     * This is a global flag, that can only be set once, indicating 
     * whether or not this code is running in a mobile app. 
     */
    isMobileApp:function() {
        return isMobileApp;
    },

    suppressContextMenu:function(evt) {
        if(evt === true) {
            suppressContextMenu = true;
            return;
        }

        if(evt === false) {
            suppressContextMenu = false;
            return;
        }

        if(suppressContextMenu === false) {
            return;
        }

        if(evt) {
            evt.preventDefault();
        }
    },
    enableTextInputContextMenu:function($container) {
        $container.find("input[type=text]").on("contextmenu", CORE.stopPropagation);
        $container.find("textarea").on("contextmenu", CORE.stopPropagation);
    },

    /**
     * If val is a valid (not NaN) Number, it is returned. If it is 
     * an empty string, nullish, or a value for which 
     * Number(val) returns NaN, then defaultVal is returned. 
     * Otherwise, Number(val) is returned. 
     * 
     * 
     * @param val any value of any type.        
     * @param defaultVal should either be a valid Number, or nullish 
     *                   or omitted, in which case 0 will be used as
     *                   the default value.
     */
    makeNumber(val, defaultVal) {
        defaultVal = defaultVal ?? 0;
        if("number" === typeof val) {
            return isNaN(val) ? defaultVal : val;
        }
        if(val === "" || val === null) {
            // Number(val) would be 0, which we don't want.
            return defaultVal;
        }
        if(!isNaN(Number(val))) {
            return Number(val);
        }
        return defaultVal;
    },

    htmlIsWhitespace(html) {
        if(!html) {
            return true;
        }
        let e = document.createElement("div");
        e.innerHTML = html;
        return ! e.textContent.trim();
    },

//
// from https://github.com/nuxodin/lazyfill/blob/main/polyfills/Element/prototype/scrollIntoViewIfNeeded.js
//
    scrollIntoViewIfNeeded(el, centerIfNeeded = true) {
        new IntersectionObserver( function( [entry] ) {
            const ratio = entry.intersectionRatio;
            if (ratio < 1) {
                let place = ratio <= 0 && centerIfNeeded ? 'center' : 'nearest';
                el.scrollIntoView( {
                    block: place,
                    inline: place,
                } );
            }
            this.disconnect();
        } ).observe(el);
    },

    hover(el, enter, leave) {
        el.addEventListener('mouseenter', enter);
        el.addEventListener('mouseleave', leave);
    },

    // Adapted from https://dev.to/bmsvieira/vanilla-js-fadein-out-2a6o
    fadeOut(el) {
        el.style.opacity = 1;
        (function fade() {
            if ((el.style.opacity -= 0.05) < 0) {
                 el.style.visibility = "hidden";
            } else {
                requestAnimationFrame(fade);
            }
        })();
    }, 

    fadeIn(el) {
        el.style.opacity = 0;
        el.style.visibility = "inherit";
        (function fade() {
            let val = parseFloat(el.style.opacity);
            if ((val += 0.05) <= 1) {
                el.style.opacity = val;
               requestAnimationFrame(fade);
            }
        })();
    },

    fadeOnHover(el) {
        el.addEventListener('mouseenter', () => CORE.fadeIn(el));
        el.addEventListener('mouseleave', () => CORE.fadeout(el));
    }



}; // end CORE


// By default, the browser's context menu will be suppressed wherever this
// module is loaded. (Which is everywhere).
$body.on("contextmenu", CORE.suppressContextMenu);
// To override it, set a contextmenu event handler on a descendant of
// the body, and call its stopPropagation method, to prevent it from
// bubbling up to the body element.


/**
 * Remove diacritics (accents, etc.) from characters on a string.
 *
 * @param  {string} term The string to remove the diacritics from.
 *
 * @return {string}      The string without diacritics.
 */
CORE.removeDiacritics = function(term) {
    return term.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

if (crypto.randomUUID) {
    CORE.randomUUID = function () {
        return crypto.randomUUID();
    };
} else {
    // From: https://stackoverflow.com/a/2117523/1141784
    CORE.randomUUID = function () {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) // jshint ignore:line
      );
    };
}


CORE.NumberUtils = {};


Object.defineProperty(CORE.NumberUtils, "DNF", {  /* Default Number Format */
   value:{"al":"left","ts":",","ut":false,"ds":".","cs":"","pr":-1,"rd":"none","un":true},
   writable:false,
   configurable:false});

const splitNum = (n) => {
    let s = String(n), parts = s.split(/\./),
        placesBefore = parts[0].length,
        placesAfter = parts[1] ? parts[1].length : 0,
        totalPlaces = placesBefore + placesAfter;

    return {
        placesBefore,
        placesAfter,
        totalPlaces
    };
};

let numFormatCalls = 0;
CORE.NumberUtils.format = function (n, format, numberAbbreviation, suppressLog) {
        //console.log(n, "INPUT NUMBER");
        numFormatCalls++;
        if(!suppressLog) {
            console.log(numFormatCalls, "NumberUtils.format: ",  n, format, numberAbbreviation || "");
        }
        if(n === null || CORE.isUndef(n) || isNaN(n)) {return "";}
        var alignSymbol = "left";
        var thousandsSeparator = "";
        var useThousandsSeparator = false;
        var decimalSeparator = ".";
        var currencySymbol = "";
        var precision = -1;
        var rounding  =  "none";
        var useNegativeSign = true;
        var numAbbrev = numberAbbreviation || "auto";
        var numAbbrevSuffix = "";

//        map.put("al", alignSymbol);
//        map.put("ts", thousandsSeparator);
//        map.put("ut", useThousandsSeparator);
//        map.put("ds", decimalSeparator);
//        map.put("cs", currencySymbol);
//        map.put("pr", precision);
//        map.put("rd", rounding);
//        map.put("un", useNegativeSign);

        if(format) {
            alignSymbol = format.al;
            thousandsSeparator = format.ts;
            useThousandsSeparator = CORE.isNil(format.ut) ? (!thousandsSeparator) : format.ut;
            decimalSeparator = format.ds || '.';
            currencySymbol = format.cs;
            precision = format.pr;
            rounding = format.rd;
            useNegativeSign = format.un;
            if(format.na) {
                numAbbrev = format.na;
            }
        }

        if ( numAbbrev != "none" ) {
            let splitBefore = splitNum(n);

            var useAuto = (numAbbrev === "auto");
            var useK = (numAbbrev === "k");
            var useM = (numAbbrev === "m");
            var useB = (numAbbrev === "b");
            var useT = (numAbbrev === "t");
            var absN = Math.abs(n);
            var valK = 1000;
            var valM = valK*1000;
            var valB = valM*1000;
            var valT = valB*1000;

            let changed = true;

            if ( useAuto && absN < valK ) {
                changed = false;
                //no suffix
            }
            else if ( useK || (useAuto && absN < valM) ) {
                n /= valK;
                numAbbrevSuffix = "K";
            }
            else if ( useM || (useAuto && absN < valB) ) {
                n /= valM;
                numAbbrevSuffix = "M";
            }
            else if ( useB || (useAuto && absN < valT) ) {
                n /= valB;
                numAbbrevSuffix = "B";
            }
            else if ( useT || useAuto ) {
                n /= valT;
                numAbbrevSuffix = "T";
            }

            if(changed) {
                let splitAfter = splitNum(n),
                    newNumDecimals = Math.max(0, splitBefore.totalPlaces - splitAfter.placesBefore);
//                console.log({splitBefore, splitAfter});
                n = Number(n.toFixed(newNumDecimals));
            }
        }


        var isNegative = n < 0;
        var numStr = n.toString();
        //console.log("numStr = " + numStr);
//        var orig = numStr;
        //DFG iDashboards modifications begin here
        var maxDigits = 20;
        var maxLen = maxDigits;
        if(numStr.toLowerCase().indexOf("e") != -1) {
            if(Math.abs(n) < 1) {
                // fractional numbers always get changed to standard notation
                numStr = CORE.NumberUtils._expandExponents(numStr);
                //console.log("NumberUtils._expandExponents converted numStr to " + numStr);
            }
            else {
                // If the number can be expressed in standard notation with less than 20 digits, we'll 
                // change it to standard notation here. However, if useThousandsSeparator is true,
                // the dataFormatter will always format it as standard notation, so save the cycles if it's true.
                if(!useThousandsSeparator) {
                    var stdStr = CORE.NumberUtils._expandExponents(numStr);
                    //console.log("NumberUtils.format(): stdStr=" + stdStr);
                    if(stdStr.indexOf("-") != -1) maxLen++;
                    if(stdStr.indexOf(".") != -1) maxLen++;
                    //if(DEBUG) debug("CurrencyFormatter.format(): stdStr.length=" + stdStr.length + ", maxLen=" + maxLen);
                    if(stdStr.length <= maxLen) {
                        numStr = stdStr;
                        //console.log("NumberUtils.format(): numStr changed from " + orig + " to " + numStr);
                    }
                }
            }
        }
         //DFG iDashboards modifications end here.

        var numArrTemp = numStr.split(".");
        var numFraction = numArrTemp[1] ? numArrTemp[1].length : 0;

        //console.log("CurrencyFormatter.format(): numStr is now " + numStr + ", precision=" + precision + ", numFraction=" + numFraction + ", rounding='" + rounding + "'");

        if (precision <= numFraction) {
            if (rounding != "none") {
                numStr = CORE.NumberUtils._formatRoundingWithPrecision(numStr, rounding, precision);
            }
        }

        //console.log("CurrencyFormatter.format(): numStr now " + numStr);

        var numValue = Number(numStr);
        if (Math.abs(numValue) >= 1)
        {
            numArrTemp = numStr.split(".");
            //console.log("CurrencyFormatter.format(): numArrTemp=" + numArrTemp + ", useThousandsSeparator=" + useThousandsSeparator);
            var front = (useThousandsSeparator ? CORE.NumberUtils._formatThousands(numArrTemp[0], thousandsSeparator, decimalSeparator) :
                numArrTemp[0]);
            //console.log("CurrencyFormatter.format(): front=" + front);
            if(numArrTemp[1]) {
                numStr = front + decimalSeparator + numArrTemp[1];
            }
            else {
                numStr = front;
            }
            //console.log("CurrencyFormatter.format(): A) numStr now is " + numStr);
        }
        else if (Math.abs(numValue) > 0)    // DFG  (was >=)
        {
            // if the value is in scientefic notation then the search for '.' 
            // doesnot give the correct result. Adding one to the value forces 
            // the value to normal decimal notation. 
            // As we are dealing with only the decimal portion we need not 
            // worry about reverting the addition
            if (numStr.indexOf("e") != -1)    // DFG - Shouldn't happen, because we changed to std notation above.
            {
                var temp = Math.abs(numValue) + 1;
                numStr = temp.toString();
            }

            // Handle leading zero if we got one give one if not don't
            var position = numStr.indexOf(".");
            var leading = position > 0 ? "0" : "";
            numStr = leading + decimalSeparator +
                     numStr.substring(position + 1);
            //console.log("CurrencyFormatter.format(): B) numStr now is " + numStr);
        }
        else {             // DFG
            numStr = "0";  // DFG
        }                  // DFG
        
        numStr = CORE.NumberUtils._formatPrecision(numStr, precision, decimalSeparator);
        numStr += numAbbrevSuffix;

        //console.log("CurrencyFormatter.format(): numStr with precision: " + numStr);

        // If our value is 0, then don't show -0
        if (Number(numStr) === 0)
        {
            isNegative = false; 
        }

        if (isNegative) {
            numStr = CORE.NumberUtils._formatNegative(numStr, useNegativeSign);
        }

//        if (!dataFormatter.isValid)
//        {
//            error = defaultInvalidFormatError;
//            return "";
//        }

        // -- currency --
        var baseVal;
        if (alignSymbol != "right")
        {
            if (isNegative) {
                var nSign = numStr.charAt(0);
                baseVal = numStr.substr(1, numStr.length - 1);
                numStr = nSign + currencySymbol + baseVal;
            }
            else {
                numStr = currencySymbol + numStr;
            }
        } 
        else {
            var lastChar = numStr.charAt(numStr.length - 1);
            if (isNegative && lastChar == ")")
            {
                baseVal = numStr.substr(0, numStr.length - 1);
                numStr = baseVal + currencySymbol + lastChar;
            }
            else
            {
                numStr = numStr + currencySymbol;
            }
        }

        //console.log("CurrencyFormatter.format(): Returning " + numStr + "\n");

        return numStr;


};

CORE.NumberUtils._formatDecimal = function(value, decimalSeparator) {//(value:String):String
    var parts = value.split(".");
    return parts.join(decimalSeparator);
};

CORE.NumberUtils._formatRounding = function(value, roundType) {//(value:String, roundType:String):String
    var v = Number(value);
    
    if (roundType != "none")
    {
        if (roundType === "up") {
             v = Math.ceil(v);
        }
        else if (roundType === "down") {
            v = Math.floor(v);
        }
        else if (roundType === "nearest") {
            v = Math.round(v);
        }
        else
        {
            //console.log("Invalid roundType: " + roundType + ", value=" + value);
        }
    }

    return v.toString();
};

CORE.NumberUtils._formatNegative = function(value, useSign) {//(value:String, useSign:Boolean):String
    if (useSign)
    {
        if (value.charAt(0) != "-") {value = "-" + value;}
    }
    else if (!useSign)
    {
        if (value.charAt(0) == "-") {
                value = value.substr(1, value.length - 1);
        }
        value = "(" + value + ")";
    }
    else
    {
        //console.log("ERROR: NumberUtils._formatNegative(): Invalid value: " + value);
    }
    return value;
};

/**
*  Formats a number by setting its decimal precision by using 
*  the <code>decimalSeparatorTo</code> property as the decimal separator.
*
*  @param value Value to be formatted.
*
*  @param precision Number of decimal points to use.
*
*  @return Formatted number.
*  
*/
CORE.NumberUtils._formatPrecision = function(value, precision, decimalSeparator) { // (value:String, precision:int):String {
    // precision works differently now. Its default value is -1
    // which stands for 'do not alter the number's precision'. If a precision
    // value is set, all numbers will contain that precision. Otherwise, there
    // precision will not be changed.
    
    if (precision === -1) {return value;}
    
    var numArr = value.split(decimalSeparator);
    
    numArr[0] = numArr[0].length === 0 ? "0" : numArr[0];
    
    if (precision > 0) {
        var decimalVal = numArr[1] ? numArr[1] : "";
        var fraction = decimalVal + "000000000000000000000000000000000";
        value = numArr[0] + decimalSeparator + fraction.substr(0, precision);
    }
    else {
        value = numArr[0];
    }
    
    return value.toString();
};


/**
 *  Formats a number by rounding it and setting the decimal precision.
 *  The possible rounding types are defined by
 *  mx.formatters.NumberBaseRoundType.
 *
 *  @param value Value to be rounded.
 *
 *  @param roundType The type of rounding to perform:
 *  NumberBaseRoundType.NONE, NumberBaseRoundType.UP,
 *  NumberBaseRoundType.DOWN, or NumberBaseRoundType.NEAREST.
 *
 *  @param precision int of decimal places to use.
 *
 *  @return Formatted number.
 *
 */
CORE.NumberUtils._formatRoundingWithPrecision = function(value, roundType, precision) {//(value:String, roundType:String, precision:int):String {
    // precision works differently now. Its default value is -1
    // which means 'do not alter the number's precision'. If a precision
    // value is set, all numbers will contain that precision. Otherwise, there
    // precision will not be changed. 

    var v = Number(value);
    
    // If rounding is not present and precision is NaN,
    // leave value untouched.
    if (roundType === "none") 
    {
        if (precision === -1 || isNaN(precision)) {
           return v.toString();
        }
    }
    else
    {
        // If rounding is present but precision is less than 0,
        // then do integer rounding.
        if (precision < 0) { 
           precision = 0;
        }
        
        // Shift decimal right as Math functions
        // perform only integer ceil/round/floor.
        v = v * Math.pow(10, precision);
        
        // Attempt to get rid of floating point errors
        v = Number(v.toString()); 
        if (roundType === "up")
        {
            v = Math.ceil(v);
        }
        else if (roundType === "down")
        {
            v = Math.floor(v);
        }
        else if (roundType == "nearest")
        {
            v = Math.round(v);
        }
        else
        {
            //console.log("NumberUtils._formatRoundingWithPrecision(): Invalid roundType: " + roundType);
            return "";
        }
        
        // Shift decimal left to get back decimal to original point.
        v = v / Math.pow(10, precision); 
    }

    return v.toString();
};

/**
 *  Formats a number by using 
 *  the <code>thousandsSeparatorTo</code> property as the thousands separator 
 *  and the <code>decimalSeparatorTo</code> property as the decimal separator.
 *
 *  @param value Value to be formatted.
 *
 *  @return Formatted number.
 *  
 *  @langversion 3.0
 *  @playerversion Flash 9
 *  @playerversion AIR 1.1
 *  @productversion Flex 3
 */
CORE.NumberUtils._formatThousands = function(value, thousandsSeparator, decimalSeparator) { //(value:String):String
    decimalSeparator = decimalSeparator || ".";

    var v = Number(value);
    
    var isNegative= (v < 0);
    
    var numStr = Math.abs(v).toString().toLowerCase();
    
    var e = numStr.indexOf("e");
    if (e != -1) { //deal with exponents
       numStr = CORE.NumberUtils._expandExponents(numStr);
    }
        
    var numArr = numStr.split((numStr.indexOf(decimalSeparator) != -1) ? decimalSeparator : ".");
    var numLen = numArr[0].length;

    if (numLen > 3)
    {
        var numSep = Math.floor(numLen / 3);

        if ((numLen % 3) === 0) {
                numSep--;
        }
        
        var b = numLen;
        var a = b - 3;
        
        var arr = [];
        for (var i = 0; i <= numSep; i++)
        {
            arr[i] = numArr[0].slice(a, b);
            a = Math.max(a - 3, 0);
            b = Math.max(b - 3, 1);
        }
        
        arr.reverse();
        
        numArr[0] = arr.join(thousandsSeparator);
    }
    
    numStr = numArr.join(decimalSeparator);
    
    if (isNegative) {
            numStr = "-" + numStr;
    }
    
    return numStr.toString();
};

CORE.NumberUtils._expandExponents = function(value) { //(value:String):String
    //Break string into component parts
    var regExp = /^([+-])?(\d+).?(\d*)[eE]([-+]?\d+)$/;
    var result = regExp.exec(value);
    var sign = result[1];
    var first = result[2];
    var last =  result[3];
    var exp =  Number(result[4]);
            
    //if we didn't get a good exponent to begin with, return what we can figure out
    if (isNaN(exp))
    {
        return (sign ? sign : "") + (first ? first : "0") + (last ? "." + last : "");
    }
            
    var r = first + last;
            
    var decimal = (exp < 0);
            
    if (decimal)
    {   
        var o = (-1 * (first.length + exp) + 1);
        return (sign ? sign : "") + "0." + new Array(o).join("0") + r;
    }
    else
    {
        var i = exp + first.length;
        if (i >= r.length) {
            return (sign ? sign : "") + r + new Array(i - r.length + 1).join("0");
        }
        else {
            return (sign ? sign : "") + r.substr(0,i) + "." + r.substr(i); 
        }
    }
};

/**
 * A general purpose function for adding suffixes for multiples. For example,
 * 4,000 becoming 4k.
 *
 * @param {number} val        The number to add the suffix to.
 * @param {number[]} milles   The multiples sorted in descending order.
 * @param {string[]} suffixes The suffixes where each suffix corresponds to the
 *                            same entry in the multiples array.
 */
function suffixMultiples(val, milles, suffixes) {
    const min = milles[milles.length - 1];
    const sign = Math.sign(val);
    let i = milles.length - 1;
    
    val = Math.abs(val);
    
    if (val > min) {
      while ((val / milles[i]) >= min && i >= 0) {
          i--;
      }
    } else {
        i -= 1;
    }

    return Math.floor((val * sign) / milles[i + 1]) + suffixes[i + 1];
}

/**
 * Format a duration of time.
 *
 * @param  {number} value The duration in milliseconds
 *
 * @return {string}       The duration formatted.
 */
CORE.NumberUtils.formatDuration = function(value) {
    const mille = [86400000, 3600000, 60000, 1000, 1];
    const suffixes = ['d', 'h', 'min', 's', 'ms'];

    return suffixMultiples(value, mille, suffixes);
};

/**
 * Summarize a number by removing insignificant digits and adding a suffix. For
 * example, turning the number 4,000 into 4k.
 *
 * @param  {number} value The number to summarize.
 *
 * @return {string}       The summarized number.
 */
CORE.NumberUtils.summarizeNumber = function (value) {
    const milles = [
        1000000000,
        1000000,
        1000,
        1
    ];
    const suffixes = [
        'b',
        'm',
        'k',
        ''
    ];

    return suffixMultiples(value, milles, suffixes);
};

/**
 * Summarize a number of bytes by removing insignificant digits and adding a
 * suffix. For example, turning the number 4,000 into 4KB.
 *
 * @param  {number} value The number to summarize.
 *
 * @return {string}       The summarized number.
 */
CORE.NumberUtils.summarizeBytes = function (value) {
    const milles = [
        // There will be precision loss for anything above 4GB (for both the
        // value and the milles) because JavaScript uses 32bit integers. We are
        // only concerned with the most significant digits which IEEE floating
        // point numbers will preserve so it shouldn't be too much of a problem.
        1125899906842624, 
        1099511627776,
        1073741824,
        1048576,
        1024,
        1
    ];
    const suffixes = [
        'PB',
        'TB',
        'GB',
        'MB',
        'KB',
        ' bytes'
    ];

    return suffixMultiples(value, milles, suffixes);
};


CORE.NumberUtils.parseInt = function(str) {
    var regex = /^[-+]?[0-9]+$/;

    if(!regex.test(str)) {
        return NaN;
    }
    else {
        return parseInt(str);
    }
};

CORE.NumberUtils.parseFloat = function(str) {
    var regex = /^[-+]?[0-9]*(\.[0-9]+)?([e][-+]*[0-9]+)?$/i;

    if(!str) return NaN;

    if(!regex.test(str)) {
        return NaN;
    }
    else {
       return parseFloat(str);
    }
};


var MathCache = CORE.MathCache =  {
    _Cos:{},
    _Sin:{},
    _AbsCos:{},
    _AbsSin:{},
    cos:function(r) {
    	var cache = MathCache._Cos;
    	var result = cache[r];

    	if ( CORE.isUndef(result) ) {
    		cache[r] = result = Math.cos(r);
    	}

    	return result;
    },
    sin:function(r) {
    	var cache = MathCache._Sin;
    	var result = cache[r];

    	if ( CORE.isUndef(result) ) {
    		cache[r] = result = Math.sin(r);
    	}

    	return result;
    },
    absCos:function(r) {
    	var cache = MathCache._AbsCos;
    	var result = cache[r];

    	if ( CORE.isUndef(result) ) {
    		cache[r] = result = Math.abs(MathCache.cos(r));
    	}

    	return result;
    },
    absSin:function(r) {
    	var cache = MathCache._AbsSin;
    	var result = cache[r];

    	if ( CORE.isUndef(result) ) {
    		cache[r] = result = Math.abs(MathCache.sin(r));
    	}

    	return result;
    }
};



var MathUtil = CORE.MathUtil = {
    calculateRotatedBounds:function(width, height, degrees) {
    	var rad = MathUtil.toRadians(degrees);
    	var cos = MathCache.absCos(rad);
    	var sin = MathCache.absSin(rad);

    	return {
    		width: height*sin + width*cos,
    		height: height*cos + width*sin
    	};
    },
    clamp:function(val, lo, hi) {
    	return (val < lo ? lo : (val > hi ? hi : val));
    },
    toRadians:function(degrees) {
    	return degrees/180*Math.PI;
    },
    modulo:function(a, n) {
        if(isNaN(n) || n <= 0) {
            return NaN;
        }
        return ((a%n)+n)%n;
    }
};

CORE.ArrayUtils = {
    makeMap:function(arr, keyFieldName) {
        return arr.reduce((map, obj) => {map[obj[keyFieldName]] = obj; return map;}, {});
    }
};



var MatchUtils  = CORE.MatchUtils =  {
    EXACT_MATCH_TYPE:"Exact",
    POSITIONAL_MATCH_TYPE:"Positional",
    BEST_MATCH_TYPE:"Best",
    IGNORE_CASE:1,
    SEPARATORS_SAME:2,
    
    getMatchTypes:function() {
        return [MatchUtils.EXACT_MATCH_TYPE, MatchUtils.POSITIONAL_MATCH_TYPE, MatchUtils.BEST_MATCH_TYPE];
    },    

    _getNameArray:function(array, nameField) {
        //
        // Assume an array of strings if no nameField specified.
        //
        if(!nameField) {
            return array;
        }
        else {
            return array.map(function(item, index, array){return array[index][nameField];});
        }
    },
    
    _constructFlagsString:function(flags) {
        /*jshint bitwise:false */
        var flagsStr = "";
        if(flags & MatchUtils.IGNORE_CASE) {
                flagsStr += "i";
            }
            
            return flagsStr;
    },
    
    _constructRegExp:function(target, flags) {
        var flagsStr = MatchUtils._constructFlagsString(flags);
        return new RegExp(target, flagsStr);
    },
        
    
   exactMatch:function(search, target, flags, nameField) {
       flags = flags || 0;
          
       var nameArray = MatchUtils._getNameArray(search, nameField),
       regExp = MatchUtils._constructRegExp("^" + target +"$", flags);
          
       for(var i = 0; i < nameArray.length; i++) {
           var name = nameArray[i];
              
           if(regExp.test(name)) {
              return i;
           }
        }
       
        return -1;
    },
      
    bestMatch:function(search, target, flags, similarityThreshold, nameField) {
        flags = flags || 0;
        var bestIndex = MatchUtils.exactMatch(search, target, flags, nameField);

        if(bestIndex >= 0) {
            return bestIndex;
        }
        else {
            bestIndex = 0;
        }
                      
        var nameArray = MatchUtils._getNameArray(search, nameField),
        bestDistance = -1;
      
          for(var i = 0; i < nameArray.length; i++) {
              var distance = StringUtils.editDistance(nameArray[i], target, flags); 
              
              if(bestDistance == -1 || distance < bestDistance) {
                  bestDistance = distance;
                  bestIndex = i;
              }
          }

          if(similarityThreshold && bestIndex >= 0 && 
                  StringUtils.similarity(nameArray[bestIndex], target, flags) < similarityThreshold) {
              bestIndex = -1;
          }
          return bestIndex;
    },
    getNameMatch:function(matchType, search, target, flags, nameField) {
        flags = flags || 0;
        switch(matchType) {
            case MatchUtils.EXACT_MATCH_TYPE:
                return MatchUtils.exactMatch(search, target, flags, nameField);
                    
            case MatchUtils.BEST_MATCH_TYPE:
               return MatchUtils.bestMatch(search, target, flags, nameField);
                    
            default:
               return -1;
        }
     }
    };        



var StringUtils = CORE.StringUtils = {
    DEFAULT_SEPARATORS:['_', '-', ' '],

    _separatorCaseCostFunction:function(flags, c1, c2) {
        /*jshint bitwise:false */
        var separators = [' ', '_'];
                     
        if((flags & MatchUtils.SEPARATORS_SAME) && separators.indexOf(c1) >= 0 && separators.indexOf(c2) >= 0) {
            return 0;
         }
         else if((flags & MatchUtils.IGNORE_CASE) && c1.toLowerCase() == c2.toLowerCase()) {
             return 0;
         }
         else {
             return 1;
         }
    },
     /**
      * Levenshtein distance (editDistance) is a measure of the similarity
      * between two strings, The distance is the number of deletions,
      * insertions, or substitutions required to transform source into target.
      *
      * @param {string} str1 The source string.
      * @param {string} str2 The target string.
      * @param {(char1: string, char2: string) => number} costFunction Calculate the cost of a substituition
      *
      */
     levenshteinDistance:function(str1, str2, costFunction) {
        var i,j;
        str1 = str1 || "";
        str2 = str2 || "";

        if(str1 === str2) return 0; 

        /**
         * @type {number[][]}
         */
        var d = [], cost, m = str1.length, n = str2.length;

        if(m === 0) return n;
        if(n === 0) return m;

        for(i=0; i<=m; i++) { 
           d[i] = [];
        }

        for(i=0; i<=m; i++) { 
            d[i][0] = i;
        }

        for(j=0; j<=n; j++) {
            d[0][j] = j; 
        }

        for(i=1; i<=m; i++) {
            var str1_i = str1.charAt(i-1);
            
            for (j=1; j<=n; j++) {
                var str2_j = str2.charAt(j-1);
                if (str1_i === str2_j) { 
                    cost = 0; 
                }
                else {
                    cost = costFunction?costFunction(str1_i, str2_j):1; 
                }

                d[i][j] = Math.min(d[i-1][j]+1, // deletion
                        d[i][j-1]+1, // insertion
                        d[i-1][j-1]+cost); // substitution
            }
        }
        return d[m][n];
    },

    editDistance:function(str1, str2, flags) {
        /*jshint bitwise:false */
        var costFunction = null;
        if(flags & (MatchUtils.IGNORE_CASE | MatchUtils.SEPARATORS_SAME)) { 
            costFunction = StringUtils._separatorCaseCostFunction.bind(null, flags);
         }
         return StringUtils.levenshteinDistance(str1, str2, costFunction);
     },
     similarity:function(source, target, flags) {
         var ed = StringUtils.editDistance(source, target, flags),
         maxLen = Math.max(source.length, target.length);
         if (maxLen === 0) return 1; 
         else return 1 - ed/maxLen; 
     },
     toTitleCase:function(str) {
         var newStr = [];
         for(var i=0; i < str.length; i++) {
             var ch = str.charAt(i);
             if(i === 0) {
                 newStr.push(ch.toUpperCase());
             }
             else {
                 newStr.push(ch.toLowerCase());
             }
         }
         return newStr.join('');
     },
     isUpperCase:function(str) {
         if(str == null || str.length == 0) {
             return true;
         }
         
         var upperCaseStr = str.toUpperCase();
         
         return upperCaseStr == str;            
     },
     isLowerCase:function(str) {
         if(str == null || str.length == 0) {
             return true;
         }
         
         var lowerCaseStr = str.toLowerCase();
         
         return lowerCaseStr == str;            
     },
     hasCase:function(str) {
         if(str == null || str.length == 0) {
             return false;
         }
         
         return str.toLowerCase() != str.toUpperCase();
     },
     splitOnSeparators:function(str, separators) {
         if(!str) {
             return null;
         }
         
         if(!separators) {
             separators = StringUtils.DEFAULT_SEPARATORS;
         }
         
         var pattern = "(" + separators.join('|') + ")+",
             regEx = new RegExp(pattern, "g"),
             newStr = str.replace(regEx, ' ');
         return newStr.split(' ');
     },
     isMixedCase:function(str) {
         if(str == null || str.length == 0) {
             return false;
         }
         
         return StringUtils.hasCase(str) && !(StringUtils.isLowerCase(str) || StringUtils.isUpperCase(str));   
     },

     splitOnTitleCase:function(str) {
         if(str == null) {
             return null;
         }
                     
         var strSplit = [];
         
         var subStr = "";
         
         for(var i = 0; i < str.length-1; i++) {
             var c1 = str.charAt(i);
             var c2 = str.charAt(i+1);
             
             subStr += c1;
             
             if(i == str.length-2) {
                 subStr += c2;
                 strSplit.push(subStr);
             }
             else if(StringUtils.hasCase(c2) && StringUtils.isUpperCase(c2)) {
                 strSplit.push(subStr);
                 subStr = "";
             }
         }
             
         return strSplit;
     },

     beautify:function(str, separators, caseSeparate) {            
        separators = separators || StringUtils.DEFAULT_SEPARATORS;
        caseSeparate = CORE.ifNil(caseSeparate, true);
        str = CORE.trim(str);

        if(str == null || str.length == 0) {
            return str;
        }
        
        str = CORE.trim(str);
        
        //
        // If the string contains spaces and separators are not specified, assume the string is formatted as desired and simply return it.
        //
        if(separators === null && str.indexOf(' ') != -1) {
            return str;
        }
        
        if(separators == null) {            
            separators = StringUtils.DEFAULT_SEPARATORS;
        }            
        
        
        //
        // First try splitting the string on the separators.
        //
        var newStrSplit = StringUtils.splitOnSeparators(str, separators);
        
        //
        // If the string contains more than one word, assume the separators indicate the word boundaries. Title case
        // if not mixed case already.
        //
        if(newStrSplit.length > 1) {
            if(!StringUtils.isMixedCase(str)) {
                newStrSplit = newStrSplit.map(function(item) {return StringUtils.toTitleCase(item);});                    
            }
        }
        else {
            //
            // If the string is mixed case, split it on case.
            //
            if(caseSeparate && StringUtils.isMixedCase(str)) {
                newStrSplit = StringUtils.splitOnTitleCase(str);
            }
            
            //
            // Title case the first item just in case it isn't capitalized.
            //
            newStrSplit[0] = StringUtils.toTitleCase(newStrSplit[0]);
        }
        
        return newStrSplit.join(' ');    
     },

     slugify: function (str, separator, normalizeCase) {
        if (!str) {
            throw new TypeError('The input string must not be null or undefined.');
        }

         separator = separator || '_';
         str = CORE.removeDiacritics(str);

         if (normalizeCase === 'upper') {
             str = str.toUpperCase();
         } else if (normalizeCase === 'lower') {
             str = str.toLowerCase();
         }

         return str
             // With out the 'u' flag this only gets latin characters. Non-latin
             // characters may cause problems wherever this token is used.
             .replace(/[^\w\d]+/g, ' ')
             // Do this after to trim any special characters at the end
             .trim()
             // Convert to the desired separator
             .replace(/ /g, separator);
     }
};



CORE.LayoutContainer = function() {
    var me = this;
    me._layoutFunc = null;
    me._layoutFuncTimeoutID = 0;
    me._layoutEnabled = true;
    me._width = me._height = 0;
    me._sizeChanged = me._widthSet = me._heightSet = me._initialLayoutComplete = false;
};

CORE.LayoutContainer.prototype = {

    /**
     * Schedules a call to doLayout() (implemented in subclass) if one is not already pending.
     * 
     * @param {boolean} [forceLayout] If the force parameter is true, and both a width and height 
     * have been set, then the _sizeChanged flag is set to true to 
     * insure the layout occurs. This is useful when the size of the 
     * LayoutContainer may not have changed, but the content has. 
     */
    invalidateLayout:function(forceLayout) {
        var me = this;

        if(CORE.isUndef(me._layoutEnabled)) {
            console.error("It appears that a subclass of LayoutContainer did not call the LayoutContainer constructor.", me);
            CORE.debugger();
        }

        if(forceLayout && me._widthSet && me._heightSet) {
            me._sizeChanged = true;
        }

        if(me._layoutFunc) {
//            console.log(me, "LayoutContainer.invalidateLayout(): Layout already scheduled.");
            return;
        }

        // This should only ever be called by the promise fulfillment handler.
        me._layoutFunc = function() {
//            console.log(me, "LayoutContainer._layoutFunc called. " + me._layoutEnabled);
            if(me._layoutEnabled) {
                me.doPreLayout();
                me.doLayout();
                me._sizeChanged = false;
                me._initialLayoutComplete = true;
            }
            me._layoutFunc = null;
        };

        Promise.resolve(me._layoutFunc).then((layoutFunc) => {
            if(me._layoutFunc === layoutFunc) {
                layoutFunc();
            }
//            else {
//                console.log("Layout cancelled.");
//            }
        });
    },

    /**
     * Cancels any pending calls to doLayout().
     */
    validateLayout:function() {
        var me = this;
        // Simply setting this to to null will prevent
        // it from getting called.
        me._layoutFunc = null;
    },
    /**
     * This is an optional method that subclasses may override. It 
     * will always be called just prior to the doLayout() method. 
     */
    doPreLayout:function() {

    },
    /**
     * Calls doLayout() then sets _sizeChanged to false and calls 
     * validateLayout().
     * 
     * @param {boolean} [forceLayout] If the forceLayout argument is truthy, then
     * the _sizeChanged property will be set to true before 
     * doLayout is called, to insure that a full layout occurs. 
     */
    layoutNow:function(forceLayout) {
        var me = this, hasSize = me._widthSet && me._heightSet && !!me._width && !!me._height;
        if(!!forceLayout) {
            me._sizeChanged = true;
        }
        if(!me._widthSet) {
            CORE.debugger();
        }
        me.doPreLayout();
        me.doLayout();
        me._sizeChanged = false;
        me._initialLayoutComplete = me._initialLayoutComplete || hasSize;
        me.validateLayout();
    },

    setWidth:function(w) {
        var me = this, changed = w !== me._width;
        me._widthSet = true;
        me._width = w;
        if(w < 0) {
            CORE.debugger();
        }
        if(changed) {
            me.invalidateLayout();
            me._sizeChanged = true;
        }
    },

    setHeight:function(h) {
        var me = this, changed = h !== me._height;
        me._heightSet = true;
        me._height = h;
        if(h < 0) {
            CORE.debugger();
        }
        if(changed) {
            me.invalidateLayout();
            me._sizeChanged = true;
        }
    },

    getWidth:function() {
        return this._width;
    },

    getHeight:function() {
        return this._height;
    },

    hasSize:function() {
        return !!this._width && !!this._height;
    },

    doBaseLayout:function() {
        const me = this, $div = me.$div, w = me._width, h = me._height, PX = "px";
        $div.css({
                 width:w + PX,
                 height:h + PX
             });
             
    }
};

/**
 * This adds _x and _y properties to LayoutContainer along with 
 * setters. Subclasses are assumed to have a $div property with 
 * non-static positioning. Setting X or Y will set the $div's 
 * left or top CSS styles in pixels. 
 */
CORE.LayoutContainerXY = CORE.subclass(CORE.LayoutContainer, 
    function LayoutContainerXY() {
        var me = this;
        me._x = me._y = 0;
        CORE.LayoutContainer.call(me);
    },
    {
        setX:function(x) {
            var me = this, $div = me.$div;
            me._x = x;
            $div.css({left:x+"px"});
        },
        setY:function(y) {
            var me = this, $div = me.$div;
            me._y = y;
            $div.css({top:y+"px"});
        },
        setXY:function(x,y) {
            var me = this;
            me._x = x;
            me._y = y;
            me.$div.css({top:y+"px", left:x+"px"});
        },
        getX:function() {
            return this._x;
        },
        getY:function() {
            return this._y;
        },

        doBaseLayout:function() {
            const me = this, $div = me.$div, w = me._width, h = me._height,
                x = me._x, y = me._y, PX = "px";

            $div.css({
                     width:w + PX,
                     height:h + PX,
                     top:y + PX,
                     left:x + PX
                 });
        }
    });

/**
 * An instance of LocalStorageObject represents a plain object 
 * that is stored as JSON in localStorage, using the given key. 
 * When properties are set through the setItem method, they are 
 * persisted immediately. A clone of the entire object can be 
 * gotten with the getStorageObject method, however, changes 
 * made to its properties directly will not be persisted in 
 * localStorage. 
 */
CORE.LocalStorageObject = function(key, defaultObject) {
    var me = this;
    if(!key || "string" !== typeof key) {
        throw new Error("LocalStorageObject: Invalid key:", key);
    }
    me._key = key;
    me._defaultObject = defaultObject ? CORE.deepClone(defaultObject) : null;
    // Initialize it.
    me.getStorageObject();

};

CORE.LocalStorageObject.prototype = {

    localStorageGetItem:function(key) {
        try {
            return localStorage.getItem(key);
        }
        catch(err) {
            console.error("ERROR READING LOCAL STORAGE", key, err);
            return undefined;
        }
    },

    localStorageSetItem:function(key, value) {
        try {
            localStorage.setItem(key, value);
        }
        catch(err) {
            console.error("ERROR WRITING LOCAL STORAGE", key, err);
        }
    },


    getStorageObject:function() {
        var me = this, key = me._key, defaultObject = me._defaultObject,
            s = me.localStorageGetItem(key), settings = null;

        if(s) {
            try {
                settings = JSON.parse(s);
            }
            catch(err) {
                console.error("LocalStorageObject: invalid JSON:", s);
            }
        }

        if(!settings) {
            settings = $.extend({}, defaultObject || {});
            me.localStorageSetItem(key, JSON.stringify(settings));
        }

        return settings;

    },
    setItem:function(name, value) {
        var me = this, obj = me.getStorageObject(), key = me._key;
        obj[name] = value;
        me.localStorageSetItem(key, JSON.stringify(obj));
    },
    getItem:function(name) {
        return this.getStorageObject()[name];
    },
    deleteItem:function(name) {
        var me = this, obj = me.getStorageObject(), key = me._key,
            val = obj[name];

        delete obj[name];
        me.localStorageSetItem(key, JSON.stringify(obj));
        return val;
    },
    keys:function() {
        return Object.keys(this.getStorageObject());
    }
};

(function(CORE) {
    let sessionKey = null;

    const storageObject = new CORE.LocalStorageObject("idb_session");
    
    const initSession = (key) => {
        if(!key) {
            console.error("Session object is already initialized.");
            throw new Error("key argument missing."); 
        }

        if(sessionKey && key !== sessionKey) {
            console.error("Session object is already initialized.");
            throw new Error("Session object is already initialized.");
        }

        if(sessionKey && key === sessionKey) {
            return;
        }

        sessionKey = key;

        let keys = storageObject.keys(),
            currentObj = storageObject.getItem(sessionKey) || {};
        // Delete leftover junk from previous sessions.
        keys.forEach(k => {
            if(k !== key) {
                storageObject.deleteItem(k);
            }
        });

        storageObject.setItem(sessionKey, currentObj);
    },
    getObj = () => {
        if(!sessionKey) {
            throw new Error("Session has not been initialized.");
        }
        let obj = storageObject.getItem(sessionKey);
        if(!obj) {
            console.warn("Session object has been purged.");
            obj = {};
            storageObject.setItem(sessionKey, obj);
        }
        return obj;
    },

    setItem = (name, val) => {
        let obj = getObj();
        obj[name] = val;
        storageObject.setItem(sessionKey, obj);
    },
    getItem = (name) => {
        return getObj()[name];
    },
    deleteItem = (name) => {
        let obj = getObj(), val = obj[name];
        delete obj[name];
        storageObject.setItem(sessionKey, obj);
        return val;
    },
    hasItem = (name) => {
        let obj = getObj(), val = obj[name];
        return obj.hasOwnProperty(name) && (val !== null) && ("undefined" !== typeof val); 
    };

    CORE.Session = {
        initSession,
        setItem,
        getItem,
        deleteItem,
        hasItem
    };

})(CORE);

(function(CORE, $) {

    /**
     * An instance of this class is instantiated for a DOM element for which touch events (excluding multi-touch events)
     * should be translated to mouse events.
     * It listens for touch events and programattically dispatches corresponding mouse
     * events, so that the event handler code needs only to listen for mouse events. If a user holds a finger
     * to the screen for longer than 500 milliseconds before lifting it, then no mouse click event is dispatched;
     * instead it's treated like hovering the mouse. If the touch is lifted within 500 milliseconds, and without
     * having been dragged, then a mouse click event is dispatched.
     */

    ////////////////////////////////////////////////////////////////////////
    // Begin closure data
    var TOUCH_SUPPORTED = CORE.touchSupported(), 
        CLICK_WINDOW = 500;


    // The property names are touch event types, and the property values
    // are arrays of the mouse event types that get dispatch when
    // the corresponding touch events occur.
    var defaultMappings = {
       "touchstart":["mouseover", "mousedown"],
       "touchmove":["mousemove"],
       "touchend":["mouseup", "mouseout"]
    };

    var primaryListeners = [
       ["touchstart", "_handleTouchstart"],
       ["touchmove", "_handleTouchmove"],
       ["touchend", "_handleTouchend"]
    ];

    function shouldIgnoreEvent(event) {
        if(!event) {
            return true;
        }
        var target = (event.orginalEvent || event).target;
        if(!target) {
            return false;
        }
        var ignore = CORE.isElementFocusable(target);
//        if(ignore) {
//            console.log("ignoring", target.tagName, event.type);
//        }
        return ignore;
    }

    /**
     * Given a touch event, this creates a mouse event the type indicated by simulatedType that has the same X and Y location
     * coordinates as where the touch event occurred.
     */
    function createMouseEvent(event, simulatedType) {

      var hasIdbIgnoreFlag = CORE.hasIdbIgnoreFlag(event), touch = event.originalEvent.changedTouches[0],
          simulatedEvent = document.createEvent('MouseEvents');

      if(hasIdbIgnoreFlag) {
        CORE.addIdbIgnoreFlag(simulatedEvent);
      }
      
      // Initialize the simulated mouse event using the touch event's coordinates
      simulatedEvent.initMouseEvent(
        simulatedType,    // type
        true,             // bubbles                    
        true,             // cancelable                 
        window,           // view                       
        1,                // detail                     
        touch.screenX,    // screenX                    
        touch.screenY,    // screenY                    
        touch.clientX,    // clientX                    
        touch.clientY,    // clientY                    
        false,            // ctrlKey                    
        false,            // altKey                     
        false,            // shiftKey                   
        false,            // metaKey                    
        0,                // button                     
        null              // relatedTarget              
      );

      simulatedEvent._pageX = touch.pageX;
      simulatedEvent._pageY = touch.pageY;


//      simulatedEvent["0-translatedEvent"] = event;
      simulatedEvent.simulatedFromTouch = true;
      return simulatedEvent;
    }

    // End closure data
    ////////////////////////////////////////////////////////////////////////

    /**
     * The third argument is an optional "options" object. The only 
     * option supported is a Boolean "kill", which defaults to 
     * false. If it's true, then CORE.killEvent will be called on 
     * the touchstart, touchmove and touchend events that are 
     * substituted, instead of simply calling their preventDefault()
     * methods. 
     */
    CORE.DOMTouchHandler = function(domObj, pMappings, options) {
        var me = this, $domObj = me._$domObj = $(domObj);
        me._domObj = domObj;
        me._inClickWindow = false;
        // The 500ms click window technique is no longer reliable,
        // since an Android touch that lasts that long will almost always
        // register some movement. So we now record the time between touchstart
        // and touchend, and if it's less than 100ms, we consider it a click
        // regardless of movement.
        me._clickStartTime = 0;
        if(!TOUCH_SUPPORTED) {
            return;
        }
        me._mappings = $.extend({}, defaultMappings, pMappings);
        me._useKill = options && !!options.kill;
        me._pendingMouseOut = null;
        for(var j=0, jj=primaryListeners.length; j<jj; j++) {
            $domObj.on(primaryListeners[j][0], me[primaryListeners[j][1]].bind(me));
        }

        me._windowListener = me._onWindowTouchEnd.bind(me); 

        me._preventDefaultOn = {
            touchstart:true,
            touchmove:true,
            touchend:true
        };
        Object.preventExtensions(me._preventDefaultOn);
    };

    CORE.DOMTouchHandler.TOUCH_SUPPORTED = TOUCH_SUPPORTED;

    CORE.DOMTouchHandler.prototype = {
        _simulateEvents:function(touchEvt) {
           var mouseEvents = this._mappings[touchEvt.type];
           for(var j=0, jj=mouseEvents.length; j<jj; j++) {
               this._simulateMouseEvent(touchEvt, mouseEvents[j]);
           }
        },

        /**
         * Given a touch event, this function will create and dispatch a mouse event
         * with the type simulatedType. preventDefault() will be called on the touch event.
         */
        _simulateMouseEvent:function(jqEvent, simulatedType) {

          var me = this, killEvent = me._useKill, hasIdbIgnoreFlag = CORE.hasIdbIgnoreFlag(jqEvent), event = jqEvent.originalEvent;
          if(!event.touches) {
              console.log(event, "Not a touch event: ");
              return;
          } // not a touch event
          $.noop(hasIdbIgnoreFlag);
          try {
              // Ignore multi-touch events
              if (event.touches.length > 1) {
                return;
              }
          } 
          catch(err) {
              console.log(err, "ERROR");
              return;
          }

          if(this._preventDefaultOn[event.type]) {
              if(killEvent) {
                  CORE.killEvent(event);
              }
              else {
                  event.preventDefault();
              }
          }
          else {
              console.log("No prevent default: " + event.type);
          }
        
          var simulatedEvent = createMouseEvent(jqEvent, simulatedType);
          simulatedEvent._touchEvent = event;

          //console.log(simulatedEvent, "Simulated Event - " + simulatedType);
        
          // Dispatch the simulated event to the target element
          event.target.dispatchEvent(simulatedEvent);
        },
        startWindowListening:function() {
            $window.on("touchend", this._windowListener);
        },
        stopWindowListening:function() {
            const me = this;
            me._pendingMouseOut = null;
            me._clickStartTime = 0; 
            $window.off("touchend", me._windowListener);
        },
        _openClickWindow:function() {
            const me = this;
            clearTimeout(me._timerId);
            me._inClickWindow = true;
            me._timerId = setTimeout( me._closeClickWindow.bind(me), CLICK_WINDOW);
        },
        _closeClickWindow:function() {
            this._inClickWindow = false;
            clearTimeout(this._timerId);
        },
        _handleTouchstart:function(e) {
            if(shouldIgnoreEvent(e)) {
                return;
            }
            const me = this;
            me._clickStartTime = Date.now();
            me._pendingMouseOut = createMouseEvent(e, "mouseout");
            me._pendingMouseOut._target = e.target;
            me.startWindowListening(); 
            me._simulateEvents(e);
            me._openClickWindow();
        },
        _handleTouchmove:function(e) {
            if(shouldIgnoreEvent(e)) {
                return;
            }
            const differ = (n1, n2) => {
                // The coordinates of touch events in some Android devices
                // have 14 decimal places of precision, and it's almost impossible
                // for two different touch events to have the exact same
                // coordinates. We used to just check for equality here, now
                // we consider them equivalent if they're within one pixel 
                // of each other.
                n1 = n1 || 0;
                n2 = n2 || 0;
//                console.log({n1, n2});
                return Math.abs(n1-n2) >= 1;
            };
            if(this._inClickWindow && this._pendingMouseOut) {
                // This touchmove might have been generated by the mousemove event we dispatched
                // from the touchstart handler, in which case the mouse will not have actually 
                // moved since the touch began. We'll compare the coordinates of this event
                // with the ones stored in the _pendingMouseOut event.
                var mo = this._pendingMouseOut, 
                    t = (e.changedTouches && e.changedTouches[0]);
                if(t && (differ(t.pageX, (mo._pageX ?? mo.pageX)) || differ(t.pageY, (mo._pageY ?? mo.pageY)) )) {
//                    console.log("Movement detected.......");
                    this._closeClickWindow();
                }
//                else {
//                    console.log("no movement");
//                }
            }
            this._simulateEvents(e);
        },
        _handleTouchend:function(e) {
            const me = this, elapsed = Date.now() - me._clickStartTime;
            me._clickStartTime = 0;

            if(shouldIgnoreEvent(e)) {
                return;
            }
            me._simulateEvents(e);
            if(me._inClickWindow || (elapsed < 100)) {
                me._simulateMouseEvent(e, "click");
            }
            me._closeClickWindow();
            me.stopWindowListening();
        },
        _onWindowTouchEnd:function(e) {
            const me = this;
            me._clickStartTime = 0;
            let mo = me._pendingMouseOut;

            me._closeClickWindow();
            me.stopWindowListening();
            if(mo) {
                var target = mo._target;
                mo._target = null;
                target.dispatchEvent(mo);
            }
            me._pendingMouseOut = null;
        }
    };

    CORE.TooltipManager = function TooltipManager(tooltip, options) {
        var me = this;
        me._options = $.extend({html:false, delay:500, owner:null, css:{}}, options || {});
        me._tooltipText = tooltip || null;
        me._tooltipVisible = false;
        me._activated = false;
        me._destroyed = false;
        me._delayTimeoutId = 0;
        me._build();
    };

    CORE.TooltipManager.prototype = {
        _build:function() {
            var me = this, options = me._options, 
                $tooltip = me.$tooltip = $("<div>")
                    .css({
                        position:"absolute",
                        top:"0px",
                        left:"0px",
                        padding:"0.4em",
                        width:"auto",
                        height:"auto",
                        overflow:"hidden",
                        display:"none",
                        "pointer-events":"none",
                        "z-index":CORE.nextZIndex(),
                        "font-size":"12px",
                        "border":"1px solid #404040",
                        "color":"#404040",
                        "box-shadow":"#888888 2px 2px 3px",
                        "background-color":"#FDFDFD"
                    })
                    .css(options.css || {});
                $.noop($tooltip);
                me.setTooltip(me._tooltipText, me._options.html);
                me._windowMouseMoveListener = me._onWindowMouseMove.bind(me);
        },
        setTooltip:function(tooltip, isHtml) {
            var me = this,
                htmlText,
                $tooltip = me.$tooltip,
                replaceLineBreaks = function(s) {
                    if (!s || s.indexOf("\n") < 0) {
                        return s;
                    }
                    return CORE.escapeHtml(s).replace(/\n/g, "<br>");
                };

            me._tooltipText = tooltip || null;
            if(isHtml) {
                $tooltip.html(me._tooltipText);    
            }
            htmlText = replaceLineBreaks(me._tooltipText);
            $tooltip[htmlText != me._tooltipText ? "html" : "text"](htmlText);
        },
        activateTooltip:function(nativeEvent) {
            var me = this, options = me._options, delay = options.delay;
            if(me._activated) {
                return;
            }
            me._activated = true;
            $body.append(me.$tooltip);
            me._positionTooltip(nativeEvent);
            $window.off("mousemove", me._windowMouseMoveListener);
            $window.on("mousemove", me._windowMouseMoveListener);
            me._delayTimeoutId = setTimeout(me._showTooltip.bind(me), delay);

        },
        _showTooltip:function(){
            var me = this, $tooltip = me.$tooltip;
            me._tooltipVisible = true;
            $tooltip.css({display:"block"});
            me._delayTimeoutId = 0;
        },
        deactivateTooltip:function() {
            var me = this, $tooltip = me.$tooltip;
            me._activated = false;
            $window.off("mousemove", me._windowMouseMoveListener);
            $tooltip.detach();
            $tooltip.css({display:"none"});
            
            me._tooltipVisible = false;
            if(me._delayTimeoutId) {
                clearTimeout(me._delayTimeoutId);
            }
            me._delayTimeoutId = 0;
        },
        _resetTimeout:function() {
            var me = this, options = me._options, delay = options.delay;
            if(me._delayTimeoutId) {
                clearTimeout(me._delayTimeoutId);
            }
            me._delayTimeoutId = setTimeout(me._showTooltip.bind(me), delay);
        },
        _onWindowMouseMove:function(jqEvent) {
            var me = this;
            if(!me._activated) {
                return;
            }
            // the mouse has to pause in one place for the delay period
            // before the tooltip becomes visible.
            if(!me._tooltipVisible) {
                me._resetTimeout();
            }
            me._positionTooltip(jqEvent.originalEvent);

        },
        _positionTooltip:function(nativeEvent) {
            var me = this, $tooltip = me.$tooltip, 
                bh = $body.height(), bw = $body.width(), 
                tw = $tooltip.outerWidth(), th = $tooltip.outerHeight(), 
                pageX = nativeEvent.clientX, pageY = nativeEvent.clientY, 
                tx = pageX + 5, ty = pageY - 5 - th, 
                flipX = Math.max(0, pageX - 5 - tw), flipY = Math.min(bh-th,  pageY + 5);

            if(ty < 0) {
                ty = flipY;
                tx = tx + 10;
            }

            if(tx + tw > bw) {
                tx = flipX;
            }

            $tooltip.css({
                top:ty + "px",
                left:tx + "px"
            });

        },
        destroy:function() {
            var me = this, $tooltip = me.$tooltip;
            if(me._destroyed) {
                return;
            }
            me._destroyed = true;
            me._deactivateTooltip();
            $tooltip.remove();
            
        }

    };

})(CORE, $);

class Rectangle {
    constructor(x, y, width, height) {
        const me = this;
        me.x = me.left = x || 0;
        me.y = me.top = y || 0;
        me.width = width || 0;
        me.height = height || 0;
    }
    static fromPointerEvents(e1, e2) {
        const x1 = e1.pageX, y1 = e1.pageY,
            x2 = e2.pageX, y2 = e2.pageY,
            x = Math.min(x1, x2),
            y = Math.min(y1, y2),
            width = Math.abs(x2 - x1),
            height = Math.abs(y2 - y1);

        return new Rectangle(x, y, width, height);
    }
    static intersectionOf(rectA, rectB) {
        const a = rectA, b = rectB, 
            ax1 = a.x, ax2 = a.x + a.width,
            ay1 = a.y, ay2 = a.y + a.height,

            bx1 = b.x, bx2 = b.x + b.width,
            by1 = b.y, by2 = b.y + b.height;

        let x1 = 0, y1 = 0, x2 = 0, y2 = 0;

        if(ax1 > bx2 || bx1 > ax2 || ay1 > by2 || by1 > ay2) {
            return null;
        }

        x1 = Math.max(ax1, bx1);
        y1 = Math.max(ay1, by1);
        x2 = Math.min(ax2, bx2);
        y2 = Math.min(ay2, by2);

        return new Rectangle(x1, y1, x2 - x1, y2 - y1);
    }
    static toCSS(rect) {
        return {
            top:rect.y + "px",
            left:rect.x + "px",
            width:rect.width + "px",
            height:rect.height + "px"
        };
    }
}

CORE.Rectangle = Rectangle;

(function(CORE, $){

    var I = new DOMMatrix(), body = $("body")[0], bodyIsRotated = false;

    function setBodyIsRotated(b) {
        bodyIsRotated = !!b;
    }

    function getBodyIsRotated() {
        return bodyIsRotated;
    }

    function Point(x, y, z) {
        var me = this;

        me.x = x;
        me.y = y;
        me.z = z;
    }

    Point.prototype = {
        /**
         * Transform the point using the matrix.
         *
         * @param  {DOMMatrix} matrix The matrix to use for transforming the point.
         * @return {Point}            A new point transformed by the matrix.
         */
        transformBy:function(matrix) {
            var me = this,
                m  = matrix.multiply(I.translate(me.x, me.y, me.z));
            return new Point(m.m41, m.m42, m.m43);
        }
    };

    /**
     * Get the transformation matrix for an element all the way up to the root
     * of the document.
     *
     * @param  {HTMLElement} element The element to get the transformation of.
     * @return {DOMMatrix}           The matrix.
     */
    function getTransformMatrix(element) {

        var transformMatrix = I, x = element, computedStyle, transform, c;

        while( x != undefined && x !== x.ownerDocument.documentElement ) {
            computedStyle = window.getComputedStyle(x, undefined);
            transform = computedStyle.transform || "none";
            c = transform === "none" ? I : new DOMMatrix(transform);
            transformMatrix = c.multiply(transformMatrix);
            x = x.parentNode;
        }

        var w = element.offsetWidth,
            h = element.offsetHeight,
            p1 = new Point(0, 0, 0).transformBy(transformMatrix),
            p2 = new Point(w, 0, 0).transformBy(transformMatrix),
            p3 = new Point(w, h, 0).transformBy(transformMatrix),
            p4 = new Point(0, h, 0).transformBy(transformMatrix),
            left = Math.min(p1.x, p2.x, p3.x, p4.x),
            top = Math.min(p1.y, p2.y, p3.y, p4.y),
            rect = element.getBoundingClientRect();

        transformMatrix = I.translate(window.pageXOffset + rect.left - left, window.pageYOffset + rect.top - top, 0).multiply(transformMatrix);

        return transformMatrix;
    }

    function maybeRotateBoundingClientRect(rect) {
        const width = rect.width, height = rect.height, top = rect.top,
            left = rect.left, right = rect.right, bottom = rect.bottom,
            winH = window.innerHeight, winW = window.innerWidth,
            W = height, H = width;

        if($html.hasClass("idb-rotate-left")) {
            let X = winH - bottom, Y = left, R = X + W, B = Y + H;
            return {
                width:W,
                height:H,
                x:X,
                y:Y,
                left:X,
                top:Y,
                right:R,
                bottom:B
            };
        }
        else if($html.hasClass("idb-rotate-right")) {
            let X = top, Y = winW - right, R = X + W, B = Y + H;
            return {
                width:W,
                height:H,
                x:X,
                y:Y,
                left:X,
                top:Y,
                right:R,
                bottom:B
            };
        }
        else {
            return rect;
        }
    }

    function maybeRotateJQueryPosition(position) {
        const top = position.top,
            left = position.left;

        if($html.hasClass("idb-rotate-left")) {
            return {
                top:left,
                left:-top
            };
        }
        else if($html.hasClass("idb-rotate-right")) {
            return {
                top:-left,
                left:top
            };
        }
        else {
            return position;
        }
    }


    CORE.pageToNode = function(element, pageX, pageY) {
        return new Point((pageX || 0), (pageY || 0), 0).transformBy(getTransformMatrix(element).inverse());
    };

    CORE.nodeToPage = function(element, offsetX, offsetY) {
        return new Point((offsetX || 0), (offsetY || 0), 0).transformBy(getTransformMatrix(element));
    };

    CORE.nodeToBody = function(element, offsetX, offsetY) {
        var pt = CORE.nodeToPage(element, offsetX, offsetY);
        return CORE.pageToNode(body,  pt.x,  pt.y);
    };

    CORE.nodeToNode = function(element, xWithinElement, yWithinElement, otherElement) {
        var pt = CORE.nodeToPage(element, xWithinElement, yWithinElement);
        return CORE.pageToNode(otherElement, pt.x, pt.y);
    };


    CORE.toBodyXY = function(evt) {
        var e = (evt.originalEvent || evt),
            pt = CORE.pageToNode(body, e.pageX, e.pageY);

        pt.pageX = pt.x;
        pt.pageY = pt.y;

        return pt;
    };

    CORE.toTopBodyXY = (evt) => {
        const e = (evt.originalEvent || evt);
        let pageX = e.pageX, pageY = e.pageY;
        const iframe = evt.view.frameElement;

        if(iframe) {
            const boundingClientRect = iframe.getBoundingClientRect();
            pageX = evt.pageX + boundingClientRect.left;
            pageY = evt.pageY + boundingClientRect.top;
        }

        const pt = CORE.pageToNode(body, pageX, pageY);

        pt.pageX = pt.x;
        pt.pageY = pt.y;

        return pt;
    };

    CORE.setBodyIsRotated = setBodyIsRotated;
    CORE.getBodyIsRotated = getBodyIsRotated;
    CORE.maybeRotateBoundingClientRect = maybeRotateBoundingClientRect;
    CORE.maybeRotateJQueryPosition = maybeRotateJQueryPosition;

    CORE.noop = () => {};

})(CORE, $);



export default CORE;

  // end define(...

