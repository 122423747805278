/*globals $ */


/**
 * @module dateutil
 */

/**
 * A parsed datetime
 * @typedef {Object} DT
 * @property {number}  year
 * @property {number} month
 * @property {number} date
 * @property {number} [hours]
 * @property {number} [minutes]
 * @property {number} [seconds]
 * @property {number} [milliseconds]
 */

        var internal, exports,
            datetimeFormat = "yyyy-MM-dd HH:mm:ss.SSS",
            dateFormat = "yyyy-MM-dd",
            dateRegExp = /^(\d{4})-(\d{1,2})-(\d{1,2})$/,
            timeRegExp = /^(\d{1,2}):(\d{1,2})(?::(\d{1,2})(?:.(\d{1,3}))?)?$/,
            DAYS_IN_MONTH = [31,28,31,30,31,30,31,31,30,31,30,31],
            MILLISECONDS_PER_MINUTE = 1000 * 60,
            CALENDAR = "ISO 8601",
            midnightRegExes = [
                / 00:00:00$/,
                / 00:00$/,
                / 12:00:00 AM$/i,
                / 12:00 AM$/i,
                / 00:00:00\.000$/
            ],
            M1 = "\u00A7",
            M2 = M1 + M1,
            M3 = M2 + M1,
            M4 = M3 + M1,

            E1 = "\u00AC",
            E2 = E1 + E1,
            E3 = E2 + E1,
            E4 = E3 + E1,
            monthKeys = [
                "january",
                "february",
                "march",
                "april",
                "may",
                "june",
                "july",
                "august",
                "september",
                "october",
                "november",
                "december"
            ],
            monthsFull = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            monthsShort = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ],
            dowKeys = [
                "sunday",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday"
            ],
            dowsFull = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
            dowsShort = [
                "Sun",
                "Mon",
                "Tues",
                "Wed",
                "Thur",
                "Fri",
                "Sat"
            ];

        function nullPadLeft(v /*: any */, len /*: number */) {
            v = v.toString();
            while (v.length < len) {
                v = '0' + v;
            }
            return v;
        }

        /**
         * Given a formatted string representing a date, this will truncate the time component if it represents
         * midnight. It removes any of the following if they appear at the end of the string:
         * <ul>
         *   <li>" 00:00:00"</li>
         *   <li>"  00:00"</li>
         *   <li>"  12:00:00 AM"</li>
         *   <li>"  12:00 AM$"</li>,
         *   <li>"  00:00:00.000"</li>
         * </ul>
         */
        function truncateMidnight(dateString /*: string */) /*: string */ {
            var j, s;
            if(!dateString) {
                return dateString;
            }
            for(j=0; j<midnightRegExes.length; j++) {
                s = dateString.replace(midnightRegExes[j], "");
                if(s != dateString) return s;
            }
            return dateString;
        }

        function hasTimeComponent(d /*: Date */) /*: boolean */ {
            if(d == null) {
                return false;
            }
            return d.getHours() != 0 || d.getMinutes() != 0 || d.getSeconds() != 0 || d.getMilliseconds() != 0;
        }
            
        function trim(s) {
            if(s === null) return '';
            return s.replace(/^\s*/, '').replace(/\s*$/, '');
        }

        function isLeapYear(year) {
            return ((year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0)));
        }

        function parseDatetimeBase(dtStr)  {
            var err, splitStr, dateResults, dt, year, month, day, hours, minutes, seconds, milliseconds, numDays, timeResults;

            dtStr = trim(dtStr);

            if(!dtStr) return null;

            err =  new Error("Invalid datetime: " + dtStr + ". Expected datetime format is " + datetimeFormat + ".");
            splitStr = dtStr.split(/\s+/);
            dateResults = splitStr[0].match(dateRegExp);

            //
            // Might never be less than 4 because of capture groups in results.
            //
            if(dateResults === null || dateResults.length < 4) {
                throw err;
            }

            dt = { };

            try {

                year = dateResults[1];

                if(year < 1 || year > 9999) {
                    throw err;
                } else {
                    dt.year = year;
                }

                month = dateResults[2]-1;

                if(month < 0 || month > 11) {
                    throw err;
                } 
                else {
                    dt.month = month;
                }

                day = 1;

                if(dateResults.length > 2) {
                    day = dateResults[3];

                    numDays = DAYS_IN_MONTH[month];

                    if(month == 1 && isLeapYear(year)) {
                        ++numDays;
                    }

                    if(day < 1 || day > numDays) {
                        throw err;
                    } else {
                        dt.date = day;

                    }
                }

                hours = 0;
                minutes = 0;
                seconds = 0;
                milliseconds = 0;

                if(splitStr.length > 1) {

                    timeResults = splitStr[1].match(timeRegExp);

                    if(timeResults === null || timeResults.length < 3) {
                        throw err;
                    }

                    hours = timeResults[1];

                    if(hours < 0 || hours > 23) {
                        throw err;
                    } else {
                        dt.hours = hours;
                    }

                    minutes = timeResults[2];
                    if(minutes < 0 || minutes > 59) {
                        throw err;
                    } else {
                        dt.minutes = minutes;
                    }

                    if(timeResults[3]) {
                        seconds = timeResults[3];

                        if(seconds < 0 || seconds > 59) {
                            throw err;
                        } else {
                            dt.seconds = seconds;
                        }
                    }

                    if(timeResults[4]) {
                        milliseconds = timeResults[4];

                        if(milliseconds < 0 || milliseconds > 999) {
                            throw err;
                        } else {
                            dt.milliseconds = milliseconds;
                        }

                    }

                }
            } catch( e ) {
                throw err;
            }

            return dt;
        }



        internal = {
            CALENDAR:CALENDAR,
            hasTime:function(dt) {
                return dt && dt.hasOwnProperty('hours');
            },
            makeDate:function(dt) {
                var seconds, milliseconds;
                if(!internal.hasTime(dt)) {
                    return new Date(dt.year, dt.month, dt.date);
                }
                else {
                    seconds = dt.seconds ? dt.seconds : 0;
                    milliseconds = dt.milliseconds ? dt.milliseconds : 0;
                    return new Date(dt.year, dt.month, dt.date, dt.hours, dt.minutes, seconds, milliseconds);
                }
            },
            makeDatetime:function(date, showTime) {
                var dt = {};
                dt.year = date.getFullYear();
                dt.month = date.getMonth();
                dt.date = date.getDate();
                if(showTime) {
                    dt.hours = date.getHours();
                    dt.minutes = date.getMinutes();
                    dt.seconds = date.getSeconds();
                    dt.milliseconds = date.getMilliseconds();
                }
                return dt;
            },


            /**
             * Parses a datetime string in the 'yyyy-MM-dd HH:mm:ss.SSS' format. Returns an object with the appropriate
             * components to construct a Date object.  
             */  
            parseDatetime:function(dtStr, timezoneOffset)  {
                var dt = parseDatetimeBase(dtStr);

                if(!isNaN(timezoneOffset)) {
                    dt = internal.adjustForTimezone(dt, timezoneOffset, -1);
                }

                return dt;
            },

            /**
             * Returns a String representing the give Date in yyyy-MM-dd HH:mm:ss.SSS format, or
             * in yyyy-MM-dd HH:mm:sss format if the alwaysIncludeMilliseconds argument is false and
             * the Date's milliseconds property is 0, or yyy-MM-dd format if the date has no time component
             * at all and both alwaysIncludeMilliseconds and alwaysIncludeTime are false.
             */
            toTimestamp:function(
                d /*: Date */,
                alwaysIncludeMilliseconds /*: boolean */,
                alwaysIncludeTime /*: boolean */
            ) /*: string */ {
                if(alwaysIncludeMilliseconds === undefined) {
                    alwaysIncludeMilliseconds = true;
                }
                if(alwaysIncludeTime === undefined) {
                    alwaysIncludeTime = true;
                }
                if(!d) {
                    return null;
                }
                var hasMillis = d.getMilliseconds() != 0;
                var hasTime = hasMillis || hasTimeComponent(d);
                var s = nullPadLeft(d.getFullYear(), 4) + "-" + nullPadLeft(1+d.getMonth(), 2) + "-" + nullPadLeft(d.getDate(), 2);
                if(hasTime || alwaysIncludeTime) {
                    s = s + " " + nullPadLeft(d.getHours(), 2) + ":" + nullPadLeft(d.getMinutes(), 2) + ":" + nullPadLeft(d.getSeconds(), 2);
                }

                if(alwaysIncludeMilliseconds || (d.getMilliseconds() != 0)) {
                    s = s + "." + nullPadLeft(d.getMilliseconds(), 3);
                }

                return s;
            },

            /**
             * Returns a string representation of the Date or Date-like 
             * object in the 'yyyy-MM-dd* HH:mm:ss.SSS' format. If the 
             * 'hours' property is present, then the string will have the 
             * time component. 
             */  
            formatDatetime:function(dt, timezoneOffsetBase) {
                if(dt instanceof Date) {
                    dt = internal.makeDatetime(dt);
                }
                var date = new Date(dt.year, dt.month, dt.date),
                    y = String(date.getFullYear()),
                    M = String(date.getMonth()+1),
                    d = String(date.getDate()),
                    showTime = internal.hasTime(dt), dateStr, result,
                    H, m, s, S, timeStr;

                if(!isNaN(timezoneOffsetBase)) {
                    dt = internal.adjustForTimezone(dt, timezoneOffsetBase);
                }

                dateStr = y + "-";
                dateStr += internal.padLeft(M, "0", 2);
                dateStr += "-";
                dateStr += internal.padLeft(d, "0", 2);
                result = dateStr;

                if(showTime) {
                    H = dt.hours;
                    m = dt.minutes;
                    s = dt.seconds;
                    S = dt.milliseconds;

                    timeStr = " ";
                    timeStr += internal.padLeft(String(H), "0", 2);
                    timeStr += ":";
                    timeStr += internal.padLeft(String(m), "0", 2);

                    if(s !== undefined) {
                        timeStr += ":";
                        timeStr += internal.padLeft(String(s), "0", 2);

                        if(S !== undefined) {
                            timeStr += ".";
                            timeStr += internal.padLeft(String(S), "0", 3);
                        }
                    }

                    result += timeStr;
                }

                return result;
            },
            padLeft:function(str, ch, num) {
                var result;

                if(num === 0) {
                    return "";
                }

                if(str.length >= num) {
                    return str.substr(str.length-num);
                } 
                else {
                    result = "";
                    for(var i = 0; i < num-str.length; i++) {
                        result += ch;
                    }
                    result += str;
                }
                return result;
            },
            validate:function(value) {
                try {
                    internal.parseDatetime(value);
                } catch( e ) {
                    return false;
                }

                return true;
            },

            /**
             * This method takes a date string and normalizes it to the 
             * 'yyyy-MM-dd HH:mm:ss.SSS' format. 
             */  
            normalizeDatetimeString:function(dtStr, allowNullIndicator) {
                if(!dtStr) {
                    return dtStr;
                }

                if(allowNullIndicator && dtStr === "null") {
                    return dtStr;
                }

                var dt = internal.parseDatetime(dtStr);

                if(!internal.hasTime(dt)) {
                    dt.hours = 0;
                    dt.minutes = 0;
                    dt.seconds = 0;
                    dt.milliseconds = 0;
                }

                if(!dt.hasOwnProperty("milliseconds")) {
                    dt.milliseconds = 0;
                }

                return internal.formatDatetime(dt);
            },


            adjustForTimezone:function(dt, timezoneOffsetBase, orientation) {
                var adjustedMillis,
                    showTime = internal.hasTime(dt),
                    date = internal.makeDate(dt),
                    newDate;

                if(!isNaN(timezoneOffsetBase)) {
                    if(isNaN(orientation)) {
                        orientation = 1;
                    }
                    orientation =(Math.abs(orientation)!= 1) ? 1 : orientation;
                    adjustedMillis = date.getTime()+ orientation*((date.getTimezoneOffset()- timezoneOffsetBase)*MILLISECONDS_PER_MINUTE);
                } else {
                    adjustedMillis = date.getTime();
                }

                newDate = new Date(adjustedMillis);

                return internal.makeDatetime(newDate, showTime);
            },
            parseDate:function(dtStr)  {

                dtStr = internal.trim(dtStr);

                if(!dtStr) return null;

                var err = new Error("Invalid date: " + dtStr + ". Expected date format is " + dateFormat + ".");
                var dateResults = dtStr.match(internal.dateRegExp);

                //
                // Might never be less than 4 because of capture groups in results.
                //
                if(dateResults === null || dateResults.length < 4) {
                    throw err;
                }

                var dt = { };

                try {

                    var year = dateResults[1];

                    if(year < 1 || year > 9999) {
                        throw err;
                    } else {
                        dt.year = year;
                    }

                    var month = dateResults[2]-1;

                    if(month < 0 || month > 11) {
                        throw err;
                    } else {
                        dt.month = month;
                    }

                    var day = 1;

                    if(dateResults.length > 2) {
                        day = dateResults[3];

                        var numDays = internal.DAYS_IN_MONTH[month];

                        if(month == 1 && internal.isLeapYear(year)) {
                            ++numDays;
                        }

                        if(day < 1 || day > numDays) {
                            throw err;
                        } else {
                            dt.date = day;

                        }
                    }
                } catch( e ) {
                    throw err;
                }

                return dt;
            },
            isValidDate:function(value) {
                try {
                    internal.parseDate(value);
                } catch( e ) {
                    return false;
                }

                return true;
            },
            makeJavaScriptDate:function(value) {
                if(value instanceof Date) {
                    return value;
                } 
                else if(typeof(value)=== 'number') {
                    return new Date(value);
                } 
                else if(value) {
                    return internal.makeDate(internal.parseDatetime(value.toString()));
                } 
                else {
                    return null;
                }
            },
            dateDifference:function(d1, d2) {
                var date1 = internal.makeJavaScriptDate(d1);
                var date2 = internal.makeJavaScriptDate(d2);
                return date1.getTime()- date2.getTime();
            },
            format:function(date/*:Date*/, 
                            formatString /*: string */,
                            $truncateMidnight /*: boolean */) /*: string */ {
                if(!date) {
                    return "";
                }
                if(!$.trim(formatString)) {
                    return $truncateMidnight ? truncateMidnight(internal.toTimestamp(date, false, true)) : internal.toTimestamp(date, false, true);
                }

                var r = formatString;
                var d = date;

                r = r.split('a').join('^');
                r = r.split('M').join(M1);
                r = r.split('E').join(E1);

                r = r.split('yyyy').join(d.getFullYear());
                var y = String(d.getFullYear());
                r = r.split('yy').join(y.substr(2));

                r = r.split('dd').join(nullPadLeft(d.getDate(),2));
                r = r.split('d').join(d.getDate());

                r = r.split('HH').join(nullPadLeft(d.getHours(),2));
                r = r.split('H').join(d.getHours());

                var hours /*: number */ = d.getHours() % 12;
                r = r.split('hh').join(nullPadLeft((hours == 0) ? 12 : hours, 2));
                r = r.split('h').join((hours == 0) ? 12 : hours);

                r = r.split('mm').join(nullPadLeft(d.getMinutes(),2));
                r = r.split('m').join(d.getMinutes());

                r = r.split('ss').join(nullPadLeft(d.getSeconds(),2));
                r = r.split('s').join(d.getSeconds());

                r = r.split('SSS').join(nullPadLeft(d.getMilliseconds(),3));
                r = r.split('SS').join(nullPadLeft(d.getMilliseconds(),2));
                r = r.split('S').join(d.getMilliseconds());

                r = r.split(M4).join(monthsFull[d.getMonth()]);
                r = r.split(M3).join(monthsShort[d.getMonth()]);
                r = r.split(M2).join(nullPadLeft(d.getMonth()+1,2));
                r = r.split(M1).join(d.getMonth()+1);

                r = r.split(E4).join(dowsFull[d.getDay()]);
                r = r.split(E3).join(dowsShort[d.getDay()]);
                r = r.split(E2).join(dowsShort[d.getDay()]);
                r = r.split(E1).join(dowsShort[d.getDay()]);

                r = r.split('^').join(d.getHours() >= 12 ? 'PM':'AM');

                return $truncateMidnight ? truncateMidnight(r) : r;
            },

            /**
             * Dependency injection - so this module does not depend on a 
             * language module for the small number of localized strings it 
             * uses, a bundle containing those strings can be passed to this 
             * method. 
             */
            localizeStrings:function(bundle) {
                var b = bundle.bundle, key, fullKey, j, jj;
                for(j=0, jj=monthKeys.length; j<jj; j++) {
                    key = monthKeys[j];
                    fullKey = "month.full." + key;
                    if(b.hasOwnProperty(fullKey)) {
                        monthsFull[j] = b[fullKey];    
                    }
                    fullKey = "month.short." + key;
                    if(b.hasOwnProperty(fullKey)) {
                        monthsShort[j] = b[fullKey];    
                    }
                }

                for(j=0, jj=dowKeys.length; j<jj; j++) {
                    key = dowKeys[j];
                    fullKey = "dow.full." + key;
                    if(b.hasOwnProperty(fullKey)) {
                        dowsFull[j] = b[fullKey];    
                    }
                    fullKey = "dow.short." + key;
                    if(b.hasOwnProperty(fullKey)) {
                        dowsShort[j] = b[fullKey];    
                    }
                }
            },
            truncateMidnight:truncateMidnight,
            padDateString:(s) => {
                if(!s || s.length === 23) {
                    return s;
                }
                if(s.length === 10) {
                    return s + " 00:00:00.000";
                }
                if(s.length === 19) {
                    return s + ".000";
                }
                console.warn("padDateString: Can't pad:",  s);
                return s;
            }
        };

        exports = $.extend({}, internal);
        export default exports;
    

