/*jshint esversion:6 */


import colorhelper from "../utils/colorhelper.js";
        const internal = {
            toFour(array) {

                let arr = array || [0,0,0,0],
                    [one, two] = arr,
                    length = arr.length;
                if(length === 0) {
                    return [0,0,0,0];
                }
                else if(length === 1) {
                    return arr.concat(one, one, one);
                }
                else if(length === 2) {
                    return arr.concat(one, two);
                }
                else if(length === 3) {
                    return arr.concat(two);
                }

                arr.length = 4;
                return arr;
            },

            addPx(n) {
                if(isNaN(n)) {
                    return "0px";
                }
                return n + "px";
            },

            toCssShorthand(arr) {
                return internal.toFour(arr).map(internal.addPx).join(" ");
            },

            toCssColor(input) {
                if(input === undefined || input === null) {
                    return "";
                }
                return colorhelper.rgb(input).css;
            },

            /**
             * Convert camelcase to hyphenated.
             * @param {string} str - string to convert
             * @returns {string} hyphenated string
             */
            camelToHyphen(str) {
                return str.replace(/([A-Z])/g, (match) => `-${match.toLowerCase()}`);
            },

            /**
             * Convert cssStyleMap to a css property string, with customizable indent and newlines.
             * @param {import("idbtypes").CssStyleMap} cssStyleMap
             * @param {string} [indent='    '] - String used for indentation.
             * @param {string} [newline='\n'] - String used for new lines.
             * @returns {string} css property string
             */
            createCssPropsString(cssStyleMap, indent = '    ', newline = '\n') {
                let cssString = '';

                Object.entries(cssStyleMap).forEach(([property, value]) => {
                    const hyphenatedProperty = internal.camelToHyphen(property);
                    cssString += `${indent}${hyphenatedProperty}: ${value};${newline}`;
                });

                return cssString;
            },

            /**
             * Create a stylesheet with given rules and id.
             * @param {import("idbtypes").CssRuleText[] | import("idbtypes").CssRuleText} rules
             * @param {string} id
             * @returns {HTMLStyleElement} mounted stylesheet
             */
            createStylesheet(rules, id) {
                rules = Array.isArray(rules) ? rules : [rules];
                const styleSheet = document.createElement('style');
                styleSheet.setAttribute('type', 'text/css');
                styleSheet.textContent = rules.join('\n');
                document.head.appendChild(styleSheet);
                if (id) {
                    styleSheet.setAttribute('id', id);
                }
                return styleSheet;
            }

        },
        exports = {};

        Object.assign(exports, internal);

        export default exports;
