/*globals URLSearchParams */
/*jshint esversion: 11*/

import $ from 'jquery';

import SecurityUtils from "../utils/security.js";
import {pageFocusIndicators, findFirstFocusUntil} from "../utils/FirstFocusUtils.js";
import session from "../session.js";
import config from "../config.js";
import core from "../idbcore.js";
import pagedata from "../pagedata.js";
import popupmgr from "../popup/popupmgr.js";
import { getBodyKeytips, createKeytipDefaultOptionsCopy } from './keytips.js';
import {PAGE_LOAD_MESSAGE, CLOSE_ON_ERROR} from "../pageloadmessage.js"; // jshint ignore:line
import {maybeAddFloatingAIHelpButton, AIHelpButton} from "../help/aihelp.js";
import userutils from "../utils/userutils.js";

export function getPageData(name/*: string */)/*: any */ {
    return pagedata[name];
}

if(config.config.ext && config.config.ext.suppressContextMenu === false) {
    core.suppressContextMenu(false);
}

const DEFAULT_PAGE_ID = 'idb-page-content';

export class PageHandler {
    init(parentElementId) {
        const deviceId = SecurityUtils.getDeviceId();
        this.deviceId = deviceId;

        if(parentElementId && ('string' === typeof parentElementId)) {
            this._parentElementId = parentElementId;
        }

        if (document.readyState === 'loading') {
            window.addEventListener('DOMContentLoaded', async () => {
                await this._onContentLoaded();
            });
        }
        else {
            this._onContentLoaded();
        }
    }

    /**
     * Subclasses may override to disable hotkeys.
     *
     */
    hotkeysEnabled() {
        return false;
    }

    firstFocusEnabled() {
        return true;
    }

    async _onContentLoaded() {
        const parent = this.getPageElement();
        const qs = new URLSearchParams(document.location.search);

        if (PAGE_LOAD_MESSAGE) {
            popupmgr.alert(PAGE_LOAD_MESSAGE, this.onPageLoadMessageDismissal.bind(this));
        }

        if (!parent) {
            throw new Error(`Page element is null, either add the ID '${ DEFAULT_PAGE_ID }' to an element or override the getPageElement method.`);
        }

        const $body = $('body').addClass(pageFocusIndicators);

        if(this.hotkeysEnabled()) {
            this._keytips = getBodyKeytips();
            if (!this._keytips) {
                console.warn('!this._keytips in pagehandler.js');
            }
        }

        await this.initPage(parent, qs);

        if(this.firstFocusEnabled()) {
            findFirstFocusUntil($body, 200, 10);
        }

        if (this.isTimeoutEnabled()) {
            session.init();
        }
        if (config.config.dli) {
            const whichHandler = this.constructor.name === 'SecurityPageHandler' ? this.currentView : this;
            const handlerString = whichHandler.constructor.PAGE_HANDLER ? whichHandler.constructor.PAGE_HANDLER : 'not found';
            $('head').attr('page_handler', handlerString);
        }
    }

    _keytipOptions(tipPositionCss = {}) {
        const def = createKeytipDefaultOptionsCopy();
        return {...def, tipPositionCss};
    }


    /**
     * Override this method to execute your page code. This method should not be
     * called directly, instead call the `init()` method.
     *
     * @param  {HTMLElement} parent The element that your page's DOM should be
     *                              appended to.
     *
     * @param  {URLSearchParams} qs The pre-parsed query string parameters.
     */
    async initPage(parent/*: HTMLElement */, qs/*: URLSearchParams */)/*: Promise<void> */ {
        throw new Error('Not Implemented!');
    }

    /**
     * Get the element that should contain all of the page content. This is the
     * element that gets passed to the `initPage()` method.
     *
     * If the instance has a property named '_parentElementId', it
     * will by default look for the id 'idb-page-content'.
     *
     * @return {HTMLElement} The element for the page content.
     */
    getPageElement()/*: HTMLElement */ {
        return document.getElementById(this._parentElementId || DEFAULT_PAGE_ID);
    }

    /**
     * Get a parameter embedded into the page from the JSP.
     */
    getPageParameter(name/*: string */)/*: any */ {
        return window[name];
    }

    /**
     * Get a property value from the pagedata object.
     */
    getPageData(name/*: string */)/*: any */ {
        return pagedata[name];
    }

    isLoggedOut() {
        return session.isLoggedOut();
    }

    isTimeoutEnabled() {
        const user = config.config.user;

        return !user?.guest;
    }

    onPageLoadMessageDismissal() {
    }
}

export class PageWithAIHelpHandler extends PageHandler {
    async _onContentLoaded() {
        await super._onContentLoaded();

        const user = config.config.user;

        if(config.config.aiHelpEnabled && user && userutils.roleIsAtLeast(user.userRole, 'N')) {
            let aiHelpDiv = document.querySelector('.idb-ai-help-div');

            if(!aiHelpDiv) {
                maybeAddFloatingAIHelpButton();
            }
            else {
                const aiHelpButton = new AIHelpButton($(aiHelpDiv), {classes:'idb-ai-help-button-sm'});
                $.noop(aiHelpButton);
            }

        }
    }
}
