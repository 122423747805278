/*globals $ */
/*jshint esversion: 11*/

/*::
  export type UserUtils = {
      createUserFromForm($form: JQuery): FvUserRequest;
      roleIsAtLeast(role: any, minRole: any): boolean;
      validatePassword(password?: string, confirmPassword?: string, allowNullPassword?: ?boolean): boolean;
      validatePasswordsMatch(password?: string, confirmPassword?: string): ValidationResult;
      validateRequired(v: string, displayName: string): ValidationResult;
  };
*/

import adminapp from "../admin/adminapp.js";
import bundle from "../lang/Abu.js";
import core from "../idbcore.js";

const EL = core.EL;
    

    const { format } = bundle,
        userRoles = ['V', 'N', 'D', 'A'];

    var UserUtils = {
        createUserFromForm: function (
            $form /*: JQuery */
        ) /*: FvUserRequest */ {
            var formData = adminapp.getFormInput($form, true),
                user /*: $Shape<FvUserRequest> */ = {};

            user.lastName = core.trim(formData.lastName);
            user.firstName = core.trim(formData.firstName);
            user.username = core.trim(formData.username);
            user.userStatus = formData.userStatus || null;

            if (
                formData.changePassword === 'true' ||
                $('input[name=changePassword]').length === 0
            ) {
                user.password = formData.password;
            } else {
                user.password = null;
            }

            user.email = core.trim(formData.email);
            user.userRole = formData.userRole;
            user.groupId = parseInt(formData.groupId, 10);
            user.smsCarrierCode = formData.smsCarrierCode;
            user.articleCommentModerator = formData.articleCommentModerator;
            user.dashboardCommentModerator = formData.dashboardCommentModerator;
            user.requiresAuthorization = formData.requiresAuthorization == 'true';
            return user;
        },

        passwordsMatch: function (
            password /*:? string */,
            confirmPassword /*:? string */
        ) /*: boolean */ {
            password = password || "";
            confirmPassword = confirmPassword || "";

            if (!password) {
                return !confirmPassword;
            } else if (confirmPassword) {
                return password === confirmPassword;
            } else {
                return false;
            }
        },

        validatePassword: function (
            password /*: string */,
            confirmPassword /*: string */,
            allowNullPassword = false /*: ?number */
        ) /*: ValidationResult */ {

            // confirm the match before doing any individual validation.
            if(!UserUtils.passwordsMatch(password, confirmPassword)) {
              return adminapp.invalidResults(
                    format('idbdata.user.password_match')
                );
            }

            if(!allowNullPassword && (password || "").length === 0) {
                return adminapp.invalidResults(
                    format("password_rule.null_password_prohibited")
                );
            }

            if (/\s+/.test(password)) {
                return adminapp.invalidResults(
                    format("password_rule.error_message.whitespace")
                );
            }

            return adminapp.validResults();
        },

        validatePasswordsMatch: function (
            password /*: string */,
            confirmPassword /*: string */
        ) /*: ValidationResult */ {
            if (!UserUtils.passwordsMatch(password, confirmPassword)) {
                return adminapp.invalidResults(
                    format('idbdata.user.password_match')
                );
            } else {
                return adminapp.validResults();
            }
        },

        validateRequired: function (
            v /*: string */,
            displayName /*: string */
        ) /*: ValidationResult */ {
            v = core.trimToNull(v);

            if (!v) {
                if (displayName) {
                    return adminapp.invalidResults(
                        format('idbdata.user.provide_required', displayName)
                    );
                } else {
                    return adminapp.invalidResults(
                        format('idbdata.user.provide_value')
                    );
                }
            } else {
                return adminapp.validResults();
            }
        },

        roleIsAtLeast: function (role, minRole) {
            let roleIdx = userRoles.indexOf(role),
                minRoleIdx = userRoles.indexOf(minRole);

            return roleIdx >= minRoleIdx;
        },

        passwordErrorTo$Div(message, violations) {
            const $div = $("<div class=\"idb-pw-err-display\">"),
                $msg = $("<div class=\"idb-pw-err-display\">")
                    .text(message).appendTo($div),
                $ul = $("<ul>").appendTo($div);

            $msg.css({
                     "font-weight":"bold"
                 });

            violations.forEach((v) => {
                $("<li>").appendTo($ul)[0].innerText = v;
            });

            return $div;
        },

        /**
         *  Builds an ordered list of objects, each of which has the rule text
         *  as the rule property, and a passed property which is null if no violations
         *  were checked, true if the rule was checked and passed, and false if
         *  the rule was checked and failed. The failed rules are grouped
         *  at the beginning of the list. Within the failed/passed subgroups,
         *  the original ordering of the rules is preserved.
         * 
         */
        buildPasswordRuleObjectsList(rules, maybeViolations) {
                const hasViolations = maybeViolations?.length > 0,
                violations = maybeViolations || [];

            const objs = rules.map((rule,  sortSeq) => {
                const obj = {
                    sortSeq,
                    rule,
                    passed:null
                };
                if(hasViolations) {
                    if(violations.indexOf(rule) > -1) {
                        obj.passed = false;
                    }
                    else {
                        obj.passed = true;
                    }
                }
                return obj;
            });

            objs.sort((r1, r2) => {
                if(violations) {
                    if(r1.passed === false) {
                        if(r2.passed === true) {
                            return -1;
                        }
                    }
                    if(r2.passed === false) {
                        if(r1.passed === true) {
                            return 1;
                        }
                    }
                }

                return r1.sortSeq - r2.sortSeq;
            });

            return objs;
        },
        buildPasswordRuleUlElement(rules, maybeViolations) {
            const ul = EL("ul"),
                pwRequirements = UserUtils.buildPasswordRuleObjectsList(rules, maybeViolations),
                parStyles = {
                    "margin-block-start":"0.5em",
                    "margin-block-end":"0.5em",
                };
            
            pwRequirements.forEach((p) => {
                const li = ul.appendChild(EL("li"));
                const {rule, passed} = p, pars = rule.split(/\n\n/),
                    firstPar = li.appendChild(EL("p"));

                Object.assign(firstPar.style, parStyles);
                if(passed !== null) {
                    let span = firstPar.appendChild(EL("span"));
                    Object.assign(span.style, {
                        display:"inline-block",
                        height:"0.8em",
                        width:"0.8em",
                        "margin-right":"0.2em"
                    
                    });
                    if(passed === false) {
                        span.classList.add("idbicon-cross-red");
                    }
                    else {
                        span.classList.add("idbicon-check-green");
                    }
                }
                let span2 = firstPar.appendChild(EL("span"));
                span2.innerText = pars.shift();

                pars.forEach((parText) => {
                    let p = li.appendChild(EL("p"));
                    Object.assign(p.style, parStyles);
                    p.innerText = parText;
                });
            });

            return ul;

        }

    };

    export default UserUtils;

