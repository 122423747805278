/*globals $ */
/*jshint esversion: 6*/
import core from '../../../../COMMON/js/modules/idbcore.js';

import { PageHandler } from '../../../../COMMON/js/modules/utils/pagehandler.js';

class LoginPageHandler extends PageHandler {
    initPage(parent, qs) {
        const me = this,
            msgCss = me.getPageData('msgCss');
        if (msgCss) {
            $('#msg').css(msgCss);
        }
        $('button span').each(function (i, e) {
            if (core.overflows(e)) {
                var $e = $(e);
                $e.closest('button').attr({ title: $e.text() });
            }
        });
        const deviceId = window.localStorage.getItem('deviceId') || '';
        $('form input[name=deviceId]').val(deviceId);
    }
}

const pageHandler = new LoginPageHandler();

pageHandler.init();
