import $ from 'jquery';

import core from '../idbcore.js';
import bundle from '../lang/A.js';
import { hasKeys, isRepeating, isModifierOnly } from './keyboard.js';
import { isModalDialogShowing } from '../popup/popupmgr.js';
import csshelper from './csshelper.js';

export const BUNDLE_SPLIT = String.fromCharCode(126); // ~
/**
 * @type {import('.').KeyCodeList}
 */
export const defaultShowHideKeytipsKeys = 'c/ cs?';
export const defaultDataAttrSeriesPrefix = 'data-hotkey_';
export const defaultNotificationKeytipLinesUnsplit = bundle.format('hotkey.body.notify');
export const eventCallbackDataName = 'keytipsEventCallback';

/**
 * Messages for the Keytips component events callback.
 *
 * @see {@link Keytips._msgs}
 *
 * @type {Record<import('.').KeytipsMessageKey, string>}
 */
export const keytipsMsgs = {
    notificationShown: 'notificationShown',
    notificationHidden: 'notificationHidden',
    keytipsToggled: 'keytipsToggled',
    keytipsShown: 'keytipsShown',
    keytipsHidden: 'keytipsHidden',
};
Object.freeze(keytipsMsgs);

/**
 * Default options for the Keytips component.
 *
 * @see {@link Keytips}
 * @type {import('.').KeytipsOptions}
 */
export const defaultOptions = {
    showHideKeytipsKeys: defaultShowHideKeytipsKeys,
    eventsCallback: null,
    notificationLines: defaultNotificationKeytipLinesUnsplit,
    notificationDisplaySeconds: 7,
};
Object.freeze(defaultOptions);

/**
 * Css Classes for the Keytips component.
 *
 * @type {import('.').CssClassesDictionary}
 */
export const cssClasses = {
    notify: 'idb-hotkey-notify',
    hotkey: 'idb-data-hotkey',
};
Object.freeze(cssClasses);

/**
 * Notification that hotkeys are available.
 *
 * @type {import('.').CssBeforeOrAfterSelector}
 */
const notificationCssSelector = 'body[data-hotkey]::before';

/**
 * Selector when showing notification.
 *
 * @type {import('.').CssBeforeOrAfterSelector}
 */
const notificationCssSelectorShowing = `body.${cssClasses.notify}[data-hotkey]::before, body.${cssClasses.hotkey}[data-hotkey]::before`;

/**
 * Default Notification css. Top middle of page.
 *
 * @type {import('idbtypes').CssStyleMap}
 */
const notificationCss = {
    content: 'attr(data-hotkey)',
    transform: 'translate(-50%, 0%)',
    left: '50vw',
    marginBottom: '10px',
    bottom: '0px',
};
Object.freeze(notificationCss);

/**
 * Default Css for hidden tips.
 *
 * @type {import('idbtypes').CssStyleMap}
 */
const tipHiddenCss = {
    opacity: '0',
    content: 'attr(data-hotkey_0)',
    // @ts-ignore [ts] Type '"all !important"' is not assignable to type 'PointerEvents'. OK
    pointerEvents: 'none !important',
    position: 'absolute',
    zIndex: '99000',
    fontSize: '0.8rem',
    fontWeight: '500',
    fontFamily: 'system-ui, Helvetica, Arial, sans-serif',
    textAlign: 'left',
    lineHeight: '1.5',
    color: '#000000',
    borderRadius: '4px',
    border: '2px solid #666666',
    backgroundColor: '#ffffff',
    padding: '0.3rem',
    boxShadow: '2px 2px 10px -2px black',
    whiteSpace: 'pre',
    transition: 'opacity 0.5s ease',
};
Object.freeze(tipHiddenCss);

/**
 * Default Css for showing tips.
 *
 * @type {import('idbtypes').CssStyleMap}
 */
const tipShowingCss = {
    opacity: '1',
    transition: 'opacity 0.5s ease',
    // @ts-ignore [ts] Type '"all !important"' is not assignable to type 'PointerEvents'. OK
    pointerEvents: 'all !important',
};
Object.freeze(tipShowingCss);

/**
 * Default Css for highlighting Element.
 *
 *
 * @type {import('idbtypes').CssStyleMap}
 */
const highlightElementCss = {
    boxShadow: 'inset 0 0 2px 2px red !important',
    outline: 'none !important',
    // these create flicker, I think solution exists
    //filter: 'brightness(0.5) !important',
    //opacity: '0.8',
};
Object.freeze(highlightElementCss);

/**
 * Default Css for highlighting tips.
 *
 * @see {@link CssStyleMap}
 *
 * @type {import('idbtypes').CssStyleMap}
 */
const highlightTipCss = {
    borderColor: 'red !important',
};
Object.freeze(highlightTipCss);

/**
 * Default Css package.
 *
 * @type {import('.').CssStyleMapMap}
 */
export const defaultCssMap = {
    /**
     * @type {import('idbtypes').CssStyleMap}
     */
    tipHiddenCss,
    /**
     * @type {import('idbtypes').CssStyleMap}
     */
    tipShowingCss,
    /**
     * @type {import('idbtypes').CssStyleMap}
     */
    highlightElementCss,
    /**
     * @type {import('idbtypes').CssStyleMap}
     */
    highlightTipCss,
};
Object.freeze(defaultCssMap);

/**
 * Base default options for `createKeytip()`.
 *
 * @type {import('.').CreateKeytipOptions}
 */
export const createKeytipDefaultOptionsValues = {
    log: false,
    bundle: null,
    beforeAfter: 'before',
    removeInset: false,
    tipPositionCss: {},
    tipHiddenCss: null,
    tipShowingCss: null,
    preventElementHighlight: false,
    preventTipHighlight: false,
    highlightElementCss: null,
    highlightTipCss: null,
};
Object.freeze(createKeytipDefaultOptionsValues);

/**
 * Final default options for `createKeytip()`.
 *
 * @type {import('.').CreateKeytipOptions}
 */
const createKeytipDefaultOptions = {
    ...createKeytipDefaultOptionsValues,
    ...{
        tipHiddenCss: { ...tipHiddenCss },
        tipShowingCss: { ...tipShowingCss },
        highlightElementCss: { ...highlightElementCss },
        highlightTipCss: { ...highlightTipCss },
    },
};
Object.freeze(createKeytipDefaultOptions);

/**
 * Export copy of `createKeytip()` default options.
 *
 * @returns CreateKeytipDefaultOptions {@link createKeytipDefaultOptions}
 */
export function createKeytipDefaultOptionsCopy() {
    return JSON.parse(JSON.stringify(createKeytipDefaultOptions));
}

/**
 * Get the Keytips instance attached to body, typical.
 *
 * @param {import('.').KeytipsOptions} options
 * @param {import('idbtypes').CssStyleMap} [notificationRulesObject]
 * @returns {Keytips} instance
 */
export function getBodyKeytips(options, notificationRulesObject) {
    return $('body').data('keytips')
        ? $('body').data('keytips')
        : new Keytips(options).setupHook(notificationRulesObject);
}

/**
 * Keytips instance currently only attaches to body and operates on body.
 * Css `::before` and `::after` are used, along with element attributes to create multi-line,
 * emoji capable, dynamic css stylesheets and element attributes that supply css `attr()`.
 */
export class Keytips {
    /**
     * Single instance, applied to body.
     * pagehandler.js calls `getBodyKeytips()`.
     *
     * @see {@link defaultOptions}
     *
     * @param {import('.').KeytipsOptions} options
     */
    constructor(options) {
        this._options = { ...defaultOptions, ...options };
        this._$body = $('body');
        this._$body.data('keytips', this);
    }
    /**
     * Alias for `keytipsMsgs`.
     *
     * @type {Record<import('.').KeytipsMessageKey, string>}
     * @readonly
     * @see {@link keytipsMsgs}
     */
    get _msgs() {
        return keytipsMsgs;
    }
    /**
     * Indirection for testing. Typically returns `core.isMobileApp()`.
     *
     * @type {boolean}
     * @readonly
     */
    get _isMobile() {
        const is = core.isMobileApp();
        return is;
    }
    /**
     * Create stylesheet 'hotkeyNotification'.
     *
     * @param {import('idbtypes').CssStyleMap} [cssPropsObject={}] - additional props
     */
    _writeNotificationCss(cssPropsObject = {}) {
        const cssPropsObj = { ...tipHiddenCss, ...notificationCss, ...cssPropsObject },
            props = csshelper.createCssPropsString(cssPropsObj),
            propsShow = csshelper.createCssPropsString(tipShowingCss),
            ruleHidden = `${notificationCssSelector} \n{${props}}\n`,
            ruleShow = `${notificationCssSelectorShowing} \n{${propsShow}}\n`;

        csshelper.createStylesheet([ruleHidden, ruleShow], 'hotkeyNotification');
    }
    /**
     * Write css and attrs.
     *
     * @param {import('idbtypes').CssStyleMap} [cssPropsObject = {}]
     */
    _createNotification(cssPropsObject = {}) {
        const opts = this._options,
            { notificationLines } = opts;

        this._writeNotificationCss(cssPropsObject);
        this._$body.attr('data-hotkey', notificationLines);
    }
    /**
     * Create, show and then hide notification.
     *
     * @param {import('idbtypes').CssStyleMap} cssPropsObject
     */
    _setupNotification(cssPropsObject = {}) {
        const opts = this._options,
            { notificationDisplaySeconds } = opts;
        this._createNotification(cssPropsObject);
        this.showNotification();
        this.hideNotification(notificationDisplaySeconds);
    }
    /**
     * Get the callback function from body data.
     *
     * @returns {import('.').KeytipsEventsCallback | null | undefined} callback
     */
    _eventCallbackFromBody() {
        const fn = $('body').data(eventCallbackDataName);
        return fn;
    }
    /**
     * Toggle keytips showing using `._options.showHideKeytipsKeys`.
     *
     * @param {JQuery.KeyDownEvent} keyEvent
     */
    _onDocumentKeyDown(keyEvent) {
        /// #keys Keytips._onDocumentKeyDown keydown $document showHideKeytipsKeys c/ cs?
        const opts = this._options,
            { showHideKeytipsKeys } = opts;
        if (!isRepeating(keyEvent)) {
            if (hasKeys(keyEvent, showHideKeytipsKeys)) {
                if (!isModalDialogShowing()) {
                    core.killEvent(keyEvent);
                    if (this.showingKeytips()) {
                        this._hideAll();
                    } else {
                        this._showAll();
                    }
                }
            }
        }
    }
    /**
     * Any key except modifiers will hide keytips.
     *
     * @param {KeyboardEvent} keyboardEvent
     */
    _onDocumentKeyDownCapture(keyboardEvent) {
        const e = keyboardEvent;
        /// #keys Keytips._onDocumentKeyDownCapture keydown $document any not mods alone
        if (!e.repeat && !isModifierOnly(e)) {
            if (this.showingKeytips()) {
                core.killEvent(e);
                this._hideAll();
            }
        }
    }
    /**
     * Hide all on mouse down.
     *
     * @param {JQuery.MouseDownEvent} mouseEvent - The mousedown event object.
     */
    _onDocumentMouseDown(mouseEvent) {
        this._hideAll();
    }
    /**
     * Capture keydown and mousedown on document for hide all and toggle.
     */
    _setupEvents() {
        $(document)
            .on('mousedown', (e) => this._onDocumentMouseDown(e))
            .on('keydown', (e) => this._onDocumentKeyDown(e));

        document.addEventListener('keydown', (e) => this._onDocumentKeyDownCapture(e), true);
    }
    /**
     * Hide notification and keytips.
     */
    _hideAll() {
        if (this.showingKeytips()) {
            this.hide();
        }
        if (this.showingNotification()) {
            this.hideNotification();
        }
    }
    /**
     * Show Notification and Keytips.
     */
    _showAll() {
        if (!this.showingKeytips()) {
            this.show();
        }
        if (!this.showingNotification()) {
            this.showNotification();
        }
    }
    /**
     * Call callback with message and data.
     *
     * @param {import('.').KeytipsMessageKey} msg
     * @param {object} [data = {}]
     * @returns {Keytips} instance
     */
    _eventCallback(msg, data = {}) {
        const cb = this._options.eventsCallback;
        if (typeof cb === 'function') {
            cb(msg, data, this);
        }
        const cbBody = this._eventCallbackFromBody();
        if (typeof cbBody === 'function') {
            cbBody(msg, data, this);
        }
        return this;
    }
    /**
     * Toogle class on body with message and data.
     *
     * @param {import('.').CssClassName} className
     * @param {import('.').KeytipsMessageKey} msg
     * @param {object} [data]
     * @returns {Keytips} instance
     */
    _toggle(className, msg, data = {}) {
        if (!this._isMobile) {
            this._$body.toggleClass(className);
            this._eventCallback(msg, data);
        }
        return this;
    }
    /**
     * Show on body using classname, message and data.
     *
     * @param {import('.').CssClassName} className
     * @param {import('.').KeytipsMessageKey} msg
     * @param {object} [data]
     * @returns {Keytips} instance
     */
    _show(className, msg, data = {}) {
        if (!this._isMobile) {
            this._$body.addClass(className);
            this._eventCallback(msg, data);
        }
        return this;
    }
    /**
     * Hide on body using classname, message and data.
     *
     * @see {@link keytipsMsgs}
     *
     * @param {import('.').CssClassName} className
     * @param {import('.').KeytipsMessageKey} msg
     * @param {object} data
     * @param {number} [delaySeconds=0]
     * @returns {Keytips} instance
     */
    _hide(className, msg, data, delaySeconds = 0) {
        if (!this._isMobile) {
            setTimeout(() => {
                this._$body.removeClass(className);
                this._eventCallback(msg, data);
            }, delaySeconds * 1000);
        }
        return this;
    }
    /**
     * Main setup. Show notification.
     *
     * @param {import('idbtypes').CssStyleMap} [notificationRulesObject = {}]
     * @returns {Keytips} instance
     */
    setupHook(notificationRulesObject = {}) {
        if (!this._isMobile) {
            this._setupNotification(notificationRulesObject);
            this._setupEvents();
        }
        return this;
    }
    /**
     * @returns {boolean} true if keytips are showing
     */
    showingKeytips() {
        return this._$body.hasClass(cssClasses.hotkey);
    }
    /**
     * @returns {boolean} true if notification is showing
     */
    showingNotification() {
        return this._$body.hasClass(cssClasses.notify);
    }
    /**
     * Show notification.
     *
     * @returns {Keytips} instance
     */
    showNotification() {
        return this._show(cssClasses.notify, 'notificationShown');
    }
    /**
     * Hide notification in optional seconds.
     *
     * @param {number} delaySeconds
     * @returns {Keytips} instance
     */
    hideNotification(delaySeconds = 0) {
        return this._hide(cssClasses.notify, 'notificationHidden', {}, delaySeconds);
    }
    /**
     * Toggle keytips showing.
     *
     * @returns {Keytips} instance
     */
    toggle() {
        return this._toggle(cssClasses.hotkey, 'keytipsToggled');
    }
    /**
     * Show keytips.
     *
     * @returns {Keytips} instance
     */
    show() {
        return this._show(cssClasses.hotkey, 'keytipsShown');
    }
    /**
     * Hide keytips.
     *
     * @param {number} delaySeconds
     * @returns {Keytips} instance
     */
    hide(delaySeconds = 0) {
        return this._hide(cssClasses.hotkey, 'keytipsHidden', {}, delaySeconds);
    }
    /**
     * Create a potentially multiline keytip that accepts escape codes for emoji at beginning of each line.
     * A seperate stylesheet is (destroyed) and dynamically created for each call. `data-` attributes
     * are used on the $target to provide the content of the keytip to be accessed via css `attr()` function
     * in the stylesheet. Stylesheet ids are derived from the `selector` parameter which should be globally unique
     * within the page.
     *
     * @see {@link CreateKeytipDefaultOptions}
     *
     * @param {import('.').BundleHelperWithFormat | null | undefined} bundle
     * @param {import('.').KeytipLinesUnsplit | import('.').KeytipLine | import('.').KeytipLine[]} bundleKeyOrKeytipLines
     * @param {JQuery | HTMLElement} $target
     * @param {import('.').CssSelector} selector
     * @param {import('.').CreateKeytipOptions | null | undefined} options
     * @returns {Keytips} instance
     */
    createKeytip(bundle, bundleKeyOrKeytipLines, $target, selector, options) {
        if (this._isMobile) {
            return this;
        }
        $target = $($target);
        const defaultOpts = createKeytipDefaultOptionsValues,
            opts = { ...defaultOpts, ...options },
            { log, beforeAfter, removeInset, preventElementHighlight, preventTipHighlight } = opts,
            //
            { createCssPropsString: cssStr } = csshelper,
            cssDefaults = defaultCssMap,
            /**
             * @param {string} key
             * @returns {import('idbtypes').CssStyleMap | string}
             */
            optOrDefault = (key) => {
                return options?.[key] ? options[key] : cssDefaults[key];
            },
            //
            tipPositionCss = optOrDefault('tipPositionCss'),
            tipHiddenCss = optOrDefault('tipHiddenCss'),
            tipShowingCss = optOrDefault('tipShowingCss'),
            highlightElementCss = optOrDefault('highlightElementCss'),
            highlightTipCss = optOrDefault('highlightTipCss'),
            //
            bkIsString = typeof bundleKeyOrKeytipLines === 'string';
        let keytipLines = [];
        if (bundle) {
            if (bkIsString) {
                keytipLines = bundle.format(bundleKeyOrKeytipLines).split(BUNDLE_SPLIT);
            }
        } else {
            if (bkIsString) {
                if (bundleKeyOrKeytipLines.indexOf(BUNDLE_SPLIT) !== -1) {
                    keytipLines = bundleKeyOrKeytipLines.split(BUNDLE_SPLIT);
                } else {
                    keytipLines = [bundleKeyOrKeytipLines];
                }
            } else {
                keytipLines = bundleKeyOrKeytipLines;
            }
        }

        const important = true,
            stylesheet = createAttrSeriesStylesheet(
                keytipLines,
                defaultDataAttrSeriesPrefix,
                important,
                selector,
                beforeAfter,
                log
            ),
            //
            asCssString = (cssValues) => {
                return typeof cssValues === 'string' ? cssValues : cssStr(cssValues);
            },
            _positionRules = asCssString(tipPositionCss),
            _hiddenRules = asCssString(tipHiddenCss),
            hiddenRules = _hiddenRules + _positionRules,
            showingRules = asCssString(tipShowingCss),
            highlightElementRules = asCssString(highlightElementCss),
            highlightTipRules = asCssString(highlightTipCss);

        attrSeries($target, defaultDataAttrSeriesPrefix, keytipLines);

        const addTextContent = (text) => {
            // @ts-ignore Property 'textContent' does not exist on type 'CSSStyleSheet'.
            stylesheet.textContent += text;
        };

        addTextContent(`${selector}::${beforeAfter} {\n${hiddenRules}}\n`);
        addTextContent(`.idb-data-hotkey ${selector}::${beforeAfter} {\n${showingRules}}\n`);

        // element highlight
        if (!preventElementHighlight) {
            const useHighlightElementRules =
                $target.is('.idb-searchinput') || removeInset
                    ? highlightElementRules.replace('box-shadow: inset', 'box-shadow:')
                    : highlightElementRules;
            addTextContent(`.idb-data-hotkey ${selector}:hover {\n${useHighlightElementRules}}\n`);
        }

        // tip highlight
        if (!preventTipHighlight) {
            addTextContent(`.idb-data-hotkey ${selector}:hover::${beforeAfter} {\n${highlightTipRules}}\n`);
        }

        $target.addClass(`idb-hotkey-position-${beforeAfter}`);
        return this;
    }
}

/**
 * Creates prefixed, numbered attrs on `$target` using `values` array. Also creates `<prefix>count` attr.
 * Previous attrs using `prefix` are removed.
 *
 * @see {@link attrSeriesRemove}
 * @see {@link createAttrSeriesStylesheet}
 *
 * @param {JQuery} $target - items to add the attrs
 * @param {string} prefix - the prefix for the attr names, typically `data-something_` which will create attrs `data-something_0` etc and `data-something_count`
 * @param {string[]} values - values to assign to each attr
 * @returns {JQuery} $target
 */
function attrSeries($target, prefix, values) {
    attrSeriesRemove($target, prefix);
    values.forEach((v, idx) => {
        // remove special escape codes that occur at beginning of line,
        // `/F0F0F0 text` or `/F0F0F0/F0F0F1 text` leaves `text`
        let value = v;

        if (v.substring(0, 1) === '/') {
            value = v.substring(v.indexOf(' ') + 1);
        }
        $target.attr(prefix + idx, value);
    });
    return $target.attr(prefix + 'count', values.length);
}

/**
 * Removes series of attrs created with `attrSeries`.
 *
 * @see {@link attrSeries}
 *
 * @param {JQuery} $target - items to have multiple attrs removed
 * @param {string} prefix - the prefix for the attr names, typically `data-something_`
 * @returns {JQuery} $target
 */
function attrSeriesRemove($target, prefix) {
    const val = $target.attr(prefix + 'count');
    if (!val) {
        return $target;
    }
    const count = parseInt(val, 10);

    if (isNaN(count) || !count) {
        console.error('attrSeriesRemove: no count');
        return $target;
    }
    for (let i = 0; i < count; i++) {
        $target.removeAttr(prefix + i);
    }

    return $target.removeAttr(prefix + 'count');
}

/**
 * Dynamically create a css rule that allows **multiline** `::before` or `::after` css content that is provided by
 * the elements `data-*` attributes via css function `attr(data-*)`.
 *
 * When given an array a single css rule is created for the items in the array which also handle special escape codes like `/F0F0F0`
 * that may occur at beginning of array texts.
 * This seems the only solution to the problem caused by failure of newlines and codes to render when content is taken from css `attr()` usage.
 *
 * @see {@link attrSeries}
 * @see {@link createAttrSeriesStylesheet}
 * @see {@link PseudoElementPosition}
 *
 * @param {import('.').KeytipLine[]} keytipLines - lines
 * @param {string} attrPrefix - the attr name prefix, typically `data-something_`
 * @param {boolean} [important=true] - whether to make rules important, default `true`
 * @param {import('.').CssSelector} [selector="body "] - the selector to appear right before the `*[<prefix>count="<size>""]...` portion of each rules selector, maybe use trailing space
 * @param {import('.').PseudoElementPosition} [beforeAfter="before"] - the trailing part of the rule selector
 * @param {boolean} [log=false] - whether to log the rules
 * @returns {string} Css Properties
 */
function createAttrSeriesCssRule(
    keytipLines,
    attrPrefix,
    important = true,
    selector = 'body ',
    beforeAfter = 'before',
    log = false
) {
    const cssContentLineSegments = [];

    const size = keytipLines.length;
    for (let i = 0; i < size; i++) {
        const v = keytipLines[i];
        let iconCodes = '';

        // handle special escape codes at start of line
        if (v.substring(0, 1) === '/') {
            iconCodes = v.split(' ')[0].replace(/\//g, '\\');
            iconCodes = "'" + iconCodes + "' "; // note space
        }

        cssContentLineSegments.push(`${iconCodes}attr(${attrPrefix}${i})`);
    }

    // inject newlines to cssRule
    const ruleContent = cssContentLineSegments.join(" '\\0000a' "),
        star = selector.slice(-1) === ' ' ? '*' : '',
        importantStr = important ? ' !important' : '',
        cssRule = `${selector}${star}[${attrPrefix}count='${size}']::${beforeAfter} { content: ${ruleContent}${importantStr}; }`;

    if (log) {
        console.log('cssRule', cssRule);
    }
    return cssRule;
}

/**
 * Translate a selector into a valid string for an element Id.
 * Id is prefixed by 'hotkey_'.
 *
 * @see {@link createAttrSeriesStylesheet}
 *
 * @param {import('.').CssSelector} selector
 * @returns {string} the derived Id
 */
function selectorToCamelCaseId(selector) {
    // Remove characters that are not valid in an HTML id
    const cleanId = selector.replace(/[^a-zA-Z0-9-. _]/g, ''),
        // Replace spaces with underscores
        underscoredId = cleanId.replace(/ +/g, '_'),
        // Add prefix
        camelCaseId = 'hotkey_' + underscoredId;

    return camelCaseId;
}

/**
 * Dynamically create a new stylesheet and append to head - with rules that allow **multiline** `::before` or `::after` css content that is provided by
 * the elements `data-*` attributes via css function `attr(data-*)`.
 * Using this function once with the default selector of `'body '` (sic space) is enough to create rules for the number of lines specified by when `numberOrArray` is a number.
 *
 * When given an array a single css rule is created for the items in the array which also handle special escape codes like `/F0F0F0`
 * that may occur at beginning of array texts.
 * This seems the only solution to the problem caused by failure of newlines and codes to render when content is taken from css `attr()` usage.
 *
 * @example
 * // usage: create selector specific using array
 * createAttrSeriesCssRules(['one', 'two'], dataAttrSeriesPrefix, true, '.some-selector', 'before');
 * // produces css rules:
 * .some_selector[data-hotkey_count='2']::before { content: attr(data-hotkey_0) '\0000a' attr(data-hotkey_1) !important; }
 *
 * // usage: create selector specific using array with special escape codes at beginning
 * createAttrSeriesCssRules(['/F0F0F0 one', '/F0F0F0/F0F0F0 two'], dataAttrSeriesPrefix, true, '.some-selector', 'before');
 * // produces css rules:
 * .some_selector[data-hotkey_count='2']::before { content: '\F0F0F0' attr(data-hotkey_0) '\0000a' '\F0F0F0\F0F0F0' attr(data-hotkey_1) !important; }
 *
 * @see {@link attrSeries}
 * @see {@link createAttrSeriesCssRule}
 *
 * @param {import('.').KeytipLine[]} keytipLines - the lines to use to create the rules
 * @param {string} prefix - the attr name prefix, typically `data-something_`
 * @param {boolean} [important=true] - whether to make rules important, default `true`
 * @param {import('.').CssSelector} [selector="body "] - the selector to appear right before the `*[<prefix>count="<size>""]...` portion of each rules selector, maybe use trailing space
 * @param {import('.').PseudoElementPosition} [beforeAfter="before"] - the trailing part of the rule selector
 * @param {boolean} [log=false] - whether to log the rules
 * @returns {CSSStyleSheet} CSSStyleSheet the stylesheet that was created
 */
function createAttrSeriesStylesheet(
    keytipLines,
    prefix,
    important = true,
    selector = 'body ',
    beforeAfter = 'before',
    log = false
) {
    const rule = createAttrSeriesCssRule(keytipLines, prefix, important, selector, beforeAfter, log),
        id = selectorToCamelCaseId(selector);
    $('style#' + id).remove();
    return csshelper.createStylesheet(rule, id);
}
