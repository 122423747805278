/* global jQuery, $, Set */

        var SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition,
            SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;

export class VoiceRecognizer {
    constructor(lang /*: string */) {
        this._lang = lang;
        this._terms = new Set();
        this._commands = [];
        /** @type {SpeechRecognition | null} */
        this._recog = null;
        this._listening = false;
        this._onInterimResult = null;
        this._interimResult = '';
    }
    addTerm(term /*: string */) {
        this._terms.add(term);
    }
    addTerms(terms /*: string[] */) {
        var termSet = this._terms;

        for (var i = 0; i < terms.length; i++) {
            termSet.add(terms[i]);
        }
    }
    _buildGrammar() {
        var grammar = new SpeechGrammarList();
        var terms = Array.from(this._terms) // Technically doesn't work in IE11, but neither does the rest of this :)
            .map(function (item) {
                return item.trim();
            })
            .filter(function (item) {
                return item.length > 0;
            })
            .map(function (item) {
                // Escape
                return item.replace(/[/\\"(){}[\]<>|*+]/g, '\\$&');
            });
        var commands = '#JSGF v1.0; public <term> = (' + terms.join(' | ') + ');\n';

        console.log(commands);

        grammar.addFromString(commands, 1);

        return grammar;
    }

    onInterimResult(funk) {
        this._onInterimResult = funk;
    }

    start() {
        var recog = this._recog;

        if (this._listening) {
            return Promise.reject({
                error: 'aborted'
            });
        }

        this._interimResult = '';

        // When permission is given it is given to a specific instance
        // of SpeechRecognition. Creating a new instance will require
        // the user to reapprove the use of the microphone. 
        if (!recog) {
            recog = new SpeechRecognition();
            this._recog = recog;
        }

        recog.lang = this._lang;
        recog.interimResults = true;
        recog.maxAlternatives = 1;

        if (this._terms.size) {
            recog.grammars = this._buildGrammar();
        }

        recog.onspeechend = this._onSpeechEnd.bind(this);

        return new Promise((resolve, reject) => {
            recog.onresult = (e) => {
                let transcript = '';

                for (const result of e.results) {
                    transcript += result[0].transcript;
                }

                // console.log(results.isFinal, result.transcript);

                if (e.results[0].isFinal) {
                    this._listening = false;
                    resolve(transcript);
                } else if (this._onInterimResult) {
                    console.log(e);
                    // this._interimResult += ' ' + e.results[0][0].transcript;

                    this._onInterimResult(transcript);
                }

                // console.log(e);
            };

            recog.onnomatch = function (e) {
                this._listening = false;
                resolve('');

                // console.log(e);
            };

            recog.onerror = function (e) {
                this._listening = false;
                reject(e);
            };

            recog.onend = (e) => {
                this._listening = false;
                // if (promise.state() === 'pending') {
                resolve('');
                console.log(e);
                // }
            };

            this._listening = true;
            recog.start();
        });
    }
    abort() {
        this._recog.abort();
    }
    _onSpeechEnd(e) {
        this._recog.stop();
    }
    static isSupported() {
        return typeof SpeechRecognition !== 'undefined';
    }
}



        export default {
            VoiceRecognizer: VoiceRecognizer
        };

    

