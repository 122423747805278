import $ from 'jquery';

import adminapp from '../admin/adminapp.js';
import config from '../config.js';
import dateutil from '../dateutil.js';
import core from '../idbcore.js';
import popupmgr from '../popup/popupmgr.js';
import bundle from '../lang/Ab.js';
import { hasKeys, isRepeating } from '../utils/keyboard.js';
import { getBodyKeytips, createKeytipDefaultOptionsCopy } from '../utils/keytips.js';
import { ChatPanel } from '../chat/chat.js';

const botIcon = config.config.brand === "TruOI" ? 'oi_blue.svg' : 'logo_icon_yellow.svg';

export const QUESTION_LIMIT = 10;

export const defaultOptions = {
    classes: "idb-ai-help-button-br idb-ai-help-button-sm",
    limitUsage: true,
    showUsageLimit: true,
    showPill: true,
    draggable: true,
    preventHotkey: false,
    preventEscapeClose: false
};

export class AIHelpButton {
    constructor($parent, options) {
        const me = this;
        me._$parent = $parent || $('body');
        me._options = $.extend(defaultOptions, options);
        me._classes = me._options.classes;
        me._build();
    }

    _build() {
        const me = this, options = me._options;
        this._keytips = getBodyKeytips();
        if (!this._keytips) {
            console.warn('!this._keytips in aihelp.js');
        }

        me._$button = $('<div>')
                           .addClass('idb-ai-help-button')
                           .addClass(me._classes)
                           .on('click', me._openDialog.bind(me))
                           .appendTo(me._$parent);


        if(options.showPill) {
            me._$pill = $('<div>').addClass('idb-ai-help-button-count')
                                  .appendTo(me._$button);
        }

        if(options.limitUsage) {
            me._updateUsageState();
        }

        if(options.draggable) {
            me._$button.attr({draggable:true});
            me._dragEndListener = me._onDragEnd.bind(me);
            me._dragOverListener = me._onDragOver.bind(me);

            me._$button.on('dragstart', me._onDragStart.bind(me));
        }

        if(options.limitUsage) {
            document.body.addEventListener("questionAnswered", me._questionCallback.bind(me));
        }

        if(!options.preventHotkey) {
            const buttonKeytipOptions = this._keytipOptions({ marginLeft: '-26px', marginTop: '30px' });

            buttonKeytipOptions.beforeAfter = 'after';
            this._keytips?.createKeytip(bundle, 'hotkey.ai_help', me._$button, '.idb-ai-help-button', buttonKeytipOptions);

            $(document).on('keydown', (e) => {
                /// #keys AIHelpButton._build $document keydown F1
                if (!isRepeating(e)) {
                    if (hasKeys(e, 'F1')) {
                        const handled =launchOrFocusDialog({limitUsage: true});
                        if (handled) {
                            core.killEvent(e);
                        }
                    }
                }
            });
        }
    }

    _keytipOptions(tipPositionCss = {}) {
        const def = createKeytipDefaultOptionsCopy();
        return {...def, tipPositionCss};
    }

    _onDragStart(e) {
        const me = this, $button = me._$button;

        $button.css({margin:'0px'});

        const rect = $button[0].getBoundingClientRect();

        $button.css({position:'fixed', cursor:'move',
                                top:rect.top + 'px', left:rect.left + 'px', right:'unset', bottom:'unset'});
        $button.appendTo($('body'));
        $(window).on('dragover', me._dragOverListener);
        $(window).on('dragend', me._dragEndListener);
    }

    _onDragOver(evt) {
        evt.preventDefault();
        evt.originalEvent.dataTransfer.dropEffect = "move";
    }

    _onDragEnd(evt) {
        const me = this, pageLoc = core.toBodyXY(evt);
        const button = me._$button[0];
        const body = document.body;
        const bodyHeight = body.offsetHeight, bodyWidth = body.offsetWidth;
        const buttonTop = Math.min(Math.max(0, pageLoc.pageY), bodyHeight-button.offsetHeight);
        const buttonLeft = Math.min(Math.max(0, pageLoc.pageX), bodyWidth-button.offsetWidth);

        me._$button.css({top:buttonTop + "px", left:buttonLeft + "px"});

        me._$button.css({cursor:'pointer'});
        $(window).off('dragend', me._dragEndListener);
        $(window).off('dragover', me._dragOverListener);
    }

    _questionCallback() {
        const me = this;
        me._updateUsageState();
    }

    _openDialog() {
        const { preventEscapeClose } = this._options;

        const dialog = new AIHelpDialog({ preventEscapeClose });
        dialog.show();
    }

    _updateUsageState() {
        const me = this, options = me._options;

        if(options.limitUsage) {
            const count = UsageMonitor.getCountForToday();
            if(count >= QUESTION_LIMIT) {
                me._$button.addClass('disabled');
            }

            if(options.showUsageLimit) {
                me._updateUsageIndicator(count);
            }
        }
    }

    _updateUsageIndicator(count) {
        const me = this;
        const remaining = QUESTION_LIMIT - count;
        const message = UsageMonitor.getUsageMessage(remaining);

        me._$button.attr({title:message});

        if(me._$pill) {
            me._$pill.text(remaining);
        }
    }
}

export const AIHelpDialogDefaultOptions = {
    showMessage:false,
    limitUsage:true,
    questionCallback: $.noop,
    closeCallback: $.noop,
    preventEscapeClose: false,
    preventRefocusOnClose: false,
};
export class AIHelpDialog {
    constructor(options) {
        this._options = $.extend(AIHelpDialogDefaultOptions, options);
    }

    _maybeBuild() {
        const me = this;
        const { preventEscapeClose } = this._options;
        const aiHelpModal = document.getElementById("ai-help-modal");
        let didBuild = false;

        if(!aiHelpModal) {
            didBuild = true;
            me._$aiHelpModal = $("<div id='ai-help-modal' class='idb-ai-help-modal'>")
                .appendTo(document.body);

            this._$aiHelpModalContent = $("<div class='idb-ai-help-modal-content'>")
                                       .appendTo(me._$aiHelpModal);

    /*        $("<span class='idb-ai-help-close'>&times;</span>")
                .appendTo($aiHelpModalContent)
                .on('click', me.close.bind(me));
    */

            $("<div id='mendable-component'>").appendTo(this._$aiHelpModalContent);

            me._addContent();

            $(window).on('click', evt => {
                                  if(me._$aiHelpModal[0] === evt.target) {
                                      me.close();
                                  }
                                  });
            if(!preventEscapeClose) {
                $(document).on('keydown', '.idb-ai-help-modal', (e) => {
                    /// #keys AIHelpDialog._maybeBuild document keydown idb-ai-help-modal  Escape
                    if(!isRepeating(e)  /* && J3363 isForegroundElementXXXX(me._$aiHelpModal) */) {
                        const $foc = $(':focus'),
                        $closest = $foc.is('.idb-ai-help-modal') ? $foc : $foc.closest('.idb-ai-help-modal');

                        if($closest.length && hasKeys(e, 'Escape')) {
                            if(this._$aiHelpModal[0] === e.currentTarget) {
                                core.killEvent(e);
                                this.close();
                            }
                        }
                    }
                });
            }
        }
        else {
            me._$aiHelpModal = $(aiHelpModal);
        }
        return didBuild;
    }

    _questionCallback(question, answer) {
        const me = this;
        UsageMonitor.updateCountForToday();
        me._options.questionCallback(question, answer);
        if(me._options.limitUsage) {
            me._toggleInput();
        }
        me._triggerQuestionAnswered(question, answer);
    }

    _addContent() {
        const me = this, imgDir = config.config.verRoot + '/images/';

        let profileImage = config.config.user.profileImage;

        if(profileImage) {
            profileImage = config.config.contextRoot + "profile/" + profileImage;
        }
        else {
            profileImage = imgDir + "users.svg";
        }

        // const chat = new ChatPanel(this._$aiHelpModalContent, {
        //     placeholder: bundle.format("aihelp.dialog.hint"),
        //     theirsImage: imgDir + botIcon,
        //     mineImage: profileImage,
        //     disclaimer(disclaimerEl) {
        //         const $disclaimer = $(disclaimerEl);

        //         $('<span>')
        //             .text(bundle.format("aihelp.dialog.disclaimer"))
        //             .appendTo($disclaimer);

        //         $('<a>')
        //             .text(bundle.format("aihelp.link.label"))
        //             .prop('href', "https://truoi.com/agreement-software/")
        //             .appendTo($disclaimer);
        //     },
        //     onNewMessage(question) {
        //         // STUB
        //         setTimeout(() => {
        //             const answer = 'Hmm... interesting. Perhaps another time.';

        //             me._questionCallback(question, answer);

        //             chat.addMessage(answer, 'theirs', {
        //                 verifiedSources: [
        //                     {
        //                         "content": "Google",
        //                         "link": "http://google.com"
        //                     }, {
        //                         "content": "Example",
        //                         "link": "http://example.com"
        //                     }, {
        //                         "content": "Local Host",
        //                         "link": "http://localhost"
        //                     }
        //                 ]
        //             });
        //         }, 1000);
        //     },
        //     onMessage(side, messageEl, metadata) {
        //         if (side === 'theirs') {
        //             const $message = $(messageEl);

        //             const $aiHelpExtras = $('<div class="idb-chat-message-extra">')
        //                 .appendTo($message);

        //             if (metadata?.verifiedSources) {
        //                 const verifiedSources = metadata.verifiedSources;

        //                 $("<div>")
        //                     .text('Was this response helpful? [TR]')
        //                     .appendTo($aiHelpExtras);

        //                 $('<button>')
        //                     .text(bundle.format('button.yes'))
        //                     .appendTo($aiHelpExtras);

        //                 $('<button>')
        //                     .text(bundle.format('button.no'))
        //                     .appendTo($aiHelpExtras);

        //                 $("<div>")
        //                     .text('Verified Sources: [TR]')
        //                     .appendTo($aiHelpExtras);

        //                 $('<ol>')
        //                     .appendTo($aiHelpExtras)
        //                     .append(verifiedSources.map(source => {
        //                         const $link = $('<a>')
        //                             .text(source.content)
        //                             .prop('href', source.link);

        //                         return $('<li>')
        //                             .append($link);
        //                     }));
        //             }
        //         }

        //     }
        // });

        // chat.addMessage(bundle.format("aihelp.dialog.welcome", config.config.brand), 'theirs');


        Promise.all([getApiKey(), import('../libs/mendable.js')])
               .then(([apiKey, {mendableInPlace}]) => {
                    mendableInPlace(this._$aiHelpModal[0].querySelector('#mendable-component'), {
                      anon_key:apiKey,
                      welcomeMessage:bundle.format("aihelp.dialog.welcome", config.config.brand),
                      style:{darkMode:false,
                             accentColor:'#0d0176',
                            },
                      botIcon:imgDir + botIcon,
                      userIcon:profileImage,
                      hintText:bundle.format("aihelp.dialog.hint"),
                      messageSettings:{openSourcesInNewTab:true},
                      onMessageForTracking:me._questionCallback.bind(me),
                      privacyDisclaimer:bundle.format("aihelp.dialog.disclaimer"),
                      footer:{bottomRightLink:{label:bundle.format("aihelp.link.label"),
                              link:"https://truoi.com/agreement-software/"}}
                    });
                   })
                   .catch(error => {
                                   me._$aiHelpModal.remove();
                                   popupmgr.alert(error.message);
                                  });
    }

    show() {
        const me = this,
           { preventEscapeClose } = this._options,
           count = UsageMonitor.getCountForToday(),
           remaining = QUESTION_LIMIT - count;

        this._$hadFocusBeforeShow = $(':focus');

        if(me._options.limitUsage) {
            if(me._options.showMessage) {
                if(remaining === 0) {
                    popupmgr.alert(bundle.format('aihelp.usage.limit_reached', QUESTION_LIMIT));
                    return;
                }
                else if (remaining === 1) {
                    popupmgr.autoclose(bundle.format('aihelp.usage.remaining_one'));
                }
                else if (remaining === 2) {
                    popupmgr.autoclose(bundle.format('aihelp.usage.remaining', remaining));
                }
            }
        }

        const didBuild = me._maybeBuild();

        setTimeout(() => me._$aiHelpModal.find('#userInput').trigger('focus'), 250);
        me._$aiHelpModal.show();

        if(me._options.limitUsage) {
            setTimeout(() => me._toggleInput(), 250);
        }
    }

    _toggleInput() {
        const me = this;
        const count = UsageMonitor.getCountForToday();
        const remaining = QUESTION_LIMIT - count;
        const disabled = (remaining <= 0);
        const $userInput = me._$aiHelpModal.find('#userInput');

        let placeholder;

        switch(remaining) {
            case 0:
                placeholder = bundle.format('aihelp.usage.limit_reached', QUESTION_LIMIT);
                break;
            case 1:
                placeholder = bundle.format('aihelp.dialog.hint1');
                break;
            default:
                placeholder = bundle.format('aihelp.dialog.hint2', remaining);
                break;
        }

        $userInput.attr({placeholder, disabled});
    }

    _triggerQuestionAnswered(question, answer) {
        const me = this;
        const evt = new CustomEvent("questionAnswered", {bubbles:true, detail:{question, answer}});
        me._$aiHelpModal[0].dispatchEvent(evt);
    }

    close() {
        const me = this;

        me._$aiHelpModal.hide();
        if (!this._options.preventRefocusOnClose) {
            this._$hadFocusBeforeShow?.trigger('focus');
        }
        if(me._options.closeCallback) {
            me._options.closeCallback();
        }
    }
}

export function launchOrFocusDialog(options) {
    // return true if handled
    const $aiButton = $('.idb-ai-help-button'),
        $aiModal = $('#ai-help-modal:visible');

    if(!$aiModal.length) {
        if($aiButton.length) {
            $aiButton.trigger('focus').trigger('click');
            return true;
        } else {
            const dialog = new AIHelpDialog(options);
            dialog.show();
            return true;
        }
    // J3364 } else if(!isForegroundElementXXXX($aiModal)) {
    //     return false;
    } else {
        $('#userInput', $aiModal).trigger('focus');
        return true;
    }
}

let ANON_KEY = null;

function getApiKey() {
    if(ANON_KEY) {
      return Promise.resolve(ANON_KEY);
    }
    else {
        return new Promise((resolve, reject) => {
                               let req = {apiKeyName:'ANON_KEY', apiName:'mendable'};
                               adminapp.jsonCmdBusy('help/getApiKey', req, resolve, reject, reject);
                            })
                            .then(response => {
                                                  if(response.confirmation === 'ok') {
                                                      ANON_KEY = response.apiKey;
                                                      return ANON_KEY;
                                                  }
                                                  else throw new Error(response.message);
                                              });
    }
}

export class UsageMonitor {
    static getCountForToday() {
        const userId = config.config.user.userId;
        const today = dateutil.format(new Date(), 'yyyy-MM-dd');
        const userStr = `ql_${userId}`;

        const dateCountStr = localStorage.getItem(userStr);
        if(dateCountStr) {
            const countStr = dateCountStr.substring(0,4);
            const timeStr = dateCountStr.substring(4);
            const newDate = new Date();
            newDate.setTime(parseInt(timeStr));
            const dateStr = dateutil.format(newDate, 'yyyy-MM-dd');
            if(dateStr === today) {
                return parseInt(countStr,3);
            }
            else {
                localStorage.removeItem(userStr);
                return 0;
            }
        }

        return 0;
    }

    static updateCountForToday() {
        const userId = config.config.user.userId;
        const today = new Date();
        const userStr = `ql_${userId}`;

        let count = UsageMonitor.getCountForToday();
        localStorage.setItem(userStr, `${(++count).toString(3).padStart(4,"0")}${today.getTime()}`);

        return count;
    }

    static hasRemainingQuestions() {
        const count = UsageMonitor.getCountForToday();
        return QUESTION_LIMIT - count > 0;
    }

    static getUsageMessage(remaining) {
        remaining = remaining ?? (QUESTION_LIMIT - UsageMonitor.getCountForToday());

        if(remaining === 0) {
            return bundle.format('aihelp.usage.limit_reached', QUESTION_LIMIT);
        }
        else if (remaining === 1) {
            return bundle.format('aihelp.usage.remaining_one');
        }
        else {
            return bundle.format('aihelp.usage.remaining', remaining);
        }
    }
}

export function maybeAddFloatingAIHelpButton($parent) {
       if(config.config.aiHelpEnabled) {
           $parent = $parent || $('body');

           return new AIHelpButton($parent, {classes:'idb-ai-help-button-sm idb-ai-help-button-tr'});
       }
       else {
           return null;
       }
}

export default {AIHelpButton, AIHelpDialog, maybeAddFloatingAIHelpButton, UsageMonitor, launchOrFocusDialog};
