/*globals $ */
/*jshint esversion: 11*/

/*::
export type ValidationStatus = 'valid' | 'invalid' | 'pending';

// interface version of validation result. temporary until result extensions
// can be resolved properly
export interface IValidationResult {
   status: ValidationStatus;
   message?: string;
};

export type ValidationResult = {
    status: ValidationStatus,
    message?: string,
    [name: string]: any,
};

type Validator = (value: any) => ValidationResult;

type jqSuccessCallback = (data: any, textStatus?: string, jqXHR?: JQueryXHR) => any;
type jqFailureCallback = (jqXHR: JQueryXHR, textStatus?: string, errorThrown?: string) => any;
type messageCloseCallback = () => any;

export type ValidateForm2Options = {};

export interface IDBURLSearchParams extends URLSearchParams {
    deleteMatching(re: RegExp): void;
}

// TODO: Same as popover options
export type ValidationIndicatorOptions = {
    placement: string,
    container: boolean | JQuery,
    alwaysShow: boolean,
};

export interface AdminAppModule {
    areNamesUnique(items: { [field: string]: any }[], nameField: string): boolean;
    clearForm($form: JQuery): void;
    clearForm(id: string): void;
    closeDialogDropdown($dropdown: JQuery): void;
    closeInvalidInputPopovers($div: JQuery): void;
    containsMacro(text: ?(string | string[])): boolean;
    debounce(funk: (...args: any) => mixed, wait: number, context: any | void ): () => void;
    dialogDropdown($dropdown: JQuery): void;
    formInputToJSON(formId: string): string;
    formInputToJSONObject(formId: string): any;
    getFormInput($form: JQuery, convertBooleans?: boolean): {[id: string]: any};
    isBusy(): boolean;
    getMoreBusy(): void;
    getLessBusy(): void;

    htmlCmd(url: string): JQueryPromise<Document>;
    jsonCmd(url: string, inputJson: any, onSuccess: jqSuccessCallback, onFailure?: jqFailureCallback): void;
    jsonCmdBusy(cmd: string, inputJson: any, onSuccess: jqSuccessCallback, onFailure?: jqFailureCallback): void;
    jsonCmdHandled(cmd: string, inputJson: any, onSucces: jqSuccessCallback, onFailure: jqFailureCallback, onError:? messageCloseCallback): void;
    jsonCmdHandledBusy(cmd: string, inputJson: any, onSucces: jqSuccessCallback, onFailure?: jqFailureCallback, onError:? messageCloseCallback): void;
    jqAjaxError: jqFailureCallback;
    jqAjaxErrorBusy: jqFailureCallback;
    LOCK_CHARACTER: string;
    makeCustomSkin(color: any): $Shape<Skin>;
    maybeHandleTimeout(outputJson: IDBResponsePacket): boolean;
    maybeResetKeepAlive(request: any): void;
    jsonErrorHandled(outputJson: IDBResponsePacket, messageCloseCallback:? messageCloseCallback): boolean;

    validResults(results: any, name:? string): ValidationResult;
    invalidResults(msg: string, props?: any): ValidationResult;
    removeInvalidIndicator($item: JQuery): void;
    resultsInvalid(results: ValidationResult): boolean;
    resultsValid(results: ValidationResult): boolean;
    resultsPending(results: ValidationResult): boolean;

    getFormInput($form: JQuery, convertBooleans: boolean): {[id: string]: any};
    populateForm($form: JQuery, values: {[id: string]: any}): void;
    textEllipsisAndTooltip($item: JQuery): void;
    generateFormItemsFromProperties2(properties: FormInputProperty[]): string;
    isEmptyMacro(value: string): boolean;
    isMacro(value: string): boolean;
    isMissingValue(value: ?any): boolean;
    isNullMacro(value: string): boolean;
    isTrue(value:any): boolean;
    valueIsEmptyOrNullMacros(value: any): boolean;
    valueIsOfType(dataType: IDBDataTypes, value: any, allowMacros: boolean): boolean;
    valueIsMissing(value: any): boolean;
    validateForm(
        $form: JQuery,
        validators?: ?{[id: string]: Validator},
        aOptions?: $Shape<ValidationIndicatorOptions>
    ): boolean;
    validateForm2(
        $div: JQuery,
        validators?: ?{[id: string]: Validator},
        options?: ValidateForm2Options
    ): boolean;
    validateParamValue(param: any, multitext: any, value: any): ValidationResult;
    validateParamValueIsOfType(dataType: IDBDataTypes, value: any): ValidationResult;

    hasMinimumPrivilege(priv: IDBUserRole, minPriv: IDBUserRole): boolean;
    setEfficientInterval(callback: () => void, interval: number): void;
    setValidationIndicator(
        $item: JQuery,
        validationResults: ValidationResult,
        aOptions: $Shape<ValidationIndicatorOptions>
    ): void;
    updateValidationTooltips($form: JQuery): void;
    URLSearchParams: Class<IDBURLSearchParams>;
}
*/

/** 
 * @module admin/adminapp 
 */

import core from "../idbcore.js";
import config from "../config.js";
import dateutil from "../dateutil.js";
import popupmgr from "../popup/popupmgr.js";
import bundle from "../lang/A.js";
import colorhelper from "../utils/colorhelper.js";

    var busyLevel = 0, contextRoot = config.config.contextRoot, format = bundle.format,

    macroRegExp = /\${.+}/;

    let stayBusyOnUnload = false;

    window.addEventListener("beforeunload", function(){
        console.log("beforeunload");
        if(stayBusyOnUnload) {
            return;
        }
        while(busyLevel > 0) {
            adminapp.getLessBusy();
        }
    });

    /**
     *  This object is intended to gather all macro related functionality in one place. The getMacroSamples method
     *  and buildMacro are just stubs that need to be implemented. They currently are not used anywhere.
     *
     */
    var MacroMgr = {
        /**
         * Note: The url should be the endpoint RELATIVE TO THE CONTEXT
         * ROOT, that does the macro validation. It MUST NOT begin with
         * a slash.
         */
        validateMacros:function(url, macros, strict, validationCallback) {
            var request = {macros:macros, strict:strict};
            adminapp.jsonCmdHandledBusy(url, request,
                                             function(data, status, jqXHR) {
                                                 if(validationCallback !== null) {
                                                     validationCallback(data.results, data.invalid);
                                                 }
                                             },
                                             adminapp.jqAjaxError);
        },

        getMacroSamples:function(options) {
        },

        buildMacro:function(name, extra) {
        },

        containsMacros:function(value) {
            if(!value) return false;
            var regex = /\${.+}/;
            if(!Array.isArray(value)) {
                value = [value];
            }
            for(var i = 0; i < value.length; i++) {
                var str = value[i];
                if(regex.test(str)) return true;
            }
            return false;
        }
    }; // end adminapp.MacroMgr


    /**
     * This replaces a ponyfill for URLSearchParams.
     */
    class URLSearchParamsUtils {
        /**
         * Get the matching parameters.
         *
         * @param  {URLSearchParams} params The params to modify.
         * @param  {RegExp} regExp The regular expression to match on.
         *
         * @return {object}        An object where the key is the
         *                         matched parameter.
         */
        static getMatching(params, regExp) {
            var output = {};

            for (const param of params.entries()) {
                if (regExp.test(param[0])) {
                    var values = output[param[0]];
                    if(!values) {
                        values = output[param[0]] = [];
                    }
                    values.push(param[1]);
                }
            }
            return output;
        }

        /**
         * Delete the matching parameters.
         *
         * @param  {URLSearchParams} params The params to modify.
         * @param  {RegExp} regExp The regular expression to match on.
         */
        static deleteMatching(params, regExp) {
            Array.from(params.keys())
                .filter((key) => {
                    return regExp.test(key);
                })
                .forEach((key) => {
                    params.delete(key);
                });
        }
    }

    // $FlowFixMe
    const adminapp = {
        bundle,
        LOCK_CHARACTER:"\uD83D\uDD12",
        LOCK_SUFFIX:" \uD83D\uDD12",
        MacroMgr: MacroMgr,

        // Formerly a ponyfill, use URLSearchParams directly.
        URLSearchParams: URLSearchParams,
        URLSearchParamsUtils,

        ErrorCodes:{
                      OBJECT_REMOVED:1220,
                      ASSOCIATED_OBJECT_REMOVED:1225
                     },

        isBusy: function () /*: boolean */ {
            return busyLevel > 0;
        },

        getMoreBusy:function() {
            busyLevel++;
            if($("#idb-app-shield").length === 0) {
                $("<div id=\"idb-app-shield\" class=\"idb-app-shield\">").appendTo($("body"));
            }
            if(busyLevel > 0) {
                $("#idb-app-shield").addClass("idb-app-shield-on");
            }
        },
        getLessBusy:function() {
            busyLevel--;
            if(busyLevel < 1) {
                $("#idb-app-shield").removeClass("idb-app-shield-on");
                if(busyLevel < 0) {
                    console.log("ERROR, busyLevel has gone below zero. ******************");
                    busyLevel = 0;
                }
            }
        },
        getUnBusy:function() {
            while(busyLevel > 0) {
                adminapp.getLessBusy();
            }
        },

        /**
         * If this is called then the busy spinner will continue 
         * to appear after the window's beforeunload even has fired. Once 
         * this is called, it cannot be reversed. 
         * 
         */
        stayBusyOnUnload() {
            stayBusyOnUnload = true;
        },
        /**
         * Wait for a "quiet period" of at least `wait` milliseconds before
         * calling `funk`.
         * 
         * @param  {function} funk A function to call
         * @param  {number} wait   The number of milliseconds to wait.
         * @param  {any} [context] Bind `funk` to this context prior to calling.
         * @return {function}      A new, debounced, version of `funk`.
         */
        debounce: function(funk, wait, context) {
            var timeout = null;

            context = context || window;

            return function () {
                if (timeout) {
                    clearTimeout(timeout);
                }

                var args = Array.prototype.slice.call(arguments);

                timeout = setTimeout(function () {
                    timeout = null;

                    funk.apply(context, args);
                }, wait);
            };
        },

        /**
         * Call funk, but the wait for at least `wait` milliseconds before
         * calling it again.
         *
         * @param  {function} funk A function to call
         * @param  {number} wait   The number of milliseconds to wait.
         * @param  {any} [context] Bind `funk` to this context prior to calling.
         * @return {function}      A new, throttled, version of `funk`.
         */
        throttle: function(funk, wait, context) {
            var timeout = null;

            context = context || window;

            return function () {
                if (!timeout) {
                    var args = Array.prototype.slice.call(arguments);
                    funk.apply(context, args);
                } else {
                    clearTimeout(timeout);
                }

                timeout = setTimeout(function () {
                    timeout = null;

                    funk.apply(context, args);
                }, wait);
            };
        },

        htmlCmd: function (url) {
            return $.ajax({
                url: contextRoot + url,
                dataType:"html",
                type: 'GET'
            })
            .then(function (htmlText) {
                var parser = new DOMParser();

                return parser.parseFromString(htmlText, 'text/html');
            });
        },
        /**
         * The URL parameter is relative to the context root of the 
         * application, and should not begin with a forward slash. The 
         * URL that is invoked will be the context root followed by a 
         * trailing slash followed by the url. 
         */
        jsonCmd:function(url, inputJson, onSuccess, onFailure) { 
        
            if(inputJson) {
                $.ajax({
                     url:contextRoot + url,
                     dataType:"json",
                     type:"POST",
                     contentType:"text/json; charset=UTF-8",
                     data:JSON.stringify(inputJson),
                     success:onSuccess,
                     error:onFailure
                     });
            }
            else {
                $.ajax({
                     url:contextRoot + url,
                     dataType:"json",
                     type:"GET",
                     success:onSuccess,
                     error:onFailure
                     });
            }
        },

        /**
         * Cut+Pasted from idbdata.js::jsonCmdHandledBusy(...) function. 
         * Then I_D_B_D_A_T_A swapped out for adminapp. ON: DATE[ 2018_10_08 ]
         *
         * @param {string} cmd    The URL without the context path.
         * @param {any} inputJson An object that will be sent with the request
         *                        as JSON. If this is falsy the request will be
         *                        a GET request, otherwise it will be a POST
         *                        request.
         * @param {(json: any, textStatus: string, xhr: JQuery.jqXHR) => void} onSuccess
         *                              A callback that is called when the
         *                              request is successful.
         * @param {()=> void} [onFailure] A callback that is called when the
         *                                request fails because of a network error
         *                                or a HTTP status code that would
         *                                indicate a failure.
         * @param {()=> void} [onError] A callback that is called when the
         *                              request fails because of a backend error
         *                              as indicated by the 'confirmation'
         *                              property of the response.
         */
        jsonCmdHandledBusy : function (cmd, inputJson, onSuccess, onFailure, onError) {
            adminapp.jsonCmdBusy(cmd, inputJson, function (json, textStatus, jqXHR) {
                // The page will redirect if this is called and the session is timed
                // out. No other handling is possible.
                if(adminapp.maybeHandleTimeout(json)) {
                    return;
                }

                if (!adminapp.jsonErrorHandled(json, onError)) {
                    // Finally, if all of the other checks pass call the
                    // success callback.
                    onSuccess(json, textStatus, jqXHR);
                }
            }, onFailure);
        },

        /**
         * Call `jsonCmd` but show a busy spinner first and hide it once the
         * request completes. 
         */
        jsonCmdBusy: function(cmd, inputJson, onSuccess, onFailure) {
            var onSuccessBusy = function(data, textStatus, jqXHR) {
                                    adminapp.getLessBusy();
                                    if(onSuccess) {
                                        onSuccess(data, textStatus, jqXHR);
                                    }
                                },
            onFailureBusy = function(jqXHR, textStatus, errorThrown) {
                                    adminapp.getLessBusy();
                                    if(onFailure) {
                                        onFailure(jqXHR, textStatus, errorThrown);
                                    }
                               };

            adminapp.getMoreBusy();
            adminapp.jsonCmd(cmd, inputJson, onSuccessBusy, onFailureBusy);
        },

        /**
         * Call `adminapp.jsonCmd` and handle possibility of errors or timeouts.
         */
        jsonCmdHandled: function (cmd, inputJson, onSuccess, onFailure, onError) {
            adminapp.jsonCmd(cmd, inputJson, function (json, textStatus, jqXHR) {
                // The page will redirect if this is called and the session is timed
                // out. No other handling is possible.
                if(adminapp.maybeHandleTimeout(json)) {
                    return;
                }

                if (!adminapp.jsonErrorHandled(json, onError)) {
                    // Finally, if all of the other checks pass call the
                    // success callback.
                    onSuccess(json);
                }
            }, onFailure);
        },

        jqAjaxError:function(jqXHR, textStatus, errorThrown){
            console.log("jqAjaxError: ", jqXHR.status,  jqXHR);
            console.log("textStatus: ", textStatus);
            if(errorThrown) {
                console.log("errorThrown:");
                console.log(errorThrown);
            }
            popupmgr.alert(format("idbdata.err.communication", (errorThrown || textStatus || '') + " (" + (jqXHR.status || '') + ")"));
        },
        jqAjaxErrorBusy: function(jqXHR, textStatus, errorThrown) {
            adminapp.getLessBusy();
            adminapp.jqAjaxError(jqXHR, textStatus, errorThrown);
        },

        maybeResetKeepAlive:function(outputJson) {
            if(!outputJson || !outputJson.hasOwnProperty("confirmation")) {
                return;
            }

            if(outputJson.confirmation !== "timeout") {
                core.resetKeepAlive();
            }
        },

        /**
         * Do a page reload, so when the user logs back in they are back
         * where they were.
         *
         * If there was never a valid login session this could end up in an
         * infinite reload scenario. This can happen when the SameSite
         * property on the jsessionid cookie is not set properly.
         */
        reloadPage() {
            if (config.config.user) {
                // @ts-ignore On some browsers, reload accepts a `forceGet` param to bypass cache
                window.location.reload(true);
            } else {
                console.error('Request timed out.');
            }
        },

        maybeHandleTimeout:function(outputJson) {
            if(!outputJson || !outputJson.hasOwnProperty("confirmation")) {
                return false;
            }

            if(outputJson.confirmation !== "timeout") {
                core.resetKeepAlive();
                return false;
            }

            adminapp.reloadPage();

            return true;
        },

        jsonErrorHandled:function(outputJson, messageCloseCallback) {
            // "unlicensed" isn't technically an error, but a unlicensed
            //  response should follow the error path in the code because it is
            //  not going to have a correct response.
            if(!outputJson || !(outputJson.confirmation === "err" || outputJson.confirmation === "unlicensed")) {
                return false;
            }

            popupmgr.alert(outputJson.message || format("idbdata.err.unexpected_error"), messageCloseCallback || $.noop);
            return true;
        },

        /**
         * validation functions copied from dscore.
         *
         * @param {unknown} [results]
         * @param {string} [name]
         * @returns {import("idbtypes").ValidationResult}
         */
        validResults:function(results, name) {
          var ret = {status:"valid"};
          name = name || "results";
          ret[name] = results;
          return ret;
        },

        /**
         * @param {string} msg
         * @param {unknown} [props]
         * @returns {import('idbtypes').ValidationResult}
         */
        invalidResults:function(msg, props) {
          props = props || {};
          return $.extend({status:"invalid", message:msg}, props);
        },

        pendingResults: function () {
            return { status: 'pending' };
        },

        /**
         * @param {import('idbtypes').ValidationResult} results
         * @returns {boolean}
         */
        resultsInvalid:function(results) {
            return results.status === "invalid";
        },

        /**
         * @param {import('idbtypes').ValidationResult} results
         * @returns {boolean}
         */
        resultsValid:function(results) {
          return results.status === "valid";
        },

        makeMultiValidateFunction:function(validators) {
            return function(value) {
                       var results;
                       for(var i = 0; i < validators.length; i++) {
                           results = validators[i](value);
                           if(adminapp.resultsInvalid(results)) return results;
                       }
                       return results;
                   };
        },
        resultsPending:function(results) {
          return results.status === "pending";
        },
        dialogDropdown:function($dropdown) {

            $dropdown
                .on('mouseover', '.dropdown-submenu', toggleSubmenu)
                .on('mouseout', '.dropdown-submenu', toggleSubmenu);

            function toggleSubmenu(evt /*: JQueryEventObject */) {
                var $menuList = $(evt.currentTarget).children('ul')[0];
                if (evt.type === "mouseover")
                {
                    $($menuList).css({display: "block"});
                }
                else
                {
                    $($menuList).css({display: ""});
                }
            }
        },
        closeDialogDropdown:function($dropdown) {
            // Bootstrap now handles its own opening and closing, this function is kept
            // for posterity.

            console.warn('The dialog dropdowns are self-closing, to manually close a dropdown refer to the Bootstrap documentation');
        },
        addTextAsTooltip:function($parent) {
            $(".idb-text-ellipsis", $parent).each(function(i, elem) {
                                             var $elem = $(elem);
                                             $elem.attr({"title":$elem.text()});
                                         });
        },
        //
        // if convertBooleans is true, booleans will be converted to strings.
        //
        getFormInput:function($form, convertBooleans) {
            var  output = {};
            var $inputs = $(":input, .idb-form-input", $form);
            $inputs.each(function() {
                    var $this = $(this);
                    var key = this.name;
                    var options;
                    if(!key) {
                        key = $this.attr("name");
                    }
                    if(!key) {
                        key = this.id;
                    }
                    if(!key) { 
                        key = $this.attr("id");
                    }

                    if($this.is("input:radio:not(:checked)")) {
                        key = null;
                    }
                    if(key) {
                        var value, getValue = $this.data("getValue");

                        if(getValue && typeof getValue === "function") {
                            value = getValue($this);
                        }
                        else if($this.is("input:checkbox")) {
                           value = $this.is(":checked");
                           if(convertBooleans) {
                               value = value?"true":"false";
                           }
                        }
                        else if ($this.is("select")) {
                            // if a select has a disabled option jQuery returns
                            // null for val(). Override to get the actual value.
                            if (this.type === 'select-multiple') {
                                value = [];
                                options = this.options;

                                for (var i = 0; i < options.length; i++) {
                                    if (options[i].selected) {
                                        value.push(options[i].value);
                                    }
                                }
                            } else {
                                value = this.value;
                            }
                        }
                        else if($this.is('.idb-form-input')) {
                            value = $this.data("value");
                        }
                        else {
                            value = $this.val();
                        }
                        output[key] = value;
                    }
            });
            return output;
        },
        populateForm:function($form /*: JQuery */, values /*: { [string]: any } */) {
           var $inputs = $(":input, .idb-form-input", $form);
           $inputs.each(function() {
                   var $this = $(this);
                   var key = this.name || this.id || $this.attr('name');
                   var value = values[key];
                   if(typeof value !== 'undefined') {
                       // Convert value to a string, nulls become empty strings
                       var formattedValue = value != null ? String(value) : '';

                       if($this.is("input:checkbox")) {
                           var checked = (value === true) || (value === "true");
                           $this.prop("checked", checked);
                       }
                       else if($this.is('.idb-form-input')) {
                           const setValue = $this.data('setValue');
                           if(setValue) {
                               setValue(value);
                               $this.data('value', value);
                           }
                           else {
                               $('*', $this).removeClass('idb-data-source-selected');
                               var $selected = $('[data-value="' + formattedValue + '"]', $this)
                                   .addClass('idb-data-source-selected');
                               if($selected.length > 0) {
                                  $this.data("value", value);
                               }
                           }
                       }
                       else {
                           // This can accept nulls to clear out the form
                           // values. Undefined is not allowed because it will
                           // make jQuery return the value rather than set it.
                           $this.val(formattedValue);
                       }
                  }
           });
        },


        textEllipsisAndTooltip:function($item) {

            $item.css({"overflow":"hidden",
                       "white-space":"nowrap",
                       "text-overflow":"ellipsis"})
                 .attr('title', $item.text());
        },


        formInputToJSONObject:function(formId) {
            var output = {};
            var $inputs = $("#" + formId + " :input");
            $inputs.each(function(idx) {
                    var key = this.id;
                    if(!key) {
                        key = this.name;
                    }
                    if(key) {
                        var value = $(this).val();
                        output[key] = value;
                    }
            });
            return output;
        },

        formInputToJSON:function(formId) {
            var output = adminapp.formInputToJSONObject(formId);
            return JSON.stringify(output);
        },

        /**
         * Post data to a given URL using a hidden form.
         * 
         * @param  {string} url  The URL to submit to.
         * @param  {string} data The data to submit.
         * @return {void}
         */
        submitAsJSONString: function(url, data) {
            var $submitter = $("#idbdataSubmitter");
            if($submitter.length === 0) {
                $submitter = $('<form method="POST" id="idbdataSubmitter" style="display:none;visibility:hidden"><input type="hidden" name="json"/></form>').appendTo('body');
            }
            $submitter.attr('action', url);
            $(":hidden", $submitter).val(data);
            $submitter.submit();
        },

        clearForm:function(form) {
            var $inputs;
            if(typeof form === 'string') {
                $inputs = $("#" + form + " :input");
            }
            else {
                $inputs = $(":input", form);
            }
            $inputs.each(function() {
                    var $item = $(this);
                    var value;
                    if($item.is('select')) {
                        value = $item.find('option:first').attr("value");
                    }
                    else {
                        value = "";
                    }
                    $item.val(value);
            });
        },
        /**
         * Finds all of the form inputs, and for the ones that have
         * the .idb-invalid class, it closes and disposes their popover.
         */
        closeInvalidInputPopovers:function($form) {
            var $inputs = $(":input", $form);

            $inputs.each(function() {
                    var $item = $(this);
                    if($item.is('.idb-invalid')) {
                        // $FlowFixMe
                        $item.popover("hide").popover("dispose");
                    }
                 });
        },
        toggleInvalidInputPopovers:function($form, display) {
            var $inputs = $(":input", $form);

            $inputs.each(function() {
                    var $item = $(this);
                    if($item.is('.idb-invalid')) {
                        $item.popover(display?"show":"hide");
                    }
                 });
        },
        generateFormItemsFromProperties:function(properties) {
            var html = "";
            properties.forEach(function(prop) {
                html += '<div class="form-group">';
                html += '<label for="' +  prop.propertyName + '">' + prop.displayName +  (prop.required?"*":"") + "</label>";
                html += '<input type="text" class="form-control" id="' + prop.propertyName + '"';
                if(prop.required) {
                   html += ' required';
                }
                if(prop.propertyValue !== null) {
                   html += ' value="' + prop.propertyValue + '"';
                }
                html += "></div>";
            });
            return html;
        },

        //
        // Possible controls as this point are only textbox, checkbox and select.
        //
        generateFormItemsFromProperties2:function(properties) {
            properties = properties || [];
            var html = "", $property, $input, prop, controlType, i, num = properties.length;
            for(i = 0; i < num; i++) {
                prop = properties[i];
                if(adminapp.isTrue(prop.hidden)) {
                    continue;
                }
                controlType = (prop.controlType || "text").toLowerCase();
                switch(controlType) {
                    case "checkbox":
                        $property = $('<div><div class="form-group idb-form-group-flat"><label></label><input type="checkbox" class="form-control"/></div></div>');
                        $input = $('input', $property).attr({"id":prop.propertyName});
                        if(adminapp.isTrue(prop.propertyValue)) {
                            $input.attr("checked", "true");
                        }
                        break;

                    case "select" :
                        $property = $('<div><div class="form-group idb-form-group-flat"><label></label><select class="form-control"/></div></div>');
                        $input = $('select', $property).attr({"id":prop.propertyName});
                        const choices = prop.choices || [], $sel = $input;
                        choices.forEach((choice) => {
                            let $o = $("<option>").appendTo($sel);
                            if("string" === typeof choice) {
                                $o.attr("value", choice);
                                $o.text(choice);
                            }
                            else { // must be an array of length 1 or 2
                                $o.attr("value", choice[0]);
                                $o.text(choice.length > 1 ? choice[1] : choice[0]);
                            }
                        });
                        if(prop.propertyValue) {
                            $input.val(prop.propertyValue);
                        }

                        break;
                    default:
                        $property = $('<div><div class="form-group"><label></label><input type="text" class="form-control"/></div></div>');
                        $input = $('input', $property).attr({"id":prop.propertyName});
                        if(prop.propertyValue !== null) {
                           $input.attr({"value":prop.propertyValue});
                        }
                        break;
                }
                if(prop.required) {
                   $input.addClass("idb-ds-required");
                }
                $('label', $property)
                        .attr("for", prop.propertyName)
                        .text(prop.displayName + (prop.required?"*":""));

                html += $property.html();
             }
             return html;
        },

        validateForm:function($form, validators, aOptions) {
            var $inputs = $(":input, .idb-form-input", $form), invalid = false, $scrollingParent,
            dscore = adminapp,
            scrollingParentChecked = false,
            options = aOptions || {}, placement = options.placement || "right", 
            container = options.container || false,
            alwaysShow = options.alwaysShow,
            maybeShowPopover = function($item, $scrollingParent) {
                                    if(!$scrollingParent) {
                                        // $FlowFixMe
                                        $item.popover("show");
                                    }
                                    else {
                                        if(core.isScrolledIntoView($item, $scrollingParent)) {
                                            // $FlowFixMe
                                            $item.popover("show");
                                        }
                                        else {
                                            // $FlowFixMe
                                            $item.popover("hide");
                                        }
                                    }
                                  };

            $inputs.each(function() {
                    var $item = $(this), value, validate/*: Validator */ = $item.data("validate"),
                    key, validationResults;
                    if(validators && validate === null) {
                        key = this.name;
                        if(!key) {
                            key = $item.attr("name");
                        }
                        if(!key) {
                            key = this.id;
                        }
                        if(!key) {
                            // $FlowFixMe: Ad-hoc property use
                            key = $item.id;
                        }

                        validate = validators[key];
                    }


                var originalValidate = validate;

                // If the field is required, wrap validation with a value presence check
                if($item.is(':required')) {
                    validate = function(value) {
                        if (dscore.valueIsMissing(value)) {
                            return dscore.invalidResults(
                                bundle.format("idbdata.etl.task.extract.param.required")
                            );
                        } else {
                            if (originalValidate != null) {
                                return originalValidate(value);
                            } else {
                                return dscore.validResults();
                            }
                        }
                    };
                }

                if(validate) {
                        if($item.is("input:checkbox")) {
                          value = $item.is(":checked");
                        } else if ($item.is("select")) {
                            // if a select has a disabled option jQuery returns
                            // null for val(). Override to get the actual value.
                            if (this.type === 'select-multiple') {
                                value = [];
                                options = this.options;

                                for (var i = 0; i < options.length; i++) {
                                    if (options[i].selected) {
                                        value.push(options[i].value);
                                    }
                                }
                            } else {
                                value = this.value;
                            }
                        } else if($item.is(".idb-form-input")) {
                           value = $item.data("value");
                        }
                        else {
                            value = $item.val();
                        }

                        validationResults = validate(value);
                        var removeIndicator = function($item) {
                            if($item.is('.idb-invalid')) {
                                $item.removeClass('idb-invalid');
                                $item.popover('hide');
                                $item.popover('dispose');
                            }
                        };
                        if(dscore.resultsInvalid(validationResults)) {
                           invalid = true; 
                        }

                        var input = $item.data('input');

                        if(validationResults.status === "invalid") {
                            invalid = true;

                            if (input != null) {
                                input.setValidity(false, 
                                      !alwaysShow ? validationResults.message : "");
                            } else {
                                $item.addClass('idb-invalid')
                                    .on("focusin", function() {removeIndicator($item);});

                                if($item.is('.idb-form-input')) {
                                    $item.on('click', function() {removeIndicator($item);});
                                }
                            }

                            if(!input || alwaysShow) {
                                if(input) {
                                    $item.on("focusin input", function() {removeIndicator($item);
                                                                    input.setValidity(true);});
                                }

                                // $FlowFixMe
                                $item.popover({
                                    placement: placement,
                                    content: validationResults.message,
                                    container: container,
                                    trigger: "manual"
                                });

                                if(!scrollingParentChecked) {
                                    $scrollingParent = core.getScrollingParent($item, "vertical");
                                    scrollingParentChecked = true;
                                }
                                maybeShowPopover($item, $scrollingParent);
                                if($scrollingParent) {
                                    $scrollingParent.scroll(maybeShowPopover.bind(null, $item, $scrollingParent));
                                }
                            }
                        } else {
                            if (input != null) {
                                input.setValidity(true);
                            } else {
                                $item.removeClass('idb-invalid');
                            }
                        }
                   }
            });
            return !invalid;
        },
        getFormItemValue:function($item) {
            var value = null, options, item = $item[0];
            if($item.is("input:checkbox")) {
              value = $item.is(":checked");
            } else if ($item.is("select")) {
                // if a select has a disabled option jQuery returns
                // null for val(). Override to get the actual value.
                if (item.type === 'select-multiple') {
                    value = [];
                    options = item.options;
   
                    for (var i = 0; i < options.length; i++) {
                        if (options[i].selected) {
                            value.push(options[i].value);
                        }
                    }
                } else {
                    value = item.value;
                }
            } else if($item.is(".idb-form-input")) {
               value = $item.data("value");
            }
            else {
                value = $item.val();
            }
            return value;
        },
        setValidationIndicator:function($item, validationResults, aOptions) {
            var input = $item.data('input'), $scrollingParent,
             options = aOptions || {}, placement = options.placement || "right",
             alwaysShow = options.alwaysShow,
            removeIndicator = function($item) {
                            if($item.is('.idb-invalid')) {
                                $item.removeClass('idb-invalid');
                                $item.popover('hide');
                                $item.popover('dispose');
                            }
                        },

            maybeShowPopover = function($item, $scrollingParent) {
                                    if(!$scrollingParent) {
                                        $item.popover("show");
                                    }
                                    else {
                                        if(core.isScrolledIntoView($item, $scrollingParent)) {
                                            $item.popover("show");
                                        }
                                        else {
                                            $item.popover("hide");
                                        }
                                    }
                                  };

            if(validationResults.status === "invalid") {
                if (input != null) {
                    input.setValidity(false, !alwaysShow ? validationResults.message : "");
                } else {
                    $item.addClass('idb-invalid')
                        .on("focusin", function() {removeIndicator($item);});

                    if($item.is('.idb-form-input')) {
                        $item.on('click', function() {removeIndicator($item);});
                    }
                }

                if(!input || alwaysShow) {
                    if(input) {
                        $item.on("focusin input", function() {removeIndicator($item);
                                                        input.setValidity(true);});
                    }

                    $item.popover({
                        placement: placement,
                        content: validationResults.message,
                        container: false,
                        trigger: "manual"
                    });

                    $scrollingParent = core.getScrollingParent($item, "vertical");
                    maybeShowPopover($item, $scrollingParent);
                    if($scrollingParent) {
                        $scrollingParent.scroll(maybeShowPopover.bind(null, $item, $scrollingParent));
                    }
                }
            } else {
                if (input != null) {
                    input.setValidity(true);
                } else {
                    $item.removeClass('idb-invalid');
                }
            }
        },
        removeValidationIndicator:function($item) {
            if($item.is('.idb-invalid')) {
                $item.removeClass('idb-invalid');
                $item.popover('hide');
                $item.popover('dispose');
            }
        },
        validateForm3:function($form, validators, aOptions) {
            var $inputs = $(":input, .idb-form-input", $form), invalid = false, $scrollingParent,
            dscore = adminapp, pending = [],
            scrollingParentChecked = false,
            options = aOptions || {}, placement = options.placement || "right", 
            feedbackMode = options.feedbackMode || "popover",
            returnPromise = options.returnPromise ?? true,
            container = options.container || false,
            alwaysShow = options.alwaysShow,
            maybeShowPopover = function($item, $scrollingParent) {
                                    if(!$scrollingParent) {
                                        $item.popover("show");
                                    }
                                    else {
                                        if(core.isScrolledIntoView($item, $scrollingParent)) {
                                            $item.popover("show");
                                        }
                                        else {
                                            $item.popover("hide");
                                        }
                                    }
                                  };

            $inputs.each(function() {
                    var $item = $(this), value, 
                       validate/*: Validator */ = $item.data("validate"),
                    key, validationResults;
                    if(validators && validate === null) {
                        key = this.name;
                        if(!key) {
                            key = $item.attr("name");
                        }
                        if(!key) {
                            key = this.id;
                        }
                        if(!key) {
                            key = $item.id;
                        }
                        
                        validate = validators[key];
                    }


                var originalValidate = validate;

                // If the field is required, wrap validation with a value presence check
                if($item.is(':required')) {
                    validate = function(value) {
                        if (dscore.valueIsMissing(value)) {
                            return dscore.invalidResults(
                                bundle.format("idbdata.etl.task.extract.param.required")
                            );
                        } else {
                            if (originalValidate != null) {
                                return originalValidate(value);
                            } else {
                                return dscore.validResults();
                            }
                        }
                    };
                }

                if(validate) {
                        var getValue = $item.data("getValue");
                        if(getValue && typeof getValue === "function") {
                            value = getValue($item);
                        } else if($item.is("input:checkbox")) {
                          value = $item.is(":checked");
                        } else if ($item.is("select")) {
                            // if a select has a disabled option jQuery returns
                            // null for val(). Override to get the actual value.
                            if (this.type === 'select-multiple') {
                                value = [];
                                options = this.options;

                                for (var i = 0; i < options.length; i++) {
                                    if (options[i].selected) {
                                        value.push(options[i].value);
                                    }
                                }
                            } else {
                                value = this.value;
                            }
                        } else if($item.is(".idb-form-input")) {
                            value = $item.data("value");
                        }
                        else {
                            value = $item.val();
                        }

                        validationResults = validate(value);
                        var removeIndicator = function($item) {
                            if($item.is('.idb-invalid')) {
                                $item.removeClass('idb-invalid');

                                switch(feedbackMode) {
                                    case "popover":
                                        $item.popover('hide');
                                        $item.popover('dispose');
                                        break;
                                    
                                    case "tooltip":
                                        $item.attr({title:""});
                                        break;
                                }
                            }
                        };
                        if(dscore.resultsInvalid(validationResults)) {
                           invalid = true; 
                        }

                        var input = $item.data('input');

                        if(validationResults.status === "invalid") {
                            invalid = true;

                            if (input != null) {
                                input.setValidity(false, 
                                      !alwaysShow ? validationResults.message : "");
                            } else {
                                $item.addClass('idb-invalid')
                                    .on("focusin", function() {removeIndicator($item);});

                                if($item.is('.idb-form-input')) {
                                    $item.on('click', function() {removeIndicator($item);});
                                }
                            }

                            if(!input || alwaysShow) {
                                if(input) {
                                    $item.on("focusin input", function() {removeIndicator($item);
                                                                    input.setValidity(true);});
                                }

                                switch(feedbackMode) { 
                                    case "popover":
                                        $item.popover({
                                            placement: placement,
                                            content: validationResults.message,
                                            container: container,
                                            trigger: "manual"
                                        });
        
                                        if(!scrollingParentChecked) {
                                            $scrollingParent = core.getScrollingParent($item, "vertical");
                                            scrollingParentChecked = true;
                                        }
                                        maybeShowPopover($item, $scrollingParent);
                                        if($scrollingParent) {
                                            $scrollingParent.scroll(maybeShowPopover.bind(null, $item, $scrollingParent));
                                        }
                                        break;
                                   case "tooltip":
                                       $item.attr({title:validationResults.message});
                                       break; 
                                }
                            }
                        } else if(validationResults.status === "pending") {
                            validationResults.$item = $item;
                            pending.push(validationResults);
                        } else {
                            if (input != null) {
                                input.setValidity(true);
                            } else {
                                $item.removeClass('idb-invalid');
                            }
                        }
                   }
            });

            if(!returnPromise) {
                return !invalid;
            }

            if(pending.length > 0) {
                return Promise.all(pending.map(function(results) {return results.promise;}))
                    .then(function(pendingResults) {
                             pendingResults.forEach(function(results, idx) {if(dscore.resultsInvalid(results)) {
                                                                invalid = true;
                                                                dscore.setValidationIndicator(pending[idx].$item, results, options);
                                                                }});
                              return !invalid;
                          }
                    );
            }
            else {
                return new Promise(function(resolve) {return resolve(!invalid);});
            } 
        },

        /**
         * Call this method when the tooltips generated by
         * `adminapp.validateForm` need to be repositioned.
         *
         * @param  {JQuery} $form The form to search for the tooltips. This
         *     should be the same form you passed into the original call to
         *     `validate form`
         */
        updateValidationTooltips: function ($form) {
            // $FlowFixMe
            $form.find('.idb-invalid').popover('update');
        },

        /**
         * Removes any validation markers from $item
         */
        removeInvalidIndicator: function($item /*: JQuery */) {
            if ($item.is('.idb-invalid')) {
                $item.removeClass('idb-invalid');
                $item.attr("title", "");
            }
        },

        validateForm2:function($form, validators, aOptions) {
            var $inputs = $(":input, .idb-form-input", $form), invalid = false;

            $inputs.each(function () {
                var $item = $(this), value, validate = $item.data("validate"),
                    validationResults, key;

                if(validators && validate === null) {
                    key = this.name;
                    if (!key) {
                        key = this.id;
                    }
                    validate = validators[key];
                }

                if(validate) {
                    var input = $item.data('input');

                    if (input != null) {
                        value = input.getValues();
                    } else {
                        if ($item.is("input:checkbox")) {
                            value = $item.is(":checked");
                        } else if ($item.is(".idb-form-input")) {
                            value = $item.data("value");
                        } else {
                            value = $item.val();
                        }
                    }

                    validationResults = validate(value);

                    if (validationResults.status === "invalid") {
                        invalid = true;

                        if (input != null) {
                            input.setValidity(false, validationResults.message);
                        } else {
                            if ($item.is('.idb-form-input')) {
                                $item.on('click', function() {
                                    adminapp.removeInvalidIndicator($item);
                                });
                            }

                            $item.addClass('idb-invalid');
                            $item.attr("title", validationResults.message);
                        }
                    } else {
                        if (input != null) {
                            input.setValidity(true);
                        } else {
                            adminapp.removeInvalidIndicator($item);
                        }
                    }
                }
            });

            return !invalid;
        },


        /**
         * Returns true if the argument is the boolean value true, or
         * the string "true" with insignificant case or leading or
         * trailing whitespace. Otherwise false is returned.
         */
        isTrue:function(value) {
            if(!value) {
                return false;
            }
            if(value === true) {
                return true;
            }
            if(typeof value !== "string") {
                return false;
            }
            value = value.trim().toLowerCase();
            return value === "true";
        },

        areNamesUnique:function(items, nameField) {
            if(!items) return true;
            nameField = nameField || "name";
            var counts = {};
            return items.every(function(item) {
                                    var name = item[nameField].toLowerCase();
                                    var count = counts[name];
                                    if(count) return false;
                                    counts[name] = 1;
                                    return true;
                      });
        },
        isMacro:function(value) {
            if(!value) return false;
            value = value.trim();
      //      return value.startsWith('${') && value.endsWith('}'); // String.startsWith and String.endsWith not supported in IE
            return value.indexOf('${') === 0 && value.indexOf('}') === value.length - 1;
        },
        containsMacro:function(value) {
            if(!value) return false;
            if(value instanceof Array) {
               return value.some(function(v) {return adminapp.containsMacro(v);});
            }
            else {
              return macroRegExp.test(value); 
            } 
        },
        isEmptyMacro:function(value) {
            if(!value) return false;
            value = value.trim().toLowerCase();
            return value === "${empty}";
        },
        isNullMacro:function(value) {
            if(!value) return false;
            value = value.trim().toLowerCase();
            return value === "${null}";
        },
        valueIsEmptyOrNullMacros:function(value) {
            if(value === undefined) {
                return false;
            }
            var values;
            if(value instanceof Array) {
                values = value;
            }
            else {
                values = [value];
            }
      
            if(values.length === 0) {
                return false;
            }
      
            for(var i=0; i < values.length; i++) {
                var v = values[i];
                if(!adminapp.isEmptyMacro(v) && !adminapp.isNullMacro(v)) {
                    return false;
                }
             }
             return true;
        },

        valueIsOfType:function(dataType, value, allowMacros) {
            var lowerDataType = (dataType || "").toLowerCase();

            if(Array.isArray(value)) {
                return value.every(function(item) {return adminapp.valueIsOfType(dataType, item, allowMacros);});
            }

            if(allowMacros) {
                if(adminapp.isMacro(value)) {
                    if(!adminapp.isEmptyMacro(value)) return true;
                    if(lowerDataType === "string") return true;
                    return false;
                }
            }
            switch(lowerDataType) {
              case "string":
                  return true;

              case  "number":
                  return !isNaN(parseFloat(value));

              case "datetime":
                  return dateutil.validate(value);

              default:
                  return false;
            }
        },
        valueIsMissing:function(value) {
            if(value === undefined) {
                return true;
            }
            var values;
            if(value instanceof Array) {
                values = value;
            }
            else {
                values = [value];
            }
      
            for(var i=0; i < values.length; i++) {
                var v = values[i];
                var missing = (v == null) || (v + '').trim() === "";
                if(!missing) {
                    return false;
                }
             }
             return true;
        },
        isMissingValue:function(value) {
            if(core.isNil(value)) return true;
      
            if((typeof value === "string") && (value.trim().length === 0)) return true;
      
            if(value instanceof Array && value.length === 0) return true;
      
            return false;
        },

        //
        // This probably isn't the best home for these methods, but they are shared between idbdata and idashboards.
        //
        validateParamValueIsOfType:function(dataType, value) /*: ValidationResult */ {
            var msg = '', dscore = adminapp;
            if(!dscore.valueIsOfType(dataType, value, true)) {
                switch (dataType) {
                    case 'Number':
                        msg = bundle.format('idbdata.etl.dataset.param.value_type_number');
                        break;
                    case 'Datetime':
                        msg = bundle.format('idbdata.etl.dataset.param.value_type_datetime');
                        break;
                }
                return dscore.invalidResults(msg);
            }
            return {status:"valid"};
        },
    
        validateParamValue:function(param, multiText, value) {
            var dataType = param.dataType, dscore = adminapp,
            required = dscore.isTrue(param.required);
            if(required) {
                if(dscore.valueIsMissing(value)) {
                    return dscore.invalidResults(bundle.format("idbdata.etl.task.extract.param.required"));
                }
            }
            if(!value) {
                return dscore.validResults(true);
            }
    
            if(param.multiVal && multiText) {
                value = multiText.value();
            }
    
            if(required && dscore.valueIsEmptyOrNullMacros(value)) {
                return dscore.invalidResults(bundle.format("idbdata.etl.task.extract.param.non_empty_null_required"));
            }
    
            if(Array.isArray(value)) {
                for(var i = 0; i < value.length; i++) {
                    var results = dscore.validateParamValueIsOfType(dataType, value[i]);
                    if(dscore.resultsInvalid(results)) {
                        return results;
                    }
                }
                return dscore.validResults(true);
            }
            else {
               return dscore.validateParamValueIsOfType(dataType, value);
            }
        },
        makeType:function(dataType, value) {
            dataType = dataType.toLowerCase();

            if(core.isNil(value)) {
                return value;
            }

            if(dataType === "datetime") {
                if(value instanceof Date) {
                    return value;
                }
                else {
                    return dateutil.makeJavaScriptDate(value);
                }
            }

            else if(dataType === "number") {
                if(typeof value === "number") {
                    return value;
                }
                else {
                   return Number(value);
                }
            }
            else {
               if(typeof value === "string") {
                   return value;
               } 
               else if(value instanceof Date) {
                   return dateutil.formatDatetime(value);
               }
               else {
                   return value.toString();
               }
            }
        },
        areColumnNamesUnique:function(columns, columnNameField) {
            if(!columns) return true;
            columnNameField = columnNameField || "colName";
            var counts = {};
            return columns.every(function(column) {
                                    var colName = column[columnNameField].toLowerCase();
                                    var count = counts[colName];
                                    if(count) return false;
                                    counts[colName] = 1;
                                    return true;
                   });
        },
        /**
         * Determine if a privilege is at least the specified minimum.
         *
         * @param {string} priv    the privilege to test.
         * @param {string} minPriv The privilege it will be compared to.
         * 
         * @return {string} true if priv greater than or equal to minPriv.
         */
        hasMinimumPrivilege: function (priv, minPriv) {
            var privilegeRanking = 'VNDA',
                privIndex = privilegeRanking.indexOf(priv),
                minPrivIndex = privilegeRanking.indexOf(minPriv);

            if (privIndex < 0) {
                return false;
            }

            if (minPrivIndex < 0) {
                return false;
            }

            return privIndex >= minPrivIndex;
        },

        /**
         * Like setInterval but stops when the tab isn't visible and restarts
         * when its shown again.
         *
         * @param {function} funk     The function to execute.
         * @param {number} interval     How often to execute it.
         */
        setEfficientInterval: function (funk, interval) {
            var lastTime,
                currTime,
                intervalId = null,
                checkInterval = function (e/*: ?Event */) {

                    // If this was triggered from a change in visibility remove
                    // the handler so it only fires once. This is to avoid more
                    // complicated logic for managing global handlers.
                    if (e && e.type === 'visibilitychange') {
                        document.removeEventListener('visibilitychange', checkInterval);
                    }

                    if (!document.hidden) {
                        currTime = new Date();

                        // If tabs are switched back and forth quickly it will
                        // execute the function too often.
                        if (!lastTime || currTime - lastTime >= interval) {
                            lastTime = currTime; 
                            funk();
                        }

                        if (!intervalId) {
                            intervalId = setInterval(checkInterval, interval);
                        } 
                    } else {
                        if (intervalId != null) {
                        clearInterval(intervalId);
                        }

                        intervalId = null;

                        // Defer checking the interval until the next change of
                        // visibility of the tab.
                        document.addEventListener('visibilitychange', checkInterval);
                    }
                };

            checkInterval();
        },
        makeCustomSkin:function(color) {
            let rgb = colorhelper.rgb(color || 0);
            return {
                name:rgb.css,
                titleBar:rgb.i,
                titleBarText:colorhelper.isLightColor(rgb) ? 0x404040 : 0xFFFFFF,
                displayKey:"skins.html5.current_with_color",
                displayName:bundle.format("skins.html5.current_with_color", rgb.css) 
            };
        },
        getNextColName:function(prefix, existingCols) {
            return prefix + adminapp.getNextColNum(prefix, existingCols);
        },

        getNextColNum:function(prefix, existingCols) {
            var result = -1,
            newRegex = new RegExp(prefix + "(\\d+)", "i"),
            existingNames, existingNums;
   
            if(!existingCols || existingCols.length === 0) {
                existingNames = [];
            }
            else {
                var col = existingCols[0],
                t = typeof col;
                if(t === "object") {
                    if(typeof col.getColName === "function") {
                        existingNames = existingCols.map(function(c) {return c.getColName();});
                    }
                    else {
                        existingNames = existingCols.map(function(c) {return c.toString();});
                    }
                }
                else if (t === "string") {
                    existingNames = existingCols;
                }
                else {
                    existingNames = existingCols.map(function(c) {return String(c);});
                }
            }
   
            existingNums = existingNames.reduce(function(acc, o) {
                                                               var m = newRegex.exec(o);
                                                               if(m) acc.push(parseInt(m[1]));
                                                               return acc;
                                                         }, []);
            existingNums.sort(function(a,b) {return a - b;});
   
            for(var i = 0; i < existingNums.length; i++) {
                if(existingNums[i] !== i+1) {
                    result = i+1;
                    break;
                }
            }
            if(result === -1) {
                result = existingNums.length+1;
            }
            return result;
        }
    }; // end adminapp

    export default adminapp;
