/* global $, jQuery, SuppressibleMsgBox, Menu */

/**
 * @module popup/popupmgr
 */

import core from '../idbcore.js';
import config from '../config.js';
import bundle from '../lang/b.js';
import {
    firstFocus,
    firstFocusControls,
    firstFocusControlsData,
    preventRefocusOnCloseDialog,
    focusReturnControlElement,
} from '../utils/FirstFocusUtils.js';
import {
    tabCycleUseFirstFocus,
    keyCtrlEnter,
    keyCtrlEnterFocusClick,
    idbBtnSuccess,
    btnSuccess,
} from '../utils/FirstFocusUtils.js';
import { hasKeys, isRepeating } from '../utils/keyboard.js';
import helpmgr from '../help/helpmgr.js';
import colorhelper from '../utils/colorhelper.js';

var nextId = 1,
    $window = $(window),
    $body = $('body').first(),
    delIconXml,
    dragHandleXml;

delIconXml =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" ' +
    'viewBox="0 0 203.2 203.2" style="enable-background:new 0 0 203.2 203.2;" xml:space="preserve">' +
    '<style type="text/css">' +
    '.st0{fill:__FILL__;}' +
    '</style>' +
    '<path class="st0" d="M163.2,13.9c-16.9,16.9-33.8,33.8-50.7,50.7c-26.9,26.9-53.8,53.8-80.8,80.8c-6.2,6.2-12.3,12.3-18.5,18.5' +
    'c-6.5,6.5-7,18.4,0,24.7c7,6.4,17.8,6.9,24.7,0c16.9-16.9,33.8-33.8,50.7-50.7c26.9-26.9,53.8-53.8,80.8-80.8' +
    'c6.2-6.2,12.3-12.3,18.5-18.5c6.5-6.5,7-18.4,0-24.7C181,7.5,170.1,7,163.2,13.9L163.2,13.9z"/>' +
    '<path class="st0" d="M188,163.9c-16.9-16.9-33.8-33.8-50.7-50.7c-26.9-26.9-53.8-53.8-80.8-80.8C50.3,26.3,44.2,20.1,38,13.9' +
    'c-6.5-6.5-18.4-7-24.7,0c-6.4,7-6.9,17.8,0,24.7c16.9,16.9,33.8,33.8,50.7,50.7c26.9,26.9,53.8,53.8,80.8,80.8' +
    'c6.2,6.2,12.3,12.3,18.5,18.5c6.5,6.5,18.4,7,24.7,0C194.4,181.6,194.9,170.8,188,163.9L188,163.9z"/>' +
    '</svg>';

dragHandleXml =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ' +
    ' version="1.1" x="0px" y="0px" width="16px" height="16px"' +
    ' viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">' +
    '<style type="text/css">' +
    '.st0{fill:none;stroke:rgb(238,238,238);stroke-miterlimit:10;opacity:0.8;}' +
    '.st1{fill:none;stroke:rgb(0,0,0);stroke-miterlimit:10;opacity:0.35;}' +
    '</style>' +
    '<line x1="8" y1="16" x2="16" y2="8" class="st0"/>' +
    '<line x1="9" y1="16" x2="16" y2="9" class="st1"/>' +
    '<line x1="10" y1="16" x2="16" y2="10" class="st0"/>' +
    '<line x1="11" y1="16" x2="16" y2="11" class="st1"/>' +
    '<line x1="12" y1="16" x2="16" y2="12" class="st0"/>' +
    '<line x1="13" y1="16" x2="16" y2="13" class="st1"/>' +
    '<line x1="14" y1="16" x2="16" y2="14" class="st0"/>' +
    '<line x1="15" y1="16" x2="16" y2="15" class="st1"/>' +
    '</svg>';


var PopupBase,
    ResizeHandle,
    Dialog,
    AutoClose,
    MsgBox,
    deleteIconDarkUrl = core.toSvgDataUrl(delIconXml.replace('__FILL__', '#58595B')),
    deleteIconLightUrl = core.toSvgDataUrl(delIconXml.replace('__FILL__', '#DDDDDD')),
    dragHandleUrl = core.toSvgDataUrl(dragHandleXml),
    transformCss = {
        transform: 'translate(0, -25%)',
        '-webkit-transform': 'translate(0, -25%)',
        transition: 'transform 0.15s ease-out, -webkit-transform 0.15s ease-out',
    };

function normalizeOptions(argLists, optionsObject, defaultOptions) {
    var missingMessage = '[MESSAGE NOT PROVIDED]',
        stringArgs = argLists.strings,
        functionArgs = argLists.functions,
        arrayArgs = argLists.arrays,
        msg = stringArgs[0],
        title = stringArgs[1],
        msgType = stringArgs[2],
        options = $.extend(
            {
                message: null,
                htmlMessage: null,
                title: null,
                htmlTitle: null,
                type: 'info',
                modal: true,
                resizable: false,
                minWidth: 250,
                minHeight: 250,
            },
            defaultOptions,
            optionsObject
        );

    options.type = msgType || options.type;
    options.callback = functionArgs[0] || options.callback || null;
    options.buttons = arrayArgs[0] || options.buttons || ['ok'];

    if (!options.htmlMessage) {
        msg = msg || options.message || missingMessage;
        options.message = msg;
        options.htmlMessage = replaceLineBreaks(msg);
        options.lineBreaks = options.htmlMessage !== options.message;
        if (!options.lineBreaks) {
            options.htmlMessage = core.escapeHtml(options.message);
        }
    }

    if (!options.htmlTitle) {
        title = title || options.title || null;
        options.title = title;
        options.htmlTitle = core.escapeHtml(options.title);
    }

    return options;
}

function replaceLineBreaks(s) {
    if (!s || s.indexOf('\n') < 0) {
        return s;
    }
    return core.escapeHtml(s).replace(/\n/g, '<br>');
}

function makeCenteringFunction($centerWithin) {
    return function ($div, popup) {
        var w = $div.outerWidth(),
            h = $div.outerHeight(),
            $outer = $centerWithin || $body,
            $parent = popup.$parent,
            isParent = $outer[0] === $parent[0],
            ow = $outer.outerWidth(),
            oh = $outer.outerHeight(),
            top = 0,
            left = 0,
            offsets;

        if (isParent) {
            ow = $parent.width();
            oh = $parent.height();
            left = (ow - w) / 2;
            top = (oh - h) / 2;
            popup.position(left, top);
            return;
        }

        offsets = core.nodeToNode($outer[0], 0, 0, $parent[0]);
        //            console.log("offsets", offsets);

        left = offsets.x + (ow - w) / 2;
        top = offsets.y + (oh - h) / 2;
        popup.position(left, top);
    };
}

/*globals $, PopupBase:true, core, $body, ResizeHandle:true, $window, dragHandleUrl, firstFocus, firstFocusControls, firstFocusControlsData, preventRefocusOnCloseDialog, tabCycleUseFirstFocus, focusReturnControlElement, hasKeys */
/*jshint esversion: 11*/
/**
 * @constructor
 */
PopupBase = function ($parent) {
    var me = this,
        initProps = {
            canDragOffScreen: false,
            _minWidth: 200,
            _minHeight: 200,
            _maxWidth: 100000,
            _maxHeight: 100000,
            _maxWidthCss: 'calc(100vw - 60px)',
            _maxHeightCss: 'calc(100vh - 60px)',
            _maxResizeWidth: 100000,
            _maxResizeHeight: 100000,
            _modal: true,
            _buildImmediate: true,
            _resizable: true,
            _resizableX: true,
            _resizableY: true,
            _draggable: true,
            // For legacy reasons, if the drag handle is null or
            // undefined the whole popup will be the drag handle. To
            // specify that there should be no drag handle, set
            // '_draggable' to false.
            _dragHandle: null,
            requiredOverlap: 58,
            _resizeHandle: null,
            _resizeCallback: $.noop,
            _removalCallback: null,
            _positioner: null,
            _hadFirstFocus: false,
            _firstFocusTryCount: 0,
        },
        propName;
    me._x = me._y = me._dragStartX = me._dragStartY = me._dragStartPageX = me._dragStartPageY = 0;

    // This is calculated when the dragging starts.
    me._dragWidth = null;
    me._dragHeight = null;

    me._setInitProps();

    // Only set the initProp properties if subclass constructors
    // have not already set them, or if they have not been set in
    // _setInitProps().
    for (propName in initProps) {
        if (initProps.hasOwnProperty(propName)) {
            if (!me.hasOwnProperty(propName) || me[propName] === undefined) {
                me[propName] = initProps[propName];
            }
        }
    }

    me.$parent = $parent || $body;
    me._dragListener = me._onDrag.bind(me);
    me._dragEndListener = me._onDragEnd.bind(me);
    me._isHidden = false;

    // If the popup is added directly to the body of the page,
    // treat it as if its fixed this prevents it scrolling to the middle
    // of the page.
    me._isFixedPosition = document.body === me.$parent[0];

    // Determines if opening the popup should take focus from the thing
    // that opened it.
    me._captureFocus = !!me._captureFocus;

    // Opt out of first focus or any automatic focusing behaviors.
    // This also overrides captureFocus
    me._manualFocus = !!me._manualFocus;

    // Try to return focus to this external control on close
    me._$focusSource = $(':focus');

    if (me._buildImmediate) {
        me._popupBuild();
    }
};

PopupBase.prototype = {
    /**
     * This is here to work around the fact that an ES6 subclass
     * cannot refer to "this" until the superclass constructor has been called.
     * Therefore, it can't set any properties on the object before it calls
     * the constructor. The PopupBase was designed to get its properties from the object.
     *
     */
    _setInitProps: function () {},
    _popupBuild: function () {
        const me = this,
            $parent = me.$parent,
            keydownHandler = me._onKeydown.bind(me);

        let $div,
            $dragHandle;

        me.$modalBackdrop = $('<div draggable="false">')
            .addClass('idb-modal-backdrop')
            .css({
                position: 'fixed',
                display: 'block',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0,
                'user-select': 'none',
                'background-color': 'rgba(0,0,0,0.25)',
                'z-index': core.nextZIndex(),
            })
            .appendTo($parent);

        $div = me.$div = $('<div draggable="false">')
            .attr('data-is', 'PopupBase')
            .addClass('idb-popup')
            .css({
                position: me._isFixedPosition ? 'fixed' : 'absolute',
                visibility: 'hidden',
                display: 'flex',
                'flex-flow': 'column nowrap',
                'justify-content': 'stretch',
                'z-index': core.nextZIndex(),
                'min-width': me._minWidth + 'px',
                'max-width': me._maxWidthCss,
                'min-height': me._minHeight + 'px',
                'max-height': me._maxHeightCss,
                'box-sizing': 'border-box',
                overflow: 'visible',
            })
            .on('focus', function () {
                //! console.log('Popup.focus me._hadFirstFocus' ,me._hadFirstFocus);
                if (!me._hadFirstFocus) {
                    me._handleFirstFocus();

                    // too bad if we fail, acceptable
                    // resist the temptation to move this into _handleFirstFocus
                    me._hadFirstFocus = true;
                }
            })
            .on('focusin', function (e) {
                me._$lastFocus = $(e.target);
            })
            .appendTo($parent)
            .data('dialog', me);
        if (!me._preventTabCycle) {
            $div.on('keydown', function (e) {
                /// #keys PopupBase._popupBuild keydown $div idb-popup Tab
                // handle tab cycle within form
                if (hasKeys(e, 'Tab sTab')) {
                    const $foc = $(':focus');
                    const $lastCtrl = me._getControlFirstLast('last');
                    if ($lastCtrl.length) {
                        const focusFirstLast = (firstLast, ignoreFirstFocus) => {
                            const $ctrl = me._getControlFirstLast(firstLast, ignoreFirstFocus);
                            e.preventDefault();
                            $ctrl.focus();
                        };
                        // @ts-ignore pound-include
                        const useFF = !$foc.is(`.${tabCycleUseFirstFocus}`);
                        const isNoFocusOrDivFocus = $foc.length === 0 || ($foc.length && $foc[0] === me.$div[0]);
                        const isLastElFocus = $lastCtrl[0] === $foc[0];

                        if (!e.shiftKey) {
                            if (isNoFocusOrDivFocus || isLastElFocus) {
                                focusFirstLast('first', useFF);
                            }
                        } else if (e.shiftKey) {
                            if (isNoFocusOrDivFocus) {
                                focusFirstLast('last');
                            } else {
                                const $first = me._getControlFirstLast('first', true);
                                if ($first.length && $first[0] === $foc[0]) {
                                    focusFirstLast('last');
                                }
                            }
                        }
                    }
                }
            });
        }

        $div[0].addEventListener("keydown", keydownHandler);


        if (!me._modal) {
            $div.addClass('no-modal');
        }

        if (me._draggable) {
            $dragHandle = this._dragHandle || $div;
            $dragHandle.on('mousedown', me._onMouseDown.bind(me));
            $dragHandle.touchHandler = new core.DOMTouchHandler($dragHandle, {}, { kill: true });
            $dragHandle.css('user-select', 'none');
        }

        if (me._divCss) {
            $div.css(me._divCss);
        }

        if (me._resizable && (me._resizableX || me._resizableY)) {
            $div.addClass('idb-dialog-sizable');
            me._resizeHandle = new ResizeHandle(me, $div);
        }

        if (me._focusable || me._captureFocus) {
            $div.attr('tabindex', 0);
        }

        if (!me._manualFocus && me._captureFocus) {
            $div.one('transitionend', function (e) {
                // Only focus if this is the result of the popup
                // animating in and not if a child animates.
                if ($div.is(e.target)) {
                    $div.focus();
                }
            });
        }

        this._windowResizeHandler = this._onWindowResized.bind(this);

        $(window).on('resize', this._windowResizeHandler);
        me.setModal(me._modal);
        try {
            if (me._modal) {
                // We call this event modalopening, rather than
                // modalopened, because the subclass constructor hasn't
                // completed yet.
                me.$parent.trigger('modalopening.idb', [me]);
            }
        } catch (err) {
            console.error('Error while triggering modalopening.idb event:', err);
        }
    },
    setModal: function (b) {
        var me = this,
            $modalBackdrop = me.$modalBackdrop;
        $modalBackdrop.css({ display: b ? 'block' : 'none' });
        $modalBackdrop.toggleClass('idb-modal-backdrop-visible', !!b);
    },

    /**
     * Centers the $div in its parent, based on its current
     * dimensions. Previously, this used the window, however that
     * won't work when the body is rotated sideways.
     */
    centerInWindow: function () {
        var me = this,
            $div = me.$div,
            $parent = me.$parent,
            w = $div.outerWidth(),
            h = $div.outerHeight(),
            W,
            H,
            left,
            top;

        if (me._isFixedPosition) {
            W = document.documentElement.clientWidth;
            H = document.documentElement.clientHeight;
        } else {
            W = $parent.width();
            H = $parent.height();
        }

        left = (W - w) / 2;
        top = (H - h) / 2;

        me.position(left, top);
    },
    centerVerticallyInWindow: function () {
        var me = this,
            $div = me.$div,
            $parent = me.$parent,
            h = $div.outerHeight(),
            H,
            top;

        if (me._isFixedPosition) {
            H = document.documentElement.clientHeight;
        } else {
            H = $parent.height();
        }

        top = (H - h) / 2;

        $div.css({top: top + 'px'});
        me._y = top;
    },

    insureWithinParent: function () {
        var me = this,
            $div = me.$div,
            $parent = me.$parent,
            x = me._x,
            y = me._y,
            w = $div.width(),
            h = $div.height(),
            W,
            H,
            newX,
            newY;

        if (me._isFixedPosition) {
            W = document.documentElement.clientWidth;
            H = document.documentElement.clientHeight;
        } else {
            W = $parent.width();
            H = $parent.height();
        }

        newX = Math.max(0, Math.min(x, W - w));
        newY = Math.max(0, Math.min(y, H - h));

        if (isNaN(newX) || isNaN(newY)) {
            /*jshint debug:true */
            debugger;
            /*jshint debug:false */

            return;
        }
        me.position(newX, newY);
    },

    /**
     * Uses the position method from jQuery UI to position the div.
     * See http://api.jqueryui.com/position/ for an explanation of
     * the options argument. If the options argument for this method
     * is null, the method is a no-op.
     *
     * @options {object}
     * If options is an ordinal string like "NW", it is probably a mistake.
     * Definitely a mistake if string passed into jQuery UI's position(...) method.
     */
    positionui: function (options) {
        if (!options) {
            return;
        }
        var me = this,
            $div = me.$div,
            newPos;
        $div.positionui(options);
        newPos = $div.position();
        me._x = newPos.left;
        me._y = newPos.top;
    },

    /**
     * @returns {Promise<void>}
     */
    async _initPosition() {
        var me = this,
            $div = me.$div,
            pos = me._position,
            positioner = me._positioner;

        if ('function' === typeof positioner) {
            positioner($div, me);
        } else if (pos) {
            me.positionui(pos);
        } else {
            me.centerInWindow();
            $div.css({ transform: 'translate(0, 0)' });
        }

        $div.css({ visibility: 'visible' });
        me.$modalBackdrop.css({ opacity: '1' });

        let fixedDimensionPromise = Promise.resolve();

        if (me._fixWidth || me._fixHeight) {
            fixedDimensionPromise = new Promise((resolve) => {
                setTimeout(function () {
                    var bounds = core.maybeRotateBoundingClientRect($div[0].getBoundingClientRect());

                    /** @type {import('idbtypes').CssStyleMap} */
                    let css = {};

                    if (me._fixWidth) {
                        css.width = Math.ceil(bounds.width);
                    }

                    if (me._fixHeight) {
                        css.height = Math.ceil(bounds.height);
                    }

                    $div.css(css);
                    resolve();
                }, 0);
            });
        }

        let fixedSizePromise = Promise.resolve();

        if (me._fixSize) {
            fixedSizePromise = new Promise((resolve) => {
                setTimeout(() => {
                    me._setFixedSize();
                    resolve();
                }, 0);
            });
        }

        await Promise.all([fixedDimensionPromise, fixedSizePromise]);
    },
    /**
     * Get the element that should be focused when the popup is opened.
     *
     * @return {HTMLElement | null} The element or null if there isn't anything to focus.
     */
    getFirstFocusElement() {
        const me = this;
        const $firstFocusSelector = me._firstFocusSelector ? me.$div.find(me._firstFocusSelector) : $([]);
        const $firstFocusJs = me.$div.find('.' + firstFocus + ':visible:not(:disabled)');
        const $firstFocuses = $firstFocusSelector.length ? $firstFocusSelector : $firstFocusJs;

        return $firstFocuses[0] ?? null;
    },
    _handleFirstFocus: function () {
        if (this._manualFocus) {
            return;
        }

        const me = this,
            tryMax = 100,
            tryDelay = 10;

        me._firstFocusTryCount++;
        if (me._firstFocusTryCount > tryMax) {
            //! console.info('me._firstFocusTryCount > 100');
            return;
        }

        const firstFocusEl = this.getFirstFocusElement();

        const checkSuccessTimeout = function (targetFocus) {
            setTimeout(function () {
                checkSuccess(targetFocus);
            }, 0);
        };

        // do not remove info logging if present

        const checkSuccess = function (targetFocus) {
            const $nowFocus = $(':focus');
            if (0 === $nowFocus.length) {
                //! console.info('0 === $nowFocus.length');
                setTimeout(() => {
                    me._handleFirstFocus();
                }, tryDelay);
                return;
            }
            if ($nowFocus[0] === targetFocus) {
                //! console.info('$nowFocus[0] === targetFocus');
                return;
            }
            //! console.info('$nowFocus[0] !== targetFocus');
            setTimeout(() => {
                me._handleFirstFocus();
            }, tryDelay);
            return;
        };

        const doFocusAndSelect = function () {
            if (firstFocusEl) {
                //! Calling focus on element directly works best when in a setTimeout
                firstFocusEl.focus();
                checkSuccessTimeout(firstFocusEl);
            } else {
                //! console.info('$firstFocuses.length === 0');
                const $closeX = me.$div.find('.idb-dialog-closebutton');
                if ($closeX.length) {
                    $closeX[0].focus();
                    //! console.info('...using CloseX Button');
                    checkSuccessTimeout($closeX[0]);
                    return;
                }
                // console.info('$firstFocuses.length === 0 && $closeX.length === 0');
                checkSuccessTimeout(null);
                return;
            }
            const $firstFocus = $(firstFocusEl);

            // console.info('_handleFirstFocus', { $firstFocus, $prevFocus: $(':focus'), $div: me.$div });

            //! Have to select here to match default browser behavior because (programmatically) focus() does not do so
            if ($firstFocus.is('input[type=number], input[type=text], input:not([type])')) {
                $firstFocus.select();
            } else if ($firstFocus.is('select')) {
                //! Select first item in selects
                if (($firstFocus.val().toString().length || 0) === 0) {
                    $firstFocus
//                        .find(':not(:disabled):first')
//                        .attr('selected', 'true')
                        .trigger('focus');
//                        .trigger('change');
                }
            }
        };

        if (me._firstFocusDelay != null) {
            //! console.info('me._firstFocusDelay ' + me._firstFocusDelay);
            setTimeout(doFocusAndSelect, me._firstFocusDelay);
        } else {
            doFocusAndSelect();
        }
    },
    _getControlFirstLast: function (firstLast, ignoreFirstFocus) {
        const me = this;
        const $ctrlData = me.$div.find(`.${firstFocusControls}`);
        const ctrlDefs = 'a input button textarea select';
        const ctrlData = $ctrlData.length ? $ctrlData.data(firstFocusControlsData) : '';
        const ctrlFilters = ':visible:not(:disabled):not([tabindex=-1])';
        const ctrlsSel = (ctrlData || ctrlDefs).split(' ').join(ctrlFilters + ',') + ctrlFilters;
        const $ctrls = me.$div.find(ctrlsSel);
        //
        if (firstLast === 'first') {
            if (ignoreFirstFocus) {
                return $ctrls.eq(0);
            }
            const $firstFocuses = me.$div.find(`.${firstFocus}${ctrlFilters}`);
            return $firstFocuses.length ? $firstFocuses.eq(0) : $ctrls.eq(0);
        } else if (firstLast === 'last') {
            return $ctrls.length ? $ctrls.eq($ctrls.length - 1) : $([]);
        }
    },
    resetFirstFocus: function () {
        this._firstFocusTryCount = 0;
        this._hadFirstFocus = false;
    },
    retryFirstFocus: function () {
        this._firstFocusTryCount = 0;
        this._hadFirstFocus = false;
        this.$div.focus();
    },
    /**
     * This exists to work around a bug in IE whereby if the outer
     * dimensions of the popup are determined through a max-width
     * and max-height property, the behavior of its flex children
     * gets broken. (Specifically, the $body will extend past the
     * bottom of the $div.)
     */
    _setFixedSize: function () {
        var me = this,
            $div = me.$div,
            bounds = core.maybeRotateBoundingClientRect($div[0].getBoundingClientRect());

        $div.css({
            // IE also rounds down, so the content won't fit within its
            // natural dimensions.
            //
            // Rounding up so there is enough room in IE and so the edge
            // of the popup is on a pixel boundary.
            width: Math.ceil(bounds.width),
            height: Math.ceil(bounds.height),
        });
    },

    _onMouseDown: function (evt) {
        var me = this,
            dragListener = me._dragListener,
            dragEndListener = me._dragEndListener,
            pageLoc = core.toBodyXY(evt);

        // We had been preventing mouse events from bubbling, or preventing their default
        // actions, to prevent dragging a dialog by certain elements within the dialog, like the
        // close button or other controls. But that broke other things that were listening
        // for mousedown events at the window level, like a popup menu that needs to be
        // closed when the user clicks outside of it. So now, to prevent dragging by certain
        // elements within the dialog, those events should be caught before they bubble up
        // to the level of the main $div, and their originalEvent should be given a property "_idbIgnore"
        // that is set to true. It has to be the originalEvent because with jQuery, it's
        // not the same Event object that gets passed to all of the handlers for
        // a given event.
        if (evt && evt.originalEvent && evt.originalEvent._idbIgnore === true) {
            return;
        }

        var dimensions = me.$div[0].getBoundingClientRect();

        me._ignoreNextClick = false;

        $window.off('mousemove', dragListener);
        $window.off('mouseup', dragEndListener);

        $window.on('mousemove', dragListener);
        $window.on('mouseup', dragEndListener);

        me._dragStartPageX = pageLoc.pageX;
        me._dragStartPageY = pageLoc.pageY;
        me._dragStartX = me._x;
        me._dragStartY = me._y;
        me._dragWidth = dimensions.width;
        me._dragHeight = dimensions.height;
    },

    /**
     * Window resize handler
     *
     * @param {JQuery.ResizeEvent} ev
     */
    _onWindowResized(ev) {
        this.updatePosition();
    },

    /**
     * @param {import("idbtypes").Coords} wanted
     *   The new position of the popup.
     *
     *   (relative to what?)
     * @param {import("idbtypes").Coords} owner
     * @param {JQuery} $div The outer main div of the popup
     * @returns {import("idbtypes").Coords}
     */
    keepInFrame(wanted, owner, $div) {
        const { requiredOverlap } = this;

        /** The width of the dialog */
        const w = $div.outerWidth();
        /** The height of the dialog */
        const h = $div.outerHeight();

        /** The desired position of the top-left corner */
        const nw = wanted;

        /** The desired position of the bottom-right corner */
        const se = { x: wanted.x + w, y: wanted.y + h };

        /** The start and end positions of the top-left corner */
        const nwDelta = { start: nw, end: { x: owner.x - nw.x, y: owner.y - nw.y } };

        /** The start and end positions of the bottom right corner */
        const seDelta = { start: se, end: { x: owner.x - se.x, y: owner.y - se.y } };

        /** was the top-left corner previously visible? */
        const nwVisibleStart = nwDelta.start.x >= 0 && nwDelta.start.y >= 0;
        /** Is the top-left corner currently visible? */
        const nwVisibleEnd = nwDelta.end.x >= 0 && nwDelta.end.y >= 0;

        /** Was the top-left corner previously and currently visible? */
        const nwVisible = nwVisibleStart && nwVisibleEnd;

        /** Was the bottom-right corner previously visible? */
        const seVisibleStart = seDelta.start.x >= 0 && seDelta.start.y >= 0;
        /** Is the bottom-right corner currently visible? */
        const seVisibleEnd = seDelta.end.x >= 0 && seDelta.end.y >= 0;

        /** Was the bottom-right corner previously and currently visible? */
        const seVisible = seVisibleStart && seVisibleEnd;

        /** The final new position for the popup */
        let newPos = wanted;

        // top left corner is off screen
        if (!nwVisible) {
            // too high
            if (seDelta.start.y < requiredOverlap) {
                // console.debug('too high');
                newPos.y = requiredOverlap - h;
            }

            // too left
            if (seDelta.start.x < requiredOverlap) {
                // console.log('too left');
                newPos.x = requiredOverlap - w;
            }
        }

        // bottom right corner is off screen
        if (!seVisible) {
            // too low
            if (nwDelta.end.y < requiredOverlap) {
                // console.log('too low');
                newPos.y = owner.y - requiredOverlap;
            }

            // too right
            if (nwDelta.end.x < requiredOverlap) {
                // console.log('too right');
                newPos.x = owner.x - requiredOverlap;
            }
        }

        return newPos;
    },

    /**
     * @param {import("idbtypes").Coords} page
     * @param {import("idbtypes").Coords} start
     * @param {import("idbtypes").Coords} dragStart
     */
    moveDialog(page, start, dragStart) {
        // console.log('moving dialog', { page, start, dragStart });
        const me = this;
        const { $div, $parent, canDragOffScreen, requiredOverlap } = this;
        const owner = { x: $parent.width(), y: $parent.height() };
        const diff = { x: page.x - start.x, y: page.y - start.y };
        const wanted = { x: dragStart.x + diff.x, y: dragStart.y + diff.y };

        let newPos;

        if (!canDragOffScreen) {
            const viewport = { x: owner.x - me._dragWidth, y: owner.y - me._dragHeight };
            // The top-left corner must be visible
            newPos = { x: Math.max(0, Math.min(wanted.x, viewport.x)), y: Math.max(0, Math.min(wanted.y, viewport.y)) };
        } else if (requiredOverlap > 0) {
            const $titleBar = $div.find('.idb-dialog-titlebar,.idb-msgbox-titlebar');
            newPos = this.keepInFrame(wanted, owner, $titleBar);
        } else {
            // dialogs can be dragged wherever
            newPos = wanted;
        }

        if (isNaN(newPos.x) || isNaN(newPos.y)) {
            /*jshint debug:true */
            debugger;
            /*jshint debug:false */
        }

        // Don't set the flag until the move is more than 2
        // pixels so that intentional clicks with slight moves aren't ignored.
        if (Math.abs(diff.x) > 2 || Math.abs(diff.y) > 2) {
            me._ignoreNextClick = true;
        }

        me.position(newPos.x, newPos.y);
    },

    /**
     * Drag the dialog to it's current position. This causes any bounds checks to be re-run
     */
    updatePosition() {
        const { $div } = this;
        const divPos = $div.position();
        const divPoint = { x: divPos.left, y: divPos.top };
        this.moveDialog(divPoint, divPoint, divPoint);
    },

    _onDrag(evt) {
        // console.log((dragNum++) + "-_onDrag", evt);
        const {
            _dragStartX: dragStartX,
            _dragStartY: dragStartY,
            _dragStartPageX: startX,
            _dragStartPageY: startY,
        } = this;
        const pageLoc = core.toBodyXY(evt);
        const page = { x: pageLoc.pageX, y: pageLoc.pageY };
        const start = { x: startX, y: startY };
        const dragStart = { x: dragStartX, y: dragStartY };
        this.moveDialog(page, start, dragStart);
    },

    _onDragEnd: function (e) {
        // console.log("_onDragEnd", evt);
        var me = this,
            dragListener = me._dragListener,
            dragEndListener = me._dragEndListener;
        $window.off('mousemove', dragListener);
        $window.off('mouseup', dragEndListener);
    },

    position: function (x, y) {
        var me = this,
            $div = me.$div;
        $div.css({
            top: y + 'px',
            left: x + 'px',
        });
        me._x = x;
        me._y = y;
    },
    remove: function () {
        var me = this,
            $div = me.$div,
            $inputs = $('input', $div);

        // Remove any popups attached to form inputs within
        // the div. The popover jQuery plugin will only exist
        // in bootstrap pages, so check for it.
        if ($inputs.length > 0 && typeof $inputs.popover === 'function') {
            $inputs.popover('hide').popover('dispose');
        }

        $div.remove();
        if (me.$modalBackdrop) {
            me.$modalBackdrop.remove();
        }

        const $retEl = me._$focusSource.data(focusReturnControlElement);
        if ($retEl) {
            $retEl.focus();
        } else {
            if (!me._$focusSource.is(`.${preventRefocusOnCloseDialog}`)) {
                me._$focusSource.focus();
            }
        }

        $(window).off('resize', this._windowResizeHandler);

        if ('function' === typeof me._removalCallback) {
            try {
                me._removalCallback(me);
            } catch (err) {
                console.error('Error calling removal callback:', err);
            }
        }

        try {
            if (me._modal) {
                me.$parent.trigger('modalclosed.idb', [me]);
            }
        } catch (err) {
            console.error('Error while triggering modalclosed.idb event:', err);
        }
    },
    /**
     * This hides the popup without destroying it. It can be
     * re-shown with show.
     */
    hide: function () {
        const me = this,
            $div = me.$div,
            div = $div[0],
            $modalBackdrop = me.$modalBackdrop,
            $inputs = $('input', $div);

        me._isHidden = true;

        // Hide any popups attached to form inputs within
        // the div. The popover jQuery plugin will only exist
        // in bootstrap pages, so check for it.
        // THIS HAS NOT BEEN TESTED.
        if ($inputs.length > 0 && typeof $inputs.popover === 'function') {
            $inputs.popover('hide');
        }

        $div.css({ visibility: 'hidden' });
        $div.addClass("js-visibility-hidden");

        if ($modalBackdrop) {
            $modalBackdrop.addClass("js-visibility-hidden");
            $modalBackdrop.css({ visibility: 'hidden' });
        }

        div.dispatchEvent(new CustomEvent("popup.hide"));
    },
    show: function () {
        const me = this,
            $div = me.$div,
            div = $div[0],
            $modalBackdrop = me.$modalBackdrop;

        const wasHidden = me._isHidden;
        me._isHidden = false;

        $div.css({ visibility: 'visible' });
        $div.removeClass("js-visibility-hidden");

        if ($modalBackdrop) {
            $modalBackdrop.removeClass("js-visibility-hidden");
            $modalBackdrop.css({ visibility: 'visible' });
        }
        if (me._captureFocus && wasHidden) {
            me.retryFirstFocus();
        }
        div.dispatchEvent(new CustomEvent("popup.show"));
    },
    showOrHide: function (showIfTrue) {
        this[showIfTrue ? 'show' : 'hide']();
    },
    toggleShowOrHide: function () {
        this.showOrHide(this._isHidden);
    },
    getWidth: function () {
        return this.$div.width();
    },
    getHeight: function () {
        return this.$div.height();
    },

    getMaxResizeWidth: function () {
        return this._maxResizeWidth;
    },
    getMaxResizeHeight: function () {
        return this._maxResizeHeight;
    },
    getMinResizeWidth: function () {
        return this._minWidth;
    },
    getMinResizeHeight: function () {
        return this._minHeight;
    },
    setWidth: function (w) {
        // console.log("setWidth", w);
        this.$div.css({
            width: w + 'px',
            'max-width': '',
        });
    },
    setHeight: function (h) {
        // console.log("setHeight", h);
        this.$div.css({
            height: h + 'px',
            'max-height': '',
        });
    },
    notifyResizeStarted: function () {},
    notifyResizeEnded: function () {},
    _onKeydown(evt) {
        const me = this;
        if(evt.repeat) {
            return;
        }
        if(evt.keyCode === 27) { // ESCAPE key
            if (evt.altKey || evt.ctrlKey || evt.shiftKey) {
                return;
            }
            me._handleEscapeKey(evt);
        }

        if(evt.keyCode === 13 && evt.ctrlKey && !evt.altKey && !evt.shiftKey) { // CTRL-ENTER
            me._handleCtrlEnterKey(evt);
        }

    },
    _handleEscapeKey(evt) {

    },
    _handleCtrlEnterKey(evt) {

    },
    addShowEventListener(listener) {
        this.$div[0].addEventListener("popup.show", listener); 
    },
    addHideEventListener(listener) {
        this.$div[0].addEventListener("popup.hide", listener); 
    }
};

PopupBase._hasPopupEscHook = null;
PopupBase._hasPopupEnterHook = null;

ResizeHandle = function ResizeHandle(owner, $parent) {
    var me = this;
    me._owner = owner;
    me.$parent = $parent;

    me._rhDragStartPageX = 0;
    me._rhDragStartPageY = 0;
    me._rightPad = 0;
    me._bottomPad = 0;
    me._rhDragStartWidth = 0;
    me._rhDragStartHeight = 0;

    me._dragStarted = false;

    me._build();
};

ResizeHandle.prototype = {
    _build: function () {
        var me = this,
            $parent = me.$parent,
            $div = (me.$div = $('<div>').attr('data-is', 'ResizeHandle').appendTo($parent)),
            $touchHandle;

        $div.css({
            position: 'absolute',
            right: '0px',
            bottom: '0px',
            width: '30px',
            height: '30px',
            'overflow-x': 'visible',
            'overflow-y': 'visible',
            'z-index': '200',
            cursor: 'se-resize',
            'background-color': 'rgba(0,0,0,0)',
            'background-image': dragHandleUrl,
            'background-position': 'bottom right',
            'background-repeat': 'no-repeat',
            'background-size': 'contain',
            'user-select': 'none',
        }).on('mousedown', me._rhOnMouseDown.bind(me));

        //            $(makeDragHandle()).css({"width":"100%",height:"100%"}).appendTo($div);

        $div.touchHandler = new core.DOMTouchHandler($div[0], {}, { kill: true });

        if (core.DOMTouchHandler.TOUCH_SUPPORTED) {
            // For touchscreens, there needs to be a larger, invisible target
            // that can be easily hit with a fingertip. Its upper-left corner
            // will align with the upper left corner of the regular drag handle,
            // but it will be 24x24 instead of 16x16. It will be underneath the
            // regular drag handle in the stacking order.
            $touchHandle = me.$touchHandle = $('<div>')
                .css({
                    position: 'absolute',
                    right: '0px',
                    bottom: '0px',
                    width: '24px',
                    height: '24px',
                    'background-color': 'rgba(0,0,0, 0.0)',
                    'z-index': '19',
                    'user-select': 'none',
                })
                .appendTo($parent);

            $touchHandle.on('mousedown click', function (evt) {
                // ignore normal mouse events that occur on the $touchHandle. Only
                // handle mousedown events that are simulated as the result of touches.
                if (!evt.originalEvent || !evt.originalEvent.simulatedFromTouch) {
                    evt.stopImmediatePropagation();
                    return;
                }

                if (evt.type === 'mousedown') {
                    me._rhOnMouseDown(evt);
                }
            });
            $touchHandle._touchHandler = new core.DOMTouchHandler($touchHandle[0], {}, { kill: true });
        }

        me._rhDragListener = me._rhOnDrag.bind(me);
        me._rhDragEndListener = me._rhOnDragEnd.bind(me);
    },
    _rhOnMouseDown: function (evt) {
        var me = this,
            owner = me._owner,
            dragListener = me._rhDragListener,
            dragEndListener = me._rhDragEndListener,
            $touchHandle = me.$touchHandle,
            evtXY = core.toBodyXY(evt);

        (evt.originalEvent || {})._idbIgnore = true;

        me._dragStarted = false;
        me._ignoreNextClick = false;

        if (owner.resizeHandleMouseDown) {
            owner.resizeHandleMouseDown();
        }

        //  me._owner.activatePanel(me);

        $window.off('mousemove', dragListener);
        $window.off('mouseup', dragEndListener);

        $window.on('mousemove', dragListener);
        $window.on('mouseup', dragEndListener);

        me.$parent.css({ cursor: 'se-resize' });

        if ($touchHandle) {
            $touchHandle.css({ cursor: 'se-resize' });
        }

        me._rhDragStartPageX = evtXY.pageX;
        me._rhDragStartPageY = evtXY.pageY;
        me._rightPad = Math.max(0, 16 - evt.offsetX);
        me._bottomPad = Math.max(0, 16 - evt.offsetY);
        me._rhDragStartWidth = owner.getWidth();
        me._rhDragStartHeight = owner.getHeight();
    },

    _rhOnDrag: function (evt) {
        var me = this,
            owner = me._owner,
            resized = false,
            //                ownerWidth = owner.getWidth(), ownerHeight = owner.getHeight(),
            evtXY = core.toBodyXY(evt),
            pageX = evtXY.pageX,
            pageY = evtXY.pageY,
            diffX = pageX - me._rhDragStartPageX,
            diffY = pageY - me._rhDragStartPageY,
            wantedWidth = me._rhDragStartWidth + diffX,
            wantedHeight = me._rhDragStartHeight + diffY,
            maxWidth = owner.getMaxResizeWidth(),
            maxHeight = owner.getMaxResizeHeight(),
            minWidth = owner.getMinResizeWidth(),
            minHeight = owner.getMinResizeHeight(),
            simulatedFromTouch = (evt.originalEvent || evt).simulatedFromTouch === true,
            newWidth = Math.min(maxWidth, Math.max(minWidth, wantedWidth)),
            newHeight = Math.min(maxHeight, Math.max(minHeight, wantedHeight));

        if (isNaN(newWidth) || isNaN(newHeight)) {
            /*jshint debug:true */
            debugger;
            /*jshint debug:false */
        }

        // If the mouse is moving, but no buttons are held down, then the
        // mouse up event was likely missed. Cancel dragging.
        if (evt.buttons == 0 && !simulatedFromTouch) {
            me._rhOnDragEnd(evt);
        } else {
            // Don't set the flag until the move is more than 2
            // pixels so that intentional clicks with slight moves aren't ignored.
            if (Math.abs(diffX) > 2 || Math.abs(diffY) > 2) {
                me._ignoreNextClick = true;
            }

            if (owner._resizableX && evt.pageX <= $window.width() - me._rightPad) {
                resized = true;
                owner.setWidth(newWidth);
            }

            if (owner._resizableY && evt.pageY <= $window.height() - me._bottomPad) {
                resized = true;
                owner.setHeight(newHeight);
            }

            if (resized && owner._resizeCallback) {
                owner._resizeCallback(newWidth, newHeight);
            }
        }
    },
    _rhOnDragEnd: function (e) {
        var me = this,
            dragListener = me._rhDragListener,
            dragEndListener = me._rhDragEndListener,
            owner = me._owner,
            $touchHandle = me.$touchHandle;
        $window.off('mousemove', dragListener);
        $window.off('mouseup', dragEndListener);
        me.$parent.css({ cursor: 'auto' });
        if ($touchHandle) {
            $touchHandle.css({ cursor: 'auto' });
        }

        if (owner != null) {
            var ownerOptions = owner._options || {};
            var resizeFinished = ownerOptions.resizeFinished;

            if (resizeFinished != null) {
                resizeFinished();
            }
        }
    },
};





/*globals Dialog:true, core, config, colorhelper, helpmgr, keyCtrlEnter, keyCtrlEnterFocusClick, PopupBase, $body, $, nextId:true, transformCss, $window, firstFocus */
/*jshint esversion: 11*/
/*jshint strict:implied */

/**
 * </p><p> stringArg1, [stringArg2], functionArg1,
 * [functionArg2], arrayArg1, [arrayArg2], jQueryArg1,
 * [jQueryArg2], plainObjectArg1, [plainObjectArg2]
 * </p>
 * <p> Example: var dialog = new
 * popupmgr.Dialog(closeCallback, $content, {title:"This is the
 * title."});
 */
Dialog = core.subclass(
    PopupBase,
    function Dialog() {
        var me = this,
            o,
            $content,
            argLists = (me._args = core.coalesceArgs(arguments));
        //            console.log("ARGS", me._args);

        var defaultOptions = {
            canDragOffScreen: true,
            preCloseHook: null,
            firstFocusDelay: null,
            firstFocusSelector: '',
            preventTabCycle: false,
            preventEnterHook: false,
            preventEscHook: false,
            // save the focus when dragging dialog or clicks on dialog whitespace
            keepControlFocus: false,
            // preCloseHook: function () {
            //     return $.Deferred().resolve(true).promise();
            // },
        };

        o = me._options = $.extend(me.getDefaultOptions(), defaultOptions, argLists.objects[0] || {});

        me._firstFocusDelay = o.firstFocusDelay;
        me._firstFocusSelector = o.firstFocusSelector;
        me._preventTabCycle = o.preventTabCycle;
        me._preventEnterHook = o.preventEnterHook;
        me._preventEscHook = o.preventEscHook;

        me._divCss = o.divCss || argLists.objects[1] || null;

        o.closeCallback = o.closeCallback || argLists.functions[0] || null;

        $content = argLists.jqueries[0] || null;

        me._minWidth = o.minWidth;
        me._minHeight = o.minHeight;

        me._maxWidthCss = o.maxWidthCss;
        me._maxHeightCss = o.maxHeightCss;

        me._maxResizeHeight = o.maxResizeHeight;
        me._maxResizeWidth = o.maxResizeWidth;

        me._position = o.position;
        me._positioner = o.positioner;
        me._noTransform = !!o.noTransform;

        me._fixSize = o.fixSize;
        me._fixWidth = o.fixWidth;
        me._fixHeight = o.fixHeight;

        me._captureFocus = o.captureFocus;
        me._focusable = o.focusable;
        me._manualFocus = o.manualFocus;

        me._resizable = o.resizable;
        me._resizableX = o.resizableX;
        me._resizableY = o.resizableY;
        me._resizeCallback = o.resizeCallback;

        // For the titlebar background color, first priority is an
        // explicitly-set color.
        let titleBarBackgroundColor = o.titleBarBackgroundColor ?? null;
        if (titleBarBackgroundColor === null) {
            // Second priority is the skinTitleBar option
            if (o.skinTitleBar) {
                titleBarBackgroundColor = config.config.skin?.titleBar ?? null;
            }
            // Default background color comes from the branding.
        }

        me._titleBarBackgroundColor =
            titleBarBackgroundColor !== null ? colorhelper.rgb(titleBarBackgroundColor) : null;

        if (o.canDragOffScreen != null) {
            me.canDragOffScreen = !!o.canDragOffScreen;
        }

        if (o.requiredOverlap != null) {
            me.requiredOverlap = o.requiredOverlap;
        }

        me._draggable = o.draggable;
        me._dragHandle = me.$titleBar = this._buildTitleBar(o.draggable);
        me._modal = o.modal;

        me.$parent = o.$parent || $body;

        PopupBase.call(me, me.$parent);
        me.$div?.attr('data-is', 'Dialog');

        me._hadFirstFocus = false;
        me._$saveFocus = null;

        me._owner = o.owner;
        me._ownedDialogs = [];

        me._externalMousedownListener = me._onExternalMousedown.bind(me);
        me.id = nextId++;
        me._dlgBuild();
        if ($content) {
            me.$body.append($content);
            // On a mobile device, things like scrolling in a div with a swipe
            // will not work if the touch event is converted to a mouse event with
            // a core.DOMTouchHandler. Since PopupBase does that with all touch events
            // that happen within the outer div, we have to prevent ours from reaching it.
            // In the unlikely event that any Dialog $content panels had been
            // relying on touch events being converted to mousevents by the PopupBase's
            // DOMTouchHandler, they will have to create their own.
            $content.on('touchstart touchend touchmove', core.stopImmediatePropagation);
        }

        if (me._owner) {
            me._owner._ownedDialogs.push(me);
        }

        if (o.enableTextInputContextMenu) {
            setTimeout(function () {
                me.enableTextInputContextMenu();
            }, 0);
        }

        // save the focus when dragging dialog or clicks on dialog whitespace
        if (me.keepControlFocus) {
            const and = ':not(.popover):not(.popover-icon):visible';
            const controls = 'button a input select textarea [tabindex]:not([tabindex="-1"])';
            const find = controls.split(' ').join(and + ', ');

            me.$div.on('focus', find, function (e) {
                me._$saveFocus = $(this);
            });
        }

        setTimeout(async function () {
            await me._initPosition();

            const onShown = o.onShown;
            if (onShown) {
                onShown(me.$body);
            }

            const onFocus = o.onFocus;
            if (onFocus) {
                me.$div.on('focus', onFocus);
            }

            const onBlur = o.onBlur;
            if (onBlur) {
                me.$div.on('blur', onBlur);
            }

            // sometimes no one got the focus on onShown, etc
            const $foc = $(':focus');
            if ($foc.length === 0) {
                me._handleFirstFocus();
            } else {
                // leaving this in until done with Dialog first focus implementations
                console.log('focus', $foc);
            }
        }, 0);
    },
    {
        // Dialog.prototype
        _dlgBuild: function () {
            var me = this,
                $div = me.$div,
                o = me._options,
                $titleBar,
                $titleText,
                $body,
                $footer,
                closeListener = me._onCloseButtonClicked.bind(me);

            me._ignoreMousedown = function (evt) {
                // originalEvent is null if evt is generated by jQuery.trigger.
                (evt.originalEvent || {})._idbIgnore = true;
            };

            me.$modalBackdrop.css({
                transition: 'opacity 0.15s linear',
            });

            $div.attr('role', 'dialog')
                .addClass('idb-dialog')
                .addClass(o.dialogClass || '')
                .css({
                    'background-color': '#F2F2F2',
                    'border-radius': '5px 5px 5px 5px',
                    'overflow-x': 'hidden',
                    'overflow-y': 'hidden',
                    display: 'flex',
                    'flex-flow': 'column nowrap',
                    'box-sizing': 'border-box',
                    'align-items': 'stretch',
                });

            if (!o.modal) {
                $div.css({ 'background-color': '#fafafa' });
            }

            if (o.fullWidth) {
                $div.css({ width: 'calc(100vw - 60px)' });
            }

            if (o.fullHeight) {
                $div.css({ height: 'calc(100vh - 60px)' });
            }

            if (!me._position && !me._positioner && !me._noTransform) {
                $div.css(transformCss);
            }

            $titleBar = me.$titleBar.appendTo($div);

            $titleText = me.$titleText = $('<div class="idb-dialog-titletext">')
                .css({
                    flex: '1 1 auto',
                })
                .attr('draggable', 'false')
                .appendTo($titleBar)
                .html(o.htmlTitle || core.escapeHtml(o.title || ''));

            if (o.helpSection && o.helpTopic) {
                me._helpMgr = new helpmgr.HelpMgr(o.helpSection);
                me._helpMgr.makeHelpButton(o.helpTopic, $titleBar).on('mousedown', me._ignoreMousedown);
            }

            if (o.closeButton) {
                me.$closeButton = $('<button type="button">')
                    .appendTo($titleBar)
                    .addClass('idb-dialog-closebutton')
                    .addClass(o.closeButtonFirstFocus ? firstFocus : '')
                    .addClass('idb-mobile-icon')
                    .addClass('idb-mobile-icon-close')
                    .on('click', closeListener)
                    .on('mousedown', me._ignoreMousedown);

                if (me._titleBarBackgroundColor) {
                    me.$closeButton.css({
                        color: colorhelper.getSimpleContrastingColor(me._titleBarBackgroundColor).css,
                    });
                }
            }

            if (!o.closeButton && !o.htmlTitle && !o.title) {
                $titleBar.addClass('idb-dialog-titlebar-empty');
            }

            $body = me.$body = $('<div class="idb-dialog-body">').appendTo($div).css({
                position: 'relative',
                flex: '1 1 auto',
                '-webkit-box-flex': '1',
                display: 'flex',
                'flex-flow': 'column nowrap',
                'align-items': 'stretch',
                padding: '16px',
                overflow: 'hidden',
            });

            if (o.footer) {
                if (core.isJQuery(o.footer)) {
                    $footer = o.footer.addClass('idb-dialog-footer').appendTo($div);
                } else {
                    $footer = me.$footer = $('<div>').addClass('idb-dialog-footer').appendTo($div);

                    if (typeof o.footer === 'function') {
                        o.footer($footer);
                    }
                }
            }

            if (o.modal) {
                if (o.closeBackdrop) {
                    me.$modalBackdrop.on('click', closeListener);
                }
            } else {
                if (o.closeExternalMousedown) {
                    $window.off('mousedown', me._externalMousedownListener);
                    $window.on('mousedown', me._externalMousedownListener);
                }
            }

            if (me._divCss) {
                // even though this was applied by PopupBase, apply it again
                // in case we overwrote any of the settings.
                $div.css(me._divCss);
            }
        },
        _buildTitleBar: function (draggable) {
            const me = this,
                $titleBar = $('<div class="idb-dialog-titlebar">')
                    .attr('draggable', 'false')
                    .css({
                        display: 'flex',
                        flex: '0 0 auto',
                        'flex-flow': 'row nowrap',
                        cursor: draggable ? 'all-scroll' : 'default',
                    });

            if (me._titleBarBackgroundColor) {
                const color = colorhelper.getSimpleContrastingColor(me._titleBarBackgroundColor);
                $titleBar.css({ color: color.css, 'background-color': me._titleBarBackgroundColor.css });
            }

            return $titleBar;
        },
        getDefaultOptions: function () {
            return {
                title: null,
                htmlTitle: null,
                modal: true,
                draggable: true,
                resizable: !!config.config.defaultResizableDialogs,
                resizableX: true,
                resizableY: true,
                footer: false,
                resizeCallback: $.noop,
                titleBarBackgroundColor: null,
                skinTitleBar: false,

                // if true, the width of the div is set to calc(100vw - 60px).
                fullWidth: false,
                // if true, the height of the div is set to calc(100vh - 60px).
                fullHeight: false,
                minWidth: 100,
                minHeight: 100,
                closeButton: true,
                closeBackdrop: true,

                // closeExternalMousedown is applicable only for
                // non-modal dialogs. It serves the same function
                // as closeBackdrop does in a modal dialog. If it's
                // true, then any mousedown events that take place
                // outside the dialog will cause the dialog to close.
                closeExternalMousedown: false,

                // If  closeButtonCallback exists,
                // it will be called when the close button is clicked. If it
                // returns truthy, the Dialog will not be closed.
                closeButtonCallback: null,

                // If closeCallback exists, it is called from within the closeDialog method
                // just before the Dialog is closed. Any return value is ignored.
                closeCallback: null,
                fixSize: false,

                fixWidth: false,
                fixHeight: false,

                propagateMousedown: false,
                position: null,
                captureFocus: true,

                // if true, tabindex="0" attribute will be added to div.
                focusable:true,

                // If true, the remove() method will never be called
                // when the dialog is closed via the close button or
                // calling closeDialog. Instead, it will be hidden with the
                // hide method. However, everything else, in terms of callbacks,
                // etc. will function the same
                hideOnClose: false,

                // If true, afer the constructor and any downstream
                // runnable code completes, any
                // text inputs or textareas on the dialog
                // will have the context menu enabled when
                // a user right-clicks on them.
                enableTextInputContextMenu: false,

                resizeFinished: function () {
                    // console.log('default resize finished handler');
                },
            };
        },
        _onExternalMousedown: function (evt) {
            var me = this,
                target = evt.target;

            if (me.$div.has(target).length > 0) {
                return;
            }
            if (
                me._ownedDialogs.some(function (owned) {
                    return owned.$div.has(target).length > 0;
                })
            ) {
                return;
            }
            $window.off('mousedown', me._externalMousedownListener);
            me.closeDialog();
        },
        _onCloseButtonClicked: function (evt) {
            var me = this,
                o = me._options,
                closeButtonCallback = o.closeButtonCallback || null,
                preventClose = false;
            if (closeButtonCallback !== null) {
                preventClose = closeButtonCallback() || false;
            }
            if (preventClose) {
                return; // not closed.
            }
            me.closeDialog();
        },

        /**
         * Attempt to close this dialog.
         *
         * @param {boolean} [bypassHooks] Should preclose hooks be skipped?
         * @param {boolean} [isCanceled] Was this close the result of a cancel?
         * @returns {Promise<boolean>} Was the dialog closed
         */
        closeDialog: async function (bypassHooks = false, isCanceled = false) {
            const { _options: options, _owner: owner } = this;
            const { closeCallback, hideOnClose, preCloseHook } = options;
            const useHook = !bypassHooks && preCloseHook != null;
            const closingAllowed = !useHook || (await preCloseHook(this, !!isCanceled));

            if (closingAllowed) {
                if (owner) {
                    var i = owner._ownedDialogs.indexOf(this);
                    if (i >= 0) {
                        owner._ownedDialogs.splice(i, 1);
                    }
                }

                if (closeCallback !== null) {
                    closeCallback(!!bypassHooks, !!isCanceled);
                }

                if (hideOnClose) {
                    this.hide();
                } else {
                    this.remove();
                }

                // we were closed.
                return true;
            } else {
                return false;
            }
        },

        enableTextInputContextMenu: function () {
            core.enableTextInputContextMenu(this.$div);
        },
        _handleEscapeKey(evt) {
            const me = this;
            if(me._preventEscHook) {
                return;
            }
            evt.preventDefault();
            evt.stopImmediatePropagation();
            this.closeDialog(false, true);
        },
        _handleCtrlEnterKey(evt) {
            const me = this, div = me.$div[0], target = evt.target;

            if(target.classList.contains(keyCtrlEnterFocusClick)) { // js-key-ctrl-enter-focus-click
                evt.preventDefault();
                evt.stopImmediatePropagation();
                target.click();
                return;
            }

            const buttons = div.querySelectorAll("." + keyCtrlEnter); // ".js-key-ctrl-enter
            if(buttons.length === 0) {
                return;
            }
            if(buttons.length > 1) {
                console.error("More than one descendant element has the class js-key-ctrl-enter.", buttons);
                return;
            }
            const btn = buttons[0], styleMap = btn.computedStyleMap(),
                visibility = styleMap.get("visibility")?.value,
                display = styleMap.get("display")?.value;

            if(btn.disabled || visibility === "hidden" || display === "none") {
                return;
            }
            evt.preventDefault();
            evt.stopImmediatePropagation();
            btn.focus();
            btn.click();
        }
    }
);

/*globals $, PopupBase, normalizeOptions, nextId:true, transformCss, config, colorhelper, bundle, jQuery, MsgBox:true, core, firstFocus */
/*jshint esversion: 11*/
/*jshint strict:implied */

var stdButtons = {
    ok: true,
    yes: true,
    no: true,
    cancel: true,
};

MsgBox = core.subclass(
    PopupBase,
    function MsgBox() {
        var me = this,
            o,
            argLists = (me._args = core.coalesceArgs(arguments));
        //            console.log("ARGS", me._args);
        me.$parent = argLists.jqueries[0] || $('body');

        o = me._options = normalizeOptions(me._args, argLists.objects[0], me.getDefaultOptions());

        me._divCss = argLists.objects[1] || null;

        o.closeCallback = o.closeCallback || argLists.functions[0] || null;

        //            $content = argLists.jqueries[0] || null;

        me._position = o.position;
        me._positioner = o.positioner;
        me._minWidth = o.minWidth;
        me._minHeight = o.minHeight;

        me._maxWidth = o.maxWidth;
        me._maxHeight = o.maxHeight;

        me._maxWidthCss = o.maxWidthCss;
        me._maxHeightCss = o.maxHeightCss;
        me._fixSize = o.fixSize;
        me._captureFocus = o.captureFocus;
        me._modal = o.modal;
        me._resizable = o.resizable;
        me._preventEscHook = o.preventEscHook;

        PopupBase.call(me, me.$parent);
        me.id = nextId++;
        me._mbBuild();
        setTimeout(me._initPosition.bind(me));
    },
    {
        // MsgBox.prototype
        _mbBuild: function () {
            var me = this,
                $div = me.$div,
                o = me._options,
                $titleBar,
                $titleText,
                $body,
                $buttonBar,
                closeListener = me.closeDialog.bind(me);

            me.$modalBackdrop.css({
                transition: 'opacity 0.15s linear',
            });

            $div.addClass('idb-msgbox').css({
                'border-radius': '5px 5px 5px 5px',
                'overflow-x': 'hidden',
                'overflow-y': 'hidden',
                display: 'flex',
                'flex-flow': 'column nowrap',
                'box-sizing': 'border-box',
                'align-items': 'stretch',
            });

            if (!me._position && !me._positioner && !me._noTransform) {
                $div.css(transformCss);
            }

            $titleBar = me.$titleBar = $('<div class="idb-msgbox-titlebar">')
                .css({
                    display: 'flex',
                    'flex-flow': 'row nowrap',
                    'align-items': 'center',
                    cursor: 'all-scroll',
                })
                .appendTo($div);

            // For the titlebar background color, first priority is an
            // explicitly-set color.
            let titleBarBackgroundColor = o.titleBarBackgroundColor ?? null;
            if (titleBarBackgroundColor === null) {
                // Second priority is the skinTitleBar option
                if (o.skinTitleBar) {
                    titleBarBackgroundColor = config.config.skin?.titleBar ?? null;
                }
                // Default background color comes from the branding.
            }

            if (titleBarBackgroundColor !== null) {
                const titleBarRgb = colorhelper.rgb(titleBarBackgroundColor),
                    color = colorhelper.getSimpleContrastingColor(titleBarRgb);
                $titleBar.css({ color: color.css, 'background-color': titleBarRgb.css });
            }

            $titleText = me.$titleText = $('<div class="idb-msgbox-titletext">')
                .css({
                    flex: '1 1 auto',
                })
                .appendTo($titleBar)
                .html(o.htmlTitle || core.escapeHtml(o.title || ''));

            if (o.closeButton) {
                me.$closeButton = $('<button type="button">')
                    .appendTo($titleBar)
                    .addClass('idb-dialog-closebutton')
                    .addClass('idb-mobile-icon')
                    .addClass('idb-mobile-icon-close')
                    .on('click', closeListener)
                    .on('mousedown', core.stopImmediatePropagation);
            }

            if (!o.closeButton && !o.htmlTitle && !o.title) {
                $titleBar.addClass('idb-msgbox-titlebar-empty');
            }

            $body = me.$body = $('<div class="idb-msgbox-body">')
                .appendTo($div)
                .css({
                    position: 'relative',
                    flex: '1 1 auto',
                    '-webkit-box-flex': '1',
                    display: 'flex',
                    'flex-flow': 'column nowrap',
                    'align-items': 'stretch',
                    padding: '16px',
                    overflow: 'auto',
                    'user-select': 'text',
                })
                .on('mousedown', core.stopImmediatePropagation)
                .on('contextmenu', core.stopPropagation)
                .html(o.htmlMessage);

            $buttonBar = me.$buttonBar = $('<div>')
                .addClass('idb-msgbox-buttonbar')
                .appendTo($div)
                .on('mousedown', core.stopImmediatePropagation)
                .css({
                    flex: '0 0 auto',
                    display: 'flex',
                    'flex-flow': 'row nowrap',
                    //                    "justify-content":"center",
                    //                    "padding":"5px"
                });

            me._createButtons();
            if (o.closeBackdrop) {
                me.$modalBackdrop.on('click', closeListener);
            }
        },
        getDefaultOptions: function () {
            return {
                modal: true,
                buttons: ['ok'],
                type: 'info',
                minWidth: 200,
                minHeight: 100,
                maxWidth: 400,
                maxHeight: 480,
                maxWidthCss: '400px',
                maxHeightCss: '240px',
                maxResizeWidth: 100000,
                maxResizeHeight: 100000,
                fixSize: false,
                position: null,
                resizable: !!config.config.defaultResizableDialogs,
                captureFocus: true,
                titleBarBackgroundColor: null,
                skinTitleBar: false,
            };
        },
        _createButtons: function () {
            var me = this,
                buttons = me._options.buttons,
                $buttonBar = me.$buttonBar;

            buttons.forEach(function (cfg, buttonIdx) {
                var $btn = me._$createButton(cfg, buttonIdx);
                $btn.appendTo($buttonBar);
                $btn.on('click', function () {
                    me._buttonClicked($btn);
                });
                $btn.on('mousedown', core.stopImmediatePropagation); // Don't invoke dragging
            });
        },
        _$createButton: function (cfg, buttonIdx) {
            var me = this,
                options = me._options;

            var $btn = $('<button>').addClass('idb-msgbox-btn');

            if (typeof cfg === 'string') {
                if (!options.firstFocusButton) {
                    if (buttonIdx === 0) {
                        $btn.addClass(firstFocus);
                    }
                } else {
                    if (options.firstFocusButton === cfg) {
                        $btn.addClass(firstFocus);
                    }
                }
            }

            if (cfg instanceof jQuery) {
                return cfg;
            }

            $btn.data('config', cfg);

            if (stdButtons[cfg]) {
                $btn.text(bundle.format('button.' + cfg)).addClass('idb-msgbox-btn-' + cfg);
                return $btn;
            }

            if (cfg.className) {
                $btn.addClass(cfg.className);
            }

            $btn.text(cfg.label || 'XXX');

            return $btn;
        },

        _buttonClicked: function ($btn) {
            var me = this,
                callback = me._options.callback,
                cfg = $btn.data('config') || $btn,
                stayOpen = false;

            try {
                if (callback) {
                    stayOpen = callback(cfg);
                }
            } finally {
                // Always close the dialog even if there was an error thrown
                // from the callback.
                if (!stayOpen) {
                    me.remove();
                }
            }
        },

        /**
         * Attempt to close this dialog.
         *
         * @param {boolean} [bypassHooks]
         *     Should preclose hooks be skipped? Does nothing for MsgBox
         * @param {boolean} [isCanceled] Was this close the result of a cancel?
         * @returns {Promise<boolean>} Was the dialog closed
         */
        closeDialog: async function (bypassHooks = false, isCanceled = false) {
            var me = this,
                o = me._options,
                closeCallback = o.closeCallback || null;
            me.remove();

            if (closeCallback !== null) {
                closeCallback(!!bypassHooks, !!isCanceled);
            }

            return true;
        },
        _handleEscapeKey(evt) {
            const me = this;
            if(me._preventEscHook) {
                return;
            }
            evt.preventDefault();
            evt.stopImmediatePropagation();
            this.closeDialog(false, true);
        }
    }
);

/*global AutoClose:true, exports, core, PopupBase, $body, normalizeOptions, $, deleteIconDarkUrl, deleteIconLightUrl */
'use strict';
(function () {
    ///////////////////////////////////////////[DOC]//
    /**
     *  THIS REPLACES: autoclose.js
     *  ( <YOUR_REPO>\Java\trunk\webapps\idbdata\js\modules\autoclose.js )
     *
     *  TODO: Implement Cardinal Directions.
     *        E.g: "NE","SE", etc.
     *
     */ //////////////////////////////////////[DOC]//
    AutoClose = core.subclass(
        PopupBase,
        function AutoClose() {
            var me = this,
                o,
                argLists = (me._args = core.coalesceArgs(arguments));
            //    console.log("ARGS", me._args);
            me.$parent = argLists.jqueries[0] || $body;
            o = me._options = normalizeOptions(me._args, argLists.objects[0], me.getDefaultOptions());
            me._minWidth = o.minWidth;
            me._maxWidthCss = o.maxWidthCss;
            me._minHeight = o.minHeight;
            me._maxHeightCss = o.maxHeightCss;
            me._position = o.position;
            me._positioner = o.positioner;
            me._resizable = o.resizable;
            me._modal = false; // autoclose is never modal.
            me._fixSize = o.fixSize;
            PopupBase.call(me, me.$parent); // call, not apply, is correct
            me._timeoutId = 0;
            me._pinned = false;
            me._build();
            me._initPosition();
        },
        {
            getDefaultOptions: function () {
                return {
                    pinned: false,
                    position: null,
                    showMillis: 2000,
                    fadeMillis: 2000,
                    minWidth: 150,
                    minHeight: 40,
                    maxWidthCss: '350px',
                    maxHeightCss: '350px',
                    fixSize: false,
                    resizable: !!config.config.defaultResizableDialogs,
                    $content: null,
                    // hideOnClose - a value of true means that when the autoclose is closed by the button
                    // or completion of the fade, it will not be destroyed, but rather it will
                    // be hidden with the hide() method and can be reshown with the show() method.
                    hideOnClose: false,
                    closeHandler: $.noop,
                };
            },
            _build: function () {
                var me = this,
                    $div = me.$div,
                    options = me._options,
                    $close,
                    $content = (me.$content = $('<div>').appendTo($div)),
                    $rh = me._resizeHandle ? me._resizeHandle.$div : null,
                    border = '1px solid #58595B';

                $content
                    .css({
                        flex: '1 1 auto',
                        padding: '8px',
                        'background-color': '#FAFAFA',
                        border: border,
                        'border-radius': '5px',
                        'overflow-x': 'auto',
                        'overflow-y': 'auto',
                    })
                    .on('click', me.pin.bind(me));

                if (options.$content) {
                    $content.append(options.$content);
                } else {
                    $content.html(options.htmlMessage);
                }

                if ($rh) {
                    $rh.css({
                        'border-right': border,
                        'border-bottom': border,
                        'border-radius': '5px 0px',
                    });
                }

                $div.css({
                    'border-radius': '5px',
                });

                $close = me.$close = $('<button>')
                    .appendTo($div)
                    .css({
                        'z-index': '20',
                        'background-image': deleteIconDarkUrl,
                        'box-sizing': 'border-box',
                        position: 'absolute',
                        right: '-20px',
                        top: '-20px',
                        width: '28px',
                        height: '28px',
                        'text-align': 'center',
                        'line-height': '1.0',
                        'border-radius': '20px',
                        'background-color': '#DDDDDD',
                        'background-repeat': 'no-repeat',
                        'background-size': '60% 60%',
                        'background-position': 'center',
                        'box-shadow': '#888888 2px 2px 3px',
                    })
                    .idbVisible(true)
                    .on('mouseover', function () {
                        $close.css({
                            'background-image': deleteIconLightUrl,
                            'background-color': '#58595B',
                        });
                    })
                    .on('mouseout', function () {
                        $close.css({
                            'background-image': deleteIconDarkUrl,
                            'background-color': '#DDDDDD',
                        });
                    })
                    .on('click', me._onCloseClicked.bind(me))
                    .on('mousedown', function (evt) {
                        evt.stopImmediatePropagation();
                    }); // Remove from drag handling

                $div.on('mouseover', me._onMouseOver.bind(me));
                $div.on('mouseout', me._onMouseOut.bind(me));

                me._pinned = options.pinned;
                me._onMouseOut();
            },
            _onCloseClicked: function (evt) {
                var me = this;
                if (me._options.hideOnClose) {
                    me.hide();
                } else {
                    me.remove();
                }
                me._options.closeHandler();
            },
            _onMouseOver: function (evt) {
                this._stopFade();
            },
            _onMouseOut: function () {
                var me = this;
                if (!me._pinned) {
                    me._fadeAndClose();
                }
            },
            _fadeAndClose: function () {
                var me = this,
                    $div = me.$div,
                    o = me._options;
                me._timeoutId = setTimeout(function () {
                    // $div.fadeOut will set display:none on $div.
                    $div.fadeOut(o.fadeMillis, function () {
                        if (o.hideOnClose) {
                            me.hide();
                        } else {
                            me.remove();
                        }
                    });
                }, me._options.showMillis);
            },
            _stopFade: function () {
                var me = this,
                    $div = me.$div;
                clearTimeout(me._timeoutId);
                $div.stop();
                $div.css({ opacity: 1 });
            },
            pin: function () {
                var me = this;
                me._pinned = true;
                me._stopFade();
            },
            hide: function () {
                var me = this;
                me.$close.idbHide();
                PopupBase.prototype.hide.apply(me, Array.prototype.slice.call(arguments));
            },
            show: function () {
                var me = this;
                me.$div.css({ display: 'flex' });
                me.$close.idbShow();
                PopupBase.prototype.show.apply(me, Array.prototype.slice.call(arguments));
                me.pin();
            },
            resizeHandleMouseDown: function () {
                this.pin();
            },
            close: function () {
                this._onCloseClicked();
            },
        }
    );
})();

/*global Menu:true, core, exports, $, $body, $window */
'use strict';

class Menu {
    constructor() {
        var me = this,
            argLists = core.coalesceArgs(arguments);
        me.$toggler = argLists.jqueries[0] || null;
        me.$content = argLists.jqueries[1] || null;
        me.$parent = argLists.jqueries[2] || $body;
        me._items = argLists.arrays[0] || null;
        me._positioner = argLists.functions[0] || null;
        me._callback = argLists.functions[1] || null;
        me._cancelCallback = argLists.functions[2] || null;

        const options = argLists.objects[0];
        const defaultOptions = {
            containerClass: '',
            onHidden() {
                // console.debug('default on hidden');
            },
            onShown() {
                // console.debug('default on shown');
            },
        };
        this.options = { ...defaultOptions, ...options };
        // console.debug('menu options', this.options);
        me._togglerClickListener = $.noop;
        me._build();
    }

    _build() {
        var me = this,
            $toggler = me.$toggler,
            $content = me.$content,
            items = me._items;
        const { options } = this;
        const { containerClass } = options;

        const $div = $('<div>', { draggable: 'false' })
            .addClass('idb-menu-container')
            .addClass(containerClass)
            .css({ display: 'none', 'z-index': core.nextZIndex() })
            .on('mousedown contextmenu', core.stopImmediatePropagation)
            .appendTo(me.$parent);
        me.$div = $div;

        if ($toggler) {
            $toggler.on('click', (me._togglerClickListener = me.toggle.bind(me)));
        }

        if ($content) {
            $content.appendTo($div);
        } else if (items) {
            me._buildItemList(items);
        }

        me._windowMousedownListener = me._onWindowMousedown.bind(me);

        me._destroyed = false;
        me._visible = false;
    }

    setParent($parent) {
        const me = this,
            $div = me.$div;
        $div.detach();
        me.$parent = $parent || null;
        if ($parent) {
            $div.appendTo($parent);
        }
    }

    _buildItem(item) {
        var me = this,
            $item /*: JQuery */;

        $item = $('<li>')
            .addClass('idb-menu-list-item')
            .css({ display: item.hidden ? 'none' : 'block' })
            .text(item.label)
            .idbDecorate(item)
            .on('click', function (evt) {
                if (me._callback) {
                    me._callback(item, evt);
                }
            });

        return $item;
    }

    _buildItemList(items) {
        var me = this,
            $div = me.$div,
            $ul;

        $ul = $('<ul>').addClass('idb-menu-list').appendTo($div);

        $ul.append(items.map(me._buildItem.bind(me)));
    }

    _onWindowMousedown(evt) {
        var me = this,
            $toggler = me.$toggler,
            target = evt ? evt.target : null;

        // if the mousedown event was on our $toggler button, ignore it, because the
        // subsequent click event will hide the menu. Otherwise, let the mousedown
        // event propagate for other code that might be responding to it. (Like closing other
        // menus that are open.)
        if (target && $toggler && ($toggler.is(target) || $toggler.has(target).length > 0)) {
            return;
        }
        core.killEvent(evt);
        $window.off('mousedown', this._windowMousedownListener);
        me.hide();
        if (me._cancelCallback) {
            me._cancelCallback();
        }
    }

    toggle(maybeEvent) {
        var me = this;
        core.killEvent(maybeEvent); // prevent #hash from being appended to URL in address bar.
        if (me._visible) {
            me.hide();
        } else {
            me.show();
        }
    }

    show(maybeEvent) {
        var me = this,
            $div = me.$div;
        const { options } = this;
        const { onShown } = options;
        core.killEvent(maybeEvent); // prevent #hash from being appended to URL in address bar.
        if (me._visible) {
            return;
        }

        $div.css({ display: '' });

        if (typeof me._positioner === 'function') {
            me._positioner(me);
        }

        $window.off('mousedown', me._windowMousedownListener);
        $window.on('mousedown', me._windowMousedownListener);

        me._visible = true;
        onShown();
    }

    showAtBodyXY(bodyX, bodyY) {
        const me = this,
            $mdiv = me.$div,
            $parent = me.$parent,
            parentW = $parent.outerWidth(),
            parentH = $parent.outerHeight(),
            menuXY = core.nodeToNode($body[0], bodyX, bodyY, $parent[0]),
            mw = $mdiv.outerWidth(),
            mh = $mdiv.outerHeight();

        if (me._visible) {
            return;
        }

        let adjX = Math.max(0, Math.min(menuXY.x + 5, parentW - mw)),
            adjY = Math.max(0, Math.min(menuXY.y + 5, parentH - mh));

        // Always have the menu either completely above or below
        // the point.
        if (adjY < menuXY.y + 1) {
            adjY = Math.max(0, menuXY.y - 5 - mh);
        }

        $mdiv.idbXY(adjX, adjY);

        $mdiv.css({ display: '' });

        $window.off('mousedown', me._windowMousedownListener);
        $window.on('mousedown', me._windowMousedownListener);

        me._visible = true;
    }

    hide() {
        var me = this,
            $div = me.$div;
        const { options } = this;
        const { onHidden } = options;
        me._visible = false;
        $window.off('mousedown', me._windowMousedownListener);
        $div.css({ display: 'none' });
        onHidden();
    }

    destroy() {
        var me = this,
            $div = me.$div,
            $toggler = me.$toggler;

        if (me._destroyed) {
            console.warn('Menu.destroy() called multiple times.', this);
            return;
        }
        // console.log("Menu.destroy()");
        me._destroyed = true;
        $window.off('mousedown', me._windowMousedownListener);
        if ($toggler) {
            $toggler.off('click', me._togglerClickListener);
            $toggler.off('mousedown', core.killEvent);
        }
        $div.remove();
    }
}

/*global SuppressibleMsgBox:true, exports, core, MsgBox, $, bundle */

class SuppressibleMsgBox extends MsgBox {
    /**
     * @param  {string | null}   name
     * Note: If null is passed as the name argument (the first argument to the
     * constructor), the user's checkbox selection will not be
     * persisted in localStorage. However it will, as usual, be passed as the first argument to
     * the callback.
     *
     * @param  {string}   msg      The content of the dialog.
     * @param  {string}   caption  The dialog title.
     * @param  {SuppressibleCallback} callback Called when a button is clicked.
     * @param  {MsgBoxButtonCfg[]}   buttons  A list of buttons to present to the user.
     * @param  {JQuery}   $parent  The element to add the message box to.
     * @param  {Partial<MsgBoxConfig>}   options  Extra options for the message box.
     * The suppressibleButtons option is an array that can contain one or more of:
     * "yes", "no", "ok", "cancel"
     * If standard buttons are used, these are the onese that will be enabled when the user checks
     * the "don't show again" box. This means they can only suppress the message when they choose
     * a particular option.
     */
    constructor(name, msg, caption, callback, buttons, $parent, options) {
        super(msg, caption, callback, buttons, $parent, options);

        this._name = name;
        this.checked = false;
    }

    _mbBuild() {
        var me = this,
            o = me._options,
            id = '' + Date.now();
        super._mbBuild();
        const $container = $('<div>')
            .on('mousedown', core.stopPropagation)
            .css({
                'padding-bottom': '5px',
                'padding-left': '16px',
                display: 'flex',
                'flex-flow': 'row-nowrap',
                'align-items': 'center',
                'justify-content': 'flex-start',
            })
            .insertBefore(me.$buttonBar);

        this._$suppressCheck = $('<input type="checkbox">')
            .attr('id', id)
            .css({
                'margin-right': '8px',
            })
            .addClass('idb-checkbox checkbox')
            .on('click', function (evt) {
                me.checked = evt.target.checked;
                me._updateButtons(me.checked);
                if (me._name !== null) {
                    localStorage.setItem(me._name, String(!!me.checked));
                }
            })
            .appendTo($container);

        $('<label>').text(o.noShowText).attr('for', id).appendTo($container);
    }

    _buttonClicked($btn) {
        var me = this,
            callback = me._options.callback,
            cfg = $btn.data('config') || $btn,
            stayOpen = false;
        if (callback) {
            stayOpen = callback(cfg, !!me.checked);
        }

        if (!stayOpen) {
            me.remove();
        }
    }

    getFirstFocusElement() {
        return this._$suppressCheck[0] ?? null;
    }

    getDefaultOptions() {
        return $.extend(
            { name: 'suppressDialog', noShowText: bundle.format('label.dont_show_again') },
            super.getDefaultOptions()
        );
    }

    closeDialog() {
        var me = this,
            o = me._options,
            closeCallback = o.closeCallback || null;
        me.remove();
        if (closeCallback !== null) {
            closeCallback(!!me.checked);
        }
    }

    _updateButtons(boxChecked) {
        var me = this,
            $div = me.$div,
            o = me._options,
            suppressibleButtons = o.suppressibleButtons || null;
        if (!suppressibleButtons || !suppressibleButtons.length) {
            return;
        }
        if (boxChecked) {
            $('button.idb-msgbox-btn', $div).prop('disabled', true);
            suppressibleButtons.forEach(function (btnCode) {
                $('button.idb-msgbox-btn-' + btnCode, $div).prop('disabled', false);
            });
        } else {
            $('button.idb-msgbox-btn', $div).prop('disabled', false);
        }
    }
}


export function autoclose(msg, $parent) {
    return new AutoClose(core.coalesceArgs(arguments));
}

export function show(msg, caption, msgType, callback, buttons, $parent, options) {
    return new MsgBox(msg, caption, msgType, callback, buttons, $parent, options);
}

/**
 * This is designed as a drop-in replacement for
 * bootbox.alert. The first argument is the message to
 * display, and the second argument is the callback function.
 * The callback will be called when the alert is dismissed, and
 * it will not be passed any arguments. The alert method will
 * also accept an options argument. The return value of this
 * function is the MsgBox that is displayed.
 */
export function alert(...args /*: any[] */) {
    var argLists = core.coalesceArgs(arguments),
        callback = argLists.functions.shift() || $.noop;
    argLists.arrays.unshift(['ok']);
    argLists.functions.unshift(function (btn) {
        callback();
    });
    return show(argLists);
}

/**
 * This is designed as a drop-in replacement for
 * bootbox.confirm. The first argument is the message to
 * display, and the second argument is the callback function.
 * The callback is passed true if OK is clicked, or false if
 * Cancel is clicked. The confirm method will also accept an
 * options argument.
 */
export function confirm(...args /*: any[] */) {
    var argLists = core.coalesceArgs(arguments),
        callback = argLists.functions.shift() || (argLists.objects[0] || {}).callback || $.noop;
    argLists.arrays.unshift(['ok', 'cancel']);
    argLists.functions.unshift(function (btn) {
        callback(btn === 'ok');
    });
    show(argLists);
}

/**
 * This is designed as a drop-in replacement for
 * IDBDATA.yesNoConfirm. The first argument is the message to
 * display, and the second argument is the callback function.
 * The callback is passed true if Yes is clicked, or false if
 * No is clicked. The confirm method will also accept an
 * options argument.
 */
export function yesNoConfirm(...args /*: any[] */) {
    var argLists = core.coalesceArgs(arguments),
        callback = argLists.functions.shift() || (argLists.objects[0] || {}).callback || $.noop;
    argLists.arrays.unshift(['yes', 'no']);
    argLists.functions.unshift(function (btn) {
        callback(btn === 'yes');
    });
    show(argLists);
}

/**
 * Returns an object that can be used as the position option,
 * that will center the popup over the element indicated by the
 * &quot;target&quot; parameter. It will be
 * {my:&quot;center&quot;, at:&quot;center&quot;, of:target}.
 * The target argument can be a jQuery object or selector string
 * or event. See http://api.jqueryui.com/position/.
 */
export function centerOf(target) {
    return {
        my: 'center',
        at: 'center',
        of: target,
    };
}

/**
 * Show a dialog to the user that can be suppressed.
 *
 * @param  {string | null}   name     The key to use to save the state of the
 *                                    suppression to localStorage or null if
 *                                    it should not be saved.
 * @param  {string}   msg      The content of the dialog.
 * @param  {string}   caption  The dialog title.
 * @param  {import('.').SuppressibleCallback} callback Called when a button is clicked.
 * @param  {import('.').MsgBoxButtonConfig[]}   buttons  A list of buttons to present to the user.
 * @param  {JQuery}   $parent  The element to add the message box to.
 * @param  {Partial<import('.').MsgBoxConfig>}   options  Extra options for the message box.
 *
 * @return {SuppressibleMsgBox} The message box instance that was shown to the user.
 */
export function showSuppressible(name, msg, caption, callback, buttons, $parent, options) {
    var modified = function (cfg, checked) {
        if (callback) {
            // The button cfg or code is passed as the second argument.
            callback(checked, cfg);
        }
    };

    return new SuppressibleMsgBox(name, msg, caption, modified, buttons, $parent, options);
}

export function maybeShowSuppressible(name, msg, caption, callback, buttons, $parent, options) {
    if (name && !isSuppressed(name)) {
        var modified = function (cfg, checked) {
            if (callback) {
                // The button cfg or code is passed as the second argument.
                callback(checked, cfg);
            }
        };

        return new SuppressibleMsgBox(name, msg, caption, modified, buttons, $parent, options);
    }
}

export function isSuppressed(name) {
    return localStorage.getItem(name) == 'true';
}

export function unsuppressMessage(name) {
    localStorage.removeItem(name);
}

export function visibleModalBackdropsExist() {
    return document.querySelectorAll(".idb-modal-backdrop-visible:not(.js-visibility-hidden)").length > 0;
}

export function visibleDialogsExist() {
    return document.querySelectorAll(".idb-dialog:not(.js-visibility-hidden)").length > 0;
}

export function isModalDialogShowing() {
    return document.querySelectorAll(".idb-modal-backdrop-visible:not(.js-visibility-hidden)").length > 0;
}

export default {
    AutoClose,
    Dialog,
    Menu,
    MsgBox,
    PopupBase,
    SuppressibleMsgBox,
    alert,
    autoclose,
    centerOf,
    confirm,
    isSuppressed,
    makeCenteringFunction,
    maybeShowSuppressible,
    showSuppressible,
    show,
    unsuppressMessage,
    yesNoConfirm,
    isModalDialogShowing
};
