import core from '../idbcore.js';

/**
 * Display validation checks for keycodes
 */
export const LOG_KEYCODE_CHECKS = false;

export const keyMods = ['c', 'a', 's', ''];
export const keyModPattern = '(|cas|ca|cs|as|c|a|s)';
// prettier-ignore
export const keyNames = ['Escape', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'Backspace', 'Space', 'Insert', 'Home', 'PageUp', '/', 'Tab', '?', 'Delete', 'End', 'PageDown', 'Enter', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'ContextMenu', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp'];
export const keyNamesPattern =
    '(Escape|F1|F2|F3|F4|F5|F6|F7|F8|F9|F10|F11|F12|1|2|3|4|5|6|7|8|9|0|-|=|Backspace|Space|Insert|Home|PageUp|/|Tab|\\?|Delete|End|PageDown|Enter|[a-zA-Z]|ContextMenu|ArrowDown|ArrowLeft|ArrowRight|ArrowUp)';
export const keyCodePattern = new RegExp(`^${keyModPattern}${keyNamesPattern}$`);

/**
 * Use {@link keyCodePattern} to validate `keyCode`.
 *
 * @param {import('.').KeyCode | string} keyCode
 * @returns {boolean} true if valid
 */
export function isValidKeyCode(keyCode) {
    return keyCodePattern.test(keyCode);
}
/**
 * Array of duplicate {@link KeyCode}s, can include invalids.
 *
 * @param {import('.').KeyCodeList} keyCodeList
 * @returns {string[]} duplicates
 */
export function getDuplicateKeyCodes(keyCodeList) {
    const keyCodes = keyCodeList.split(' ');
    const seenKeyCodes = new Set();
    const dupes = [];

    for (let keyCode of keyCodes) {
        if (seenKeyCodes.has(keyCode)) {
            dupes.push(keyCode);
        }
        seenKeyCodes.add(keyCode);
    }
    return dupes;
}
/**
 * Array of Invalid {@link KeyCode}s, can contain duplicates.
 *
 * @param {import('.').KeyCodeList} keyCodeList
 * @returns {string[]} invalids
 */
export function getInvalidKeyCodes(keyCodeList) {
    const keyCodes = keyCodeList.split(' ');
    const invalids = [];

    for (let keyCode of keyCodes) {
        if (!isValidKeyCode(keyCode)) {
            invalids.push(keyCode);
        }
    }
    return invalids;
}
/**
 * Check `keyCodeList` for duplicates and invalids and create console errors.
 *
 * @param {import('.').KeyCodeList} keyCodeList
 * @returns {boolean} true if duplicates or invalids found
 */
export function checkKeyCodes(keyCodeList) {
    const invalids = getInvalidKeyCodes(keyCodeList);
    const dupes = getDuplicateKeyCodes(keyCodeList);
    if (invalids.length) {
        console.error('Invalid KeyCodes:' + invalids.join(' '));
    }
    if (dupes.length) {
        console.error('Duplicate KeyCodes:' + dupes.join(' '));
    }
    return !!(invalids.length || dupes.length);
}
/**
 * Determine if given event has `keys`, where `keys` is a space seperated string of keycodes,
 * where keycode is in the form of &lt;cas modifier&gt;&ltEvent Key Name&gt;
 * Though .key uses ' ' to represent Space, `keys` should use 'Space' for the keyname.
 *
 * @example `sEnter cEnter` or `sa sA`
 *
 * @param {JQuery.KeyUpEvent | JQuery.KeyDownEvent | KeyboardEvent} keyboardEvent
 * @param {import('.').KeyCodeList} keyCodeList - keycode string
 * @returns {boolean} true if event matches any of the keys in the keycode `keys`
 */
export function hasKeys(keyboardEvent, keyCodeList) {
    const e = keyboardEvent;
    // space is the separator for keys and e.key when space is ' ', so this fixes that
    const key = e.key === ' ' ? 'Space' : e.key;

    // do console errors
    if (LOG_KEYCODE_CHECKS) {
        checkKeyCodes(keyCodeList);
    }

    return keyCodeList.split(' ').includes(cas(e) + key);
}

/**
 * Determine if given event is a modifier key only.
 *
 * @param {JQuery.KeyUpEvent | JQuery.KeyDownEvent | KeyboardEvent} keyboardEvent
 * @returns {boolean} true if modifier only
 */
export function isModifierOnly(keyboardEvent) {
    const e = keyboardEvent;
    return e.key === 'Control' || e.key === 'Alt' || e.key === 'Shift' || e.key === 'Meta';
}

/**
 * Mapping of keyboard event modifier keys to corresponding single character representations.
 *
 * @example
 * export function cas(keyEvent) {
 *     return Object.keys(KEY_TO_CAS)
 *         .reduce((accumulator, key) => {
 *             if (keyEvent[key]) {
 *                 accumulator += KEY_TO_CAS[key];
 *             }
 *             return accumulator;
 *          }, "");
 *  }
 *
 * @readonly
 * @see {@link cas}
 *
 * @type {Record<import('.').KeyboardEventModifierKey, string>}
 */
export const KEY_TO_CAS = {
    ctrlKey: 'c',
    metaKey: 'c',
    altKey: 'a',
    shiftKey: 's',
};
Object.freeze(KEY_TO_CAS);

/**
 * Return a string representation of keyboard modifiers from Event
 *
 * @see {@link KEY_TO_CAS}
 *
 * @param {JQuery.KeyUpEvent | JQuery.KeyDownEvent | JQuery.MouseDownEvent | KeyboardEvent} keyboardEvent
 * @return {string} - a series like `cas`, letter order is constant
 */
export function cas(keyboardEvent) {
    return Object.keys(KEY_TO_CAS)
        .reduce((accumulator, key) => {
            if (keyboardEvent[key]) {
                accumulator += KEY_TO_CAS[key];
            }
            return accumulator;
        }, '')
        .replace('cc', 'c'); // apple and the metaKey, understood as 'command' or 'ctrl'
}

/**
 * Use `core.killEvent` and return whether event was repeating
 *
 * @see {@link isRepeating}
 *
 * @param {JQuery.KeyDownEvent |  JQuery.KeyUpEvent } keyboardEvent - the subject event
 * @returns {boolean} true if the event was repeating, else false
 */
export function killRepeating(keyboardEvent) {
    core.killEvent(keyboardEvent);
    return isRepeating(keyboardEvent);
}

/**
 * Determine if event is repeating.
 *
 * @see {@link killRepeating}
 *
 * @param { JQuery.KeyDownEvent | JQuery.KeyUpEvent } keyboardEvent - the subject event
 * @return true if the event was repeating, else false
 */
export function isRepeating(keyboardEvent) {
    return keyboardEvent.originalEvent.repeat;
}
