/*globals $*/
/*jshint esversion: 8*/

/**
 * @module help/helpmgr
 */
import config from "../config.js";
import core from "../idbcore.js";
import popupmgr from "../popup/popupmgr.js";
import forms from "../ui/forms.js";

const $body = $("body"),
    PX = "px",
    cache = {};

function triggerCloseAll() {
    $body.trigger("idbhelp.closeall");
}

/**
 * HelpMgr is a class that manages help functionality.
 * It provides methods for loading help content, creating help buttons, and displaying help panels.
 *
 * <p>An instance of this class loads from the server a
 * server-rendered HTML page containing online documentation.
 * It can extract portions of that documentation and display
 * them in non-modal popup dialogs.</p>
 *
 * <p>
 * The portion of the loaded HTML that is associated with a help
 * button is referred to as its topic. The button should have an
 * attribute:<br><br>
 * data-topic="my-topic-name"<br><br>
 * and any elements in the documentation page that are part of
 * the topic should have a class named "js-topic-my-topic-name".
 * </p>
 *
 * @class
 * @type {import('.').HelpMgr}
 */
export class HelpMgr {
    /**
     * @param {string} path
     * @param {JQuery<HTMLElement>} [$maybeParent]
     */
    constructor(path, $maybeParent) {
        const me = this,
            divId = "idb-help-section-" + path.replace(/\//g, "-");
        me._path = path;
        me._opens = {};
        me._loaded = false;
        me._nextPopoverId = 0;
        me._promiseControl = new core.PromiseControl();
        me._promise = me._promiseControl.promise();

        me.$div = null;

        $body.on("idbhelp.closeall", me.closeAllPopups.bind(me));

        let $div = $maybeParent || cache[divId] || $("div#" + divId),
            status = $div.attr("data-status") || null,
            loaded = status === "complete",
            pending = status === "pending";

        if (!$maybeParent) {
            if ($div.length == 0) {
                $div = $("<div>")
                    .css({
                        display: "none",
                    })
                    .appendTo($body);
            }
        }

        $div.attr("id", divId);
        me.$div = cache[divId] = $div;

        if (loaded) {
            me._loaded = true;
            me._promiseControl.resolve(me);
            return;
        }

        if (pending) {
            $div.on("idb.helploaded", (evt) => {
                me._loaded = true;
                me._promiseControl.resolve(me);
            });
            return;
        }

        me._load();
    }
    loadCompletion() {
        return this._promise;
    }

    /** @undocumented   */
    _load() {
        const me = this,
            $div = me.$div,
            path = me._path;
        // @ts-ignore OK [ts] '$div' is possibly 'null'.
        $div.attr("data-status", "pending");
        const source = config.config.contextRoot + "help/section/" + path;
        // console.log({source});
        // @ts-ignore OK [ts] '$div' is possibly 'null'.
        $div.load(source, me._onLoaded.bind(me));
    }
    /** @undocumented   */
    _onLoaded() {
        const me = this,
            $div = me.$div;
        // @ts-ignore OK [ts] '$div' is possibly 'null'.
        $div.attr("data-status", "complete");
        // @ts-ignore OK [ts] No overload matches this call.
        $(".js-replicate", $div).each(function (idx, toReplicate) {
            let $toReplicate = $(toReplicate),
                targetTag = $toReplicate.attr("data-replication"),
                // @ts-ignore OK [ts] No overload matches this call.
                $replications = $("." + targetTag, $div);

            // @ts-ignore OK [ts] Property 'html' does not exist on type 'JQueryStatic'.
            $replications.html(toReplicate.innerHTML);
        });

        me._loaded = true;
        me._promiseControl.resolve(me);
        // @ts-ignore OK [ts] '$div' is possibly 'null'.
        $div.trigger("idb.helploaded", [me]);
    }

    /**
     * This creates a help button (in a jQuery wrapper) and links
     * the indicated topic to it.
     *
     * @param {string} topic
     * @param {JQuery | null} $maybeParent
     * @param {string} [formatClass]
     * @returns {JQuery}
     */
    makeHelpButton(topic, $maybeParent, formatClass) {
        const me = this,
            $button = $("<button>")
                .addClass("help-icon")
                .attr({
                    tabindex: "-1",
                    "data-topic": topic,
                    "data-registered": "true",
                    type: "button", // So this doesn't submit forms if clicked
                })
                .on("click", me._onPopupTriggerActivated.bind(me));
        if ($maybeParent) {
            $button.appendTo($maybeParent);
        }
        if (formatClass) {
            $button.attr("data-format-class", formatClass);
        }
        return $button;
    }

    makeHelpUsingButton($btn, topic, isPopover, formatClass) {
        const me = this;
        $btn.attr({
            "data-topic": topic,
            "data-registered": "true",
        });
        if (formatClass) {
            $btn.attr("data-format-class", formatClass);
        }
        if (isPopover) {
            me.makePopoverTrigger(topic, $btn);
        } else {
            $btn.on("click", me._onPopupTriggerActivated.bind(me));
        }
        return $btn;
    }

    /**
     * This will find all of the buttons descending from $ancestor
     * and add the event listener that will pop up their dialog when
     * they're clicked.
     *
     * @param $ancestor
     */
    registerHelpButtons($ancestor) {
        const me = this,
            $matches = $ancestor.find("button.help-icon:not([data-registered=true])");
        $matches.on("click", me._onPopupTriggerActivated.bind(me)).attr({
            "data-registered": "true",
        });
    }
    makePanelForTopic(topic) {
        const me = this,
            $div = me.$div,
            // @ts-ignore OK [ts] '$div' is possibly 'null'.
            $d = $div.find(".js-topic-" + topic),
            innerHTML = $d[0] ? $d[0].innerHTML : '<p>Missing Topic: <span class="js-missing-topic"></span></p>',
            $panel = $("<div>").addClass("help-dialog").css({
                overflow: "auto",
            }),
            panelTitle = $d.data("popup-title") || "";
        if (!$d[0]) {
            console.log("Missing topic: " + topic);
        }

        $panel.html(innerHTML).find("span.js-missing-topic").text(topic);

        // we have to put this back because it won't be included in innerHTML.
        $panel.data("popup-title", panelTitle);
        me._addPanelEventListeners($panel);
        return $panel;
    }
    _addPanelEventListeners($panel) {
        const me = this;
        // This is needed so the same JSP can be used by this class and
        // the older helppanel.js in the ETL job editor.
        $panel.find(".idb-popover-link").removeClass("idb-popover-link");
        $panel.find(".idb-popout-link").removeClass("idb-popout-link");

        $panel.on("mouseover", ".help-popover-link", me._showPopover.bind(me));
        $panel.on("mouseout", ".help-popover-link", me._hidePopover.bind(me));
        // prevent "#" from being appended to the URL if they click the link.
        $panel.on("click", "a.help-popover-link", core.killEvent);

        $panel.on("click", ".help-popout-link", me._onPopupTriggerActivated.bind(me));
        return $panel;
    }
    /** @undocumented   */
    _onPopupTriggerActivated(evt) {
        const me = this,
            opens = me._opens,
            topic = evt.target.dataset.topic ? evt.target.dataset.topic : evt.currentTarget.dataset.topic,
            formatClass = $(evt.target).attr("data-format-class") ? 
                $(evt.target).attr("data-format-class") : 
                $(evt.currentTarget).attr("data-format-class");

        if (evt.currentTarget.nodeName === "A") {
            core.killEvent(evt);
        }

        if (!topic) {
            return;
        }

        if (opens[topic]) {
            opens[topic].closeDialog();
            delete opens[topic];
        }

        let $panel = me.makePanelForTopic(topic),
            panelTitle = $panel.data("popup-title") || "";
        if (formatClass) {
            $panel.addClass(formatClass);
        }

        //        let $d = $div.find(".js-topic-" + topic), innerHTML = $d[0] ? $d[0].innerHTML : "<p>Missing Topic: <span class=\"js-missing-topic\"></span></p>",
        //            $panel = $("<div>");
        //
        //        $panel.html(innerHTML).find("span.js-missing-topic").text(topic);

        // @ts-ignore OK [ts] 'popupmgr.Dialog' is possibly 'undefined'.
        let dlg = new popupmgr.Dialog(
            $panel,
            {
                dialogClass: "help-dialog",
                modal: false,
                title: panelTitle,
                resizable: true,
                resizableX: true,
                resizableY: true,
                // Somewhere along the line, the closeCallback became
                // asynchronous, which doesn't work here.
                preCloseHook: () => {
                    delete opens[topic];
                    // This tells the dialog it can close.
                    return $.Deferred().resolve(true).promise();
                },
            },
            { width: "500px" }
        );

        opens[topic] = dlg;
    }
    closeAllPopups() {
        const me = this,
            opens = me._opens,
            topics = Object.keys(opens);

        topics.forEach((topic) => {
            let dlg = opens[topic];
            if (dlg) {
                dlg.closeDialog(true);
            }
            delete opens[topic];
        });
    }
    closeAllPopovers() {
        $("[id^='help-popover-']").remove();
    }
    triggerCloseAll() {
        triggerCloseAll();
    }
    /** @undocumented   */
    _showPopover(evt) {
        const me = this,
            $div = me.$div;
        me._hidePopover(evt);
        const $link = $(evt.target),
            popoverId = me._nextPopoverId++,
            // @ts-ignore OK [ts] Cannot find name 'm$div'. Did you mean '$div'?
            $content = $div.find("#" + $link.attr("data-popover-content-id")),
            $popover = $("<div>")
                .html($content.html())
                .addClass("help-popover")
                .attr("id", "help-popover-" + popoverId)
                .css({
                    "z-index": core.nextZIndex(),
                    overflow: "hidden",
                    position: "fixed",
                    "box-sizing": "border-box",
                    "max-width": "500px",
                    visibility: "hidden",
                    top: "0",
                    left: "0",
                });
        const formatClass = $(evt.target).attr("data-format-class");
        if (formatClass) {
            $popover.addClass(formatClass);
        }
        console.log($content.html());
        $popover.appendTo($body);
        let bh = $body.height(),
            bw = $body.width(),
            // @ts-ignore OK [ts] 'bw' is possibly 'undefined'.
            rightSideAvailable = bw - evt.pageX,
            height = $popover.outerHeight(),
            width = $popover.outerWidth(),
            // @ts-ignore OK [ts] 'bh' is possibly 'undefined'.
            top = Math.max(0, Math.min(bh - height, evt.pageY - height * 0.5)),
            // @ts-ignore OK [ts] 'width' is possibly 'undefined'.
            left = rightSideAvailable > width + 30 ? evt.pageX + 30 : evt.pageX - 20 - width;
        $popover.css({
            top: top + PX,
            left: left + PX,
            width: width + PX,
            height: height + PX,
            visibility: "",
        });

        $link.attr("data-popover-id", String(popoverId));
    }
    /** @undocumented   */
    _hidePopover(evt) {
        const $link = $(evt.target),
            oldPopoverId = $link.attr("data-popover-id");
        if (oldPopoverId) {
            const $pop = $("#help-popover-" + oldPopoverId);
            $pop.fadeOut(200, function () {
                $pop.remove();
            });
        }
        $link.attr("data-popover-id", "");
    }
    makePopoverButton(topic, $maybeParent, formatClass) {
        const me = this,
            $button = $("<button>")
                .attr("tabindex", "-1")
                .attr("type", "button") // So this doesn't submit forms if clicked
                .addClass("help-icon popover-icon");
        me.makePopoverTrigger(topic, $button);
        if($maybeParent instanceof Element) {
            $maybeParent = $($maybeParent);
        }
        if ($maybeParent) {
            $button.appendTo($maybeParent);
        }
        if (formatClass) {
            $button.attr("data-format-class", formatClass);
        }
        return $button;
    }
    makePopoverTrigger(topic, $element) {
        const me = this;
        $element
            .attr({
                "data-popover-topic": topic,
                title: "",
            })
            .on("mouseover", me._showPopover2.bind(me))
            .on("mouseout", me._hidePopover.bind(me));
    }

    makePopoverForTopic(topic) {
        const me = this,
            $div = me.$div,
            // @ts-ignore OK [ts] '$div' is possibly 'null'.
            $d = $div.find(".js-topic-" + topic),
            innerHTML = $d[0] ? $d[0].innerHTML : '<p>Missing Topic: <span class="js-missing-topic"></span></p>',
            $panel = $("<div>");

        if (!$d[0]) {
            console.log("Missing topic: " + topic);
        }

        $panel.html(innerHTML).find("span.js-missing-topic").text(topic);
        return $panel;
    }

    /** @undocumented   */
    _showPopover2(evt) {
        const me = this;
        me._hidePopover(evt);
        const $link = $(evt.target),
            popoverId = me._nextPopoverId++,
            $content = me.makePopoverForTopic($link.attr("data-popover-topic")),
            $popover = $("<div>")
                .html($content.html())
                .addClass("help-popover")
                .attr("id", "help-popover-" + popoverId)
                .css({
                    "z-index": core.nextZIndex(),
                    overflow: "hidden",
                    position: "fixed",
                    "box-sizing": "border-box",
                    "max-width": "500px",
                    visibility: "hidden",
                    top: "0",
                    left: "0",
                });
        //console.log($content.html());
        $popover.appendTo($body);
        const formatClass = $(evt.target).attr("data-format-class");
        if (formatClass) {
            $popover.addClass(formatClass);
        }
        let bh = $body.height(),
            bw = $body.width(),
            // @ts-ignore OK [ts] 'bw' is possibly 'undefined'.
            rightSideAvailable = bw - evt.pageX,
            height = $popover.outerHeight(),
            width = $popover.outerWidth(),
            // @ts-ignore OK [ts] 'bh' is possibly 'undefined'.
            top = Math.max(0, Math.min(bh - height, evt.pageY - height * 0.5)),
            // @ts-ignore OK [ts] 'width' is possibly 'undefined'.
            left = rightSideAvailable > width + 30 ? evt.pageX + 30 : evt.pageX - 20 - width;
        $popover.css({
            top: top + PX,
            left: left + PX,
            width: width + PX,
            height: height + PX,
            visibility: "",
        });

        $link.attr("data-popover-id", String(popoverId));
    }
    //        /** @undocumented   */
    //        _hidePopover2(evt) {
    //            const $link = $(evt.target), oldPopoverId = $link.attr("data-popover-id");
    //            if(oldPopoverId) {
    //                $("#help-popover-" + oldPopoverId).remove();
    //            }
    //            $link.attr("data-popover-id", "");
    //        }
    //
}

/**
 * Applies a fix for labels to handle click and focus events.
 *
 * @param {JQuery<HTMLElement>} $labels - The jQuery object that is a label.
 * @param {boolean} [cursorPointer=true] - Indicates whether to set the cursor as pointer for the label span and toggle elements. Default is true.
 * @param {boolean} [noAutoClick=false] - Indicates whether to disable the automatic click behavior on :standardInputControls. Default is false.
 */
export function labelsClickFocusFix($labels, cursorPointer = true, noAutoClick = false) {
    $labels.each(function () {
        labelClickFocusFix($(this), cursorPointer, noAutoClick);
    });
}

/**
 * Fixes the focus behavior when clicking on a label element. Stop clicks on the label element from doing a flash-focus on HelpMgr help-icon buttons.
 * Handles special case of idb-form-row and form-row.
 * @param {JQuery<HTMLElement>} $label - The label element to fix the focus behavior for.
 * @param {boolean} [cursorPointer=true] - Specifies whether to add a cursor pointer style to the label span and toggle elements.
 * @param {boolean} [noAutoClick=false] - Specifies whether to prevent automatic click behavior on :standardInputControls.
 */
export function labelClickFocusFix($label, cursorPointer = true, noAutoClick = false) {
    const debug = $label.hasClass("debug");
    const wholeLabelClickClass = "idb-whole-label-click";
    const wholeLabelClick = $label.hasClass(wholeLabelClickClass);
    const classFn = "js-label-click-focus-fix";
    const classToggle = "idb-ui-toggle-slider";
    const classCursor = "idb-label-click-focus-fix-cursor";
    const selectHelpButtons = ".help-icon[data-popover-topic], .help-icon[data-topic], .idb-ui-help-button";
    const otherExclusions = ".js-copy-input";
    const classFormRowParents = "idb-form-row form-row form-group label-section".split(" "); // special case for controls which are siblings to the label
    function isClassLikeRow($parent) {
        return classFormRowParents.some((cls) => $parent.hasClass(cls));
    }

    function controlFromLabel($lbl, notDisabled = true) {
        const selectDisabled = notDisabled ? "[disabled], .disabled" : "";
        const $lblParent = $lbl.parent();
        const maybeUseParent = isClassLikeRow($lblParent);
        const $ctrlLabelStandard = $lbl.find(":standardInputControls");
        const $ctrlParentStandard = $lbl.parent().find(":standardInputControls");
        const $ctrlParentSection = $lbl.parent().parent().parent().find(".settings-wrapper").find(":standardInputControls");
        // console.log("$ctrlParentSection", $ctrlParentSection);

        const $ctrl = $ctrlLabelStandard.not(selectHelpButtons).not(selectDisabled).not(otherExclusions).first();
        const $ctrl2 = $ctrlParentStandard.not(selectHelpButtons).not(selectDisabled).not(otherExclusions).first();
        const $ctrl3 = $ctrlParentSection.not(selectHelpButtons).not(selectDisabled).not(otherExclusions).first();
        if (debug) {
            console.log({
                $lbl,
                $ctrl,
                $ctrl2,
                $ctrl3,
                maybeUseParent,
                $lblParent,
                $ctrlLabelStandard,
                $ctrlParentStandard,
                $ctrlParentSection,
            });
            console.log($lblParent.html());
        }
        if ($ctrl.length === 0 && maybeUseParent) {
            if ($ctrl2.length === 0) {
                return $ctrl3;
            }
            return $ctrl2;
        }
        const $ctrl0 = $ctrl.length ? $ctrl : maybeUseParent ? $ctrl2 : $([]);

        return $ctrl0;
    }
    function labelHasControls($lbl, notDisabled = false) {
        return controlFromLabel($lbl, notDisabled).length > 0;
    }

    //

    if (!$label.length) {
        if (debug) {
            console.log("!$label.length return;");
        }
        return;
    }
    if ($label.length > 1) {
        labelsClickFocusFix($label);
        if (debug) {
            console.log("$label.length > 1; labelsClickFocusFix($label); return;");
        }
        return;
    }
    if (!$label.is("label")) {
        if (debug) {
            console.log("!$label.is('label'); labelsClickFocusFix($label); return;");
        }
        console.error("The element is not a label.");
        return;
    }

    if ($label.hasClass(classFn) || $label.hasClass(classToggle)) {
        if (debug) {
            console.log("$label.hasClass(classFn) || $label.hasClass(classToggle); return;");
        }
        return;
    }
    $label.addClass(classFn);

    if (cursorPointer) {
        if (debug) {
            console.log("cursorPointer; $label.addClass(classCursor);");
        }
        $label.addClass(classCursor);
    }

    const hasFor = $label.is("[for]");
    if (hasFor) {
        if (debug) {
            console.log("hasFor return;");
        }
        return;
    }
    if (!labelHasControls($label)) {
        // disable the label default click action
        $label.addClass("idb-label-click-focus-fix-no-controls");
        $label.on("click", (e) => {
            core.stopPropagation(e);
            core.killEvent(e);
        });
        if (debug) {
            console.log("labelHasControls($label) .addClass $label.click killEvent return;");
        }
        return;
    }
    if (wholeLabelClick) {
        $label.css({ cursor: "pointer" });
    }

    $label.on("click", (e) => {
        const $target = $(e.target);
        const $lbl = $(e.currentTarget);
        const $firstDivOrSpan = $lbl.find(">div, >span").first();
        const $lastDivOrSpan = $lbl.find(">div, >span").last();
        if (!$firstDivOrSpan.is($target)) {
            if ($lastDivOrSpan.is($target)) {
            } else {
                if (!wholeLabelClick) {
                    core.killEvent(e);
                    core.stopPropagation(e);
                    return;
                }
            }
        }

        const $ctrl = controlFromLabel($lbl);
        if ($ctrl.length) {
            if ($ctrl.is("[disabled], .disabled")) {
                core.killEvent(e);
                core.stopPropagation(e);
                return;
            }
            if (
                $ctrl.is("button") ||
                $ctrl.is('input[type="button"]') ||
                $ctrl.is('input[type="checkbox"]') ||
                $ctrl.is('input[type="submit"]') ||
                $ctrl.is('input[type="reset"]') ||
                $ctrl.is("a") ||
                $ctrl.is('input[type="radio"]')
            ) {
                if (!noAutoClick) {
                    core.killEvent(e);
                    core.stopPropagation(e);
                    // NOT $ctrl0.trigger('focus').trigger('click'); LOOPS!
                    $ctrl.trigger("focus");
                    $ctrl[0].click();
                }
            } else {
                core.killEvent(e);
                core.stopPropagation(e);
                $ctrl.trigger("focus");
            }
        } else {
            core.killEvent(e);
            core.stopPropagation(e);
        }
    });
}

/**
 * Creates a help button element and appends it to the specified element.
 * @param {HelpMgr} helpMgr - The help manager object.
 * @param {string} topic - The topic of the help button.
 * @param {JQuery<HTMLElement>} $maybeAppendTo - The jQuery element to which the help button will be appended.
 * @param {boolean} [noReveal=false] - Indicates whether the help button should have a reveal class.
 * @returns {JQuery<HTMLElement>} - The created help button element.
 */
export function makeHelpButton(helpMgr, topic, $maybeAppendTo, noReveal = false) {
    const $pop = helpMgr.makeHelpButton(topic, null, "inline-help-formatted").attr("tabindex", "-1");
    const revealClass = noReveal ? "" : "idb-help-icon-reveal";
    if ($maybeAppendTo) {
        $pop.appendTo($maybeAppendTo.addClass(revealClass));
    }
    return $pop;
}
/**
 * Creates a popover button element with the specified topic.
 *
 * @param {HelpMgr} helpMgr - The help manager object.
 * @param {string} topic - The topic for the popover button.
 * @param {JQuery<HTMLElement>} $maybeAppendTo - The optional jQuery element to append the popover button to.
 * @param {boolean} [noReveal=false] - Whether to hide the popover button initially.
 * @returns {JQuery<HTMLElement>} The created popover button element.
 */
export function makePopoverButton(helpMgr, topic, $maybeAppendTo, noReveal = false) {
    const $pop = helpMgr.makePopoverButton(topic, null, "inline-help-formatted").attr("tabindex", "-1");
    const revealClass = noReveal ? "" : "idb-help-icon-reveal";
    if ($maybeAppendTo) {
        $pop.appendTo($maybeAppendTo.addClass(revealClass));
    }
    return $pop;
}

/**
 * Fixes up the label by wrapping the rendered label text with a span element,
 * and optionally adds a popover or help button based on the provided parameters.
 *
 * @param {JQuery<HTMLElement>} $label - The label element to fix up.
 * @param {HelpMgr} helpMgr - The help manager object.
 * @param {string} topic - The topic for the popover or help button.
 * @param {boolean} [isPopover=true] - Indicates whether to create a popover button (default) or a help button.
 * @param {boolean} [noReveal=false] - Indicates whether to prevent the popover or help button from revealing the topic immediately.
 * @returns {JQuery<HTMLElement>} - The popover or help button element.
 */
export function labelFixup($label, helpMgr, topic, isPopover = true, noReveal = false) {
    forms.wrapRenderedLabelTextWithSpan($label);
    let $pop;
    if (helpMgr && topic) {
        $pop = isPopover ? 
            makePopoverButton(helpMgr, topic, $label, noReveal) : 
            makeHelpButton(helpMgr, topic, $label, noReveal);
    } else {
       $pop = $([]);
    }
    labelsClickFocusFix($label);
    return $pop;
}

export default {
    HelpMgr,
    triggerCloseAll,
};
