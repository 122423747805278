import bundle from '../../lang/Z.js';

const { format } = bundle;

/**
 * Returns the object passed to it if non-null, otherwise, throw an error
 *
 * @template {*} T
 * @param {T | null | undefined} obj
 * @returns {T}
 */
export function requireDefined(obj) {
    if (obj != null) {
        return obj;
    } else {
        console.warn('An object was expected to be defined but was null');
        throw new Error(format('err.generic.unexpected_error'));
    }
}
